<template>
    <div class="loginPageWrapper">
            <loading :active.sync="isLoading" 
            :can-cancel="true"        
            :is-full-page="true"
            loader='bars'></loading>

            <div class="col-12 subPageHeader" style="  position:relative;">
                <h1>{{ $t('loginPageTitle') }}</h1>
                <!-- {{ $t('loginPageTitle') }} -->
                <button v-if="!config.FORCE_LOGIN_BEFORE_USE && !config.IS_PURE_ACCOUNTMANAGER" class="btn btn-primary subPageHeader subPageHeaderButton" @click="goBack()">
                    {{$t('back')}}
                </button>
            </div>



            <div class="col-12 loginBlock">
                <div class="col-12 loginLeftBlock">
                    <span class="col-12 loginSubTitle"><h2>{{$t('loginPageTitleLeft')}}</h2></span>

                    <form @submit.prevent="onSubmit">

                    <div class="form-group" style="padding-top: 30px;">
                        <label for="email" class="fontField">{{$t('customerEmail')}}</label>
                        <!-- <input id="email" type="email" class="form-control form-control-lg" v-model="login.email" :placeholder="$t('customerEmail')" /> -->
                        <input id="email" type="email" class="form-control form-control-lg" v-model="login.email"/>
                        <div class="inputError" role="alert" v-if="!$v.login.email.email && submit"><b-icon-exclamation-triangle-fill></b-icon-exclamation-triangle-fill> {{$t('invalidEmail')}}</div>
                    </div>
                    <!-- AP 20220506 added show password button-->
                    <div class="form-group" v-if="!config.SHOW_PASSWORD_VISIBILITY_SWITCH">
                        <label for="password" class="fontField">{{$t('customerPassword')}}</label>
                        <!-- <input id="password" type="password" v-if="hidePassword" class="form-control form-control-lg" v-model="login.password" :placeholder="$t('customerPassword')" />
                        <input type="text" v-else class="form-control form-control-lg" v-model="login.password" :placeholder="$t('customerPassword')"/> -->
                        <input id="password" type="password" v-if="hidePassword" class="form-control form-control-lg" v-model="login.password"/>
                        <input type="text" v-else class="form-control form-control-lg" v-model="login.password"/>
                        <div class="inputError" role="alert" v-if="!$v.login.password.minLength && submit"><b-icon-exclamation-triangle-fill></b-icon-exclamation-triangle-fill> {{ $t('inputErrorPasswordMinLength1') }} {{$v.login.password.$params.minLength.min}} {{$t('inputErrorPasswordMinLength2')}}</div>
                    </div>
                    <div class="form-group" v-else>
                        <label id="passwordLabel" class="fontField">{{$t('customerPassword')}}</label>
                        <b-input-group>
                            <!-- <b-form-input aria-labelledby="passwordLabel" type="password" v-if="hidePassword" class="form-control form-control-lg" v-model="login.password" :placeholder="$t('customerPassword')" />
                            <b-form-input aria-labelledby="passwordLabel" type="text" v-else class="form-control form-control-lg" v-model="login.password" :placeholder="$t('customerPassword')"/> -->
                            <b-form-input aria-labelledby="passwordLabel" type="password" v-if="hidePassword" class="form-control form-control-lg" v-model="login.password"/>
                            <b-form-input aria-labelledby="passwordLabel" type="text" v-else class="form-control form-control-lg" v-model="login.password"/>
                            <b-input-group-append>
                                <b-button variant="outline-secondary" type="button" @click="showPassword()" :aria-label="hidePassword ? $t('showPasswordAriaLabel') : $t('hidePasswordAriaLabel')">
                                    <b-icon-eye-fill v-if="hidePassword" aria-hidden="true" :aria-label="$t('showPasswordAriaLabel')"></b-icon-eye-fill>
                                    <b-icon-eye-slash-fill v-else aria-hidden="true" :aria-label="$t('hidePasswordAriaLabel')"></b-icon-eye-slash-fill>
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                        <div class="inputError" role="alert" v-if="!$v.login.password.minLength && submit"><b-icon-exclamation-triangle-fill></b-icon-exclamation-triangle-fill> {{ $t('inputErrorPasswordMinLength1') }} {{$v.login.password.$params.minLength.min}} {{$t('inputErrorPasswordMinLength2')}}</div>
                    </div>

                    <!-- AP 20220506 Show password prototype
                    <div>
                        <button type="button" @click="showPassword()">
                            <b-icon-eye-fill v-if="hidePassword"></b-icon-eye-fill>
                            <b-icon-eye-slash-fill v-else></b-icon-eye-slash-fill>
                        </button>
                    </div> -->

                    <!-- <div v-if="config.SHOW_LOGIN_REMEMBERME" class="form-group">
                        <check-box                         
                            :label='$t("rememberMe")'
                            :value="1"
                            :trueValue="'1'"
                            :falseValue="'0'"
                            v-model="rememberMe">
                        </check-box>  
                    </div> -->

                    <p class="forgot-password text-left mt-2 mb-4">
                        <router-link class="linkColor" to="/ForgotPassword">{{$t('forgotPasswordPageTitle')}}</router-link>
                    </p>

                    <!-- <div class="col-12"> -->
                    <div class="">
                        <!-- TODO -->
                        <button
                            @click.stop="onClickCaptcha"
                            :disabled="submitStatus === 'PENDING'" class="btn btn-success btn-lg btn-block btnSubmit loginButtonSize g-recaptcha" style="width: 100%">{{$t('customerSignIn')}}</button>
        <!-- type="submit" @click.stop="onSubmit" -->
                        <button type="submit" @click.stop.prevent="loginSSO" v-if="SSOEnabled" :disabled="submitStatus === 'PENDING'" style="width: 100%" class="btn btn-dark btn-lg btn-block btnSubmit loginButtonSize">
                            <!-- <img width="50px" height="35px" style="border-radius:75px;" src="../../assets/images/logo.png" /> -->
                            {{ $t('customerSignInSSO') }}
                        </button>
                    </div>



                    <!-- <p class="typo__p" v-if="submitStatus === 'OK'">Thanks for your submission!</p>
                    <p class="typo__p" v-if="submitStatus === 'ERROR'">Please fill the form correctly.</p>
                    <p class="typo__p" v-if="submitStatus === 'PENDING'">Sending...</p>
                    <p class="typo__p" v-if="formErrorMessage !== ''">{{ formErrorMessage }}</p> -->
                </form>
            </div>

            <div class="col-12 loginRightBlock">

                <span class="col-12 loginSubTitle" ><h2>{{ $t('loginPageTitleRight') }}</h2></span>
                <div style="padding-top: 30px;" v-if="!tableMode" v-html="$t('loginPresentation')"> </div>

                <div style="" class="col-12 loginButtons">
                    <button type="submit" @click="goToRegister(false)" :disabled="submitStatus === 'PENDING'" class="btn btn-primary btn-lg btn-block btn-justify loginButtonSize V2-0-17">{{$t('noAccountYet')}}</button>
                </div>

                <!-- v-if="!tableMode" -->
                <div v-if="!isCartEmpty && config.GUEST_CHECKOUT == true" class="col-12 guestLoginButton">
                    <button type="submit" @click="goToRegister(true)" :disabled="submitStatus === 'PENDING'" class="btn btn-primary btn-lg btn-block btn-justify loginButtonSize">{{$t('orderAsGuest')}}</button>
                </div>

                <!-- <span class="col-12 loginSubTitle" >{{ $t('loginPageTitleRight') }}</span>
                <div v-if="!isCartEmpty && config.GUEST_CHECKOUT == true">
                    <button type="submit" @click="goToRegister(true)" :disabled="submitStatus === 'PENDING'" class="btn btn-primary btn-lg btn-block btn-justify loginButtonSize">{{$t('orderAsGuest')}}</button>
                </div>

                <div style="padding-top: 30px;" v-if="!tableMode" v-html="$t('loginPresentation')"> </div> -->
                <!-- v-if="!tableMode" -->
                <!-- <div class="col-12 loginButtons">
                    <button type="submit" @click="goToRegister(false)" :disabled="submitStatus === 'PENDING'" class="btn btn-primary btn-lg btn-block btn-justify loginButtonSize">{{$t('noAccountYet')}}</button>
                </div>                    -->
           </div>
        </div>
    </div>
</template>

<script>
import { required, minLength, email } from 'vuelidate/lib/validators'
import $ from 'jquery'
import axios from 'axios'
import { BIconEyeFill, BIconEyeSlashFill, BIconExclamationTriangleFill } from 'bootstrap-vue'
import Swal from 'sweetalert2'
import VueLoading from 'vue-loading-overlay'
// import CheckBox from '../sub-components/CheckBoxCustomer.vue'

// eslint-disable-next-line no-unused-vars
function goToForgotPasswort2(){
    Swal.close();
    window.location.href = "/dist/#/ForgotPassword";
}

export default {
    data() {
        return {
            submitStatus: null,
            alert: {
                message: "hello world"
            },
            login: {
                email: "",
                password: ""
            },
            formErrorMessage : null,
            siteKey: "",
            responseToken: "",
            hidePassword: true,
            rememberMe: false,
            isLoading: false,
            submit: false,
        }
	},
    computed: {
        config(){
            return window.config;
        },
        tableMode(){
            if(window.TableMode == true)
                return true;
            else
                return false;
        },
        SSOEnabled(){
            var retVal = false;

            if(this.config.SSO_LOGIN_URL != undefined && this.config.SSO_LOGIN_URL != null && this.config.SSO_LOGIN_URL != "")
            {
                retVal = true;
            }

            return retVal;
        },
        isCartEmpty()
        {
            if(window.TableMode == true)
            {
                return false;
            }

            if(window.shoppingCart !== undefined && window.shoppingCart !== null)
            {
                if(window.shoppingCart.length == 0)
                    return true;
                else
                    return false;
            }
            else
            {
                return true;
            }
        }
    },
    validations: {
         login: {
            email: {
                required,
                minLength: minLength(4),
                email
            },
            password: {
                required,
                minLength: minLength(8),
            }
        },
    },
    components: {
        'loading': VueLoading,
        BIconEyeFill,
        BIconEyeSlashFill,
        BIconExclamationTriangleFill
        // 'check-box' : CheckBox,
    },
    methods: {
        // eslint-disable-next-line no-undef
        onClickCaptcha(e) {
            var vueInstance = this;
            vueInstance.submit = true;
            console.log("onClickCaptcha");
            e.preventDefault();
            if(window.config.G_CAPTCHA_ENABLED == true)
            {
                // eslint-disable-next-line no-undef
                grecaptcha.ready(function() {
                    // eslint-disable-next-line no-undef
                    grecaptcha.execute(window.config.G_CAPTCHA_SITE_KEY, {action: 'submit'}).then(function(token) {
                        vueInstance.responseToken = token;
                        vueInstance.onSubmit(e);
                    });
                });
            }
            else
            {
                vueInstance.onSubmit(e);
            }

        },

        // AP 20220506
        showPassword() {
            this.hidePassword = !this.hidePassword;
            return;
        },

        handleLoginSuccess: function (result) {
            if(result.errorCode < 0)
            {
                return;
            }

            window.customerLoggedIn = result.loginUsername;
        },

        makeRequest: function (type, url, data, callbackSuccess, callbackError, dataType) {
            if(type == "POST")
            {
                axios.post(url, JSON.parse(data))
                .then(function (response) {
                    console.log(response);
                    callbackSuccess(response.data,response,response);
                })
                .catch(function (error) {
                    callbackError(error,error,error);
                });
                // $.ajax({
                //   method : type,
                //   type : type,
                //   timeout : '20000',
                //   headers: {
                //     "Access-Control-Allow-Origin":"*",
                //   },
                //   scriptCharset : "utf-8",
                //   contentType : "application/json; charset=utf-8",
                //   url : url,
                //   data : "kkj=kkj&" + encodeURIComponent(data),
                //   // context : callback,
                //   success : callbackSuccess,
                //   error: callbackError,
                //   dataType : dataType != undefined ? dataType :'jsonp'l
                // });
            }
            else
            {
                    $.ajax({
                        method : "GET",
                        type : "GET",
                        timeout : '20000',
                        headers: {
                            "Access-Control-Allow-Origin":"*",
                        },
                        scriptCharset : "utf-8",
                        contentType : "application/json; charset=utf-8",
                        url : url,
                        data : "kkj=kkj&" + encodeURIComponent(data),
                        // context : callback,
                        success : callbackSuccess,
                        error: callbackError,
                        dataType : dataType != undefined ? dataType :'jsonp'
                    });
            }
            window.REQUEST_TIMESTAMP = new Date().getTime();
        },
        goBack(){
            this.$router.go(-2);
        },
        HtmlToText(HTML)
        {
            var input = HTML;
            return input.replace("<br>"," ");
        },
        goToRegister(isGuestCheckout) {
            if(isGuestCheckout)
            {
                window.isGuestCheckout = true;
                //avoid redundant navigation since its throws a exception in the console of the site
                if(!(this.$route.path == '/Guest'))
                {
                    this.$router.push({ path: '/Guest' })
                }
            }
            else
            {
                window.isGuestCheckout = false;
                //avoid redundant navigation since its throws a exception in the console of the site
                if(!(this.$route.path == '/Register'))
                {
                    this.$router.push({ path: '/Register' })
                }
            }


        },
        goToForgotPasswort() {
            Swal.close();
            this.$router.push({ path: '/ForgotPassword' })
        },
        loginSSO() {
            window.location.href = window.config.SSO_LOGIN_URL;
        },
        onSubmit: function(event) {
            this.formErrorMessage = null;
            var vueInstance = this;
            var config = window.config;
			event.preventDefault();
            this.isLoading = true;

            // this.$v.$touch();
            // if (this.$v.$invalid) {
            //     this.submitStatus = 'ERROR';
            // } else {
            //     //submit logic here
            //     this.submitStatus = 'PENDING';
            //     setTimeout(() => {
            //         console.log('OK');
            //         this.submitStatus = "OK";
            //     }, 500)
            // }
            var url = config.API_BASE + 'TCPLoginSubmitJ.action';

            var params = {
                json: "true",
                loginUsername: this.login.email,
                password: this.login.password,
            };

            if(this.responseToken != "")
            {
                params.responseToken = this.responseToken;
            }

            if (this.rememberMe) {
                params.rememberMe = true;
            }

            // eslint-disable-next-line no-unused-vars
            var callbackSuccess= function(result, textStatus, jqXHR) {
                vueInstance.isLoading = false;
                if(result.exception !== undefined)
                {
                    vueInstance.$alert(vueInstance.$t('loginUnavailable'));
                    return;
                }

                if(result.page =="tcp.catalog.change.password.page")
                {
                    vueInstance.$router.push({ path: '/PasswordReset' });
                    return;
                }

                if(result.errorCode >= 0)
                {
                    if (config.USE_ALLERGEN_FILTERS && result.wondCustomer.dietaryInfo != undefined) {
                        window.dietaryInfo = result.wondCustomer.dietaryInfo;
                    }           

                    vueInstance.handleLoginSuccess(result);


                    // if(window.history.length == 2)
                    // {
                    //     vueInstance.$router.push({ path: '/Shop' });
                    // }
                    // else
                    // {
                        if(window.shoppingCart != undefined && window.shoppingCart != null)
                        {
                            vueInstance.$router.push({ path: '/OrderConfirmation' });
                        }
                        else
                        {
                            vueInstance.$router.go(-1);
                        }
                    // }
                }
                else
                {
                    if(vueInstance.config.USE_SPECIFIC_ERROR_MESSAGE_TPCLOGIN){
                        if(result.errorCode == -1 || result.errorCode == -2 || result.errorCode == -3 || result.errorCode == -4 || result.errorCode == -6){

                            vueInstance.formErrorMessage = result.errorMsg;
                            // V1 extra Leerzeile
                            Swal.fire(
                                {
                                    html: vueInstance.$t('loginFailed') + '<br><br>' + vueInstance.$t('loginFailedSpecific') 
                                    + '<br>' + "<a href='#/ForgotPassword'>" + vueInstance.$t('forgotPasswordLink') + "</a>"
                                }
                                // '',
                                // 'Kein Login mit diesen Daten gefunden',
                                // 'Wenn Sie sich sicher sind, dass Sie sich erfolgreich registriert haben, können Sie ihr Passwort zurücksetzen'
                                // vueInstance.$t('loginFailed'),
                                // "<a href='#'  onclick='vueInstance.$router.push({ path: '/ForgotPassword' })'>[Passwort vergessen]</a>"
                            )
                            // // V2 Mit Titel
                            // Swal.fire(
                            //     {
                            //         title: vueInstance.$t('loginFailed'),
                            //         html: vueInstance.$t('loginFailedSpecific') 
                            //         + '<br>' + "<a href='#/ForgotPassword'>[Passwort vergessen]</a>"
                            //     }
                            //     // '',
                            //     // 'Kein Login mit diesen Daten gefunden',
                            //     // 'Wenn Sie sich sicher sind, dass Sie sich erfolgreich registriert haben, können Sie ihr Passwort zurücksetzen'
                            //     // vueInstance.$t('loginFailed'),
                            //     // "<a href='#'  onclick='vueInstance.$router.push({ path: '/ForgotPassword' })'>[Passwort vergessen]</a>"
                            // )
                            // vueInstance.$alert(vueInstance.$t('loginFailedSpecific'));
                        } else  {
                            vueInstance.formErrorMessage = result.errorMsg;
                            vueInstance.$alert(vueInstance.$t('loginFailed'));
                        }
                    }else {
                            vueInstance.formErrorMessage = result.errorMsg;
                            vueInstance.$alert(vueInstance.$t('loginFailed'));
                    }


                }
            }

            // eslint-disable-next-line no-unused-vars
            var callbackError = function(result, textStatus, jqXHR){
                vueInstance.isLoading = false;
            }

            vueInstance.makeRequest("POST", url, JSON.stringify(params), callbackSuccess, callbackError);

			this.shake = false;
		}
    },

    created() {
        var config = window.config;
        var vueInstance = this;
        var url = config.API_BASE + 'TCPLoginJ.action';

        var params = {
            json: "true"
        };

        // eslint-disable-next-line no-unused-vars
        var callbackSuccess= function(result, textStatus, jqXHR){
            if(result.xsrfToken != undefined)
            {
                window.xtoken = atob(result.xsrfToken);
            }


            if(result.exception !== undefined)
            {
                vueInstance.$alert(vueInstance.$t('loginUnavailable'));
                return;
            }

            if(result.page == "tcp.catalog.afterlogin.page")
            {
                window.customerLoggedIn = true;
                console.log(window.history.length);
                if(window.history.length > 2)
                    vueInstance.$router.go(-1);
                return;
            }

            if(window.customerLoggedIn != undefined && window.customerLoggedIn != null)
            {
                vueInstance.$router.push({ path: '/Account' });
                return;
            }


        }

        // eslint-disable-next-line no-unused-vars
        var callbackError = function(result, textStatus, jqXHR){
        }

        vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);
    },
    beforeRouteLeave (to,from,next) {
        Swal.close();
        next();
    },
    mounted(){   
    //    $(".header").addClass("header-big");
    //         $(".header").addClass("animTransition");
    //         $(".content").addClass("header-big-content-margin");
    //         $(".content").removeClass("header-small-content-margin");
        $(".content").addClass("header-small-content-margin");
        // $(".click").on('click', goToForgotPasswort());
        this.siteKey = window.config.G_CAPTCHA_SITE_KEY;
    },
     beforeRouteEnter (to, from, next) {
         // eslint-disable-next-line no-unused-vars
         next(vm => {
            var myDiv = document.getElementById('contentSection');
            $("#contentSection").scrollTop(0);
            $("#app").scrollTop(0);
            if(myDiv != null)
            myDiv.scrollTop = 0;
        })
    },
}
</script>

<style scoped>

#content-wrapper{
    padding-bottom: 10vh;
}

.linkColor{
    color:black;
}
    #content-wrapper{
        padding-top:0px;
    }

    .loginButtons
    {
        /* bottom:170px;
        position: absolute; */
        left: 0;
    }

    .loginButtonSize {
        width: 80%;
        /* float:right; */
        margin-left: auto;
        margin-right: auto;
    }

    .guestLoginButton
    {
        /* bottom:100px;
        position: absolute; */
        left: 0;
    }

    .loginBlock{
        display: inline-block;
        overflow: hidden;
    }

    .loginSubTitle {
        text-align: center;
        font-weight: bold;
        margin: auto;
    }

    .loginLeftBlock
    {
        width: 100%;
        float: left;
        padding-right: 10px;
        min-height: 400px;
        border-right:2px solid var(--main-color);
    }

    .loginRightBlock
    {
        width: 100%;
        min-height: 340px;
    }

    .loginRightBlock p
    {
        padding: 30px 10% 30px 10%;
        display: inline-block;
        font-size: 18px;
    }

    @media screen and (max-width:770px) {

        .loginButtonSize {
            width: 100%;
            float:right;
            margin-left: 0;
            margin-right: 0;
        }

        .loginLeftBlock
        {
            border-right:none;
        }

        .loginRightBlock
        {
            min-height:300px;
        }
    }

     @media (min-width: 768px) {
        .loginRightBlock
        {
            width: 50%;
        }
         .loginLeftBlock
        {
            width: 50%;
        }
    }

</style>