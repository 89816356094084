<template>
    <div style="height:100%; width:100%; z-index:999999999999;">
        <div style="top:45%;position:inherit;" v-if="success">            
            <p>{{ message }}</p>
            <button @click="$router.push({path:'/Shop'})" class="btn btn-primary">{{ $t('backToShop') }}</button>     
        </div>      
    </div>
</template>

<script>
import $ from 'jquery'
export default {
    data() {
        return {
            success: null,
            message:"",
        }
    },
    computed:{
        config(){
            return window.config;
        }
    },
    props:{
        k: {
            Type: String
        },
        m: {
            Type: String
        },
        i: {
            Type: String
        }
    },
    methods:{
         makeRequest: function (type, url, data, callbackSuccess, callbackError, dataType) {
        
            $.ajax({
                method : type,
                type : type,
                timeout : '20000',
                headers: {
                    "Access-Control-Allow-Origin":"*",
                },
                scriptCharset : "utf-8",
                contentType : "application/json; charset=utf-8",        
                url : url,
                data : "kkj=kkj&" + encodeURIComponent(data),
                // context : callback,
                success : callbackSuccess,
                error: callbackError,
                dataType : dataType != undefined ? dataType :'jsonp'
            });
            window.REQUEST_TIMESTAMP = new Date().getTime();
        }
    },
    beforeMount(){
          var config = window.config;
            var vueInstance = this;
            var url = config.API_BASE + 'TCPEnableCustomerJ.action';     
            var params = {
                json: "true",
                key: this.k,
                m: this.m,
                i: this.i
            };

            // eslint-disable-next-line no-unused-vars
            var callbackSuccess= function(result, textStatus, jqXHR){                   
                vueInstance.success = true;
                if(result.errorCode == 0)
                {
                    vueInstance.message = vueInstance.$t('emailConfirmed1') + " " + result.emailAddr + " " +  vueInstance.$t('emailConfirmed2');
                    return;
                }
                vueInstance.message = result.message;      
            }

            // eslint-disable-next-line no-unused-vars
            var callbackError = function(result, textStatus, jqXHR){
                vueInstance.success = false;
            }          

            vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);
    },
    mounted(){        
        
    }
}

</script> 