<template>
    <div class="registrationPageWrapper">        
        <loading :active.sync="isLoading" 
            :can-cancel="true"        
            :is-full-page="true"
            loader='bars'></loading>

        <div class="col-12 subPageHeader d-flex flex-row" style="  position:relative;">
            <button class="btn btn-primary subPageHeader subPageHeaderButton" @click="goBack()">
                {{$t('back')}}
            </button>
            <div class="my-auto">
                <span v-if="!isGuestCheckout"><h1>{{  $t('registrationPageTitle')}}</h1></span>
                <span v-if="isGuestCheckout"><h1>{{  $t('orderAsGuest')}}</h1></span>
            </div>
        </div>  

        <form @submit.prevent="onSubmit">
            <div class="registrationForm">
                <div class="registrationLeft">
                    <div class="form-group" v-show="config.REGISTRATION_FORM_FIELDS.emailAddr !== undefined">
                        <label for="email" class="fontField">{{$t('customerEmail')}}<span v-if="status($v.registration.emailAddr)" style="color:var(--main-color);">*</span></label>
                        <input id="email" @change="validate()" type="email" class="form-control form-control-lg" v-model="registration.emailAddr" required/>
                        <div class="inputError" role="alert" v-if="!$v.registration.emailAddr.email && submit"><b-icon-exclamation-triangle-fill></b-icon-exclamation-triangle-fill> {{$t('invalidEmail')}}</div>  
                        <!-- <div class="inputError" role="alert" v-if="!$v.registration.emailAddr.email">{{$t('customerFieldGenericError')}}</div>   -->
                                
                    </div>

                    <!-- AP 20220506 Added show password Button -->
                    <div class="form-group" v-if="config.REGISTRATION_FORM_FIELDS.password == '' && !isGuestCheckout && !config.SHOW_PASSWORD_VISIBILITY_SWITCH">
                        <label for="password" class="fontField">{{$t('customerPassword')}}<span v-if="status($v.registration.password)" style="color:var(--main-color);">*</span></label>
                        <input id="password" @change="validate()" type="password" class="form-control form-control-lg" v-model="registration.password" required/>
                        <div class="inputError" role="alert" v-if="!$v.registration.password.minLength && submit"><b-icon-exclamation-triangle-fill></b-icon-exclamation-triangle-fill> {{ $t('inputErrorPasswordMinLength1') }} {{$v.registration.password.$params.minLength.min}} {{$t('inputErrorPasswordMinLength2')}}</div>
                    </div>

                    <div class="form-group" v-else>
                        <label id="passwordLabel" class="fontField">{{$t('customerPassword')}}<span v-if="status($v.registration.password)" style="color:var(--main-color);">*</span></label>
                        <b-input-group>
                            <b-form-input aria-labelledby="passwordLabel" type="password" v-if="hidePassword" class="form-control form-control-lg" v-model="registration.password" @change="validate()" required/>
                            <b-form-input aria-labelledby="passwordLabel" type="text" v-else class="form-control form-control-lg" v-model="registration.password" @change="validate()" required/>
                            <b-input-group-append>
                                <b-button variant="outline-secondary" type="button" @click="showPassword()" :aria-label="hidePassword ? $t('showPasswordAriaLabel') : $t('hidePasswordAriaLabel')">
                                    <b-icon-eye-fill v-if="hidePassword" aria-hidden="true"></b-icon-eye-fill>
                                    <b-icon-eye-slash-fill v-else aria-hidden="true"></b-icon-eye-slash-fill>
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                        <div class="inputError" role="alert" v-if="!$v.registration.password.minLength && submit"><b-icon-exclamation-triangle-fill></b-icon-exclamation-triangle-fill> {{ $t('inputErrorPasswordMinLength1') }} {{$v.registration.password.$params.minLength.min}} {{$t('inputErrorPasswordMinLength2')}}</div>
                    </div>

                    <!-- AP 20220506 Added password repeat -->
                    <div class="form-group" v-if="config.REGISTRATION_FORM_FIELDS.password == '' && !isGuestCheckout && !config.SHOW_PASSWORD_VISIBILITY_SWITCH">
                        <label for="passwordRepeat" class="fontField">{{$t('customerPasswordRepeat')}}<span v-if="status($v.registration.repeat)" style="color:var(--main-color);">*</span></label>
                        <input id="passwordRepeat" @change="validate()" type="password" class="form-control form-control-lg" v-model="registration.repeat" required/>
                        <div class="inputError" role="alert" v-if="$v.registration.repeat.required && !$v.registration.repeat.sameAsPassword && submit"><b-icon-exclamation-triangle-fill></b-icon-exclamation-triangle-fill> {{$t('fieldWarningPasswordEqual')}}</div>             
                    </div>

                    <div class="form-group" v-if="config.REGISTRATION_FORM_FIELDS.password == '' && !isGuestCheckout && config.SHOW_PASSWORD_VISIBILITY_SWITCH">
                        <label id="passwordRepeatLabel" class="fontField">{{$t('customerPasswordRepeat')}}<span v-if="status($v.registration.password)" style="color:var(--main-color);">*</span></label>
                        <b-input-group>
                            <b-form-input aria-labelledby="passwordRepeatLabel" type="password" v-if="hideRepeat" class="form-control form-control-lg" v-model="registration.repeat" @change="validate()" required />
                            <b-form-input aria-labelledby="passwordRepeatLabel" type="text" v-else class="form-control form-control-lg" v-model="registration.repeat" @change="validate()" required />
                            <b-input-group-append>
                                <b-button variant="outline-secondary" type="button" @click="showRepeat()" :aria-label="hideRepeat ? $t('showPasswordRepeatAriaLabel') : $t('hidePasswordRepeatAriaLabel')">
                                    <b-icon-eye-fill v-if="hidePassword" aria-hidden="true"></b-icon-eye-fill>
                                    <b-icon-eye-slash-fill v-else aria-hidden="true"></b-icon-eye-slash-fill>
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                        <div class="inputError" role="alert" v-if="$v.registration.repeat.required && !$v.registration.repeat.sameAsPassword && submit"><b-icon-exclamation-triangle-fill></b-icon-exclamation-triangle-fill> {{$t('fieldWarningPasswordEqual')}}</div>             
                    </div>

                    <div class="form-group" v-if="config.REGISTRATION_FORM_FIELDS.cardNumber == ''">
                        <label for="customerCardNumber" class="fontField">{{$t('customerCardNumber')}}<span v-if="status($v.registration.cardNumber)" style="color:var(--main-color);">*</span></label>
                        <input id="customerCardNumber" @change="validate()" type="text" maxlength="30" class="form-control form-control-lg" v-model="registration.cardNumber" required/>
                        <!-- <div class="inputError" role="alert" v-if="!$v.registration.cardNumber.error == true">{{$t('customerFieldGenericError')}}</div> -->
                    </div>

                    <div class="form-group" v-if="config.REGISTRATION_FORM_FIELDS.title == ''">
                        <label for="customerTitle" class="fontField">{{$t('customerTitle')}} <span v-if="status($v.registration.title)" style="color:var(--main-color);">*</span></label>
                        <input id="customerTitle" @change="validate()" type="text" class="form-control form-control-lg" maxlength="10" v-model="registration.title" required/>
                        <!-- <div class="inputError" role="alert" v-if="!$v.registration.title.error == true">{{$t('customerFieldGenericError')}}</div> -->
                    </div>

                    <div class="form-group" v-if="config.REGISTRATION_FORM_FIELDS.firstName == ''">
                        <label for="customerFirstName" class="fontField">{{$t('customerFirstName')}}<span v-if="status($v.registration.firstName)" style="color:var(--main-color);">*</span></label>
                        <input id="customerFirstName" @change="validate()" type="text" maxlength="40" class="form-control form-control-lg" v-model="registration.firstName" required/>
                        <!-- <div class="inputError" role="alert" v-if="!$v.registration.firstName.error == true">{{$t('customerFirstNameError')}}</div>           -->
                    </div>

                    <div class="form-group" v-if="config.REGISTRATION_FORM_FIELDS.lastName == ''">
                        <label for="customerLastName" class="fontField">{{$t('customerLastName')}}<span v-if="status($v.registration.lastName)" style="color:var(--main-color);">*</span></label>
                        <input id="customerLastName" @change="validate()" type="text" class="form-control form-control-lg"  maxlength="40"  v-model="registration.lastName" required/>
                        <!-- <div class="inputError" role="alert" v-if="!$v.registration.lastName.error == true">{{$t('customerLastNameError')}}</div>           -->
                    </div>

                     <div class="form-group" v-if="config.REGISTRATION_FORM_FIELDS.streetAddress == ''">
                        <label for="customerAddress" class="fontField">{{$t('customerAddress')}}<span v-if="status($v.registration.streetAddress)" style="color:var(--main-color);">*</span></label>
                            <!-- <span style="color:var(--main-color);">*</span> -->
                        <input id="customerAddress" @change="validate()" maxlength="40" type="text" class="form-control form-control-lg" v-model="registration.streetAddress" required/>
                        <!-- <div class="inputError" role="alert" v-if="!$v.registration.streetAddress.error == true">{{$t('customerFieldGenericError')}}</div> -->
                    </div>

                    <div class="form-group" v-if="config.REGISTRATION_FORM_FIELDS.otherInfos == ''">
                        <label for="customerOtherInfos" class="fontField">{{$t('customerOtherInfos')}} <span v-if="status($v.registration.otherInfos)" style="color:var(--main-color);">*</span></label>
                        <input id="customerOtherInfos" @change="validate()" type="text" class="form-control form-control-lg" v-model="registration.otherInfos" required/>
                        <!-- <div class="inputError" role="alert" v-if="!$v.registration.otherInfos.error == true">{{$t('customerFieldGenericError')}}</div> -->
                    </div>
             
                    <div class="form-group" v-if="config.REGISTRATION_FORM_FIELDS.customerCode == ''">
                        <label for="customerCustomerCode" class="fontField">{{$t('customerCustomerCode')}} <span v-if="status($v.registration.customerCode)" style="color:var(--main-color);">*</span></label>
                        <input id="customerCustomerCode" @change="validate()" type="text" class="form-control form-control-lg" v-model="registration.customerCode" required/>
                        <!-- <div class="inputError" role="alert" v-if="!$v.registration.customerCode.error == true">{{$t('customerFieldGenericError')}}</div> -->
                    </div>

                </div>

                <div class="registrationRight">                 

                    <div class="form-group" v-if="config.REGISTRATION_FORM_FIELDS.postcode  == ''">
                        <label for="customerPostal" class="fontField">{{$t('customerPostal')}}<span v-if="status($v.registration.postcode)" style="color:var(--main-color);">*</span></label>
                        <input id="customerPostal" @change="validate()" type="text" class="form-control form-control-lg" maxlength="10"  v-model="registration.postcode" required/>
                        <!-- <div class="inputError" role="alert" v-if="!$v.registration.postcode.error == true">{{$t('customerPostalcodeError')}}</div>           -->
                    </div>

                    <div class="form-group" v-if="config.REGISTRATION_FORM_FIELDS.city  == ''">
                        <label for="customerCity" class="fontField">{{$t('customerCity')}} <span v-if="status($v.registration.city)" style="color:var(--main-color);">*</span> </label>
                        
                        <input id="customerCity" @change="validate()"  type="text" class="form-control form-control-lg" maxlength="50" v-model="registration.city" required/>
                        <!-- <div class="inputError" role="alert" v-if="!$v.registration.city.error == true">{{$t('customerCityError')}}</div>           -->
                    </div>

                    <div class="form-group" v-if="config.REGISTRATION_FORM_FIELDS.countryId  == ''">
                        <label for="customerCountry" class="fontField">{{$t('customerCountry')}}</label>
                        <!-- <input type="text" class="form-control form-control-lg" v-model="registration.countryId" /> -->
                        <!-- <div class="inputError" role="alert" v-if="!$v.registration.country.required">{{$t('customerCountryError')}}</div>           -->
                    </div>

                    <div class="form-group" v-if="config.REGISTRATION_FORM_FIELDS.country !== undefined">
                        <label for="customerCountry" class="fontField">{{$t('customerCountry')}}<span v-if="status($v.registration.countryId)" style="color:var(--main-color);">*</span></label>   
                            <!-- <span style="color:var(--main-color);">*</span>                    -->
                        <select-picker-country id="customerCountry" class="countrySelect" v-if="countriesLoaded" :options="countries" :callback="selectCountry" :preselectedId="selectedCountryId" ></select-picker-country>
                        <!-- <div class="inputError" role="alert" v-if="!$v.registration.streetAddress.error == true">{{$t('customerFieldGenericError')}}</div> -->
                    </div>
             
                    <div class="form-group" v-if="config.REGISTRATION_FORM_FIELDS.telephoneNumber  == ''">
                        <label for="customerPhone" class="fontField">{{$t('customerPhone')}}<span v-if="status($v.registration.telephoneNumber)" style="color:var(--main-color);">*</span></label>
                        <input id="customerPhone" @change="validate()"  type="text" class="form-control form-control-lg" maxlength="40"  v-model="registration.telephoneNumber" required/>
                        <!-- <div class="inputError" role="alert" v-if="!$v.registration.telephoneNumber.error == true">{{$t('customerFieldGenericError')}}</div> -->
                    </div>                    

                    <div class="form-group" v-if="config.REGISTRATION_FORM_FIELDS.telephoneNumber1  == ''">
                        <label for="customerPhone1" class="fontField">{{$t('customerPhone')}}<span v-if="status($v.registration.telephoneNumber1)" style="color:var(--main-color);">*</span></label>
                        <input id="customerPhone1" @change="validate()"  type="text" class="form-control form-control-lg" maxlength="40"  v-model="registration.telephoneNumber1" required/>
                        <!-- <div class="inputError" role="alert" v-if="!$v.registration.telephoneNumber1.error == true">{{$t('customerFieldGenericError')}}</div> -->
                    </div>

                    <div class="form-group" v-if="config.REGISTRATION_FORM_FIELDS.birthDateString  == ''">
                        <label for="customerBirtDate" class="fontField">{{$t('customerBirthDate')}}<span v-if="status($v.registration.birthDateString)" style="color:var(--main-color);">*</span></label>
                        <!-- <span style="color:var(--main-color);">*</span></label> -->
                        <!-- <input type="text" class="form-control form-control-lg" v-model="registration.birthDateString" /> -->
                        <!-- <datetime-picker format="DD/MM/YYYY" width="100%" height="100%" firstDayOfWeek="1" v-model="registration.birthDateString"></datetime-picker> -->
                        <datepicker id="customerBirtDate" :language="currentLanguage" format="dd.MM.yyyy" class="form-control" style="width:100%; height:100%;" width="100%" height="100%" firstDayOfWeek="1" name="birthDateString" v-model="registration.birthDateString"></datepicker>
                        <!-- <div class="inputError" role="alert" v-if="!$v.registration.birthDateString.error == true">{{$t('customerFieldGenericError')}}</div> -->
                    </div>
                    
                    <div class="form-group" v-if="config.REGISTRATION_FORM_FIELDS.notes1 !== undefined">
                        <label for="customerNotes1" class="fontField">{{$t('customerNotes1')}}<span v-if="status($v.registration.notes1)" style="color:var(--main-color);">*</span></label>
                        <input id="customerNotes1" @change="validate()"  type="text" class="form-control form-control-lg" maxlength="50"  v-model="registration.notes1" required/>
                        <!-- <div class="inputError" role="alert" v-if="!$v.registration.notes1.error == true">{{$t('customerFieldGenericError')}}</div> -->
                    </div>
                    <div class="form-group" v-if="config.REGISTRATION_FORM_FIELDS.notes2 !== undefined">
                        <label for="customerNotes2" class="fontField">{{$t('customerNotes2')}}<span v-if="status($v.registration.notes2)" style="color:var(--main-color);">*</span></label>
                        <input id="customerNotes2" @change="validate()"  type="text" class="form-control form-control-lg" maxlength="50"  v-model="registration.notes2" required/>
                        <!-- <div class="inputError" role="alert" v-if="!$v.registration.notes2.error == true">{{$t('customerFieldGenericError')}}</div> -->
                    </div>
                    
                    <div class="form-group" v-if="config.REGISTRATION_FORM_FIELDS.notes3  == ''">
                        <label for="customerNotes3" class="fontField">{{$t('customerNotes3')}}<span v-if="status($v.registration.notes3)" style="color:var(--main-color);">*</span></label>
                        <input id="customerNotes3" @change="validate()"  type="text" class="form-control form-control-lg" maxlength="50"  v-model="registration.notes3" required/>
                        <!-- <div  class="inputError" role="alert" v-if="!$v.registration.notes3.error == true">{{$t('customerFieldGenericError')}}</div> -->
                    </div>
                    <div class="form-group" v-if="config.REGISTRATION_FORM_FIELDS.notes4  == ''">
                        <label for="customerNotes4" class="fontField">{{$t('customerNotes4')}}<span v-if="status($v.registration.notes4)" style="color:var(--main-color);">*</span></label>
                        <input id="customerNotes4" @change="validate()"  type="text" class="form-control form-control-lg" v-model="registration.notes4" required/>
                        <!-- <div class="inputError" role="alert" v-if="!$v.registration.notes4.required">{{$t('customerFieldGenericError')}}</div> -->
                        <!-- <div v-for="error of $v.registration.notes4.$errors" :key="error.$uid"> -->
                            <!-- <div class="inputError" role="alert" v-if="$v.registration.notes4.error == true">{{$t('customerFieldGenericError')}}</div> -->
                        <!-- </div> -->
                    </div>
                </div>

                <div class="col-12">
                    <div class="form-group col-12 my-auto" v-if="config.REGISTRATION_FORM_FIELDS.privacy == ''">
                        <!-- <label class="fontField">{{$t('customerNotes4')}}</label> -->
                        <check-box
                            v-if="!config.USE_ACCESSIBLE_CHECKBOX"                                           
                            :label='$t("customerPrivacy") +  "<span style="+"color:var(--main-color);"+">*</span>"'
                            :value="1"
                            :trueValue="'1'"
                            :falseValue="'0'"
                            :hyperlink ="config.REGISTRATION_FORM_FIELDS.privacyHyperlink"
                            :biggerCheckbox=true
                            :newTab=config.REGISTRATION_FORM_FIELDS.privacyHyperlinkNewTab
                            :aria-label="$t('customerPrivacy')"
                            v-model="registration.privacy">
                        </check-box>  
                        <div class="col-auto form-check" v-else>        
                            <input aria-labelledby="labelPrivacy" id="checkBoxPrivacy" class="form-check-input" type="checkbox" @change="updatePrivacy" style="-webkit-appearance: auto"/>
                            <label id="labelPrivacy" v-if="config.REGISTRATION_FORM_FIELDS.privacyHyperlink === undefined || config.REGISTRATION_FORM_FIELDS.privacyHyperlink === ''" class="form-check-label" v-html='$t("customerPrivacy")'></label>
                            <a :target="config.OPEN_PRIVACY_LINK_NEW_TAB ? '_blank' : '_self'" id="labelPrivacy" v-else class="form-check-label darkLink" :href='config.REGISTRATION_FORM_FIELDS.privacyHyperlink' v-html='$t("customerPrivacy")'></a>
                        </div>                   
                    </div>

                    <div class="form-group col-12 my-auto" v-if="config.REGISTRATION_FORM_FIELDS.newsletter  == ''">
                        <!-- <label class="fontField">{{$t('customerNotes4')}}</label> -->

                        <check-box       
                            v-if="!config.USE_ACCESSIBLE_CHECKBOX"                  
                            :label='$t("customerNewsletter")'
                            :value="1"
                            :trueValue="'1'"
                            :falseValue="'0'"
                            :hyperlink ="config.REGISTRATION_FORM_FIELDS.newsletterHyperlink"
                            :biggerCheckbox=true
                            :newTab=config.REGISTRATION_FORM_FIELDS.newsletterHyperlinkNewTab
                            :aria-label='$t("customerNewsletter")'
                            v-model="registration.newsletter">
                        </check-box>           
                        <div class="col-auto form-check" v-else>        
                            <!-- <input  id="checkBoxNewsletter" class="form-check-input" type="checkbox" @change="updateNewsletter" style="-webkit-appearance: auto"/>
                            <label for="checkBoxNewsletter" class="form-check-label" v-html='$t("customerNewsletter")'></label> -->
                            <input aria-labelledby="labelNewsletter" id="checkBoxNewsletter" class="form-check-input" type="checkbox" @change="updateNewsletter" style="-webkit-appearance: auto"/>
                            <label id="labelNewsletter" v-if="config.REGISTRATION_FORM_FIELDS.newsletterHyperlink === undefined || config.REGISTRATION_FORM_FIELDS.newsletterHyperlink === '' " for="checkBoxNewsletter" class="form-check-label" v-html='$t("customerNewsletter")'></label>
                            <a :target="config.OPEN_NEWSLETTER_LINK_NEW_TAB ? '_blank' : '_self'" id="labelNewsletter" v-else class="form-check-label darkLink" :href='config.REGISTRATION_FORM_FIELDS.newsletterHyperlink' v-html='$t("customerNewsletter")'></a>
                        </div>       
                    </div>

                    <div class="form-group col-12 my-auto" style="text-alignment:center;" v-if="config.REGISTRATION_FORM_FIELDS.tc  == '' && config.SHOW_ORDERCONFIRMATION_TC_CONSENT != true">
                        <!-- <label class="fontField">{{$t('customerNotes4')}}</label> -->

                        <check-box  
                            v-if="!config.USE_ACCESSIBLE_CHECKBOX"                  
                            :label='$t("customerTC")+  "<span style="+"color:var(--main-color);"+">*</span>"'
                            :value="1"
                            :trueValue="'1'"
                            :falseValue="'0'"
                            :hyperlink ="config.REGISTRATION_FORM_FIELDS.tcHyperlink"
                            :biggerCheckbox=true
                            :newTab=config.REGISTRATION_FORM_FIELDS.tcHyperlinkNewTab
                            :aria-label='$t("customerTC")'
                            v-model="registration.tc">
                        </check-box>    
                        <div class="col-auto form-check" v-else>        
                            <input aria-labelledby="labelTC" id="checkBoxTC" class="form-check-input" type="checkbox" @change="updateTC" style="-webkit-appearance: auto"/>
                            <label id="labelTC" v-if="config.REGISTRATION_FORM_FIELDS.tcHyperlink === undefined || config.REGISTRATION_FORM_FIELDS.tcHyperlink === '' " for="checkBoxTC" class="form-check-label" v-html='$t("customerTC")'></label>
                            <a :target="config.OPEN_TC_LINK_NEW_TAB ? '_blank' : '_self'" id="labelTC" v-else class="form-check-label darkLink" :href='config.REGISTRATION_FORM_FIELDS.tcHyperlink' v-html='$t("customerTC")'></a>
                        </div>                            
                    </div>
                </div>

                <div class="col-12" style="padding-right:0px;">
                    <button type="submit"  @click.stop="onClickCaptcha" v-if="!isGuestCheckout" :disabled="submitStatus === 'PENDING'" class="btn btn-success btn-lg btnSubmit floatRight g-recaptcha">{{$t('register')}}</button>
                    <button type="submit"  @click.stop="onClickCaptcha" v-if="isGuestCheckout" :disabled="submitStatus === 'PENDING'" class="btn btn-success btn-lg btnSubmit floatRight g-recaptcha">{{$t('orderAsGuest')}}</button>
                </div>
            </div>

        </form>

    </div>
</template>


<script>
import { required, sameAs, minLength, email } from 'vuelidate/lib/validators'
import VueLoading from 'vue-loading-overlay'
import CheckBox from '../sub-components/CheckBoxCustomer.vue'
import $ from 'jquery'
// import datetime from 'vuejs-datetimepicker';
import datepicker from 'vuejs-datepicker'
import {en, de, fr} from 'vuejs-datepicker/dist/locale'
// import selectPickerCountry from '../sub-components/SelectPickerCountry.vue'
import selectPickerCountry from '../sub-components/SelectPickerCountry.vue'
import { BIconEyeFill, BIconEyeSlashFill, BIconExclamationTriangleFill } from 'bootstrap-vue'

export default {
    data() {
        return {
            submitStatus: null,           
            registration: {
                default:''
            },
            isLoading:false,
            en: en,
            de: de, 
            fr: fr,
            countriesLoaded: false,
            countries: {
                Type: Array
            },
             selectedCountry: {
                Type:Object
            },
            zones: {
                Type: Array
            },
            selectedCountryId: 0,
            responseToken: "",
            hidePassword: true,
            hideRepeat: true,  
            submit: false,
        }	
	},
    computed: {
        isTableMode(){
            if(window.TableMode == true)
                return true;
            else
                return false;
        },
        isGuestCheckout(){
            if(window.isGuestCheckout == true)
                return true;
            else
                return false;
        },
        config() {
            return window.config;
        },
        currentLanguage() {

            if (this.$i18n.locale == "fr_FR")
            {
                return fr;
            }
            if (this.$i18n.locale == "de_DE")
            {
                return de;
            }
            if (this.$i18n.locale == "en_GB")
            {
                return en;
            }

            return en;
        }
    },
    components:{
        'loading' : VueLoading,
        'check-box' : CheckBox,
        // 'datetime-picker': datetime,
        'datepicker': datepicker,
        'select-picker-country': selectPickerCountry,
        BIconEyeFill,
        BIconEyeSlashFill,
        BIconExclamationTriangleFill
    },
    validations: { // STANDARD
        registration: {
            emailAddr:{
                required,
                email,
                minLength : minLength(1)
            },
            password: {
                minLength : minLength(8),
                required
            },
            firstName:{
                required,
            },
            lastName:{
                required,
            },
            streetAddress:{
             required,
            },
            city:{
             required,
            },
            postcode:{
             required,
            },
            title:{
            // required
            },
            cardNumber:{
            required
            },
            notes1:{
            // required
            },
            notes2:{
            // required
            },
            notes3:{
            // required
            },
            notes4:{
            // required,
            },
            birthDateString:{
            // required
            },
            countryId:{
            // required
            },
            telephoneNumber:{
             required
            },
            telephoneNumber1:{
            // required
            },
            otherInfos:{
            // required
            },
            repeat: {
                required,
                sameAsPassword : sameAs('password')
            },
            customerCode: {
                
            } 
        }
    },
    // validations: { // Donhauser
    //     registration: {
    //         emailAddr:{
    //             required,
    //             email,
    //             minLength : minLength(1)
    //         },
    //         password: {
    //             minLength : minLength(8),
    //             required
    //         },
    //         firstName:{
    //             required,
    //         },
    //         lastName:{
    //             required,
    //         },
    //         // streetAddress:{
    //         //  required,
    //         // },
    //         // city:{
    //         //  required,
    //         // },
    //         // postcode:{
    //         //  required,
    //         // },
    //         // title:{
    //         // // required
    //         // },
    //         // cardNumber:{
    //         // // required
    //         // },
    //         // notes1:{
    //         // // required
    //         // },
    //         // notes2:{
    //         // // required
    //         // },
    //         // notes3:{
    //         // // required
    //         // },
    //         // notes4:{
    //         // // required,
    //         // },
    //         // birthDateString:{
    //         // // required
    //         // },
    //         // countryId:{
    //         // // required
    //         // },
    //         // telephoneNumber:{
    //         //  required
    //         // },
    //         // telephoneNumber1:{
    //         // // required
    //         // },
    //         // otherInfos:{
    //         // // required
    //         // },
    //     }
    // },
    methods: { 	
          // eslint-disable-next-line no-undef
        onClickCaptcha(e) {
            var vueInstance = this;
            vueInstance.submit = true;
            console.log("onClickCaptcha");
            e.preventDefault();
            if(window.config.G_CAPTCHA_ENABLED == true)
            {
                // eslint-disable-next-line no-undef
                grecaptcha.ready(function() {
                    // eslint-disable-next-line no-undef
                    grecaptcha.execute(window.config.G_CAPTCHA_SITE_KEY, {action: 'submit'}).then(function(token) {
                        vueInstance.responseToken = token;
                        vueInstance.onSubmit(e);                        
                    });
                });
            }
            else
            {
                vueInstance.onSubmit(e);
            }
       
        },
        updateNewsletter(){
            var checkbox = document.getElementById("checkBoxNewsletter");
            var vueInstance = this;
            // console.log(document.getElementById("checkBoxNewsletter"))
            // console.log("CHECKED? " + document.getElementById("checkBoxNewsletter").checked)

            if(checkbox.checked){
                vueInstance.registration.newsletter = "1";
            }else{
                vueInstance.registration.newsletter = "0";
            }

            // console.log("NEWSLETTER: " + this.registration.newsletter);
        },
        updatePrivacy(){
            var checkbox = document.getElementById("checkBoxPrivacy");
            var vueInstance = this;

            if(checkbox.checked){
                vueInstance.registration.privacy = "1";
            }else{
                vueInstance.registration.privacy = "0";
            }

            // console.log("PRIVACY = " + vueInstance.registration.privacy);
        },
        updateTC(){
            var checkbox = document.getElementById("checkBoxPrivacy");
            var vueInstance = this;

            if(checkbox.checked){
                vueInstance.registration.tc = "1";
            }else{
                vueInstance.registration.tc = "0";
            }

            // console.log("TC = " + vueInstance.registration.privacy);
        },
        // AP 20220506
        showPassword() {
            this.hidePassword = !this.hidePassword;
            return;
        },
        // AP 20220508
        showRepeat() {
            this.hideRepeat = !this.hideRepeat;
            return;
        },
        status(validation) 
        {
            // var result = {
            //     error: validation.$error,
            //     dirty: validation.$dirty
            // }   
            return validation.$error;
        },
        selectCountry(countryId) {
            console.log("selectCountry");
            console.log(countryId);
            this.registration.countryId = countryId;      
        },
        formatDate(date) {
            if(typeof(date) !== Date)
            {
                return;
            }

            let diff = new Date() - date; // the difference in milliseconds

            if (diff < 1000) { // less than 1 second
                return 'right now';
            }

            let sec = Math.floor(diff / 1000); // convert diff to seconds

            if (sec < 60) {
                return sec + ' sec. ago';
            }

            let min = Math.floor(diff / 60000); // convert diff to minutes
            if (min < 60) {
                return min + ' min. ago';
            }

            // format the date
            // add leading zeroes to single-digit day/month/hours/minutes
            let d = date;
            d = [
                '0' + d.getDate(),
                '0' + (d.getMonth() + 1),                
                // '0' + d.getHours(),
                // '0' + d.getMinutes()
            ].map(component => component.slice(-2)); // take last 2 digits of every component

            d.push(date.getFullYear());

            // join the components into date
            return d.slice(0, 3).join('/');
        },
        goBack() {
            this.$router.go(-1);
        },
        validate(){
            this.$v.$touch();
            this.$forceUpdate();
        },
        onSubmit: function(event) {
            var vueInstance = this;
            var config = window.config;
            if(config.GUEST_CHECKOUT == true && window.isGuestCheckout == true)
            {
                this.registration.password = "xxxxxxxx";
            }
			event.preventDefault();    
            console.log(vueInstance.registration);              
            this.$v.$touch();
            console.log(this.$v);
            
            if (this.$v.$invalid) {
                this.$alert(this.$t('fillAllRequiredFields'));
                return;
            }                

            if(this.registration.privacy == 0 || this.registration.privacy == undefined)
            {
                this.$alert(this.$t('acceptAllRequiredConditions'));
                return;
            }
            
            if( (this.registration.tc == 0 || this.registration.tc == undefined) && config.SHOW_ORDERCONFIRMATION_TC_CONSENT != true )
            {
                this.$alert(this.$t('acceptAllRequiredConditions'));
                return;
            }  

            if(this.registration.birthDateString != undefined && this.registration.birthDateString != null)
            {
                this.registration.birthDateString = this.formatDate(this.registration.birthDateString);
            }

            if(this.isGuestCheckout == true)
            {
                this.registration.allowNoRegister = "true";
            }

            this.registration.privacyHyperlink = undefined;
            this.registration.tcHyperlink = undefined;

            if(this.responseToken != "")
            {
                this.registration.responseToken = this.responseToken;
            }
            
            var url = config.API_BASE + 'TCPCustomerRegistrationSubmitJ.action';                
            // eslint-disable-next-line no-unused-vars                         
            var callbackSuccess= function(result, textStatus, jqXHR) {
                if(result.page =="tcp.catalog.change.password.page")
                {
                    vueInstance.$router.push({ path: '/PasswordReset' });
                    return;
                }
                
                if(result.errorCode > 0)
                {
                    if(result.errorCode == 10) // password does confine to everything needed (length, special characters, numbers, ...)
                    {
                        vueInstance.$alert(vueInstance.$t("customerRegistrationSubmitError17"));
                        return;
                    }
                    else
                    {
                        vueInstance.$alert(vueInstance.$t('registrationFailGeneric'));
                        return;
                    }

                    // if(vueInstance.isGuestCheckout)
                    // {
                    //     if(vueInstance.isTableMode)
                    //     {
                    //         window.customer = result;
                    //         var path = "/QRLanding?s=" + window.tableModeShop + "&t=" + window.tableModeTable; 
                    //         vueInstance.$router.push({path:path});
                    //     }
                    //     else
                    //     {
                    //         window.customer = result;
                    //         vueInstance.$router.push({path:"/OrderConfirmation"});
                    //     }
                    // }
                    // else
                    // {
                    //     vueInstance.$alert(vueInstance.$t('registrationSuccess'));
                    //     vueInstance.$router.go(-1);
                    // }
                    
                }
                else
                {      
                    if(result.errorCode == 0)
                    {                        
                        if(vueInstance.isGuestCheckout)
                        {
                            if(vueInstance.isTableMode)
                            {
                                window.customer = result;
                                var path = "/QRLanding?s=" + window.tableModeShop + "&t=" + window.tableModeTable; 
                                vueInstance.$router.push({path:path});
                            }
                            else
                            {
                                window.customer = result;
                                vueInstance.$router.push({path:"/OrderConfirmation"});
                            }
                        }
                        else
                        {
                            vueInstance.$alert(vueInstance.$t('registrationSuccess'));
                            vueInstance.$router.go(-1);
                        }

                        return;
                    }

                    if(result.exception !== undefined)
                    {
                        vueInstance.$alert(vueInstance.$t('registrationFailGeneric'));
                        console.log(result.exception);
                        return;
                    }    
                    vueInstance.$alert(vueInstance.$t('registrationFailGeneric'));
                }

           
            }

            // eslint-disable-next-line no-unused-vars
            var callbackError = function(result, textStatus, jqXHR){  
            }

            vueInstance.registration.json = "true";
            // vueInstance.registration.countryId = config.REGISTRATION_FORM_FIELDS.countryId;
            // vueInstance.registration.state = config.REGISTRATION_FORM_FIELDS.state;
            vueInstance.makeRequest("POST", url, JSON.stringify(vueInstance.registration), callbackSuccess, callbackError);
		}
    },
    mounted(){
        var config = window.config;     

        this.registration = JSON.parse(JSON.stringify(config.REGISTRATION_FORM_FIELDS))
        // this.registration = config.REGISTRATION_FORM_FIELDS;
        
        if(config.GUEST_CHECKOUT == true && window.isGuestCheckout == true)
        {
            console.log("guestCheckout true");
            this.registration.password = "xxxxxxxxxxx";
        }  

        this.validate();
    },
    beforeMount(){
        var config = window.config;
        var vueInstance = this;
        var url = config.API_BASE + 'TCPCustomerRegistrationJ.action';        
        var params = {
            json: "true",
            countryChange : 1
        };        

        // eslint-disable-next-line no-unused-vars
        var callbackSuccess= function(result, textStatus, jqXHR) {          
            vueInstance.countries = result.countries;
            if(result.selectedCountry != undefined)
            {
                vueInstance.selectedCountry = result.selectedCountry;
                vueInstance.registration.countryId = result.selectedCountry.id;  
                vueInstance.selectedCountryId = result.selectedCountry.id; 
            }
            else
            {
                if(vueInstance.countries.length > 0)
                    vueInstance.selectCountry(vueInstance.countries[0].id);
            }    
            
            
            
            vueInstance.countriesLoaded = true;
        }

        // eslint-disable-next-line no-unused-vars
        var callbackError = function(result, textStatus, jqXHR){  
        }

        vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);
    },
    beforeCreate() {
      
    },
    beforeRouteEnter (to, from, next) {
         // eslint-disable-next-line no-unused-vars
         next(vm => {
            var myDiv = document.getElementById('contentSection');
            $("#contentSection").scrollTop(0);
            $("#app").scrollTop(0);
            if(myDiv != undefined)
                myDiv.scrollTop = 0;
        })        
    },
}
</script>

<style scoped>
    .registrationPageWrapper
    {
        overflow-y:auto;
        /* background-color: var(--text-color);
        color: black;       */
        padding:0px;               
    }

@media (min-width: 768px) {
    .registrationLeft
    {        
        width: 40%;
        float: left;
        margin-top:10px;
    }
}

@media (min-width: 768px) {
    .registrationRight
    {
        width: 40%;        
        float: right;
        margin-top:10px;
    }
}

.fontTitle { 
    text-align:center;
}
     
</style>