<template>
<form @submit.prevent id="addressForm">

            <loading :active.sync="isLoading" 
                :can-cancel="true"        
                :is-full-page="true"
                loader='bars'></loading>

            <div class="col-12">
                <!-- <div class="form-group" v-show="config.CUSTOMER_FORM_FIELDS.email !== undefined">
                    <label class="fontField">{{ $t('email')}}</label>
                    <input type="email" class="form-control form-control-lg" v-model="edit.emailAddr" />          
                </div> -->

                <div class="form-group" v-show="config.ADDRESS_FORM_FIELDS.firstName ==''">
                    <label class="fontField">{{ $t('customerFirstName')}}<span v-if="status($v.addressObj.firstName)" style="color:var(--main-color);">*</span></label>
                    <input type="text" maxlength="40" class="form-control form-control-lg" v-model="addressObj.firstName" />
                    <!-- <div class="inputError" role="alert" v-if="!$v.edit.firstName.required">Please enter your name</div>           -->
                </div>

                <div class="form-group" v-show="config.ADDRESS_FORM_FIELDS.lastName ==''">
                    <label class="fontField">{{ $t('customerLastName')}}<span v-if="status($v.addressObj.lastName)" style="color:var(--main-color);">*</span></label>
                    <input type="text" class="form-control form-control-lg"  maxlength="40"  v-model="addressObj.lastName" />
                    <!-- <div class="inputError" role="alert" v-if="!$v.edit.lastName.required">Please enter your last name</div>           -->
                </div>

                <div class="form-group" v-show="config.ADDRESS_FORM_FIELDS.streetAddress == ''">
                    <label class="fontField">{{ $t('streetAddress')}}<span v-if="status($v.addressObj.streetAddress)" style="color:var(--main-color);">*</span></label>
                    <input type="text" maxlength="40" class="form-control form-control-lg" v-model="addressObj.streetAddress" />     
                </div>

                <div class="form-group" v-show="config.ADDRESS_FORM_FIELDS.streetAddress1 == ''">
                    <label class="fontField">{{ $t('streetAddressAddition')}}<span v-if="status($v.addressObj.streetAddress1)" style="color:var(--main-color);">*</span></label>
                    <input type="text" class="form-control form-control-lg" v-model="addressObj.streetAddress1" />                 
                </div>

                 <div class="form-group" v-show="config.ADDRESS_FORM_FIELDS.suburb == ''">
                    <label class="fontField">{{ $t('suburb')}}<span v-if="status($v.addressObj.suburb)" style="color:var(--main-color);">*</span></label>
                    <input type="text" class="form-control form-control-lg" v-model="addressObj.suburb" />                 
                </div>

                <div class="form-group" v-show="config.ADDRESS_FORM_FIELDS.postcode == ''">
                    <label class="fontField">{{ $t('postalCode')}}<span v-if="status($v.addressObj.postcode)" style="color:var(--main-color);">*</span></label>
                    <input type="text" class="form-control form-control-lg"  maxlength="10"  v-model="addressObj.postcode" />   
                </div>

                <div class="form-group" v-show="config.ADDRESS_FORM_FIELDS.city == ''">
                    <label class="fontField">{{ $t('city')}}<span v-if="status($v.addressObj.city)" style="color:var(--main-color);">*</span></label>
                    <input type="text"  class="form-control form-control-lg" maxlength="50" v-model="addressObj.city" />    
                </div>

                <div class="form-group" v-show="config.ADDRESS_FORM_FIELDS.countryId  == ''">
                    <label class="fontField">{{$t('customerCountry')}}</label>
                    <input type="text" class="form-control form-control-lg" v-model="addressObj.countryId" />
                    <!-- <div class="inputError" role="alert" v-if="!$v.registration.country.required">{{$t('customerCountryError')}}</div>           -->
                </div>

                <div class="form-group" v-if="countriesLoaded && config.ADDRESS_FORM_FIELDS.country !== undefined">
                    <label class="fontField">{{$t('customerCountry')}}</label>
                    <!-- <input type="text" class="form-control form-control-lg" v-model="registration.country" />
                    <div class="inputError" role="alert" v-if="!$v.registration.country.required">Please enter your country</div> -->
                    <!-- <select-picker-country class="countrySelect" v-if="countriesLoaded" :options="countries" :callback="selectCountry" :preselectedId="selectedCountryId" ></select-picker-country> -->
                    <select-picker-country class="countrySelect"  :options="countries" :callback="selectCountry" :preselectedId="selectedCountryId" ></select-picker-country>
                </div>

                <div class="form-group" v-show="config.ADDRESS_FORM_FIELDS.state == ''">
                    <label class="fontField">{{ $t('state')}}<span v-if="status($v.addressObj.state)" style="color:var(--main-color);">*</span></label>
                    <input type="text" class="form-control form-control-lg" v-model="addressObj.state" />
                </div>

                <div class="form-group" v-show="config.ADDRESS_FORM_FIELDS.telephoneNumber == ''">
                    <label class="fontField">{{ $t('telephoneNumber')}}<span v-if="status($v.addressObj.telephoneNumber)" style="color:var(--main-color);">*</span></label>
                    <input type="text" class="form-control form-control-lg" maxlength="40"  v-model="addressObj.telephoneNumber" />    
                </div>
            </div>
            <div class="col-6" style="padding-right:0px;">
               
            </div>        
                   
            <!-- <button @click="onSubmit" :disabled="submitStatus === 'PENDING'" class="btn btn-dark btn-lg btn-block btnSubmit floatRight"> -->
            <button @click="onSubmit" :disabled="submitStatus === 'PENDING'" class="btn btn-success btn-lg btn-block btnSubmit floatRight">
                {{ $t('save') }}
            </button>
        </form>
</template>

<script>
// import $ from 'jquery'
import VueLoading from 'vue-loading-overlay'
import selectPickerCountry from '../sub-components/SelectPickerCountry.vue'
import { required } from 'vuelidate/lib/validators'
export default {
    data(){
        return {
            submitStatus: "",
            addressObj: {
                Type:Object
            },   
            countriesLoaded: false,
            countries: {
                Type: Array,
                default:[]
            },
             selectedCountry: {
                Type:Object
            },
            zones: {
                Type: Array
            },
            selectedCountryId: 0,
            isLoading: false
        }
    },
     validations: { // STANDARD
        addressObj: {           
            firstName:{
                required,
            },
            lastName:{
                required,
            },
            streetAddress:{
             required,
            },
            streetAddress1:{             
            },
            state:{             
            },
            city:{
             required,
            },
            postcode:{
             required,
            },
            title:{
            // required
            },  
            suburb:{
            // required
            },                           
            countryId:{
            // required
            },
            telephoneNumber:{
            //required
            }                 
        }
    },
    components:{
        'loading': VueLoading,
        'select-picker-country' : selectPickerCountry
    },
    props:{
        isEdit: {
            Type:Boolean
        },
        addressModel: {
            Type: Object,
            default: { id: 0 }
        },
        addressId: { default: 0 },
        callbackClose: { Type: Function },      
        customer: { Type: Object }
    },
    computed: {
        config(){
            return window.config;
        }
    },
    methods:{
        status(validation) 
        {
            console.log(validation);
            // var result = {
            //     error: validation.$error,
            //     dirty: validation.$dirty
            // }   
            return validation.$error;
        },
        validate(){
            this.$v.$touch();
            this.$forceUpdate();
        },
        selectCountry(countryId) {
            console.log("selectCountry");
            console.log(countryId);
            this.addressObj.countryId = countryId;      
        },
        onSubmit(){
            this.isLoading = true;
            this.submitStatus = "PENDING";
            this.$v.$touch();
            console.log(this.$v);
            
            if (this.$v.$invalid) {
                this.isLoading = false;
                this.submitStatus = "FAIL";
                this.$alert(this.$t('fillAllRequiredFields'));                
                return;
            }                

            if(this.isEdit && this.addressId > 0) 
            {
                var config = window.config;
                var vueInstance = this;
                var url = "";

                if(this.addressModel.isPrimary)
                {
                    url = config.API_BASE + 'TCPEditCustomerSubmitJ.action';     
                }
                else
                {
                    url = config.API_BASE + 'TCPEditAddrSubmitJ.action';
                }                                 

                vueInstance.addressObj.json = true;
     
                console.log(vueInstance.customer);  
                console.log(vueInstance.addressModel);

                var params = {
                    gender: vueInstance.addressObj.gender ?? vueInstance.customer.gender,
                    firstName:  vueInstance.addressObj.firstName ?? vueInstance.customer.firstName,
                    lastName:   vueInstance.addressObj.lastName ?? vueInstance.customer.lastName,
                    city:   vueInstance.addressObj.city ?? vueInstance.customer.city,
                    company:    vueInstance.addressObj.company ?? vueInstance.customer.company,
                    countryId:  vueInstance.addressObj.countryId.toString() ??  vueInstance.customer.countryId.toString(),
                    postcode:   vueInstance.addressObj.postcode ?? vueInstance.customer.postcode,
                    state:  vueInstance.addressObj.state ?? vueInstance.customer.state,
                    streetAddress:  vueInstance.addressObj.streetAddress ?? vueInstance.customer.streetAddress,
                    streetAddress1: vueInstance.addressObj.streetAddress1 ?? vueInstance.customer.streetAddress1,
                    suburb: vueInstance.addressObj.suburb ?? vueInstance.customer.suburb,                  
                    email:  vueInstance.addressObj.email ?? vueInstance.customer.email,
                    phone: vueInstance.addressObj.phone ?? vueInstance.customer.phone,
                    countryChange:  "0",
                    json: "true"
                }

                if(window.xtoken != undefined)
                {
                    params.xsrfToken = btoa(window.xtoken);
                }
                

                if(this.addressModel.isPrimary)
                {
                    params.telephoneNumber =  vueInstance.addressObj.telephoneNumber ?? vueInstance.customer.telephoneNumber;
                    params.telephoneNumber1 = vueInstance.addressObj.telephoneNumber1 ?? vueInstance.customer.telephoneNumber1;            
                }
                else
                {
                    params.phone =  vueInstance.addressObj.phone ?? vueInstance.customer.phone;
                    params.phone1 = vueInstance.addressObj.phone1 ?? vueInstance.customer.phone1;
                }

            //                 var params = {  
            //     "gender": "-",
            //     "json": "true",
            //     "allowNoRegister": "false",
            //     "firstName": this.edit.firstName,
            //     "lastName": this.edit.lastName,
            //     "company": this.edit.company,
            //     "taxId": "",
            //     "streetAddress": this.address.streetAddress,
            //     "streetAddress1": this.address.streetAddress1,
            //     "suburb": this.address.suburb,
            //     "postcode": this.address.postcode,
            //     "city":this.address.city,
            //     "state":this.address.state,
            //     "telephoneNumber":this.address.telephoneNumber,
            //     "telephoneNumber1": "89898989",
            //     "faxNumber": "12124545h",
            //     "countryChange": "0"
            // };

                // eslint-disable-next-line no-unused-vars
                var callbackSuccess= function(result, textStatus, jqXHR){     
                    vueInstance.isLoading = false;  
                    // vueInstance.addressModel = result;               
                    vueInstance.submitStatus = "SUCCESS";
                    vueInstance.callbackClose();
                }

                // eslint-disable-next-line no-unused-vars
                var callbackError = function(result, textStatus, jqXHR){
                    vueInstance.isLoading = false;
                    vueInstance.submitStatus = "FAIL";
                }
                
                // console.log(vueInstance.addressModel);
                vueInstance.makeRequest("POST", url, JSON.stringify(params), callbackSuccess, callbackError, "jsonp");
            }
            else
            {
                config = window.config;
                vueInstance = this;
                url = config.API_BASE + 'TCPNewAddrSubmitJ.action';     

                vueInstance.addressObj.json = true;          

            
                params = {
                    //gender:  (vueInstance.addressObj.gender ?? vueInstance.customer.gender) ?? config.ADDRESS_FORM_FIELDS.gender,
                    gender:  (config.ADDRESS_FORM_FIELDS.gender != "" ? config.ADDRESS_FORM_FIELDS.gender : vueInstance.customer.gender) ?? vueInstance.addressObj.gender,

                    // firstName:   (vueInstance.addressObj.firstName ?? vueInstance.customer.firstName) ?? config.ADDRESS_FORM_FIELDS.firstName,
                    // lastName:    (vueInstance.addressObj.lastName ?? vueInstance.customer.lastName) ?? config.ADDRESS_FORM_FIELDS.lastName,
                    // city:    (vueInstance.addressObj.city ?? vueInstance.customer.city) ?? config.ADDRESS_FORM_FIELDS.city,
                    // company:     (vueInstance.addressObj.company ?? vueInstance.customer.company) ?? config.ADDRESS_FORM_FIELDS.company,
                    // countryId:  ( vueInstance.addressObj.countryId ?? vueInstance.customer.addresses[0].countryId.toString()) ?? config.ADDRESS_FORM_FIELDS.countryId,
                    // postcode:   ( vueInstance.addressObj.postcode ?? vueInstance.customer.postcode) ?? config.ADDRESS_FORM_FIELDS.postcode,
                    // state:  (vueInstance.addressObj.state ?? vueInstance.customer.state) ?? config.ADDRESS_FORM_FIELDS.state,
                    // streetAddress:  ( vueInstance.addressObj.streetAddress ?? vueInstance.customer.streetAddress) ?? config.ADDRESS_FORM_FIELDS.streetAddress,
                    // streetAddress1:  (vueInstance.addressObj.streetAddress1 ?? vueInstance.customer.streetAddress1) ?? config.ADDRESS_FORM_FIELDS.streetAddress1,
                    // suburb: ( vueInstance.addressObj.suburb ?? vueInstance.customer.suburb) ?? config.ADDRESS_FORM_FIELDS.suburb,
                    // telephoneNumber:  ( vueInstance.addressObj.telephoneNumber ?? vueInstance.customer.telephoneNumber) ?? config.ADDRESS_FORM_FIELDS.telephoneNumber,
                    // phone1:  (vueInstance.addressObj.phone1 ?? vueInstance.customer.phone1) ?? config.ADDRESS_FORM_FIELDS.phone1,
                    // email:   (vueInstance.addressObj.email ?? vueInstance.customer.email) ?? config.ADDRESS_FORM_FIELDS.email,
                    // countryChange:  "0",
                    // json: "true"

                    firstName:    (config.ADDRESS_FORM_FIELDS.firstName != "" ? config.ADDRESS_FORM_FIELDS.firstName : vueInstance.addressObj.firstName) ?? vueInstance.customer.firstName,
                    lastName:    (config.ADDRESS_FORM_FIELDS.lastName != "" ? config.ADDRESS_FORM_FIELDS.lastName : vueInstance.addressObj.lastName) ?? vueInstance.customer.lastName,
                    city:    (config.ADDRESS_FORM_FIELDS.city != "" ? config.ADDRESS_FORM_FIELDS.city : vueInstance.addressObj.city) ?? vueInstance.customer.city,
                    company:     (config.ADDRESS_FORM_FIELDS.company != "" ? config.ADDRESS_FORM_FIELDS.company : vueInstance.addressObj.company) ?? vueInstance.customer.company,
                    countryId:  (config.ADDRESS_FORM_FIELDS.countryId != "" ? config.ADDRESS_FORM_FIELDS.countryId : vueInstance.addressObj.countryId) ?? vueInstance.customer.addresses[0].countryId.toString(),//( vueInstance.addressObj.countryId ?? vueInstance.customer.addresses[0].countryId.toString()) ?? config.ADDRESS_FORM_FIELDS.countryId,
                    postcode:    (config.ADDRESS_FORM_FIELDS.postcode != "" ? config.ADDRESS_FORM_FIELDS.postcode : vueInstance.addressObj.postcode) ?? vueInstance.customer.postcode,
                    state:   (config.ADDRESS_FORM_FIELDS.state != "" ? config.ADDRESS_FORM_FIELDS.state : vueInstance.addressObj.state) ?? vueInstance.customer.state,
                    streetAddress:   (config.ADDRESS_FORM_FIELDS.streetAddress != "" ? config.ADDRESS_FORM_FIELDS.streetAddress : vueInstance.addressObj.streetAddress) ?? vueInstance.customer.streetAddress,
                    streetAddress1:  (config.ADDRESS_FORM_FIELDS.streetAddress1 != "" ? config.ADDRESS_FORM_FIELDS.streetAddress1 : vueInstance.addressObj.streetAddress1) ?? vueInstance.customer.streetAddress1,
                    suburb: (config.ADDRESS_FORM_FIELDS.suburb != "" ? config.ADDRESS_FORM_FIELDS.suburb : vueInstance.addressObj.suburb) ?? vueInstance.customer.suburb,
                    telephoneNumber:   (config.ADDRESS_FORM_FIELDS.telephoneNumber != "" ? config.ADDRESS_FORM_FIELDS.telephoneNumber : vueInstance.addressObj.telephoneNumber) ?? vueInstance.customer.telephoneNumber,
                    phone1:  (config.ADDRESS_FORM_FIELDS.phone1 != "" ? config.ADDRESS_FORM_FIELDS.phone1 : vueInstance.addressObj.phone1) ?? vueInstance.customer.phone1,
                    email:  (config.ADDRESS_FORM_FIELDS.email != "" ? config.ADDRESS_FORM_FIELDS.email : vueInstance.addressObj.email) ?? vueInstance.customer.email,
                    countryChange:  "0",
                    json: "true"
                }

                
                if(window.xtoken != undefined)
                {
                    params.xsrfToken = btoa(window.xtoken);
                }

            //                 var params = {  
            //     "gender": "-",
            //     "json": "true",
            //     "allowNoRegister": "false",
            //     "firstName": this.edit.firstName,
            //     "lastName": this.edit.lastName,
            //     "company": this.edit.company,
            //     "taxId": "",
            //     "streetAddress": this.address.streetAddress,
            //     "streetAddress1": this.address.streetAddress1,
            //     "suburb": this.address.suburb,
            //     "postcode": this.address.postcode,
            //     "city":this.address.city,
            //     "state":this.address.state,
            //     "telephoneNumber":this.address.telephoneNumber,
            //     "telephoneNumber1": "89898989",
            //     "faxNumber": "12124545h",
            //     "countryChange": "0"
            // };

                // eslint-disable-next-line no-unused-vars
                callbackSuccess= function(result, textStatus, jqXHR){      
                    vueInstance.isLoading = false; 
                    vueInstance.addressModel = result;               
                    vueInstance.submitStatus = "SUCCESS";
                    vueInstance.callbackClose();
                }

                // eslint-disable-next-line no-unused-vars
                callbackError = function(result, textStatus, jqXHR){
                    vueInstance.isLoading = false;
                    vueInstance.submitStatus = "FAIL";
                }
                
                console.log(vueInstance.addressModel);
                vueInstance.makeRequest("POST", url, JSON.stringify(params), callbackSuccess, callbackError);
            }          
        }
    },
    mounted(){
        this.addressObj = this.addressModel;
        if(this.isEdit && this.addressId > 0) 
        {
            var config = window.config;
            var vueInstance = this;
            var url = config.API_BASE + 'TCPEditAddrJ.action?json=true&addrId='+ vueInstance.addressId;     

            var params = {};

            var callbackSuccess= function(result, textStatus, jqXHR){    
                console.log(result);
                console.log(textStatus);
                console.log(jqXHR);  
                vueInstance.validate();
                return;
            }

            var callbackError = function(result, textStatus, jqXHR){
                console.log(result);
                console.log(textStatus);
                console.log(jqXHR);
            }
            
            vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);
            
        }
        else
        {
            config = window.config;
            vueInstance = this;
            url = config.API_BASE + 'TCPNewAddrJ.action';     

            params = {
                json: "true"
            };

            callbackSuccess= function(result, textStatus, jqXHR){    
                console.log(result);
                console.log(textStatus);
                console.log(jqXHR);
                // vueInstance.countriesLoaded = true;
                vueInstance.validate();
                return;
            }

            callbackError = function(result, textStatus, jqXHR){
                console.log(result);
                console.log(textStatus);
                console.log(jqXHR);
            }
            
            vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);
        }
    },
    beforeMount(){
        var config = window.config;
        var vueInstance = this;
        var url = config.API_BASE + 'TCPCustomerRegistrationJ.action';        
        var params = {
            json: "true",
            countryChange : 0
        };        

        // eslint-disable-next-line no-unused-vars
        var callbackSuccess= function(result, textStatus, jqXHR) {          
            vueInstance.countries = result.countries;       
            // vueInstance.selectedCountry = result.selectedCountry;
            // vueInstance.addressObj.countryId = result.selectedCountry.id;  
            vueInstance.selectedCountryId = result.selectedCountry.id;     
        
            vueInstance.countriesLoaded = true;
            vueInstance.$forceUpdate();
            //vueInstance.selectCountry(result.selectedCountry.id);
        }

        // eslint-disable-next-line no-unused-vars
        var callbackError = function(result, textStatus, jqXHR){  
        }

        vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);
    }
}
</script>

<style scoped>
form {
    padding: 0 10% 0 10%;
}

@media screen and (max-width:768px) {
    form {
        padding: 0 0% 0 0%;
    } 
}
</style>