// ShopPage.vue

<template>
    <div class="shopCards startPageBackgroundImage">

        <modal ref="shopModal" >
            <template v-slot:header>                
                <h1>{{selectedShop.name}}</h1>
                <!-- <h2 :html="selectedShop.addresses[0].formattedAddress"></h2> -->
            </template>

            <template v-slot:body>
                <div class="col-12">
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" style="padding-left:0;" >
                        <!-- :style="'height:inherit;background-image:url(' + selectedShop.image + ');'" -->
                        <div style="text-align:start; padding-bottom:10px;" v-if="showShopExtraDetails" v-html="selectedShop.custom6"> </div>
                        
                        <div style="text-align:start" v-if="shopShopModalAddress" v-html="selectedShop.addresses[0].formattedAddress"></div>
                        
                        <img class="imageModal" :src="selectedShop.image" />
                    </div> 
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" style="padding:0;">
                        <order-options-page></order-options-page>  
                    </div>     
                </div>
            </template>

            <template v-slot:footer>
                <div class="d-flex align-items-center justify-content-between">
                <!-- <button class="btn btn--secondary" @click="$refs.modalName.closeModal()">Cancel</button> -->
                <!-- <button class="btn btn--primary" @click="$refs.modalName.closeModal()">Continue</button> -->
                </div>
            </template>
        </modal>
        
        <loading :active.sync="isLoading" 
            :can-cancel="true"        
            :is-full-page="true"
            loader='bars'></loading>   

        <div v-if="showChoice" class="shopCards"> <!-- wrapper to prevent showing an empty shop-card when the element is still loading -->
            <div class="shopPageTitle">
                    {{ $t('shopPageTitle') }}
            </div>   
            <shop-carousel v-if="showCarousel" :shops="filterShops(shops)" :callbackSelect="selectShop"> </shop-carousel> 
            <div class="col-12 shopCardWrapper" style="padding: 2% 10% 15% 10%; align-items: center;justify-content: center;">
                <shop-card v-for="shop in shops" v-bind:key="shop.id" :id="shop.id" :addresses="shop.addresses" :visible="shopIsAvailable(shop.custom1) && ((shop.custom9 != 1 && config.FILTER_SHOPS_CUSTOM9_FLAG)||(!config.FILTER_SHOPS_CUSTOM9_FLAG))" :image="shop.image" :name="shop.name" :url="shop.custom10" :code="shop.custom2" :callback="selectShop"></shop-card>
            </div>
            
        </div>            

        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
    </div>
</template>
<script>

import Modal from '../sub-components/Modal.vue'
import ShopCard from '../main-components/ShopCard.vue'
import axios from 'axios'
import VueLoading from 'vue-loading-overlay'
import Carousel from '../sub-components/ShopCarousel.vue'
import OrderOptionsPage from '../page-components/OrderOptionsPage.vue'
import $ from 'jquery'

export default {
    data() {
        return {
            isLoading: false,
            shops: {
                Type: Array
            },
            visible : true,
            showChoice: false,
            selectedShop: {
                Type: Object
            },
            shopsFilter: {
                Type: Array
            },
            shopIdPreselected: 0,
            jwtString: "",
            showCarousel: true,
            shopShopModalAddress: false,
            showShopExtraDetails: false
        }
    },
    computed:{
        config() {
            return window.config;
        }
    },
    beforeMount(){
        var vueInstance = this;
        if((vueInstance.jwtString != "" && vueInstance.jwtString != undefined && vueInstance.jwtString != null) || (vueInstance.code != "" && vueInstance.code != undefined && vueInstance.code != null))
        {
            vueInstance.isLoading = true;
        }

        if(window.preselectedShopCode !== undefined)
        {
            vueInstance.shopIdPreselected = window.preselectedShopCode;
        }
        else
        {
            vueInstance.shopIdPreselected = vueInstance.shopId;
            window.preselectedShopCode = vueInstance.shopId;
        }   
        
        if(this.jwt != undefined){
            window.jwt = this.jwt;
        }
        if (this.code != undefined) {
            window.ssoCode = this.code;
        }
    },
    mounted() {   
        this.showChoice = false;
         var vueInstance = this;
         var config = window.config;  

            if((vueInstance.jwtString != "" && vueInstance.jwtString != undefined && vueInstance.jwtString != null) || (vueInstance.code != "" && vueInstance.code != undefined && vueInstance.code != null))
            {
                vueInstance.isLoading = true;
            }

        if(config.TABLE_MODE_OVERRIDE == true)
        {
            if (window.shopId != undefined && window.tableId != undefined) {
                if (vueInstance.jwt != undefined || vueInstance.code != undefined) {                  
                    let path = "/QRLanding?s=" + window.shopId + "&t=" + window.tableId; 
                    if (vueInstance.jwt != undefined) {
                    path = path + "&id_token=" + vueInstance.jwt; 
                    } else if(vueInstance.code != undefined){
                    path = path + "&code=" + vueInstance.code; 
                    }
                    window.jwt = undefined;
                    window.ssoCode = undefined;
                    vueInstance.$router.push({path:path});
                }else{
                    //nur mit shop und tableid senden
                    let path = "/QRLanding?s=" + window.shopId + "&t=" + window.tableId; 
                    vueInstance.$router.push({path:path});
                }
                }else{
                    let path = "/QRLanding?h=true"; 
                    vueInstance.$router.push({path:path});
            }
            

            if(window.tableId == undefined || window.tableId == null){
                window.location.href = config.TABLE_MODE_OVERRIDE_HOMEPAGE;
            }else{
                vueInstance.$router.push( { path: "/Menu" } );
            }
            return; 
        }
        this.checkJWT();
        vueInstance.shopIdPreselected = vueInstance.shopId;  
        vueInstance.isLoading = true;
        setTimeout(() => {   
            
            
            // if(window.customerLoggedIn === undefined && window.config.FORCE_LOGIN_BEFORE_USE == true && !(vueInstance.jwt != "" && vueInstance.jwt != undefined && vueInstance.jwt != null) || (vueInstance.code != "" && vueInstance.code != undefined && vueInstance.code != null))
            // {
            //     console.log("Routing to LOGIN");
            //     vueInstance.$router.push( { path: "/Login" } );
            //     return;
            // }       
            
            // vueInstance.shopIdPreselected = vueInstance.shopId;       
                
            if(window.screen.width > 700 && config.SHOW_GLOBAL_HEADER_BANNER_IMAGE == true)
            {
                $(".header").addClass("header-big");
                $(".content").addClass("header-big-content-margin");   
                $("#content-wrapper").bind("wheel", function (event) {
                    vueInstance.handleWheelContent(event);
                });
            }
            else
            {
                $(".content").addClass("header-small-content-margin");   
            }                 
            
            var instance = this;                
            
            instance.visible = true;
            window.promotionBarcode = "";
            axios.post(config.API_BASE + 'TCPShopsJ.action', JSON.stringify({
            emptyBasket: true,
            json: "true"
            })).then(function (response) {
                vueInstance.isLoading = false;
                instance.isLoading = false;
                instance.shops = JSON.parse(response.request.response).shops;

                if(JSON.parse(response.request.response).emptyBasket)
                {
                    window.shoppingCart = JSON.parse(response.request.response).basketTile.basketItems;
                    instance.updateShoppingCart();
                }

                if(instance.shops.length === 0)
                {
                    instance.$alert(vueInstance.$t('noShopsAvailable'));
                    return;
                }
                
                if(instance.shops.length === 1 && config.FLAG_AUTOSELECTSHOP)
                {
                    instance.selectShop(instance.shops[0].id);
                }

                instance.visible = false;   

                if(vueInstance.shopIdPreselected > 0)
                {
                    console.log("ShopCode was passed! auto-selecting.");
                    vueInstance.selectShopCode(vueInstance.shopIdPreselected);            
                }
                
                instance.showChoice = true;      

                // if(window.locale == undefined || window.locale == null){
                //     vueInstance.changeLocale(vueInstance.$i18n.locale);
                // }
            }); 

            instance.updateShoppingCart();
            instance.visible = false;
            window.shopObj = null;                
            // if(window.config.COOKIES_ENABLED){
            //     vueInstance.$cookies.remove('shopObj');
            // }
            window.timeString = null;      
        }, 5);     
               
    
    },
    props: {
        callback: {
            Type: Function
        },
        jwt : {
            Type:String,
            Default: "",
        },
        shopId:{
            Type: Number,
            Default: 0
        },
        code: {
            Type:String,
            Default: "",
        },
    },
    methods: { 
        getToken(){
            var config = window.config;
            var vueInstance = this;
            var url = config.API_BASE + 'TCPLoginJ.action';     

            var params = {
                json: "true"
            };

            // eslint-disable-next-line no-unused-vars
            var callbackSuccess= function(result, textStatus, jqXHR){     
                if(result.xsrfToken != undefined)
                {
                    window.xtoken = atob(result.xsrfToken);
                }                                          
            }

            // eslint-disable-next-line no-unused-vars
            var callbackError = function(result, textStatus, jqXHR){
            }

            vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);
        },  
        checkJWT(){
            var config = window.config;
            this.showCarousel = config.SHOW_SHOP_CAROUSEL;
            this.shopShopModalAddress = config.SHOW_SHOP_DETAILS_ADDRESS;
            this.showShopExtraDetails = config.SHOW_SHOP_EXTRA_DETAILS;
            window.shopSelected = false;
            var vueInstance = this;
            var url = "";
            vueInstance.isLoading = true;
            vueInstance.jwtString = vueInstance.jwt;
            console.log(vueInstance.jwtString);
            console.log(vueInstance.code);
            console.log(vueInstance.shopId);
            if((vueInstance.jwtString != "" && vueInstance.jwtString != undefined && vueInstance.jwtString != null) || (vueInstance.code != "" && vueInstance.code != undefined && vueInstance.code != null))
            {
                vueInstance.getToken();
                
                url = config.API_BASE + 'TCPLoginWithJWTJ.action';

                var paramsJWT = {
                    // jwt: vueInstance.jwtString,
                    returnCustomerData: "true"                       
                };
                
                if(vueInstance.jwtString != "" && vueInstance.jwtString != undefined && vueInstance.jwtString != null)
                {
                    paramsJWT.jwt = vueInstance.jwtString;                    
                }

                // if( window.preselectedShopCode != undefined )
                // {
                //     paramsJWT.code = window.preselectedShopCode;
                // }

                if(vueInstance.code != "" && vueInstance.code != undefined && vueInstance.code != null)
                {
                    window.preselectedShopCode = vueInstance.code;
                    paramsJWT.code = vueInstance.code;
                }

                

                // eslint-disable-next-line no-unused-vars
                var callbackSuccessJWT= function(result, textStatus, jqXHR){   
                    vueInstance.isLoading = false;     
                    vueInstance.showChoice = true;
                    
                    if(result.exception !== undefined)
                    {
                        console.log("SSO Login Failed - Routing to LOGIN");
                        vueInstance.$alert(vueInstance.$t('SSOLoginError'));
                        vueInstance.$router.push( { path: "/Login" } );
                        return;
                    }

                    if(result.requiresLogin == true)
                    {
                        console.log("Routing to LOGIN");
                        vueInstance.$router.push( { path: "/Login" } );
                        return;
                    } 

                    if(result.customer == undefined)
                    {
                        console.log("JWT Login unsuccesful!");
                        if(result.requiresLogin == false)
                        {
                            console.log("A user is already logged in, JWT ignored!");
                            return;
                        }
                        if(config.FORCE_LOGIN_BEFORE_USE)
                        {
                            console.log("Routing to LOGIN");
                            vueInstance.$router.push( { path: "/Login" } );
                            return;
                        }
                    }
                    
                    vueInstance.customer = result.customer;
                    window.customerLoggedIn = true;
                    vueInstance.shopsFilter = result.wondCustomer.availableShops;
            
                    if(vueInstance.shopIdPreselected > 0)
                    {
                        console.log("ShopCode was passed! auto-selecting.");
                        vueInstance.selectShopCode(vueInstance.shopIdPreselected);
                    }

                    if(config.IS_PURE_ACCOUNTMANAGER){
                        console.log("Routing to Account");
                        vueInstance.$router.push( { path: "/Account" } );
                    }

                    return;
                }              

                // eslint-disable-next-line no-unused-vars
                var callbackErrorJWT = function(result, textStatus, jqXHR){
                    vueInstance.isLoading = false;
                    vueInstance.showChoice = true;
                }

                vueInstance.makeRequest("GET", url, JSON.stringify(paramsJWT), callbackSuccessJWT, callbackErrorJWT);
            }
            else
            {               
                vueInstance.showChoice = true;
                
                vueInstance.isLoading = false;

                if(config.IS_PURE_ACCOUNTMANAGER){
                    console.log("Routing to Account");
                    vueInstance.$router.push( { path: "/Account" } );
                }

                if (!config.FORCE_LOGIN_BEFORE_USE)
                {
                    return;
                }
                vueInstance.forceLogin();
            }
        },
        filterShops(shops){  
            if(window.config.FILTER_SHOPS_CUSTOM9_FLAG == true)
                return shops.filter(shop => this.shopIsAvailable(shop.custom1) && shop.custom9 != 1);
            else
                return shops.filter(shop => this.shopIsAvailable(shop.custom1));
        },
        shopIsAvailable(shopId) {
            if(this.showChoice == false)
            {
                return true;
            }

            if(shopId == undefined) 
            {
                return true;
            }

            if(this.shopsFilter.length == 0)
            {
                return true;
            }

            else
            {
                if(this.shopsFilter == undefined) return true;
                if(this.shopsFilter == null) return true;
                if(typeof this.shopsFilter.filter !== "function") return true;              
                if(this.shopsFilter.filter(shop => shop.shopId.toString() === shopId.toString()).length > 0)
                {
                    return true;
                }
                else
                {
                    return false;
                }                
            }
        },
        changeLocale(locale) {    
            this.$i18n.locale = locale;       
            var vueInstance = this;
            var config = window.config;
            var parms = {
                appLocale: locale,
                "json": "true"
            };

            var url = config.API_BASE + 'TCPSetLocaleJ.action';

            // eslint-disable-next-line no-unused-vars
            var callbackSuccess = function(data, textStatus, request) {
                console.log("language Change successfull " + locale);
                window.locale = locale;
                document.documentElement.lang = config.LANGUAGE_LIST.find(lang => lang.value === locale).tag;
                if(window.timeString != null && window.timeString != undefined)
                    window.timeString = window.timeString + ' ';
                //vueInstance.$router.push({ path: config.AFTER_SHOP_PAGE });   
                vueInstance.updateDietaryList();             
            }

            // eslint-disable-next-line no-unused-vars
            var callbackError = function(){
                //vueInstance.$router.push({ path: config.AFTER_SHOP_PAGE });
            }

            // let loader = this.$loading.show({
            //     loader: 'dots'
            // });            

            vueInstance.makeRequest("GET", url, JSON.stringify(parms), callbackSuccess, callbackError);
        },  
        updateDietaryList(){
            var vueInstance = this;
            var config = window.config;
            var parms = {
                "json": "true"
            };

            var url = config.API_BASE + 'TCPGetDietaryInfoTypesJ.action';

            // eslint-disable-next-line no-unused-vars
            var callbackSuccess = function(result, textStatus, jqXHR) {
                window.allergens = result.allergens;
                window.nutriValues = result.nutriValues;
                window.dietaryProperties = result.proberties;
            }

            // eslint-disable-next-line no-unused-vars
            var callbackError = function(){
                //vueInstance.$router.push({ path: config.AFTER_SHOP_PAGE });
            }

            vueInstance.makeRequest("GET", url, JSON.stringify(parms), callbackSuccess, callbackError);
        },  
        forceLogin(){
            var config = window.config;        
            var vueInstance = this;
            var url = "";
            url = config.API_BASE + 'TCPAfterLoginJ.action';     

            var params = {
                json: "true",       
            };

            // eslint-disable-next-line no-unused-vars
            var callbackSuccess= function(result, textStatus, jqXHR){   
                if(result.requiresLogin == true)
                {
                    console.log("Routing to LOGIN");
                    vueInstance.$router.push( { path: "/Login" } );
                    return;
                }    
                
                vueInstance.customer = result.customer;
                vueInstance.shopsFilter = result.wondCustomer.availableShops;

                if(result.customer != undefined)
                {
                    // JP 20221005
                    // window.customerLoggedIn = true;
                    
                    if(result.customer.type != undefined)
                    {
                        if(result.customer.type == 2)
                        {
                            window.isGuestCheckout = true;
                        }
                    }
                }else{
                    // JP 20221005
                    if(window.config.FORCE_LOGIN_BEFORE_USE == true)
                    {
                        console.log("Routing to LOGIN");
                        vueInstance.$router.push( { path: "/Login" } );
                        return;
                    }   
                }
            }

            // eslint-disable-next-line no-unused-vars
            var callbackError = function(result, textStatus, jqXHR){
                vueInstance.isLoading = false;
            }          

            vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);  
        },
        updateShoppingCart: function() {
            var config = window.config;
            var vueInstance = this;
            var url = config.API_BASE + 'TCPConfirmOrderJ.action';   
            var params = {};
            if(window.customerLoggedIn)
            {
                params = {
                    calculateOrder: "true",
                    orderType: window.orderType,
                    promotionBarcode: window.promotionBarcode != undefined ? window.promotionBarcode : ""
                };
                
            }  
  
            
            var callbackSuccess= function(result){       
                window.shoppingCart = result.basketItems;
                if(result.basketTile.paymentsList != undefined){
                    window.subsidy = vueInstance.formatCurrency(result.basketTile.paymentsList[0].paymentAmount);
                }else{
                    window.subsidy = "";
                }
                if(result.xsrfToken != undefined)
                {
                    window.xtoken = atob(result.xsrfToken);
                } 
            }

            var callbackError = function(){
            }

            vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);
        },
        formatCurrency(value){
            var formatter = new Intl.NumberFormat('de-DE', {style: 'currency', currency: window.config.SUBSIDY_CURRENCY});
            return formatter.format(value);
        },
        maximizeHeader() {
            var config = window.config;
            if(config.SHOW_GLOBAL_HEADER_BANNER_IMAGE)
            {
                $(".header").addClass("header-big");
                $(".header").addClass("animTransition");
                $(".content").addClass("header-big-content-margin");
                $(".content").removeClass("header-small-content-margin");
            }
           
        },
        minimizeHeader() {
            var config = window.config;
            if(config.SHOW_GLOBAL_HEADER_BANNER_IMAGE)
            {
                $(".header").removeClass("header-big");
                $(".header").addClass("animTransition");
                $(".content").removeClass("header-big-content-margin");
                $(".content").addClass("header-small-content-margin");
            }       
        },
        handleWheelContent(event) {
            // console.log(event.originalEvent.deltaY);
            // if (event.originalEvent.deltaY < 0 && $("#content-wrapper").scrollTop() == 0)
            // {       
            //     this.maximizeHeader();         
            // }
            // else if (event.originalEvent.deltaY > 0)
            // {
            //     if($(".header").hasClass("header-big"))
            //     {
            //         var myDiv = document.getElementById('content-wrapper');
            //         myDiv.scrollTop = 0;
            //         this.minimizeHeader();            
            //     }  
            // }    
            if(event.originalEvent == undefined)
            {
                return;
            }

            if (event.originalEvent.deltaY > 0)
            {
                this.minimizeHeader();    
            }    
        },
        selectShop(shopId, shopCode){
            var vueInstance = this;
            vueInstance.isLoading = true;
            var config = window.config;
            var parms = {
                id: shopId,
                "json": "true"
            };

            var url = config.API_BASE + 'TCPSelectShopJ.action';

            // eslint-disable-next-line no-unused-vars
            var callbackSuccess = function(data, textStatus, request) {
                vueInstance.isLoading = false;
                console.log("Select Shop success: " + shopId);
                window.shopSelected = true;                 
                window.shop = shopId;
                console.log(vueInstance.shops);
                console.log(shopId);
                var shopcopyid = shopId;
                vueInstance.selectedShop = vueInstance.shops.filter(shop => shop.id.toString() === shopcopyid.toString())[0];
                console.log(config.AGE_VERIFICATION_SHOPCODES);
                console.log(shopCode);
                if(config.AGE_VERIFICATION_SHOPCODES.includes(shopCode))
                {
                    window.ageVerification = true; 
                }
                else
                {
                    window.ageVerification = false; 
                }
                window.shopObj =vueInstance.selectedShop;
                // if(window.config.COOKIES_ENABLED){
                //     vueInstance.$cookies.set('shopObj', vueInstance.selectedShop);
                // }

                setTimeout(() => {                        
                    vueInstance.$refs.shopModal.openModal();
                }, 300);
                
                //vueInstance.$router.push({ path: config.AFTER_SHOP_PAGE });                
            }

            // eslint-disable-next-line no-unused-vars
            var callbackError = function(){
                vueInstance.isLoading = false;
                //vueInstance.$router.push({ path: config.AFTER_SHOP_PAGE });
            }

            // let loader = this.$loading.show({
            //     loader: 'dots'
            // });            

            vueInstance.makeRequest("GET", url, JSON.stringify(parms), callbackSuccess, callbackError);

            // loader.hide();
        },

        selectShopCode(shopCode){
            var vueInstance = this;
            var config = window.config;
            vueInstance.isLoading = true;
            var shopcopycode = shopCode;     
            var shop = vueInstance.shops.filter(shop => shop.custom2.toString() === shopcopycode)[0];
            if(shop == undefined)
            {
                console.log("ShopCode " + shopCode + " not found! Shop could not be auto-selected!")
                return;
            }

            var parms = {
                id: shop.id,
                "json": "true"
            };

            var url = config.API_BASE + 'TCPSelectShopJ.action';

            // eslint-disable-next-line no-unused-vars
            var callbackSuccess = function(data, textStatus, request) {
                vueInstance.isLoading = false;
                console.log("Select Shop success: " + shopCode);                
                vueInstance.selectedShop = vueInstance.shops.filter(shop => shop.custom2.toString() === shopcopycode)[0]; 
                window.shopSelected = true;                                 
                window.shop = vueInstance.selectedShop.id;                           
                window.shopObj = vueInstance.selectedShop; 
                // if(window.config.COOKIES_ENABLED){
                //     vueInstance.$cookies.set('shopObj', vueInstance.selectedShop);
                // }
                vueInstance.$refs.shopModal.openModal()
                if(config.AGE_VERIFICATION_SHOPCODES.includes(shopCode))
                {
                    window.ageVerification = true; 
                }
                else
                {
                    window.ageVerification = false; 
                }
                //vueInstance.$router.push({ path: config.AFTER_SHOP_PAGE });                
            }

            // eslint-disable-next-line no-unused-vars
            var callbackError = function(){
                vueInstance.isLoading = false;
                //vueInstance.$router.push({ path: config.AFTER_SHOP_PAGE });
            }

            // let loader = this.$loading.show({
            //     loader: 'dots'
            // });            

            vueInstance.makeRequest("GET", url, JSON.stringify(parms), callbackSuccess, callbackError);

            // loader.hide();
        },

        makeRequest: function (type, url, data, callbackSuccess, callbackError, dataType) {
            console.log("ajax request:");
            console.log(type);
            console.log(url);
            $.ajax({
                method : type,
                type : type,
                timeout : '20000',
                headers: {
                    "Access-Control-Allow-Origin":"*",
                },
                scriptCharset : "utf-8",
                contentType : "application/json; charset=utf-8",        
                url : url,
                data : "kkj=kkj&" + encodeURIComponent(data),
                // context : callback,
                success : callbackSuccess,
                error: callbackError,
                dataType : dataType != undefined ? dataType :'jsonp'
            });
            window.REQUEST_TIMESTAMP = new Date().getTime();
        },
    },
    components: {
        'shop-card' : ShopCard,
        'shop-carousel' : Carousel,
        'loading': VueLoading,
        'modal' : Modal,
        'order-options-page':OrderOptionsPage
    },
    // eslint-disable-next-line no-unused-vars
    beforeRouteEnter (to, from, next) {
         // eslint-disable-next-line no-unused-vars
         next(vm => {          
            var myDiv = document.getElementById('contentSection');
            $("#contentSection").scrollTop(0);
            $("#app").scrollTop(0);
            
            if(myDiv != undefined)
              if(myDiv != null)
                myDiv.scrollTop = 0;                            
        })        
    },
}
</script>

<style scoped>
    .shopCardWrapper {
        margin-bottom:50px;
        display:flow-root;
    } 
@media screen and (max-width:770px) {
    .shopCardWrapper {
        display:flow-root;
    }
}

</style>