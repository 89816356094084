<template>
    <div class="accountPageWrapper">
        <loading :active.sync="isLoading" 
            :can-cancel="true"        
            :is-full-page="true"
            loader='bars'></loading>   

        <div class="col-12 subPageHeader row" style="">
            <h1>{{ $t('accountPageTitle') }}</h1>
            <button class="btn btn-primary subPageHeader subPageHeaderButton" v-if="!config.IS_PURE_ACCOUNTMANAGER" @click="goBack(false)">
               {{$t('back')}}
            </button>

            <div class="ml-auto row" style="padding:0;">
                <div class="" v-if="showEditAddressForm == false && showNewAddressForm == false">
                    <button class="btn btn-primary btn-justify btn-right" @click="changePassword">{{$t('buttonChangePassword')}}</button>
                </div>
                <div class="" v-if="showEditAddressForm == false && showNewAddressForm == false">
                    <button class="btn btn-primary btn-justify btn-right" @click="logout">{{$t('buttonLogout')}}</button>
                </div>

            </div>
        </div>                 

        <modal ref="topUpModal">
            <template v-slot:header>
                <h2>{{$t('topUpModalTitle')}}</h2>
                <!-- <div>{{timeRemaining()}}</div> -->
            </template>

            <template v-slot:body>
                <div v-if="!showTopUpPayment">
                    <button  v-for="topUpValue in config.TOPUP_PREDEFINED_VALUES" v-bind:key="topUpValue.value" class="btn btn-primary topUpBtn" @click="topUpAmount = topUpValue.value; confirmTopUp()" :aria-label="$t('topUpAmountSelectAriaLabel') + topUpValue.title">{{topUpValue.title}}</button>
                </div>

                <input v-if="!showTopUpPayment" class="form-control form-control-lg topUpInputField" type="number" :placeholder="$t('topUpInputPlaceholder')" v-model="topUpAmount" />         

                <div class="col-12">
                    <button v-if="!showTopUpPayment" class="btn btn-primary btn-lg" style="margin-top: 0px;" @click="confirmTopUp">{{$t('confirm')}}</button>   
                </div>

                <div v-if="showTopUpPayment">
                    <!-- <div v-if="paymentList.length == 1">
                        <div class="fontSubTitle col-12 paymentSelectTitle">
                            {{$t('topUpPayTitle')}} {{paymentList[0].subCode != undefined ? paymentList[0].subCode : paymentList[0].title}}
                        </div>
                        <button type="button" class="btn btn-primary" style="margin-top:0px; height:45px;" @click="onSubmitTopUp(paymentList[0])">{{$t('topUpPayNow')}}</button>
                    </div> -->
                    <div v-if="paymentList.length > 1">
                        <div class="fontSubTitle col-12 paymentSelectTitle" v-if="!showFreeButton">
                            {{$t('PleaseSelectPayment')}}
                        </div>      
                        <div class="col-12" v-if="paymentList.length > 0">
                            <!-- <check-box @change="onPaymentSelect" 
                                v-for="payment in paymentList" :key="payment.subCode == undefined ? payment.code : (payment.code + '~~' + payment.subCode)" 
                                :label="payment.subCode != undefined ? payment.subCode : payment.title"                         
                                :value="payment.code"   
                                :subCode="payment.subCode"
                                v-model="currentPayment">
                            </check-box> -->
                            <div class="form-check form-check-inline" v-for="payment in paymentList" :key="payment.subCode == undefined ? payment.code : (payment.code + '~~' + payment.subCode)" >
                                <input @change="onPaymentSelect" 
                                    type="radio"
                                    style="appearance: auto"
                                    name="paymentSelect"
                                    class="form-check-input"
                                    :id="payment.code + payment.subCode"
                                    :label="payment.subCode != undefined ? payment.subCode : payment.title"                         
                                    :value="payment.code"   
                                    :subCode="payment.subCode"
                                    v-bind="currentPayment">
                                <!-- <input 
                                    type="radio"
                                    style="appearance: auto"
                                    :name="payment.code"
                                    class="form-check-input"
                                    :value="payment.code"   
                                    v-bind="currentPayment"> -->
                                <label class="form-check-label" :for="payment.code + payment.subCode">{{ $t(payment.subCode != undefined ? payment.subCode : payment.title) }}</label>
                            </div>
                            <!-- <div class="form-check form-check-inline">
                                <input class="corm-check-input" id="radio2" type="radio" style="appearance: auto" name="a12">
                                <label class="form-check-label" for="radio2"> test</label>
                            </div> -->

                        </div> 
                        <button type="button" class="btn btn-primary" style="margin-top:0px; height:45px;" v-if="currentlySelectedPayment == 'cod' || currentlySelectedPayment == 'customercard' || currentlySelectedPayment == 'TCPDebitor'" @click="onSubmit(false)"> {{$t('submitPayment')}} </button>                   
                    </div>


                    <credit-card-payment-saferpay v-if='showSaferpay == true' :subCode="currentPaymentSubCode"></credit-card-payment-saferpay>
                    <credit-card-payment-securepay v-if='showSecurepay == true' :subCode="currentPaymentSubCode" ></credit-card-payment-securepay>
                    <credit-card-payment-novalnet v-if='showNovalnet == true' :subCode="currentPaymentSubCode"></credit-card-payment-novalnet>
                    <credit-card-payment-payone v-if='showPayone == true' :subCode="currentPaymentSubCode" :callback="closeTopUp" ></credit-card-payment-payone>
                </div>

            </template>

            <template v-slot:footer>
                <!-- <div class="d-flex align-items-center justify-content-between">
                <button class="btn btn--secondary" @click="$refs.modalName.closeModal()">Cancel</button>
                <button class="btn btn--primary" @click="$refs.modalName.closeModal()">Save</button>
                </div> -->
            </template>
        </modal>

        <modal ref="orderDetailsModal">
            <template v-slot:header>
                <h2 id="modalTitle">{{$t('yourorder')}}</h2>
            </template>

            <template v-slot:body>
               <order-details :order="currentOrderSelected" :callbackClose="closeOrderDetails"></order-details>
                <button v-if="config.SHOW_ACCOUNT_STORNO" class="btn btn-secondary mr-2" @click="$refs.orderDetailsModal.closeModal()">{{$t("cancel")}}</button>
                <!-- <button class="btn btn--primary" @click="$refs.modalName.closeModal()">Save</button> -->
                <button v-if="config.SHOW_ACCOUNT_STORNO" class="btn btn-danger" @click="stornoOrder()">{{$t("stronoButton")}}</button>
            </template>

            <template v-slot:footer>
                <!-- <div class="d-flex align-items-center justify-content-between">
                <button class="btn btn--secondary" @click="$refs.modalName.closeModal()">Cancel</button>
                <button class="btn btn--primary" @click="$refs.modalName.closeModal()">Save</button>
                </div> -->
            </template>
        </modal>


        <modal ref="transactionDetailsModal">
            <template v-slot:header>
                <h2 id="modalTitle">{{$t('yourTransaction')}}</h2>
            </template>

            <template v-slot:body>
               <transaction-details :transaction="currentTransactionSelected" :callbackClose="closeOrderDetails"></transaction-details>
                <div class="align-items-center justify-content-between">
                <button v-if="config.SHOW_ACCOUNT_STORNO" class="btn btn-secondary mr-2" @click="$refs.transactionDetailsModal.closeModal()">{{$t("cancel")}}</button>
                <!-- <button class="btn btn--primary" @click="$refs.modalName.closeModal()">Save</button> -->
                <button v-if="config.SHOW_ACCOUNT_STORNO" class="btn btn-danger" @click="stornoOrder()">{{$t("stronoButton")}}</button>
                </div>

            </template>

            <template v-slot:footer>
                <!-- <div class="d-flex align-items-center justify-content-between">
                <button v-if="config.SHOW_ACCOUNT_STORNO" class="btn btn--secondary" @click="$refs.modalName.closeModal()">Cancel</button> -->
                <!-- <button class="btn btn--primary" @click="$refs.modalName.closeModal()">Save</button> -->
                <!-- <button v-if="config.SHOW_ACCOUNT_STORNO" class="btn btn-danger" onclick="window.confirm('Sind Sie sich sicher?') ? window.alert('Bestellung erfolgreich storniert')">Stornieren</button>
                </div> -->
            </template>
        </modal>
        
        <modal ref="autoTopupModal">
            <template v-slot:header>
                <h2 id="modalTitle">{{$t('autoTopup')}}</h2>
            </template>

            <template v-slot:body>

                <b-form @submit="autoTopupFormSubmit">
                    <!-- <b-form-group id="input-group-1"> -->
                        <b-form-checkbox
                        id="checkbox-1"
                        v-model="autoTopupForm.active"
                        name="checkbox-1"
                        value="1"
                        unchecked-value="0"
                        switch
                        >
                        {{$t('enabled')}}
                        </b-form-checkbox>
                    <!-- </b-form-group> -->

                    <b-form-group
                            id="input-group-2"
                            :label="$t('thresholdLabel')"
                            label-for="input-2"
                            :description="$t('thresholdDescription')"
                            :append="config.ACCOUNT_BALANCE_CURRENCY"
                        >
                    <b-form-input
                        id="input-2"
                        v-model="autoTopupForm.thresholdValue"
                        type="number"
                        placeholder="0"
                        step="0.01"
                        required
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group id="input-group-3" :label="$t('rechargeValueLabel')" label-for="input-3" :description="$t('rechargeValueDescription')">
                        <b-form-input
                        id="input-3"
                        v-model="autoTopupForm.rechargeValue"
                        type="number"
                        placeholder="0"
                        step="0.01" 
                        required                            
                        ></b-form-input>
                    </b-form-group>

                    <b-button type="submit" variant="primary">{{$t("confirm")}}</b-button>
                    <b-button type="reset" variant="primary" @click="$refs.autoTopupModal.closeModal()">{{$t("cancel")}}</b-button>
                </b-form>

                <!-- <button class="btn btn-secondary mr-2" @click="$refs.autoTopupModal.closeModal()">{{$t("cancel")}}</button>
                <button class="btn btn--primary" @click="$refs.autoTopupModal.closeModal()">Save</button> -->
            </template>

            <template v-slot:footer>
                <!-- <div class="d-flex align-items-center justify-content-between">
                <button class="btn btn--secondary" @click="$refs.autoTopupModal.closeModal()">Cancel</button>
                <button class="btn btn--primary" @click="$refs.autoTopupModal.closeModal()">Save</button>
                </div> -->
            </template>
        </modal>

        <modal ref="dietaryModal">
            <template v-slot:header>
                <h2 id="modalTitle">{{$t('dietaryModalTitle')}}</h2>
            </template>

            <template v-slot:body>

                <b-form @submit="dietaryFormSubmit">
                    <h4 v-if="alergenOptions.length > 0">{{$t('allergenTitle')}}</h4>
                    <b-form-checkbox-group
                        id="allergenCheckboxes"
                        v-model="selectedAllergens"
                        :options="alergenOptions"
                        name="allergens"
                        class="mb-4"
                        v-if="alergenOptions.length > 0"
                    >
                    </b-form-checkbox-group>

                    <h4 v-if="propertiesOptions.length > 0">{{$t('propertyTitle')}}</h4>
                    <b-form-checkbox-group
                        id="propertiesCheckboxes"
                        v-model="selectedProperties"
                        :options="propertiesOptions"
                        name="dietaryProperties"
                        class="mb-4"
                        v-if="propertiesOptions.length > 0"
                    >

                    </b-form-checkbox-group>

                    <b-button type="submit" variant="primary">{{$t('confirm')}}</b-button>
                    <b-button type="reset" variant="primary" @click="$refs.dietaryModal.closeModal()">{{$t('cancel')}}</b-button>
                </b-form>
            </template>

            <template v-slot:footer>
            </template>
        </modal>

        <div class="col-12 customerJustify">
            <div class="col-12 customerData">        
                <div style="display:inline-block;width:100%;padding-bottom:50px;">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding:0;">
                        <span class="col-12 customerHeadLine d-flex flex-row">
                            <h2 class="my-auto">{{$t('customerAddress')}}</h2>
                            <div class="col-lg-4 col-md-6 col-sm-6 col-xs-6 logoutButton ml-auto my-auto" style="padding:0;"  v-if="showEditAddressForm == false && showNewAddressForm == false">
                                <!-- <span class="" @click="modify">{{$t('buttonModify')}}</span> -->
                                <!-- <button class="btn btn-primary btn-inverted btn-justify btn-right bg-transparent btn-outline-transparent" @click="modify">{{$t('buttonModify')}}</button> -->
                                <!-- <button role="link" class="btn btn-link btn-justify btn-right" @click="modify">{{$t('buttonModify')}}</button> -->
                                <!-- <router-link to="/Edit" class="darkLink">{{$t('buttonModify')}}</router-link> -->
                                <!-- <a href="#" class="text-secondary">{{$t('buttonModify')}}</a> -->
                                <!--button class="btn btn-primary btn-justify btn-right" @click="modify">{{$t('buttonModify')}}</button-->
                            </div>
                        </span>        
                        <ul class="customerDataTable">                
                            <li v-if="addressLoaded">{{checkEmpty(addresses[0].firstName)}} {{checkEmpty(addresses[0].lastName)}}</li>
                            <li v-if="addressLoaded">{{checkEmpty(addresses[0].streetAddress)}}</li>
                            <li v-if="addressLoaded">{{checkEmpty(addresses[0].postcode)}} {{checkEmpty(addresses[0].city)}}</li> 
                            <!--li v-if="addressLoaded">{{checkEmpty(addresses[0].countryName)}}</li--> 
                            <li v-if="addressLoaded">{{checkEmpty(addresses[0].telephoneNumber)}}</li>
                        </ul>

                        <div style="display: inline-block;" v-if="showQrCode && config.SHOW_CUSTOMER_QR_CODE"> 
                            <label>{{$t('qrCodeTitle')}}</label>
                            <radial-progress-bar
                                                :diameter="200"
                                                :completed-steps="30 - qrCodeSeconds"
                                                :total-steps="30"
                                                startColor="var(--main-color)" 
                                                stopColor="var(--main-color)" 		
                                                innerStrokeColor ="lightgray">
                                <qr-code :value="qrCode"></qr-code>
                                {{qrCodeSeconds}}s
                            </radial-progress-bar>                            
                        </div>

                    </div>                    
                </div>
                <button v-if="config.USE_ALLERGEN_FILTERS == true" class="customerCardBalanceButton btn btn-primary" @click="openDietaryModal()">{{ $t('dietaryInfos') }}</button>

                <div style="padding-bottom:50px;" v-if="config.SHOW_ACCOUNT_PAGE_ADVANCED_CUSTOMER_AREA">
                    <div v-for="item in pointCampaignsDefinitions" v-bind:key="item.campaignId"  style="text-align:left;  background:white; padding: 5px; border-bottom:0.2px solid var(--main-color); margin:5px;">
                        <span style="font-size:20px;">{{ getCampaignName(item.id) }}</span>
                        <br/>
                        <span v-if="item.points > 0">
                            <span style="font-size:15px;"> {{ $t('rewardPointsBalance') }} </span>                        
                            <span style="color:var(--main-color); font-size:20px;"> {{ item.points }} </span>
                        </span>                                          
                    </div>

                    <div class="customerCardBalance">
                        <span class="customerCardBalanceLabel" v-if="customerCardWithCreditFunktion">{{ $t('customerCardCreditBalance') }}</span>
                        <span class="customerCardBalanceLabel" v-else>{{ $t('customerCardBalance') }}</span>
                        <span class="customerCardBalanceAmount" v-if="customerCardWithCreditFunktion"> {{ creditBalance }} {{ config.ACCOUNT_BALANCE_CURRENCY}}</span> 
                        <span class="customerCardBalanceAmount" v-else> {{ prepayBalanceCash }} {{ config.ACCOUNT_BALANCE_CURRENCY}}</span> 
                        <div class="customerCardBalance" v-if="config.ACCOUNT_PAGE_SHOW_PENDING_TOPUP && pendingCustomerCardPayments.length > 0">
                            <h3>{{$t('customerCardBalancePendingTitle')}}</h3>
                            <div class="customerCardBalance d-flex flex-row">
                                <span class="customerCardBalancePendingLabel col-3 p-1">{{$t('customerCardBalancePendingDate')}}</span>
                                <span class="customerCardBalancePendingAmount col-3 p-1">{{$t('customerCardBalancePendingAmount')}}</span>
                            </div>
                            <div class="customerCardBalance d-flex flex-row" v-for="item in pendingCustomerCardPayments" :key="item.paymentTimestamp" :value="item.amount">
                                <span class="customerCardBalancePendingLabel col-3 p-1">{{formatDate(item.paymentTimestamp)}}</span>
                                <span class="customerCardBalancePendingAmount col-3 p-1">{{formatPrice(item.amount)}}</span>
                            </div>
                        </div>  
                        <button v-if="config.TOPUP_ENABLED == true" class="customerCardBalanceButton btn btn-primary" @click="clearModal();  $refs.topUpModal.openModal()">{{ $t('topUp') }}</button>   
                        <button v-if="config.AUTO_TOPUP_ENABLED == true" class="customerCardBalanceButton btn btn-primary" @click="openAutoTopupModal()">{{ $t('autoTopup') }}</button>
                    </div>
                </div>
                <h5 v-if="!config.IS_PURE_ACCOUNTMANAGER && config.SHOW_ACCOUNT_PAGE_ADRESSES">{{$t('customerAddresses')}}</h5>
                <div v-if="!config.IS_PURE_ACCOUNTMANAGER && config.SHOW_ACCOUNT_PAGE_ADRESSES" style="margin-bottom:120px;">
                    <select class="vueselect-wrapper col-xl-8 col-sm-7" id="addressPicker" style="padding:0;"  @change="closeAddressForm">
                        <option v-for="address in addresses" :key="address.id" :value="address.id" v-html="address.firstName + ' ' + address.lastName + ', ' + address.streetAddress + ', ' + address.postcode + ' ' +  address.city + ', ' + address.countryName + ' - ' + (address.telephoneNumber ? address.telephoneNumber: '') "></option>
                    </select>

                    <button type="button" @click="editAddress" class="btn btn-primary btnAddress col-2" style="margin: 0px 0px 5px 0px;height: 48px; padding:0;">
                        <i class="editIcon fa fa-pencil">   </i>  
                    </button> 

                    <button type="button" @click="addNewAddress" class="btn btn-primary btnAddress col-2" style="margin: 0px 0px 5px 0px;height: 48px; padding:0;">
                        <i class="editIcon fa fa-plus">   </i>
                    </button> 
                </div>

                <!-- <button type="button" v-if="selectedAddress.isPrimary == false" @click="deleteAddress" class="btn btn-inverted col-1" style="margin: 0px 0px 5px 0px;height: 48px;min-width:10%;">
                    <i class="editIcon fa fa-trash">   </i>
                 </button>  -->

                <div id="addressFormWrapper">
                    <div class="col-12 addressFormPadding"  v-if="showEditAddressForm">
                        <h2>{{$t('editAddress')}}</h2>
                        <button type="button" v-if="selectedAddress.isPrimary == false" @click="deleteAddress" class="btn btn-inverted col-1" style="margin: 0px 0px 5px 0px;height: 48px;min-width:10%;">
                            <i class="editIcon fa fa-trash">   </i>
                        </button> 
                        <address-form :addressId="selectedAddress.id" :addressModel="selectedAddress" :isEdit="true" :callbackClose="closeAddressForm" :customer="customer"></address-form>
                        
                    </div>   

                    <div class="col-12 addressFormPadding"  v-if="showNewAddressForm">
                        <h2>{{$t('addAddress')}}</h2>
                        <address-form :addressId="0" :addressModel="addAddress" :isEdit="false" :callbackClose="closeAddressForm" :customer="customer"></address-form>            
                    </div>      
                </div>
                
                <div style="padding:0;padding-top:20px;" v-if="config.SHOW_ACCOUNT_PAGE_NEWSLETTER_SETTINGS && showEditAddressForm == false && showNewAddressForm == false">
                     <div>
                        <span class="col-12 customerHeadLine">{{ $t('recentOrdersNewsTitle') }}</span> 
                    </div>
                    <div class="col-12" style="text-align: left;">
                        <span>{{$t('newsletterABO')}}</span>
                    </div>
                </div>
               
               
            </div>
        </div>
       
        <div class="col-12 customerJustifyRight">
            <span class="col-12 customerHeadLine" v-if="config.SHOW_ACCOUNT_PAGE_ORDER_HISTORY">
                <h2>{{ $t('recentOrdersPreviewTitle') }}</h2></span>
            <div class="col-12 recentOrdersPreview" v-if="config.SHOW_ACCOUNT_PAGE_ORDER_HISTORY">   
                                    
                <div class="recentCustomerOrdersList">
                    <div class="recentCustomerOrdersEntry" v-for="item in recentOrders" v-bind:key="item.guid" @click="openOrderDetails(item.guid, item.shopId)" v-on:keydown.enter="openOrderDetails(item.guid, item.shopId)">
                        <ul class="recentCustomerOrdersList">
                            <li class="">
                                {{item.formattedDate}}
                            </li>                   
                            <li class="">
                                {{item.status}}
                            </li>
                            <li class="recentCustomerOrdersEntryAmount">
                                {{item.formattedTotal}} 
                            </li>
                        </ul>
                    </div>
                </div>   

                <!-- <div class="orderOverview" v-if="showOrderDetails">
                    <order-details :order="currentOrderSelected" :callbackClose="closeOrderDetails"></order-details>
                </div> -->
            </div>

            <span class="col-12 customerHeadLine" v-if="config.SHOW_ACCOUNT_PAGE_TRANSACTION_HISTORY">
                <h2 class="my-auto">{{ $t('recentTransactionsPreviewTitle') }}</h2></span>
            <div class="col-12 recentOrdersPreview"  v-if="config.SHOW_ACCOUNT_PAGE_TRANSACTION_HISTORY">                               
                <div class="recentCustomerOrdersList" >
                    <div class="recentCustomerOrdersEntry" v-for="item in recentTransactions" v-bind:key="item.id" @click="openTransactionDetails(item.id)" v-on:keydown.enter="openTransactionDetails(item.id)" tabindex="0">
                        <!-- <div class="col-auto"> -->
                        <ul v-if="!item.prepayment" class="recentCustomerOrdersList">
                            <li class="">                         
                                {{item.formattedDate}}
                               
                            </li>                   
                            <li class="">                      
                                {{item.shopDescription}}
                              
                            </li>
                            <li class="recentCustomerOrdersEntryAmount">
                                {{item.formattedTotal}} 
                            </li>
                        </ul>

                        <ul v-if="item.prepayment" class="recentCustomerOrdersList">
                            <li class="">                         
                                {{item.formattedDate}}
                               
                            </li>                   
                            <li class="">                      
                                {{item.shopDescription}}
                              
                            </li>
                            <li class="recentCustomerOrdersEntryAmount">
                                {{$t('prepayment')}}
                            </li>
                        </ul>
                        <!-- </div>
                        <div class="col-auto">
                            <button class="btn btn-primary">Details anzeigen</button>
                        </div> -->
                    </div>
                </div>   

                <!-- <div class="orderOverview" v-if="showOrderDetails">
                    <order-details :order="currentOrderSelected" :callbackClose="closeOrderDetails"></order-details>
                </div> -->
            </div>
        </div>        
    </div>
</template>

<script>
import Modal from '../sub-components/ModalAccessible.vue'
import VueLoading from 'vue-loading-overlay'
import OrderDetails from '../sub-components/OrderDetails.vue'
import TransactionDetails from '../sub-components/TransactionDetails.vue'
import $ from 'jquery'
import AddressForm from '../main-components/AddressForm.vue'
import QrcodeVue from 'qrcode.vue'
import moment from 'moment'
import CryptoJS from 'crypto-js'
import base32Encode from 'base32-encode'
import RadialProgressBar from 'vue-radial-progress'


import CreditCardPaymentComponentSaferpay from '../main-components/CreditCardPaymentComponentSaferpay.vue'
import CreditCardPaymentComponentSecurepay from '../main-components/CreditCardPaymentComponentSecurepay.vue'
import CreditCardPaymentComponentNovalnet from '../main-components/CreditCardPaymentComponentNovalnet.vue'
import CreditCardPaymentComponentPayone from '../main-components/CreditCardPaymentComponentPayone.vue'
// import CheckBox from '../sub-components/CheckBoxSimple.vue'



// import CircularCountDownTimer from "vue-circular-count-down-timer";
// import VueEllipseProgress from 'vue-ellipse-progress';
// import base32Encode from 'base32-encode'
// import crypto from 'crypto'
// import derivePassword from 'derive-password-bytes'

export default {
    data(){
        return {
            topUpAmount:null,
            currentOrderSelected: {
                Type: Object
            },
            currentTransactionSelected: {
                Type: Object
            },      
            recentOrders: {
                Type: Array,
                default: []
            },
            recentTransactions: {
                Type: Array,
                default: []
            },
            selectedAddress: {
                Type: Object
            },
            addresses: {
                Type: Array,
                default: []
            },
            pendingCustomerCardPayments:{
                Type: Array,
                default: []
            },
            isLoading: false,
            showEditAddressForm: false,
            showNewAddressForm: false,
            cardNum: "",
            qrCode:"",
            qrCodeSeconds: 30,
            intervalId: 0,
            pointsBalance:0,
            showQrCode: false,
            pointCampaigns: [],   
            pointCampaignsDefinitions: [],
            addressLoaded: false,
            prepayBalanceCash: 0,
            showCustomerAdvancedArea: false,
            showTopUpPayment: false,
            showSaferpay: false,
            showSecurepay: false,
            showNovalnet: false,
            showPayone: false,
            paymentList: [],
            currentPayment: "",
            currentPaymentSubCode: "",
            autoTopupForm: {
                active: false,
                thresholdValue: 0,
                rechargeValue: 0,
            },
            selectedAllergens: [],
            previousSelectedAllergens: [],
            alergenOptions: [],
            selectedProperties: [],
            previousSelectedProperties: [],
            propertiesOptions: [],
            customerCardWithCreditFunktion: false,
            creditBalance: 0,
        }
    },
    computed:{
        config(){
            return window.config;
        },
        currentlySelectedPayment (){            
            if(this.currentPayment == "Saferpay")
            {
                console.log(this.currentPayment + "~~" + this.currentPaymentSubCode);
               return this.currentPayment + "~~" + this.currentPaymentSubCode;
            }
            else if(this.currentPayment == "PayOne")
            {
                if(this.currentPaymentSubCode !== "")
                {
                    return this.currentPayment + "~~" + this.currentPaymentSubCode;
                }
                else
                {
                    return this.currentPayment;
                }
            } 
            else
            {
                return this.currentPayment;
            }            
        },        
    },
    components:{
        'loading' : VueLoading,
        'order-details': OrderDetails,
        'transaction-details': TransactionDetails,
        'address-form' : AddressForm,
        'modal': Modal,
        'qr-code' : QrcodeVue,
        'radial-progress-bar': RadialProgressBar,
        'credit-card-payment-saferpay': CreditCardPaymentComponentSaferpay,
        'credit-card-payment-securepay': CreditCardPaymentComponentSecurepay,
        'credit-card-payment-novalnet': CreditCardPaymentComponentNovalnet,
        'credit-card-payment-payone': CreditCardPaymentComponentPayone,        
        // 'check-box': CheckBox    
    },
    methods:{
        refreshUserData() {
            //  $("#siteHeader").unbind("click");

            var config = window.config;
            var vueInstance = this;
            var url = config.API_BASE + 'TCPAfterLoginJ.action';
            var params = {
                json: "true",       
            };

            // eslint-disable-next-line no-unused-vars
            var callbackSuccess = function(result, textStatus, jqXHR) {       
            
                if (result.exception !== undefined)
                {                
                    vueInstance.$router.go(-1);
                    return;
                }
                if (result.requiresLogin)
                {
                    vueInstance.$router.push({ path:'/Login' });
                    return;
                }            
                vueInstance.customer = result.customer;
                vueInstance.addresses = result.customer.addresses;
                vueInstance.addressLoaded = true;
                vueInstance.pointsBalance = result.rewardPointsAvailable;
                vueInstance.selectedAddress = result.customer.addresses[0];
                
            
                if(result.wondCustomer != undefined)
                {
                    vueInstance.pointCampaignsDefinitions = result.wondCustomer.pointCampaigns;
                    vueInstance.cardNum = result.wondCustomer.cardNum;
                    if (result.wondCustomer.cardType.toUpperCase() === "CREDIT") {
                        vueInstance.creditBalance =  result.wondCustomer.creditBalance.toFixed(config.CUSTOMER_BALANCE_DECIMAL_PLACES);
                        vueInstance.customerCardWithCreditFunktion = true;
                    }else{
                        vueInstance.prepayBalanceCash = (result.wondCustomer.prepayBalanceCash + result.wondCustomer.prepayBalanceVoucher + result.wondCustomer.prepayBalanceBonus).toFixed(config.CUSTOMER_BALANCE_DECIMAL_PLACES);
                    }
                    vueInstance.startQrCodeInterval();

                    if(result.wondCustomer.pendingTopup != undefined){
                        vueInstance.pendingCustomerCardPayments = result.wondCustomer.pendingTopup;
                        // result.wondCustomer.pendingTopup.forEach(element => {
                        //     vueInstance.pendingCustomerCardPayments.push({date: element.paymentTimestamp, amount: element.amount})
                        // });
                    }
                }

                if(result.customer != undefined)
                {
                    if(result.customer.type != undefined)
                    {
                        if(result.customer.type == 2)
                        {
                            window.isGuestCheckout = true;
                        }
                    }
                }  
        
            }

            // eslint-disable-next-line no-unused-vars
            var callbackError = function(result, textStatus, jqXHR) {
                vueInstance.isLoading = false;
            }     
                
            vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);              
        },
        timeRemaining(){
            var remainingTimeMessage = "";
            var remainingTimeInMillisecounds = window.remainingSessionTime;
            if (remainingTimeInMillisecounds > 0) {
                var timeString = "";
                if ((remainingTimeInMillisecounds / 60000) > 1) {
                    timeString = Math.floor(remainingTimeInMillisecounds / 60000) + " min";
                }else{
                    timeString = "< 1 min";
                }
                remainingTimeMessage = this.$t('timeRemaining') + "" + timeString + " ";
            }
            return remainingTimeMessage;
        },
        closeTopUp(status){
            var vueInstance = this;
            if(status >= 0)
            {
                setTimeout(() => {                           
                    vueInstance.$refs.topUpModal.closeModal();
                    vueInstance.$alert(vueInstance.$t('topUpSuccess'));

                    var url = window.config.API_BASE + 'TCPStartJ.action';

                    // eslint-disable-next-line no-unused-vars
                    var callbackSuccess= function(result, textStatus, jqXHR) {
                        window.shoppingCart = null;           
                    }

                    var params = {                    
                        json: "true",
                        emptyBasket: "true"
                    };

                    vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, null);
                }, 300);    
                
                setTimeout(() => {
                    vueInstance.refreshUserData();
                    vueInstance.$forceUpdate();
                }, 3000);
            }                
        },
           onPaymentSelect(element){  
            this.showSaferpay = false;
            this.showSecurepay = false;
            this.showNovalNet = false;
            this.showPayone = false;

            element = element.target;
            element.subCode = element.getAttribute("subcode"); 

            //if values are identical then this is the deselecting click on the check-box
            // if(this.currentPayment.value == element.value)
            // {
            //     if(!this.firstClickDone)
            //         this.firstClickDone = true;

            //     this.currentPayment = "";
                
            //     this.$forceUpdate();
            //     console.log("curr payment");
            //     console.log(this.currentPayment);
            //     return;
            // }

            this.currentPayment = element.value;
            console.log("current payment method: " + this.currentPayment);
            console.log("current subcode:" + element.subCode);
            
            if(element.subCode != undefined)
            {
                if(element.subCode == "T")
                {
                    this.currentPaymentSubCode = element.subCode;
                }
                else if(element.subCode == "P")
                {
                    this.currentPaymentSubCode = element.subCode;
                }
                // else if(element.subCode == "PDT")
                // {
                //     this.currentPaymentSubCode = element.subCode;
                // }
                else
                {
                    element.subCode = "";
                    this.currentPaymentSubCode = "";
                }
            }
            else
            {
                element.subCode = "";
                this.currentPaymentSubCode = "";
            }

            if(this.currentlySelectedPayment != "customercard")
            {
                this.onSubmit(true);
            }
        },
        onSubmitFree(){
            this.currentPayment = "free";
            this.onSubmit(false);
        },
        onSubmitTopUp(paymentMethod){
            this.currentPayment = paymentMethod.code;
            if(paymentMethod.subCode != null || paymentMethod.subCode != undefined)
            {
                if( paymentMethod.subCode != ""){
                    this.currentPaymentSubCode = paymentMethod.subCode;
                }
            }
            this.onSubmit(false);
        }, 
        onSubmit(checkCod) {   
            
            if(this.currentlySelectedPayment == "customercard" && this.basketTile.basketTotal > this.prepayBalanceCash)
            {
                this.$alert(this.$t("balanceNotEnough"));
                return;
            }

            if( (this.currentlySelectedPayment == "cod" || this.currentlySelectedPayment== "customercard" || this.currentlySelectedPayment== "TCPDebitor") && checkCod)
            {
               return;
            }    

            this.isLoading = true;
            var config = window.config;
            var vueInstance = this;
            var url = config.API_BASE + 'TCPPaymentSubmitJ.action';            
            var params = {                       
                deliveryAddress: "0",
                paymentMethod: this.currentlySelectedPayment,
                json: "true"
            };

            if(window.xtoken != undefined)
            {
                params.xsrfToken = btoa(window.xtoken);
            }

            // eslint-disable-next-line no-unused-vars
            var callbackSuccess= function(result, textStatus, jqXHR) 
            {
                if(result.paymentMethod == "Saferpay~~P" || result.paymentMethod == "Saferpay~~T")
                {
                    vueInstance.isLoading = false;
                    console.log(vueInstance.currentPayment);                    
                    vueInstance.showSaferpay = true;
                    vueInstance.$forceUpdate();
                    return;
                }

                if(result.paymentMethod == "Securepay"){
                    vueInstance.isLoading = false;
                    console.log(vueInstance.currentPayment);                    
                    vueInstance.showSecurepay = true;
                    vueInstance.$forceUpdate();
                    return;
                }
                
                if(result.paymentMethod == "Novalnet")
                {
                    vueInstance.isLoading = false;
                    console.log(vueInstance.currentPayment);                    
                    vueInstance.showNovalnet = true;
                    vueInstance.$forceUpdate();
                    return;
                }

                if(result.paymentMethod == "PayOne")
                {
                    vueInstance.showPayone = true;
                    console.log(vueInstance.currentPayment); 
                    vueInstance.isLoading = false;
                    vueInstance.$forceUpdate();
                    return;
                }

                if(result.paymentMethod == "StripeModule")
                {                                                          
                    return;
                }

                if(result.exception !== undefined)
                {
                    vueInstance.isLoading = false;
                    vueInstance.$alert(this.$t("exceptionMessage"));
                    return;
                }

                if(result.page == "tcp.catalog.checkoutfinished.page")
                {
                    if(vueInstance.currentlySelectedPayment == "cod" || vueInstance.currentlySelectedPayment == "customercard" || vueInstance.currentlySelectedPayment == "free")
                    {
                        window.shoppingCart = null;
                        url = config.API_BASE + 'TCPCheckoutFinishedJ.action';            

                        params = {                    
                            json: "true"
                        };
                        
                        vueInstance.makeRequest("GET", url, JSON.stringify(params), null, null);
                    }

                    vueInstance.isLoading = false;
                    window.shoppingCart = undefined;
                    vueInstance.$router.push({path: '/CheckoutFinished'});
                    return;
                }

                vueInstance.isLoading = false;
                window.shoppingCart = null;
                vueInstance.$router.push({ path: '/Payment' });                     
            }

            // eslint-disable-next-line no-unused-vars
            var callbackError = function(result, textStatus, jqXHR){
                vueInstance.isLoading = false;
                vueInstance.$alert(vueInstance.$t('failedGeneric'));
            }
        
            vueInstance.makeRequest("POST", url, JSON.stringify(params), callbackSuccess, callbackError);
        },
        processPayment(){
            return;
        },
        confirmAndPayOrder(){
            var vueInstance = this;
   

            this.isLoading = true;
            var config = window.config;
      
            var url = config.API_BASE + 'TCPPaymentJ.action';

            var params = {
                json: "true",
                orderType: window.orderType,                
                comment: this.comment
            };

            // eslint-disable-next-line no-unused-vars
            var callbackSuccess= function(result, textStatus, jqXHR){       
                if(result.exception !== undefined)
                {
                    vueInstance.isLoading = false;                    
                    return;
                }

                if(result.requiresLogin == true)
                {    
                    vueInstance.isLoading = false;
                    return;
                }

                vueInstance.processPayment();
                vueInstance.paymentList = result.paymentDetails.filter(function(x) { return (x.code != "customercard" && x.code != "cod") });
                if(vueInstance.paymentList.length == 1){
                    vueInstance.onSubmitTopUp(vueInstance.paymentList[0]);
                }
                vueInstance.showTopUpPayment = true;
                // window.shoppingCart = result.basketTile.basketItems;
                vueInstance.isLoading = false;

                
            }

            // eslint-disable-next-line no-unused-vars
            var callbackError = function(result, textStatus, jqXHR){
                vueInstance.isLoading = false;
            }
        
            vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);

            window.onmessage = function(e) {                      
                console.log("WINDOW ONMESSAGE EVENT " + e.data);
                var config = window.config;     
                if(e.data == "S" || e.data == "A" || e.data == "F" ||
                e.data.startsWith("payoneA")  || e.data.startsWith("payoneB") || e.data.startsWith("payoneD"))
                {
                    vueInstance.isLoading = true;
                }
                
                if(e.data == "S")
                {           
                    if(vueInstance.currentPaymentSubCode == "P")
                    {
                         //TODO
                        vueInstance.closeTopUp(0);
                        vueInstance.isLoading = false;
                        //vueInstance.$router.push({ path: '/CheckoutFinished' });
                        return; 
                    }

                    var url = config.API_BASE + 'ProcessSaferpayJ.action';   
                    var params = {
                        json: "true",
                        vresult: e.data
                    };
                    // eslint-disable-next-line no-unused-vars
                    var callbackSuccess= function(result, textStatus, jqXHR) {
                        vueInstance.isLoading = false;
                        //TODO
                        vueInstance.closeTopUp(0);
                        //vueInstance.$router.push({ path: '/CheckoutFinished' });
                        
                    }
                    // eslint-disable-next-line no-unused-vars
                    var callbackError = function(){
                        vueInstance.isLoading = false;   
                    }
                
                    vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);      
                } else if (e.data == "A")
                {
                    //vueInstance.$alert("PaymentAborted!");
                    // vueInstance.$router.push({ path: '/Payment' });
                    // vueInstance.$router.go(0);
                    // vueInstance.currentPayment = "cod";
                    vueInstance.showSaferpay = false;
                    vueInstance.showSecurepay = false;
                    vueInstance.showNovalnet = false;
                    vueInstance.showPayone = false;
                    vueInstance.isLoading = false; 

                } else if (e.data == "F")
                {                    
                    vueInstance.$alert(vueInstance.$t('paymentFail'));
                    // vueInstance.currentPayment = "cod";
                    vueInstance.showSaferpay = false;
                    vueInstance.showSecurepay = false;
                    vueInstance.showNovalnet = false;
                    vueInstance.showPayone = false;
                    vueInstance.isLoading = false; 
                } else if (e.data.startsWith("payoneA"))
                {                    
                    //var vresult = e.data.slice(e.data.length - 1);
                    url = config.API_BASE + 'ProcessPayOneJ.action'//?vresult='+ e.data.replace('payone','');
                    params = {
                        json: "true",
                        vresult: e.data.replace('payone','')
                    };
                    // eslint-disable-next-line no-unused-vars
                    callbackSuccess= function(result, textStatus, jqXHR) {
                        vueInstance.isLoading = false;
                        //vueInstance.$router.push({ path: '/CheckoutFinished' });                        
                    }
                    // eslint-disable-next-line no-unused-vars
                    callbackError = function(){
                        vueInstance.isLoading = false;   
                    }
                
                    vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);                      
                    vueInstance.$refs.topUpModal.closeModal();
                } else if(e.data.startsWith("payoneB")){
                    vueInstance.$alert(vueInstance.$t('paymentAborted'));
                    vueInstance.$refs.topUpModal.closeModal();
                } else if(e.data.startsWith("payoneD")){
                    vueInstance.$alert(vueInstance.$t('paymentFail'));
                    vueInstance.$refs.topUpModal.closeModal();
                } else if (e.data.startsWith("novalnet")){
                    url = config.API_BASE + 'ProcessNovalnetJ.action';   
                    params = {
                        json: "true",
                        vresult: e.data
                    };
                    // eslint-disable-next-line no-unused-vars
                    callbackSuccess= function(result, textStatus, jqXHR) {
                        vueInstance.isLoading = false;
                        //TODO
                        vueInstance.closeTopUp(0);
                        //vueInstance.$router.push({ path: '/CheckoutFinished' });
                        
                    }
                    // eslint-disable-next-line no-unused-vars
                    callbackError = function(){
                        vueInstance.isLoading = false;   
                    }
                
                    vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);
                }
            }
        },
        clearModal() {
            var vueInstance = this;
            vueInstance.topUpAmount = null; 
            vueInstance.showTopUpPayment = false;
            vueInstance.currentPayment = "";
            vueInstance.currentPaymentSubCode = "";
            vueInstance.showSaferpay = false;
            vueInstance.showSecurepay = false;
            vueInstance.showNovalnet = false;
            vueInstance.showPayone = false;       
        },
        openAutoTopupModal(){
            var vueInstance = this;
   

            this.isLoading = true;
            var config = window.config;
      
            var url = config.API_BASE + 'TCPEditCustomerJ.action';

            var params = {
                json: "true",
            };

            // eslint-disable-next-line no-unused-vars
            var callbackSuccess= function(result, textStatus, jqXHR){       
                var recurringPaymentData = result.recurringPayment;
                if (recurringPaymentData.active != undefined) {
                    vueInstance.autoTopupForm.active = recurringPaymentData.active;
                }
                if (recurringPaymentData.thresholdValue != undefined) {
                    vueInstance.autoTopupForm.thresholdValue = recurringPaymentData.thresholdValue;

                }
                if (recurringPaymentData.rechargeValue != undefined) {
                    vueInstance.autoTopupForm.rechargeValue = recurringPaymentData.rechargeValue;
                }
                vueInstance.isLoading = false;

                vueInstance.$refs.autoTopupModal.openModal()
            }

            // eslint-disable-next-line no-unused-vars
            var callbackError = function(result, textStatus, jqXHR){
                vueInstance.isLoading = false;
            }
        
            vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);
        },
        openDietaryModal(){
            var vueInstance = this;
   

            this.isLoading = true;
            var config = window.config;
      
            var url = config.API_BASE + 'TCPGetDietaryInfoTypesJ.action';

            var params = {
                json: "true",
            };

            // eslint-disable-next-line no-unused-vars
            var callbackSuccess= function(result, textStatus, jqXHR){       

                vueInstance.isLoading = false;

                vueInstance.previousSelectedAllergens = vueInstance.selectedAllergens;
                vueInstance.alergenOptions = [];
                vueInstance.previousSelectedProperties = vueInstance.selectedProperties;
                vueInstance.propertiesOptions = [];
                           
                if (result.allergens != undefined) {
                    $.each(result.allergens, function(index, value) {
                        vueInstance.alergenOptions.push({text: value.name, value: value.id});
                    });
                }

                if (result.properties != undefined) {
                    $.each(result.properties, function(index, value) {
                        vueInstance.propertiesOptions.push({text: value.name, value: value.id});
                    });
                }

                vueInstance.$refs.dietaryModal.openModal()
            }

            // eslint-disable-next-line no-unused-vars
            var callbackError = function(result, textStatus, jqXHR){
                vueInstance.isLoading = false;
            }
        
            vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);
        },
        autoTopupFormSubmit(event){
            var vueInstance = this;
            event.preventDefault();

            this.isLoading = true;
            var config = window.config;
      
            var url = config.API_BASE + 'TCPEditCustomerSubmitJ.action';

            var recurringPayment =  {
                active: vueInstance.autoTopupForm.active,
                thresholdValue: vueInstance.autoTopupForm.thresholdValue,
                rechargeValue: vueInstance.autoTopupForm.rechargeValue,
            }

            var params = {
                json: "true",
                recurringPayment: recurringPayment
            };

            // eslint-disable-next-line no-unused-vars
            var callbackSuccess= function(result, textStatus, jqXHR){       

                vueInstance.isLoading = false;

                vueInstance.$alert(vueInstance.$t('autoTopupSuccess'));

                vueInstance.$refs.autoTopupModal.closeModal();
                
            }

            // eslint-disable-next-line no-unused-vars
            var callbackError = function(result, textStatus, jqXHR){
                vueInstance.isLoading = false;
            }
        
            vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);
        },
        dietaryFormSubmit(event){
            var vueInstance = this;
            event.preventDefault();

            this.isLoading = true;
            var config = window.config;
      
            var url = config.API_BASE + 'TCPEditCustomerDietaryAttributesSubmitJ.action';

            var attributes =  [];

            $.each(vueInstance.selectedAllergens, function(index, value) {
                attributes.push({attributeId: value, value: "1"});
            });

            $.each(vueInstance.previousSelectedAllergens, function(index, value) {
                if (vueInstance.selectedAllergens.indexOf(value) < 0) {
                    attributes.push({attributeId: value, value: "0"});
                }
            });

            $.each(vueInstance.selectedProperties, function(index, value) {
                attributes.push({attributeId: value, value: "1"});
            });

            $.each(vueInstance.previousSelectedProperties, function(index, value) {
                if (vueInstance.selectedProperties.indexOf(value) < 0) {
                    attributes.push({attributeId: value, value: "0"});
                }
            });

            var params = {
                json: "true",
                attributes: attributes
            };

            // eslint-disable-next-line no-unused-vars
            var callbackSuccess= function(result, textStatus, jqXHR){       

                vueInstance.isLoading = false;

                window.dietaryInfo = result.wondCustomer.dietaryInfo;

                vueInstance.$alert(vueInstance.$t('dietaryInfosUpdateSuccess'));

                vueInstance.$refs.dietaryModal.closeModal();
                
            }

            // eslint-disable-next-line no-unused-vars
            var callbackError = function(result, textStatus, jqXHR){
                vueInstance.isLoading = false;
            }
        
            vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);
        },
        confirmTopUp() {
            var vueInstance = this;
            vueInstance.isLoading = true;
            if(vueInstance.topUpAmount == null || vueInstance.topUpAmount == 0)
            {
                vueInstance.$alert(vueInstance.$t('topUpAmountNotEnteredMessage'));
                return;
            }

            if(isNaN(vueInstance.topUpAmount))
            {
                vueInstance.$alert(vueInstance.$t('topUpAmountNotNumberMessage'));
                return;
            }
            
            var config = window.config;         

            var url = config.API_BASE + 'TCPConfigurePrepaymentJ.action';
            var params = {
                json: "true",
                amount: vueInstance.topUpAmount.toString()
            };
            
            if(window.xtoken != undefined)
            {
                params.xsrfToken = btoa(window.xtoken);
            }

            // eslint-disable-next-line no-unused-vars
            var callbackSuccess= function(result, textStatus, jqXHR){          
                vueInstance.isLoading = false;         
                vueInstance.confirmAndPayOrder();
            }

            // eslint-disable-next-line no-unused-vars
            var callbackError = function(result, textStatus, jqXHR){
                vueInstance.isLoading = false;         
            }          

            vueInstance.makeRequest("POST", url, JSON.stringify(params), callbackSuccess, callbackError);
        },
        checkEmpty(stringToCheck) {            
            if(stringToCheck.indexOf("---") > -1)
            {
                return "";
            }
            else
            {
                return stringToCheck;
            }
        },
        formatDate(dateString){
            if(dateString == null || dateString == "" || dateString == undefined)
            {
                return "";
            }
            var date = new Date(dateString);
            return date.toLocaleDateString();
        },
        formatPrice(priceString){
            var formatter = new Intl.NumberFormat('de-DE', {style: 'currency', currency: window.config.SUBSIDY_CURRENCY});
            return formatter.format(priceString);
        },
        getTransactions(){
            var config = window.config;
            var vueInstance = this;

            var url = config.API_BASE + 'TCPGetTransactionsJ.action';
            var params = {
                json: "true",
                showDetails: "true",
                dateFromStr: "2000-01-01",
                dateToStr: "2050-01-01"
            };

            let date = new Date();
            var dateFromStrCalculated = moment(date).subtract(config.ACCOUNT_PAGE_TRANSACTIONS_FROM_DAYS, 'days').format('YYYY-MM-DD');
            params.dateFromStr = dateFromStrCalculated;
            date = new Date();
            var dateToStrCalculated = moment(date).add(config.ACCOUNT_PAGE_TRANSACTIONS_TO_DAYS, 'days').format('YYYY-MM-DD');
            params.dateToStr = dateToStrCalculated;

            // eslint-disable-next-line no-unused-vars
            var callbackSuccess= function(result, textStatus, jqXHR){                   
                vueInstance.recentTransactions = result.transactions;
            }

            // eslint-disable-next-line no-unused-vars
            var callbackError = function(result, textStatus, jqXHR){
        
            }          

            vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);
        },
        getCampaignName(campaignId){
            for(var i  = 0; i < this.pointCampaignsDefinitions.length; i++)
            {
                if(this.pointCampaignsDefinitions[i].id == campaignId)
                {
                    return this.pointCampaignsDefinitions[i].description;
                }
            }

            return "";
        },
        wordToByteArray(wordArray) {
            var byteArray = [], word, i, j;
            for (i = 0; i < wordArray.length; ++i) {
                word = wordArray[i];
                for (j = 3; j >= 0; --j) {
                    byteArray.push((word >> 8 * j) & 0xFF);
                }
            }
            return byteArray;
        },
        startQrCodeInterval() {
            var vueInstance = this;
            vueInstance.generateQrCode();            
            vueInstance.intervalId = setInterval(() => {                               
                vueInstance.qrCodeSeconds = vueInstance.qrCodeSeconds - 1;

                if(vueInstance.qrCodeSeconds == 0)
                {               
                    vueInstance.generateQrCode();
                    // vueInstance.startQrCodeInterval();                                        
                }                
            }, 1000);
        },
        generateQrCode() {
            // 1- Gets cardNumber
            let date = new Date();
            var newDateObj = moment(date).add(30,'seconds').utc().format('yyyyMMDDHHmmss'); 
            var barcode = this.cardNum + newDateObj;

            //checksum
            var result = '*';
            var code39Chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ-. $/+%";       
            var sum = 0;
            for (var i = 0; i < barcode.length; i++)
            {
                sum += code39Chars.indexOf(barcode.charAt(i));
            }
            result = code39Chars[sum % 43];
            barcode = barcode + result;     
             
            var C = CryptoJS;
            var bytes = C.enc.Utf8.parse(barcode);                     
            // const password = 'f6@#baka';
            // var salt = [ 0x49, 0x76, 0x61, 0x6e, 0x20, 0x4d, 0x65, 0x64, 0x76, 0x65, 0x64, 0x65, 0x76 ];
            // var saltUInt8 = new Uint8Array(salt);        
            // var saltText = "";
            // saltText = new TextDecoder().decode(saltUInt8);            
            //  console.log(saltText);

            var key = new Uint8Array(32);
            var IV = new Uint8Array(16);
            key = new Uint8Array( [60, 175, 77, 70, 58, 137, 151,252,5,30,164,241,182,3,136,151,129,196,108,49,255,108,131,159,207,90,24,11,38,215,110,138] );              
            IV = new Uint8Array( [140,184,62,82,119,188,41,89,188,112,122,223,51,65,170,21] );
            var aes = C.algo.AES.createEncryptor(C.lib.WordArray.create(key), {
                mode: C.mode.CBC,
                padding: C.pad.Pkcs7,
                iv: C.lib.WordArray.create(IV)
            });

            var encrypted = aes.finalize(bytes);
            var uint8Array = this.wordToByteArray(encrypted.words);
            var uint9Array = new Uint8Array(uint8Array);                
            var qrcode = base32Encode(uint9Array, 'RFC4648', { padding: false });
            qrcode = "TCPOSAPP" + qrcode;
            this.qrCode = qrcode
            this.qrCodeSeconds=30;
            this.showQrCode = true;
            this.$forceUpdate();
        },
        editAddress() {
            this.showEditAddressForm = true;
            this.showNewAddressForm = false;

            var selectedAddressId = $('#addressPicker').val(); 
            var selectedAddr = this.addresses.filter(function(x) { return x.id == selectedAddressId })[0];          
            this.selectedAddress = selectedAddr;

            setTimeout(() => {                             
                $(".content").animate({ scrollTop: 400 }, 300);
            }, 150);

        } , 

        updateCustomerObject(){
            var config = window.config;
            var vueInstance = this;
            var url = config.API_BASE + 'TCPAfterLoginJ.action';
            var params = {
                json: "true",       
            };

            // eslint-disable-next-line no-unused-vars
            var callbackSuccess = function(result, textStatus, jqXHR) {       
            
                if (result.exception !== undefined)
                {                
                    console.log(result.exception);
                    return;
                }
            
                vueInstance.customer = result.customer; 
                window.customer = vueInstance.customer;    
                if(result.customer != undefined)
                {
                    if(result.customer.type != undefined)
                    {
                        if(result.customer.type == 2)
                        {
                            window.isGuestCheckout = true;
                        }
                    }
                }             
            }

            // eslint-disable-next-line no-unused-vars
            var callbackError = function(result, textStatus, jqXHR) {
                vueInstance.isLoading = false;
            }     
                
            vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);      
        },
        closeAddressForm(){
            // $("#addressFormWrapper").animate({ height: 0 }, 900);
                  
            setTimeout(() => {                        
                this.showEditAddressForm = false;
                this.showNewAddressForm = false;
            }, 100);

            this.refreshAddresses();
        },
        deleteAddress(){
            var config = window.config;
            var vueInstance = this;
            var url = config.API_BASE + 'TCPDeleteAddrJ.action?json=true&addrId='+ vueInstance.selectedAddress.id;     
            var params = {
                
            };

            // eslint-disable-next-line no-unused-vars
            var callbackSuccess= function(result, textStatus, jqXHR){                   
                vueInstance.confirmDeleteAddress();
            }

            // eslint-disable-next-line no-unused-vars
            var callbackError = function(result, textStatus, jqXHR){
        
            }          

            vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);

        },
        confirmDeleteAddress(){
              var config = window.config;
                var vueInstance = this;
                var url = config.API_BASE + 'TCPDeleteAddrSubmitJ.action';     
                var params = {
                    json: "true"
                };
                
                if(window.xtoken != undefined)
                {
                    params.xsrfToken = btoa(window.xtoken);
                }

                // eslint-disable-next-line no-unused-vars
                var callbackSuccess= function(result, textStatus, jqXHR){                   
                    vueInstance.$alert(vueInstance.$t('addressDeleted'));
                    vueInstance.closeAddressForm();
                }

                // eslint-disable-next-line no-unused-vars
                var callbackError = function(result, textStatus, jqXHR){
            
                }          

                vueInstance.makeRequest("POST", url, JSON.stringify(params), callbackSuccess, callbackError);
        },
        addNewAddress(){
            this.showEditAddressForm = false;
            this.showNewAddressForm = true;
            // $("#addressFormWrapper").animate({ height: 1000 }, 900);
            setTimeout(() => {                             
                $(".content").animate({ scrollTop: 400 }, 300);
            }, 150);
        },
        refreshAddresses(){
            var currentlySelectedAddress = $('#addressPicker').val(); 

            var config = window.config;
            var vueInstance = this;

            var url = config.API_BASE + 'TCPAddrBookJ.action';     

            var params = {
                json: "true",       
            };

            // eslint-disable-next-line no-unused-vars
            var callbackSuccess= function(result, textStatus, jqXHR){       
            
                if(result.exception !== undefined)
                {                
                    vueInstance.$router.go(-1);
                    return;
                }

                if(result.requiresLogin)
                {
                    vueInstance.$router.push({ path:'/Login' });
                    return;
                }
                
                // vueInstance.customer = result.customer;
                vueInstance.addresses = result.addresses;
                vueInstance.selectedAddress = result.addresses.filter(addr => addr.id.toString() === currentlySelectedAddress.toString())[0];
                vueInstance.selectedAddressId = vueInstance.selectedAddress.id;
                vueInstance.addressLoaded = true;
                vueInstance.updateCustomerObject();
                vueInstance.$forceUpdate();
            }

            // eslint-disable-next-line no-unused-vars
            var callbackError = function(result, textStatus, jqXHR){
                vueInstance.isLoading = false;
            }          

            vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);       

        },
        openOrderDetails(guid, shopId){
            var config = window.config;
            var vueInstance = this;
            var url = config.API_BASE + 'TCPOrderDetailsJ.action';     

            var params = {
                guid: guid,
                json: "true",
                shopId: shopId
            };

            // eslint-disable-next-line no-unused-vars
            var callbackSuccess= function(result, textStatus, jqXHR){       
                vueInstance.currentOrderSelected = result.order;
                vueInstance.currentOrderSelected.guid = result.guid;
                // vueInstance.showOrderDetails = true;
                vueInstance.$refs.orderDetailsModal.openModal();
            }

            // eslint-disable-next-line no-unused-vars
            var callbackError = function(result, textStatus, jqXHR){
                // console.log(result);
                // console.log(textStatus);
                // console.log(jqXHR);
            }
            
            vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);
        },
        openTransactionDetails(id){
            var vueInstance = this;
            var transaction = vueInstance.recentTransactions.filter(trans => trans.id.toString() === id.toString())[0];
            if(transaction == undefined)
            {
                console.log("Could not load transaction data!");
                return;
            }

            vueInstance.currentTransactionSelected = transaction;
            vueInstance.$refs.transactionDetailsModal.openModal();
        },
        closeOrderDetails(){
            // this.showOrderDetails = false;
            this.$refs.orderDetailsModal.closeModal();
            this.currentOrderSelected = null;
        },                
        goBack(){
            this.$router.go(-1);
        },
        modify(){
            window.customer = this.customer;
            this.$router.push({path: '/Edit', params: { customer: { "customer": this.customer} } });
        },
        changePassword(){
            this.$router.push({path: '/PasswordReset' });
        },
        logout(){
            var config = window.config;
            var vueInstance = this;
            var url = config.API_BASE + 'TCPLogoutJ.action';     

            var params = {};

            var callbackSuccess= function(result, textStatus, jqXHR){       
                if(result.errorCode < 0)
                {
                    vueInstance.$alert(result.errorMsg);
                    return;                   
                }
         
                window.customerLoggedIn = null;
                vueInstance.$router.push({ path: '/Login' });
                console.log(result);            
                console.log(textStatus);
                console.log(jqXHR);        
            }

            var callbackError = function(result, textStatus, jqXHR){
                console.log(result);
                console.log(textStatus);
                console.log(jqXHR);
            }
            
            vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);
        },
        stornoOrder(){
            let vueInstance = this;
            vueInstance.$confirm(this.$t("stornoOrder")).then(() => {
                vueInstance.$alert(this.$t("orderStornoed"));
                vueInstance.$refs.transactionDetailsModal.closeModal();
            });
        }
    },

    created() {
        if (window.customerLoggedIn == null)
        {
            this.$router.push({path: '/Login'});
            return;
        }

        this.isLoading = true;
        var config = window.config;
        var vueInstance = this;
        var url = config.API_BASE + 'TCPRetrieveOrdersJ.action';     

        var params = {
            
        };

        var callbackSuccess= function(result, textStatus, jqXHR) {       
                
            if (result.errorCode < 0)
            {
                vueInstance.$alert(result.errorMsg);
                return;
                //failed, show errormsg
            }
        
            if (result.requiresLogin == true)
            {
                window.customerLoggedIn = null;
                // vueInstance.$router.push({path: '/Login'});
                return;
            }

            console.log(result);            
            console.log(textStatus);
            console.log(jqXHR);        
                
            vueInstance.recentOrders = result.orders;
            vueInstance.getTransactions();
            vueInstance.isLoading = false;            
        }

        var callbackError = function(result, textStatus, jqXHR) {

            console.log(result);
            console.log(textStatus);
            console.log(jqXHR);
            vueInstance.isLoading = false;
        } 
        
        vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);
    },
    beforeRouteLeave (to,from,next) {
        if(to.fullPath === "/Edit"){
            window.customer = this.customer;
        }

        clearInterval(this.intervalId);
        next();
    },
    mounted() {
        //  $("#siteHeader").unbind("click");

        var config = window.config;
        var vueInstance = this;
        var url = config.API_BASE + 'TCPAfterLoginJ.action';
        var params = {
            json: "true",       
        };

         // eslint-disable-next-line no-unused-vars
        var callbackSuccess = function(result, textStatus, jqXHR) {       
         
            if (result.exception !== undefined)
            {                
                vueInstance.$router.go(-1);
                return;
            }
            if (result.requiresLogin)
            {
                vueInstance.$router.push({ path:'/Login' });
                return;
            }       
            
            if (config.USE_ALLERGEN_FILTERS && result.wondCustomer.allergenIds != undefined) {
                vueInstance.selectedAllergens = result.wondCustomer.allergenIds;
            }

            if (config.USE_ALLERGEN_FILTERS && result.wondCustomer.propertyIds != undefined) {
                vueInstance.selectedProperties = result.wondCustomer.propertyIds;
            }

            if(result.customer != undefined)
            {
                if(result.customer.type != undefined)
                {
                    if(result.customer.type == 2)
                    {
                        window.isGuestCheckout = true;
                    }
                }
            }  
            
            vueInstance.customer = result.customer;
            vueInstance.addresses = result.customer.addresses;
            vueInstance.addressLoaded = true;
            vueInstance.pointsBalance = result.rewardPointsAvailable;
            vueInstance.selectedAddress = result.customer.addresses[0];
            
          
            if(result.wondCustomer != undefined)
            {
                vueInstance.pointCampaignsDefinitions = result.wondCustomer.pointCampaigns;
                vueInstance.cardNum = result.wondCustomer.cardNum;         
                if (result.wondCustomer.cardType.toUpperCase() === "CREDIT") {
                    vueInstance.creditBalance =  result.wondCustomer.creditBalance.toFixed(config.CUSTOMER_BALANCE_DECIMAL_PLACES);
                    vueInstance.customerCardWithCreditFunktion = true;
                }else{
                    vueInstance.prepayBalanceCash = (result.wondCustomer.prepayBalanceCash + result.wondCustomer.prepayBalanceVoucher + result.wondCustomer.prepayBalanceBonus).toFixed(config.CUSTOMER_BALANCE_DECIMAL_PLACES);
                }
                vueInstance.startQrCodeInterval();

                // console.log(vueInstance.pendingCustomerCardPayments);
                if(result.wondCustomer.pendingTopup != undefined){
                    vueInstance.pendingCustomerCardPayments = result.wondCustomer.pendingTopup;
                    // result.wondCustomer.pendingTopup.forEach(element => {
                    //     vueInstance.pendingCustomerCardPayments.push({date: element.paymentTimestamp, amount: element.amount})
                    // });
                }

            }
      
        }

         // eslint-disable-next-line no-unused-vars
        var callbackError = function(result, textStatus, jqXHR) {
            vueInstance.isLoading = false;
        }     
            
        vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);              
    }

}
</script>

<style scoped>

   #addressPicker{
        height:50px;
    }

    .btnAddress{
        min-width:10%;
    }
    
    .customerDataTable 
    {        
        padding: 10px 0px 0px 0px;
        margin-bottom:0;
        width: 50%;
        display: inline-block;
        text-align:start;
    }

    .customerDataTable ul, li
    {
        width: 100%;             
        list-style: none;

    }

    .customerJustify, .customerJustifyRight
    {
        width: 100%;    
        display: inline-block;          
    }    

    .customerData
    {       
        overflow-y:auto;
        /* height: 500px; */
        display:contents;
        height:auto;
        text-align: left;     
        width: 100%;
        border-right:2px solid var(--main-color);         
    }
    
    .customerHeadLine
    {
        position: sticky;
        background-color: lightgray;   
        color: black;            
        text-align: left;
        font-weight: bold;                    
    }
    
    .recentOrdersPreview 
    {        
        min-height:450px;  
        height:68vh;                                     
        width: 100%;
        overflow-y: auto;         
        padding-top:0px;        
    }

    @media screen and (max-width:768px) {
        .recentOrdersPreview
        {           
            min-height:450px;  
            height:auto;  
        }

        .customerData
        {
            padding:0;
            border-right:0px;
        }

        .btnAddress{
            min-width:50%;
        }
    }

    @media (min-width: 768px) {
        .customerJustifyRight 
        {
            float: right;
            width: 50%;
        }
        .recentOrdersPreview
        {
            float: right;     
        }
        .customerJustify
        {
            float: left;
            width: 50%;
        }        
        .customerData
        {
            float: left;
        }
        .customerHeadLine
        {            
            display: block;
        }       
    }

    .recentCustomerOrdersList
    {
        color: black;
        list-style: none;
        text-align: left;
        overflow-y: auto;
        padding-right: 10px;
        
    }

    .recentCustomerOrdersEntry
    { 
        border-bottom: 0.2px solid gray;        
    }

    .recentCustomerOrdersEntry:hover
    {         
        
        cursor:pointer;
        border-left: 3px solid var(--main-color) !important;
    }

    .recentCustomerOrdersEntryAmount
    {
        color: var(--main-color);
        text-align: right;
    }

    .logoutButton {
        background-color: inherit;
        float: right;
        color:var(--main-color);
    }

    .logoutButton:hover{
        text-decoration:underline;
        text-decoration-color: var(--main-color);;
        color:white;
    }

    @media screen and (max-width:453px) {
        .logoutButton {
            width:50%;

        }
    }

    .navBackIcon {
        height:50px;
        float:left;
        width:50px;
    }
    
    .navBack{
        background-color:black;
        color:var(--main-color);
    }

    @media screen and (max-width:770px) {
        #addressPicker{
            width:100% !important;
            max-width: 100% !important;
        }
    }


.topUpBtn {
    display:inline-block;
    width:50%;
    margin:10px;
}

.topUpInputField{
    display:inline-block;
    width:50%;
    margin:10px;
}

.customerCardBalanceButton {
    width:100%;
}

#content-wrapper{
    padding-bottom: 0%;
}

</style>