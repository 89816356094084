<template>
    <div class="qrLandingPageWrapper">
        <loading :active.sync="isLoading" 
            :can-cancel="true"        
            :is-full-page="true"
            loader='bars'></loading> 
        <!-- Play short looped video as background -->
        <!-- <video autoplay muted loop id="myVideo">
            <source src="@/assets/videos/landing-page.mp4" type="video/mp4">
        </video> -->
        
        <!-- Or set an image as the background -->
        <img  id="myImage" src="@/assets/images/QRLandingBackground.jpg">

        <div class="qrLandingPageBody">
            <div class="col-12" style="padding-top:15%">
                <div v-if="!showHomeMode">
                    <img style="max-width:150px;width:100%" src="@/assets/images/logo.png" />
                </div>
                <div v-else>
                    <img @click="goToShop()" style="max-width:150px;width:100%" src="@/assets/images/logo.png" />
                </div>
            </div>
               

            <div class="col-12 fontTitle fontLandingTitle" v-if="landSuccess || showHomeMode">
                {{$t('qrLandingPageTitle')}}
            </div>            

            <div class="col-12 fontContent fontLandingContent" v-if="landSuccess">
                {{$t('qrLandingPageBody1')}}{{table}}{{$t('qrLandingPageBody2')}}
            </div> 
            
            <div class="col-12 fontContent fontLandingContent" v-if="showHomeMode">
                {{$t('qrLandingPageHomeText')}}
            </div> 

            <div class="col-12 btnLandingWrapper" v-if="landSuccess">
                <button class="btn btn-primary btnLanding" v-if="openTableExists" @click="payOrder">{{$t('pay')}}</button>
                <button class="btn btn-primary btnLanding" @click="startOrder">{{$t('landingOrder')}}</button>                             
            </div>   

            <h2 v-if="orderDetails != null && orderDetails.order != undefined" style="color:white;"> Unbezahlt: </h2>
            <table class="shoppingCartItemsTable"  style="table-layout:fixed" v-if="orderDetails != null && orderDetails.order != undefined">
        
                <thead>
                    <th class="shoppingCartHeaderBig">{{ $t('article') }}</th>
                    <!-- <th class="shoppingCartHeaderSmall"></th> -->
                    <th class="shoppingCartHeaderBig">{{ $t('summ') }}</th>                   
                </thead>

                <tr v-for="item in orderDetails.order.menuItems" v-bind:key="item.kkProdId">
                    <td class="shoppingCartLine">{{item.name}}
                        <!-- <div v-if="item.hasOptions" style="padding:0;text-align:right;">
                            <span style="font-size:13px;padding:0;" v-html="item.optionText"></span>
                        </div> -->
                    </td>
                    <!-- <td class="shoppingCartLine">
                        {{item.formattedPrice}}
                    </td> -->

                    <!-- <td class="shoppingCartLine">
                    </td> -->
                    <!-- <td class="shoppingCartLine mainColor" style="text-align:center; font-size:14px;">{{ item.weightOrVolume != undefined ? item.weightOrVolume * 1000 : item.quantity }} {{item.weightOrVolume != undefined ? 'g' : 'x'}} </td> <span style="font-weight:400; font-size:14px;">{{item.weightOrVolume != undefined ? " - " + item.formattedPrice + "/kg" : ""}} -->
                    <td class="shoppingCartLine mainColor" style="font-weight:600;">{{item.weightOrVolume != undefined ? item.formattedPrice : item.formattedPrice}} </td>
                </tr>       

                <tr style="border-top:2px solid var(--main-color)">
                    <td class="shoppingCartLine" style="font-weight:600;">{{$t('total')}}
                        <!-- <div v-if="item.hasOptions" style="padding:0;text-align:right;">
                            <span style="font-size:13px;padding:0;" v-html="item.optionText"></span>
                        </div> -->
                    </td>
                    <!-- <td class="shoppingCartLine">
                        {{item.formattedPrice}}
                    </td> -->

                    <!-- <td class="shoppingCartLine">
                    </td> -->
                    <!-- <td class="shoppingCartLine mainColor" style="text-align:center; font-size:14px;">{{ item.weightOrVolume != undefined ? item.weightOrVolume * 1000 : item.quantity }} {{item.weightOrVolume != undefined ? 'g' : 'x'}} </td> <span style="font-weight:400; font-size:14px;">{{item.weightOrVolume != undefined ? " - " + item.formattedPrice + "/kg" : ""}} -->
                    <td class="shoppingCartLine mainColor" style="font-weight:800;">{{ orderDetails.order.formattedTotal }}</td>
                </tr>       
            </table>


                       

        </div>

    </div>
</template>


<script>
import $ from 'jquery'
import VueLoading from 'vue-loading-overlay'

// import { required, sameAs } from 'vuelidate/lib/validators'

export default {
     data() {
        return {

            footerSaveDisplay: "",
            headerSaveDisplay: "",
            landSuccess: false,
            openTableExists: false,
            openTable: {
                Type:Object,
                default: null
            },
            orderDetails: {
                Type: Object,
                default: null
            },
            showHomeMode: false,
            isLoading: false,

        }
	},
    props:{
        shop: {
            Type: String
        },
        table: {
            Type: String
        },
        home: {
            Type: Boolean
        },
        jwt : {
            Type:String,
            Default: "",
        },
        code : {
            Type:String,
            Default: "",
        },
    },
    computed: {
    },
    components:{
        'loading' : VueLoading,
    },
    methods: { 
        payOrder() {
            window.shopSelected = true;
            window.TableMode = true;
            if(window.config.COOKIES_ENABLED){
                let duration = 60;
                if (window.config.TABLEMODE_COOKIE_DURATION > 0) {
                    duration = window.config.TABLEMODE_COOKIE_DURATION
                }
                this.$cookies.set('TableMode', true, duration * 60);
            }
            this.TableMode = true;
            this.$router.push( { path:'/TablePay' } );
        },
        startOrder() {            
            window.shopSelected = true;
            window.TableMode = true;
            if(window.config.COOKIES_ENABLED){
                let duration = 60;
                if (window.config.TABLEMODE_COOKIE_DURATION > 0) {
                    duration = window.config.TABLEMODE_COOKIE_DURATION
                }
                this.$cookies.set('TableMode', true, duration * 60);
            }            
            window.timeSet = true;
            this.TableMode = true;
            this.$router.push( { path:'/Menu' } );
        },
        makeRequest: function (type, url, data, callbackSuccess, callbackError, dataType) {
            console.log("ajax request:");
            console.log(type);
            console.log(url);
            $.ajax({
                method : type,
                type : type,
                timeout : '20000',
                headers: {
                    "Access-Control-Allow-Origin":"*",
                },
                scriptCharset : "utf-8",
                contentType : "application/json; charset=utf-8",        
                url : url,
                data : "kkj=kkj&" + encodeURIComponent(data),
                // context : callback,
                success : callbackSuccess,
                error: callbackError,
                dataType : dataType != undefined ? dataType :'jsonp'
            });
            window.REQUEST_TIMESTAMP = new Date().getTime();
        },
        getOrderDetails() {
            console.log(window.shopObj);
            var config = window.config;
            var vueInstance = this;
            var url = config.API_BASE + 'TCPOrderDetailsJ.action';
            var params = {
                guid: window.openTable.guid,
                json: "true",
                shopId: window.openTable.shopId
            };
                        
            // eslint-disable-next-line no-unused-vars
            var callbackSuccess= function(result, textStatus, jqXHR) {       
                if( (result.exception !== undefined) || (result.item !== undefined) )
                {             
                    vueInstance.$alert(vueInstance.$t('getItemFailed'));
                    window.isLoading = false;
                    return;
                }                 

                vueInstance.orderDetails = result;
            }

            // eslint-disable-next-line no-unused-vars
            var callbackError = function(result, textStatus, jqXHR) {   

            }
            
            vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);
        },
        selectTableByQRCode(){
            var config = window.config;
            var vueInstance = this;

            var url = config.API_BASE + 'TCPSelectTableByQRCodeJ.action';        
            var params = {                
                "json": "true",
                "billNum": "1",
                "shopCode": window.tableModeShop,
                "tableCode": window.tableModeTable
            }

            var callbackSuccess= function(result){         
                console.log(result);
                if(result.exception != undefined)
                {    
                    vueInstance.$alert(vueInstance.$t('failedGeneric'));
                    window.isLoading = false;               
                    return;
                }

                window.shopObj = {name:result.shopCode};                
                window.orderType = "dinein";
                window.tableId = result.tableId;
                vueInstance.landSuccess = true;

                
                if(result.table != undefined && config.QRLANDING_OPEN_TABLES_ENABLE == true)
                {
                    window.shopObj.id = result.table.shopId;       
                    vueInstance.openTableExists = true;
                    vueInstance.openTable = result.table;
                    window.openTable = result.table;
                    vueInstance.getOrderDetails();

                    // if(window.config.COOKIES_ENABLED){
                    //     vueInstance.$cookies.set('shopObj', window.shopObj);
                    // }
                }
                vueInstance.isLoading = false;
            }

            var callbackError = function(result, textStatus, jqXHR){
                console.log(result);
                console.log(textStatus);
                console.log(jqXHR);
                vueInstance.isLoading = false;
            }
        
            this.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);
        },
        goToShop(){
            if(window.config.TABLE_MODE_OVERRIDE && window.config.TABLE_MODE_OVERRIDE_HOMEPAGE != "")
            {
                window.location = window.config.TABLE_MODE_OVERRIDE_HOMEPAGE;
                return;
            }
        },
        checkCustomer(){
            var config = window.config;
            var vueInstance = this;
            var url = config.API_BASE + 'TCPAfterLoginJ.action';
            var params = {
                json: "true",       
            };

            // eslint-disable-next-line no-unused-vars
            var callbackSuccess = function(result, textStatus, jqXHR) {       
            
                if (result.exception !== undefined)
                {                
                    console.log(result.exception);
                    return;
                }
                
                if(result.requiresLogin == true)
                {
                    vueInstance.$router.push({ path: "/Login" });
                }

                if(result.requiresLogin == false)
                {
                    vueInstance.selectTableByQRCode();
                }

                if(result.customer != undefined)
                {
                    if(result.customer.type != undefined)
                    {
                        if(result.customer.type == 2)
                        {
                            window.isGuestCheckout = true;
                        }
                    }
                }  
            }

            // eslint-disable-next-line no-unused-vars
            var callbackError = function(result, textStatus, jqXHR) {
                vueInstance.isLoading = false;
            }     
                
            vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);      
        },
        getToken(){
            var config = window.config;
            var vueInstance = this;
            var url = config.API_BASE + 'TCPLoginJ.action';     

            var params = {
                json: "true"
            };

            // eslint-disable-next-line no-unused-vars
            var callbackSuccess= function(result, textStatus, jqXHR){     
                if(result.xsrfToken != undefined)
                {
                    window.xtoken = atob(result.xsrfToken);
                }                                          
            }

            // eslint-disable-next-line no-unused-vars
            var callbackError = function(result, textStatus, jqXHR){
            }

            vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);
        },
        forceLogin(){
            var config = window.config;        
            var vueInstance = this;
            var url = "";
            url = config.API_BASE + 'TCPAfterLoginJ.action';     

            var params = {
                json: "true",       
            };

            // eslint-disable-next-line no-unused-vars
            var callbackSuccess= function(result, textStatus, jqXHR){   
                if(result.requiresLogin == true)
                {
                    console.log("Routing to LOGIN");
                    vueInstance.$router.push( { path: "/Login" } );
                    return;
                }    
                
                vueInstance.customer = result.customer;
                vueInstance.shopsFilter = result.wondCustomer.availableShops;

                if(result.customer != undefined)
                {
                    if(result.customer.type != undefined)
                    {
                        if(result.customer.type == 2)
                        {
                            window.isGuestCheckout = true;
                        }
                    }
                }  
            }

            // eslint-disable-next-line no-unused-vars
            var callbackError = function(result, textStatus, jqXHR){
                vueInstance.isLoading = false;
            }          

            vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);  
        },
        checkJWT(){
            console.log("checkJWT");
            var config = window.config;
            var vueInstance = this;
            var url = "";
            vueInstance.isLoading = true;
            vueInstance.jwtString = vueInstance.jwt;
            if((vueInstance.jwtString != "" && vueInstance.jwtString != undefined && vueInstance.jwtString != null) || (vueInstance.code != "" && vueInstance.code != undefined && vueInstance.code != null))
            {
                vueInstance.getToken();
                
                url = config.API_BASE + 'TCPLoginWithJWTJ.action';

                var paramsJWT = {
                    // jwt: vueInstance.jwtString,
                    returnCustomerData: "true"                       
                };
                
                if(vueInstance.jwtString != "" && vueInstance.jwtString != undefined && vueInstance.jwtString != null)
                {
                    paramsJWT.jwt = vueInstance.jwtString;                    
                }

                // if( window.preselectedShopCode != undefined )
                // {
                //     paramsJWT.code = window.preselectedShopCode;
                // }

                if(vueInstance.code != "" && vueInstance.code != undefined && vueInstance.code != null)
                {
                    window.preselectedShopCode = vueInstance.code;
                    paramsJWT.code = vueInstance.code;
                }

                

                // eslint-disable-next-line no-unused-vars
                var callbackSuccessJWT= function(result, textStatus, jqXHR){   
                    vueInstance.isLoading = false;     
                    vueInstance.showChoice = true;
                    
                    if(result.exception !== undefined)
                    {
                        console.log("SSO Login Failed - Routing to LOGIN");
                        vueInstance.$alert(vueInstance.$t('SSOLoginError'));
                        vueInstance.$router.push( { path: "/Login" } );
                        return;
                    }

                    if(result.requiresLogin == true)
                    {
                        console.log("Routing to LOGIN");
                        vueInstance.$router.push( { path: "/Login" } );
                        return;
                    }

                    if(result.customer == undefined)
                    {
                        console.log("JWT Login unsuccesful!");
                        if(result.requiresLogin == false)
                        {
                            console.log("A user is already logged in, JWT ignored!");
                            vueInstance.checkCustomer();     
                            return;
                        }
                        if(config.FORCE_LOGIN_BEFORE_USE)
                        {
                            console.log("Routing to LOGIN");
                            vueInstance.$router.push( { path: "/Login" } );
                            return;
                        }
                    }
                    
                    vueInstance.customer = result.customer;
                    window.customerLoggedIn = true;       
                    vueInstance.checkCustomer();     
                    return;
                }              

                // eslint-disable-next-line no-unused-vars
                var callbackErrorJWT = function(result, textStatus, jqXHR){
                    vueInstance.isLoading = false;
                    vueInstance.showChoice = true;
                }

                vueInstance.makeRequest("GET", url, JSON.stringify(paramsJWT), callbackSuccessJWT, callbackErrorJWT);
            }
            else
            {               
                vueInstance.isLoading = false;
                if (!config.FORCE_LOGIN_BEFORE_USE)
                {
                    return;
                }
                // vueInstance.forceLogin();
            }
        },
    },

    mounted() {
        $("#siteHeader").css({"display": "none"});
        $("#global-footer").css({"display": "none"});
        $("#ControlGroupButtonClusterFixed").css({"display": "none"});

        if(this.home){
            this.showHomeMode = true;
            return;
        }else{
            this.isLoading = true;
        }

        window.TableMode = true;
        if(window.config.COOKIES_ENABLED){
            let duration = 60;
            if (window.config.TABLEMODE_COOKIE_DURATION > 0) {
                duration = window.config.TABLEMODE_COOKIE_DURATION
            }
            this.$cookies.set('TableMode', true, duration * 60);
            this.$cookies.set('tableId', this.table, duration * 60);
            this.$cookies.set('shopId', this.shop, duration * 60);
            // vueInstance.$cookies.set('shopObj', {name:result.shopCode});
            this.$cookies.set('orderType', "dinein");        
        }

        
        window.tableModeShop = this.shop;
        window.tableModeTable = this.table;
        window.shopId = this.shop;
        window.tableId = this.table;
                
        if(window.config.AGE_VERIFICATION_SHOPCODES.includes(this.shop))
        {
            window.ageVerification = true; 
        }
        else
        {
            window.ageVerification = false; 
        }


        if(!window.TableModeDataSaved)
            if (this.jwt != undefined || this.code != undefined) {
                this.checkJWT();
            } else {
                this.checkCustomer();
            }
        else
            this.selectTableByQRCode();

        window.TableModeDataSaved = true;


    },
    beforeMount() {
        
    },
    // eslint-disable-next-line no-unused-vars
    beforeRouteLeave (to, from , next) {
        $("#siteHeader").css({"display": "block"});
        $(".globalFooter").css({"display": "block"});
        $("#ControlGroupButtonClusterFixed").css({"display": "block"});
        $(".content").addClass("header-small-content-margin");
        next();
    },
    // eslint-disable-next-line no-unused-vars
    beforeRouteEnter (to, from, next) {  
        Object.assign(window.config, window.configTablemode);
        $("#siteHeader").css({"display": "none"});
        $(".globalFooter").css({"display": "none"});
        $("#ControlGroupButtonClusterFixed").css({"display": "none"});
        $(".content").removeClass("header-small-content-margin");
        next();   
    },
}
</script>

<style scoped>

    #myImage {
        position: fixed;
        right: 0;
        bottom: 0;
        min-width: 100%;
        min-height: 100%;
        z-index: -1;
        max-width: 100%;
    }

    #myVideo {
        position: fixed;
        right: 0;
        bottom: 0;
        min-width: 100%;
        min-height: 100%;
        z-index: -1;
    }

    .fontLandingTitle{
        font-family: var(--fonts-promo-font-family);
        text-align:center;
        font-size:35px;
        color:var(--landing-text-color);
        margin-top:3vh;
    }

    .fontLandingContent{
        /* font-family: var(--fonts-promo-font-family); */
        color:var(--landing-text-color);;
        margin-top:3vh;
    }

    .btnLanding {
        width:100%;
        max-width:600px;
        height:55px;
    }

    table{
        color:var(--landing-text-color);;
    }

    .btnLandingWrapper{
        /* margin-top:30vh; */
    }

    .qrLandingPageBody{
        z-index: 1000001;
        
    }

    .shoppingCartHeader{
        width:30%;
    }

    .shoppingCartLine{
        height:40px;    
    }

    .shoppingCartHeaderBig{
        width:50%;
    }

    .shoppingCartHeaderSmall{
        width:10%;
    }

    .shoppingCartItemsTable{
        width:100%;
    }

    .shoppingCartSummaryTable{
        width:100%;
        border-top: 1px solid var(--main-color);
    }

    .shoppingCartSummaryLine{
        font-weight:600;    
    }


</style>