<template>
    <div style="padding-top: 10vh;">

        <div v-if="paymentFailed"> {{$t('paymentFail')}}</div>
        <button v-if="paymentFailed" @click="returnToOrder()" class="btn btn-primary">{{$t('paymentEndBack')}}</button>

        <!-- <button @click="loginTest">Test</button> -->

    </div>
</template>

<script>
import $ from 'jquery'

export default {
    data() {
        return {
            paymentFailed: false,
        }
    },
    props:{
        payment: {
            Type: String
        },
        result: {
            Type: String
        }
    },
    methods: {
        makeRequest: function (type, url, data, callbackSuccess, callbackError, dataType) {
            console.log("ajax request:");
            console.log(type);
            console.log(url);
            $.ajax({
                method : type,
                type : type,
                timeout : '20000',
                headers: {
                    "Access-Control-Allow-Origin":"*",
                },
                scriptCharset : "utf-8",
                contentType : "application/json; charset=utf-8",        
                url : url,
                data : "kkj=kkj&" + encodeURIComponent(data),
                // context : callback,
                success : callbackSuccess,
                error: callbackError,
                dataType : dataType != undefined ? dataType :'jsonp'
            });
            window.REQUEST_TIMESTAMP = new Date().getTime();
        },
        isInIFrame(){
            try{
                return window.self !== window.top;
            } catch (e) {
                return true;
            }
        },
        returnToOrder(){
            this.$router.push({ path: '/OrderConfirmation' });
        },
        loginTest(){
        // for testing
        // 1F125881DF67E58F82214DF728F2B3BD
        var config = window.config;
        var vueInstance = this;
        var url = config.API_BASE + 'TCPStartFromKKSessionJ.action';     

        var params = {
            json: "true",
            sessionId: "38F469A37F3779A7603D109DD94F728D",
            returnCustomerData: true,
            returnMealBalance: false      
        };

        console.log(window.paymentOrigin);

        // eslint-disable-next-line no-unused-vars
        var callbackSuccess= function(result, textStatus, jqXHR){
            console.log(result);
        }

        // eslint-disable-next-line no-unused-vars
        var callbackError = function(result, textStatus, jqXHR){
            vueInstance.isLoading = false;
        }

        vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);
        },
    },
    beforeMount(){
        window.shoppingCart = null;
    },
    mounted(){        

        var config = window.config;
        var vueInstance = this;
        var url = config.API_BASE + 'TCPAfterLoginJ.action';     

        var params = {
            json: "true",       
        };

        console.log(window.paymentOrigin);

        // eslint-disable-next-line no-unused-vars
        var callbackSuccess= function(result, textStatus, jqXHR){       
            vueInstance.customer = result.customer;   
            if(result.customer != undefined)
            {
                if(result.customer.type != undefined)
                {
                    if(result.customer.type == 2)
                    {
                        window.isGuestCheckout = true;
                    }
                }
            }
            
            // if(vueInstance.isInIFrame()){         
            // if(vueInstance.payment == "payone"){
            //     console.log("POSTING TOP MESSAGE: " + vueInstance.result);
            //     window.top.postMessage("payone" + vueInstance.result, '*'); 
            // }   else{
                // if(vueInstance.payment == "securepay"){
                //     console.log("POSTING TOP MESSAGE: " + vueInstance.result);
                //     window.top.postMessage("securepay" + vueInstance.result, '*'); 
                // }else{
                    console.log("POSTING TOP MESSAGE: " + vueInstance.result);
                    window.top.postMessage(vueInstance.result, '*'); 
                // }


            // }          

            // } else {   

                 // AP 20220505

                if( vueInstance.payment == "novalnet" && vueInstance.result == "S"){
                    vueInstance.isLoading = false;
                    vueInstance.$router.push({ path: '/CheckoutFinished' });
                }

                // AP 20220512
                // var config = window.config;   

                if(vueInstance.result == "S"){

                    // We don't have the subCode anymore
                    // if(vueInstance.currentPaymentSubCode == "P")
                    // {
                    //     vueInstance.isLoading = false;
                        vueInstance.$router.push({ path: '/CheckoutFinished' });
                        // return; 
                    // }              
                    
                    // If Transaction interface then  use this:
                    // var url = config.API_BASE + 'ProcessSaferpayJ.action';
                    // var params = {
                    //     json: "true",
                    //     vresult: this.result
                    // };
                    // // eslint-disable-next-line no-unused-vars
                    // var callbackSuccess= function(result, textStatus, jqXHR) {
                        
                    //     // vueInstance.isLoading = false;
                    //     vueInstance.$router.push({ path: '/CheckoutFinished' });
                        
                    // }
                    // // eslint-disable-next-line no-unused-vars
                    // var callbackError = function(){
                    //     // vueInstance.isLoading = false;   
                    //     console.log("Request result error")
                    // }
                
                    // vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);
                } else if(vueInstance.result == "F"){
                    vueInstance.paymentFailed = true;
                    if(!config.COOKIES_ENABLED  == false && config.TABLE_MODE_OVERRIDE){
                        window.location = config.TABLE_MODE_OVERRIDE_HOMEPAGE;
                    }
                } else if(vueInstance.result == "A"){
                    vueInstance.paymentFailed = true;
                    if(config.COOKIES_ENABLED  == false && config.TABLE_MODE_OVERRIDE){
                        window.location = config.TABLE_MODE_OVERRIDE_HOMEPAGE;
                    }
                }

            // }
        }

        // eslint-disable-next-line no-unused-vars
        var callbackError = function(result, textStatus, jqXHR){
            vueInstance.isLoading = false;
        }

        vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);

       
    }
}

</script> 