<template>
  <transition name="fade">
    <dialog>
    <b-modal v-model="show" size="lg" title="modalTitle" centered aria-labelledby="modalTitle" hide-footer>
        <template #modal-header="{ close }">
            <slot name="header"/>
            <button type="button" class=" btn btn-primary" @click="close()" :aria-label="$t('closeModalButtonAriaLabel')" style="min-width: auto">
                <i class="fa fa-times" style=""></i>
                <!-- <svg xmlns="http://www.w3.org/2000/svg" height="25px" width="18px">
                <path
                    fill="currentColor"
                    d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                ></path>
                </svg> -->
            </button>
        </template>

        <template #default>
            <slot name="body" />
        </template>

        <template #modal-footer>
            <slot name="footer" />
             
        </template>

        <!-- </template> -->
    </b-modal>
    <!-- <div class="modal" aria-labelledby="modalTitle" v-if="show">
     

      <div class="modal__dialog">
        <div class="modal__header">
          <slot name="header"/>
          <button type="button" class="modal__close" @click="closeModal()" :aria-label="$t('closeModalButtonAriaLabel')">
            <i class="fa fa-times" style=""></i> -->
            <!-- <svg xmlns="http://www.w3.org/2000/svg" height="25px" width="18px">
              <path
                fill="currentColor"
                d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
              ></path>
            </svg> -->
          <!-- </button>
        </div>

        <div class="modal__body">
          <slot name="body"/>
        </div>

        <div class="modal__footer">
          <slot name="footer"/>
        </div>
      </div>

       <div class="modal__backdrop" @click="closeModal()"/>
    </div> -->
    </dialog>
  </transition>
</template>

<script>
export default {
  name: "Modal",
  data() {
    return {
      show: false
    };
  },
  methods: {
    closeModal() {
      this.show = false;
      document.querySelector("body").classList.remove("overflow-hidden");
    },
    openModal() {       
      this.show = true;
      document.querySelector("body").classList.add("overflow-hidden");
    }
  }
};
</script>


<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 0;
  right: 0;
  display:block;
  bottom: 0;
  left: 0;
  z-index: 200000;
  overflow-x: hidden;
  overflow-y: auto;
  &__backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1;

    @media screen and (max-width: 720px) {
      display:none;
      // height:90%;
    }
  }
  &__dialog {
    position: relative;
    width: 900px;
    background-color: #ffffff;
    border-radius: 5px;
    margin: 50px auto;    
    display: flex;
    flex-direction: column;
    z-index: 200002;
    @media screen and (max-width: 992px) {
      width: 90%;
      // height:90%;
    }
    
    @media screen and (max-width: 720px) {
      box-shadow: 0 0 300px rgb(0, 0, 0);
      // height:90%;
    }
  }
  &__close {
    min-width: 30px;
    height: 30px;
    border-radius:5px;
    border:none;
    background-color: var(--main-color);
    color:var(--main-color-highlight);
  }
  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 20px 20px 0px;
  }
  &__body {
    padding: 0px 10px 10px 10px;
    overflow: revert;
    display: flow-root;
    flex-direction: column;
    align-items: stretch;
  }
  &__footer {
    padding: 10px 20px 20px;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.modal{
  &__close:hover {
    width: 30px;
    height: 30px;
    border-radius:5px;
    border:0.2px solid var(--main-color);
    background-color: var(--main-color-highlight);
    color: var(--main-color);
  }
}
</style>
