<template>
    <div class="vueselect-wrapper">
        <v-select :options="optionsTranslated" @input="onSelectChange" label="title" :value="selectedOption">
            <template v-slot:option="option">     
                {{option.title}}
            </template>
        </v-select>
    </div>
</template>

<script>

import vselect from 'vue-select'
import $ from 'jquery'

export default {
    name:"select-picker",
    data(){
        return {
            selectedOption: {
                Type:Object
            }
        }
    },
    props: {
        options:{
            type: Array,
            required: true
        },
        callback: {
            Type: Function
        }
    },
    components: {
        'v-select':vselect
    },
    computed:{
        optionsTranslated(){
            console.log(this.options);
            var optTranslated = [];
            for(var i = 0; i < this.options.length; i++)
            {
                optTranslated.push(this.$t(this.options[i]));
            }
            return optTranslated;
        }
    },
    methods:{
        onSelectChange(element){   
            this.selectedOption = element;         
            var ele = this.options[this.optionsTranslated.indexOf(element)];
            this.selected = ele;
            if(this.callback != undefined)
            {
                if(ele != undefined)
                {
                    console.log("callback with element");
                    this.callback(ele);
                }
                else
                {
                    console.log("callback with element");
                    this.callback(null);
                }                
            }            
        }
    },
    mounted(){
        $('input').attr('readonly','readonly');
        this.selectedOption = this.optionsTranslated[0];
    }
}
</script>

<style>

.vueselect-wrapper .vs--searchable .vs__dropdown-toggle {
    width:100%;
    margin-right:0px;
}

</style>