<template>
    <div class="forgotPasswordPageWrapper">
        <div class="fontTitle row">
            <button class="btn btn-primary subPageHeader subPageHeaderButton" @click="goBack()">
                {{$t('back')}}
            </button>
        <div><h1 class="fontTitle">
            {{ $t('forgotPasswordPageTitle') }}
        </h1></div>
        </div>

        <form @submit.prevent="onSubmit">
            <div class="form-group">
                <label for="email" class="fontField">{{ $t('forgotPasswordEmail') }}</label>
                <input id="email" type="email" class="form-control form-control-lg" v-model="forgotPw.email" required/>
                <!-- <div class="inputError" role="alert" v-if="!$v.forgotPw.email.required">Email is required</div> -->
                <div class="inputError" role="alert" v-if="!$v.forgotPw.email.email && submitStatus === 'ERROR'"><b-icon-exclamation-triangle-fill></b-icon-exclamation-triangle-fill> {{$t('invalidEmail')}}</div>   
            </div>
<!-- 
            <div class="form-group">
                <label class="fontField">Repeat Email address</label>
                <input type="email" class="form-control form-control-lg" v-model="forgotPw.repeat" />
      
                <div class="inputError" role="alert" v-if="!$v.forgotPw.repeat.sameAsEmail && $v.forgotPw.repeat.required">The two emails should be equal</div>      
            </div> -->

            <!-- <button type="submit"  @click.stop="onClickCaptcha" :disabled="submitStatus === 'PENDING'" class="btn btn-dark btn-lg btn-block btnSubmit g-recaptcha">{{ $t('forgotPasswordSubmit') }}</button> -->
            <button type="submit"  @click.stop="onClickCaptcha" :disabled="submitStatus === 'PENDING'" class="btn btn-success btn-lg btn-block btnSubmit g-recaptcha">{{ $t('forgotPasswordSubmit') }}</button>

            <p class="forgot-password text-right mt-2 mb-4">
                <router-link to="/Register" class="darkLink">{{$t('noAccountYet')}}</router-link>
            </p>

            <!--<p class="typo__p" v-if="submitStatus === 'OK'">Please check your inbox!</p> -->
            <p class="typo__p" v-if="submitStatus === 'ERROR'">Please fill the form correctly.</p>
            <!--<p class="typo__p" v-if="submitStatus === 'PENDING'">Sending...</p> -->
        </form>

    </div>
</template>


<script>

import { required, email } from 'vuelidate/lib/validators'
import { BIconExclamationTriangleFill } from 'bootstrap-vue'

export default {
     data() {
        return {
            submitStatus: null,
            forgotPw: {
                email: "",
                // repeat: ""
            },
            shake: false,
            good: "",   
            responseToken: ""        
        }	
	},
    computed: {
    },
    validations: {
         forgotPw: {
            email: {
                required,
                email   
            },
            // repeat: {
            //     required,
            //     email,
            //     sameAsEmail : sameAs('email')     
            // }
        },        
    },
    components: {
        BIconExclamationTriangleFill
        // 'check-box' : CheckBox,
    },
    methods: { 
        // eslint-disable-next-line no-undef
        onClickCaptcha(e) {
            var vueInstance = this;
            console.log("onClickCaptcha");
            e.preventDefault();
            if(window.config.G_CAPTCHA_ENABLED == true)
            {
                // eslint-disable-next-line no-undef
                grecaptcha.ready(function() {
                    // eslint-disable-next-line no-undef
                    grecaptcha.execute(window.config.G_CAPTCHA_SITE_KEY, {action: 'submit'}).then(function(token) {
                        vueInstance.responseToken = token;
                        vueInstance.onSubmit(e);          
                    });
                });
            }
            else
            {
                vueInstance.onSubmit(e);
            }
       
        },
        goBack(){
            this.$router.go(-1);
        },
       onSubmit: function(event) {
			event.preventDefault();
           
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.submitStatus = 'ERROR';
                return;
            } else {
                this.submitStatus = 'OK'
            }
            
            var config = window.config;
            var vueInstance = this;
            var url = config.API_BASE + 'TCPForgotPasswordSubmitJ.action';     

            var params = {
                json: "true",
                emailAddr: this.forgotPw.email
            };

            if(window.xtoken != undefined)
            {
                params.xsrfToken = btoa(window.xtoken);
            }

            if(this.responseToken != "")
            {
                params.responseToken = this.responseToken;
            }

            var callbackSuccess= function(result, textStatus, jqXHR){
                vueInstance.$alert(vueInstance.$t('forgotPasswordSuccess'));
                vueInstance.$router.push({ path: '/Login' });
                console.log(result);            
                console.log(textStatus);
                console.log(jqXHR);
            }

            var callbackError = function(result, textStatus, jqXHR){

                console.log(result);
                console.log(textStatus);
                console.log(jqXHR);

            }
            
            vueInstance.makeRequest("POST", url, JSON.stringify(params), callbackSuccess, callbackError);
			this.shake = false;   
		}
    },
    beforeMount() {
        // var cachebuster = Math.round(new Date().getTime() / 1000);
        // var configjs = document.createElement("script");
        // configjs.src = 'config_tablemode.js?cb=' + cachebuster;
        // document.head.appendChild(configjs);
        // this.$forceUpdate();
        // var config = window.config;
        // var vueInstance = this;
        // var url = config.API_BASE + 'TCPMenuJ.action';     

        // var params = {
        //     includeAllergenIds: "true",
        //     includePropertyIds: "true",
        //     includeNutriValues: "true",
        //     checkPickupTime: "true",
        //     addChildren: "true",
        //     json: "true",
        //     timestampIn: "2018-10-08T06:57:22",
        //     includeBasketItems: "true" 
        // };

        // var callbackSuccess= function(result, textStatus, jqXHR){       
        //     if(result.exception !== undefined)
        //     {
        //         vueInstance.$alert("Menu not available: ");
        //         return;
        //     }
            
        //     console.log(result);            
        //     console.log(textStatus);
        //     console.log(jqXHR);
        //     vueInstance.MenuGroups = result.menuTop.items;
        // }

        // var callbackError = function(result, textStatus, jqXHR){
        //     console.log(result);
        //     console.log(textStatus);
        //     console.log(jqXHR);
        // }
     
        // vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);
    }   
}
</script>

<style scoped>

</style>