<script src="https://gist.github.com/Jonarod/7ff2fe4f81aae39e431aa7a08ce815bc.js"></script>

<template>
  <!-- <div class="wrapper flex items-center" v-if="!refresh" @click.stop="updateInput">
    <a v-if='hyperlink != ""' target="_blank" style="padding-left:30px;" :href="hyperlink" v-html="extendLabel(label)"></a>
    <span v-if='hyperlink == ""' v-html="extendLabel(label)"></span>
    <input class="checkbox" type="checkbox" :value="value" v-bind:id="value.lineId.toString() + value.articleId.toString() + value.prodId.toString()"/>
    <span class="checkmark" v-html="checkedMarker"></span>
  </div> -->



  <label class="wrapper flex items-center" >
    <a v-if='hyperlink != ""' target="_blank" :href="hyperlink" v-html="extendLabel(label)"></a>
    <span v-if='hyperlink == ""' v-html="extendLabel(label)"></span>
    <input class="checkbox" type="checkbox" :checked="isChecked" :value="value" @change="updateInputSimple" v-bind:id="value.lineId.toString() + value.articleId.toString() + value.prodId.toString()"/>
    <span class="checkmark" v-html="checkedMarker"></span>
  </label>
</template>

<script>
import $ from 'jquery'
export default {
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  data(){
    return {
      isDisabled: false,
      checkedMarker : "",
      refresh: false,    
    }
  },
  props: {
    "value": "",
    "modelValue": { default: "" },
    "label": { type: String, required: true},
    "trueValue": { default: true },
    "falseValue": { default: false },
    object: {Type: Object},
    available: { Type:Number },
    hyperlink: "",
    dataOpCnt: {Type:Number},
    hidePrice: Boolean
  },
  computed: {
    config(){
      return window.config;
    },

    
    isChecked() {
      if (this.modelValue instanceof Array) {
        return this.modelValue.includes(this.value)
      }
      // Note that `true-value` and `false-value` are camelCase in the JS
      return this.modelValue === this.trueValue
    },   
  },
  mounted(){
    var vueInstance = this;
    $(document).on("touchend", function(e) {
      vueInstance.$forceUpdate();
    });
    this.checkedMarker = window.config.CHECKBOX_INNER_SYMBOL;
  },
  //   watch: {
  //     modelValue: function(val, oldVal) {
  //       console.log("model value watch!");
  //       var vueInstance = this;
  //       var selectedValues = val.filter(function(x) { return x.lineId == vueInstance.value.lineId && x.articleId == vueInstance.value.articleId && x.prodId == vueInstance.value.prodId});
  //       console.log(selectedValues);
  //       if(selectedValues.length == 0)
  //       {                    
  //         vueInstance.isNotChecked = true;
  //         vueInstance.isCecked = false;
  //         var id = "#" + vueInstance.value.lineId + vueInstance.value.articleId + vueInstance.value.prodId;         
  //         $(id).prop("checked", false );
  //       }
  //       else
  //       {        
  //         vueInstance.isNotChecked = false;
  //       }
  //     }
  // },
  methods: {
    extendLabel(label){
      if(this.object.virtual == false && this.object.price > 0 && this.config.EXTRAS_SHOW_PRICE == true)
      {
        if (this.hidePrice) {
          return label;
        } else {
          // AP 20220508
          if(config.USE_LONG_SUBITEM_PRICE){
            return label + " ( "+ this.config.EXTRAS_SHOW_PRICE_PREFIX + this.object.formattedPrice + " )";
          } else {
            return label + " ("+ this.config.EXTRAS_SHOW_PRICE_PREFIX + this.object.formattedPrice.replace(" ","") + ")"; 
          }
        }


      }
      else
      {
        return label;
      }
    },
    checkIfChecked(){
       if (this.modelValue instanceof Array) {
          var isFound = false;
          for(var i=0; i<this.modelValue.length; i++)
          {
            if(this.modelValue[i].articleId == this.value.articleId &&
              this.modelValue[i].lineId == this.value.lineId &&
              this.modelValue[i].prodId == this.value.prodId)
            {
              isFound=true;
            }
          }

          return isFound;
      }
      // Note that `true-value` and `false-value` are camelCase in the JS
      return this.modelValue === this.trueValue;
    },
    updateInputSimple(event) {
      let isChecked = event.target.checked
      if (this.modelValue instanceof Array) {
        let newValue = [...this.modelValue]
        if (isChecked) {
          newValue.push(this.value)
        } else {
          if(newValue.indexOf(this.value) != -1){
            newValue.splice(newValue.indexOf(this.value), 1) 
          } else {
            let index = newValue.findIndex((val) => JSON.stringify(val) == JSON.stringify(this.value));
            if(index != -1){
              newValue.splice(index, 1)      
            }
          }
        }
        this.$emit('change', newValue)
      } else {
        this.$emit('change', isChecked ? this.trueValue : this.falseValue)
      }
    },
    updateInput() { 
      this.refresh = true;
      var isChecked = !this.checkIfChecked();       
      if (this.modelValue instanceof Array) {      
        let newValue = [...this.modelValue];

        var eleId = this.value.lineId.toString() + this.value.articleId.toString() + this.value.prodId.toString();
        if (isChecked) {
          newValue.push(this.value);
          $("#"+eleId).prop("checked", true);
          
        } else {
          newValue.splice(newValue.indexOf(this.value), 1);
          $( "#"+eleId ).prop("checked", false);
          this.checkedMarker = "";
        }
        
        this.$forceUpdate();
        this.$emit('change', newValue);
        this.refresh = false;
      } else {
        this.$forceUpdate();
        this.$emit('change', isChecked ? this.trueValue : this.falseValue);
        this.refresh = false;

        
      }   
    }
  }
}
</script>

<style lang="postcss" scoped>
/*
 Customize the label (the wrapper)
.wrapper {
  display: inline-block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 16px;
  max-width:500px;
  width:100%;
  height:30px;
  text-align:left;
}

.wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 30px;
  width: 300px;
  left:0;
}


.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 21px;
  width: 21px;
  border-radius: 2px;
  background-color: var(--background-color);
  border: 0.5px solid darkgray;
  color: transparent;
  font-size: 20px;
  line-height: 0.9;
}

.wrapper:hover input ~ .checkmark {
  color: white;
  background-color: var(--main-color);
}

.wrapper input ~ .checkmark {

  background-color: white;
  cursor: pointer;
  color:var(--main-color);
}


.checkmark:after {
  
  position: absolute;
  display: none;
}
@media screen and (max-width:800px) {
  .wrapper:hover input ~ .checkmark {
    color: transparent;
    background-color: var(--background-color);
  }  
}

.wrapper input:checked ~ .checkmark:after {
  display: block;
}

.wrapper .checkmark:after {
  left: 7px;
  top: 0px;
  width: 7px;
  height: 15px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.btn-success{
  display:none;
}




.wrapper input[type=checkbox]+label::after {
  content: "\2713";
  color: #000;
  text-align: center;
  opacity: 0;
  position: absolute;
  left: 2px;
  transform: rotate(45deg);
  font-weight: bold;
}

.wrapper input[type=checkbox]:checked+label {
  transform: rotate(-45deg);

  cursor: pointer;

} */



/* Customize the label (the wrapper) */
.wrapper {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  /* padding-right: 35px; */
  margin-bottom: 6px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 16px;
  max-width:500px;
  /* width:250px; */
}
/* Hide the browser's default checkbox */
.wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 21px;
  width: 21px;
  border-radius: 2px;
  background-color: var(--background-color);
  border: 0.5px solid darkgray;
  color: transparent;
  font-size: 20px;
  line-height: 0.9;
  /* border-width: 4px; */
}

/* On mouse-over, add a grey background color */
.wrapper:hover input ~ .checkmark {
  color: white;
  background-color: var(--main-color);
}
@media screen and (max-width:800px) {
  .wrapper:hover input ~ .checkmark {
    color: transparent;
    background-color: var(--background-color);
  }  
}
/* On mouse-over, add a grey background color */
.wrapper input ~ .checkmark {
  color: transparent;
  background-color: var(--background-color);
}
/* When the checkbox is checked, add a blue background */
.wrapper input:checked ~ .checkmark {  
  background-color: white;
  cursor: pointer;
  color:var(--main-color);
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  /* content: ""; */
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.wrapper input:checked ~ .checkmark:after {
  display: block;
}
/* Style the checkmark/indicator */
.wrapper .checkmark:after {
  left: 7px;
  top: 0px;
  width: 7px;
  height: 15px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.btn-success{
  display:none;
}
.wrapper input[type=checkbox]+label::after {
  content: "\2713";
  /* color: #000; */
  text-align: center;
  opacity: 0;
  position: absolute;
  left: 2px;
  transform: rotate(45deg);
  font-weight: bold;
}
.wrapper input[type=checkbox]:checked+label {
  transform: rotate(-45deg);
  /* Testing purpose */
  cursor: pointer;
  /* doesnt work like supposed to */
}

</style>
