// <template>
    <div class="paymentPageWrapper">
        <loading :active.sync="isLoading" 
            :can-cancel="false"        
            :is-full-page="false"
            loader='bars'></loading>
        
        <div class="col-12 subPageHeader">
            {{ $t('paymentPageTitle') }}
            <button class="btn btn-primary subPageHeader subPageHeaderButton" @click="goBack()">
               {{$t('back')}}
            </button>
        </div>

        <flow :currentPoint="4"></flow>

        <div class="col-12 checkoutPadding">  
            <div class="checkoutPadding">        
                <div class="col-12 chosenShopTile checkoutTilePadding">     
                    <h2>{{shop}}</h2>
                    <span v-html="shopAddress"></span>
                </div>

                <div class="col-12 chosenTimeTile checkoutTilePadding" v-if="orderType != 'delivery'">
                    <span v-html="time"></span>
                    <!-- <h5 v-if="TableModeSetting">{{$t('tableIdTitle')}}</h5> -->
                    <span v-if="TableModeSetting">{{$t('tableIdTitle')}} </span>
                    <span v-if="TableModeSetting" v-html="tableNumber"></span>
                </div>

                <div class="col-12 chosenArticlesTile">

                    <div v-for="item in pointCampaigns" v-bind:key="item.campaignId"  style="text-align:right; background:lightgray; display: display: flow-root; padding: 10px;  border-bottom:0.2px solid var(--main-color);">
                        <span style="font-size:25px;">{{ getCampaignName(item.campaignId) }}</span>
                        <br/>
                        <span v-if="item.gainedPoints > 0">
                            <span style="font-size:15px;"> {{ $t('rewardPointsGained') }} </span>                        
                            <span style="color:var(--main-color); font-size:25px;"> {{ item.gainedPoints }} </span>
                        </span>
                        <br/>
                        <span v-if="item.usedPoints > 0">
                            <span style="font-size:15px;"> {{ $t('rewardPointsBurned') }} </span>
                            <span style="color:var(--main-color); font-size:25px;"> {{ item.usedPoints }} </span>
                        </span>

                    </div>
                    <br/>

                    <!-- <span v-for="item in basketItems" v-bind:key="item.id"> -->
                    <table class="shoppingCartItemsTable"  style="table-layout:fixed" v-if="basketLoaded && basketItems.length > 0">
                        <thead>
                            <th class="shoppingCartHeaderBig">{{ $t('article') }}</th>
                            <th class="shoppingCartHeaderSmall"></th>
                            <th class="shoppingCartHeader">{{ $t('summ') }}</th>                   
                        </thead>
            
                        <tr v-for="item in basketItems" v-bind:key="item.id">
                            <td class="shoppingCartLine">{{(item.prodText !== undefined) ? item.prodText :item.text}}
                                <div v-if="item.hasOptions" style="padding:0;text-align:right;">
                                    <span style="font-size:13px;padding:0;" v-html="item.optionText"></span>
                                </div>
                            </td>
                            <td class="shoppingCartLine mainColor" style="text-align:center; font-size:14px;">{{ item.weightOrVolume != undefined ? item.weightOrVolume * 1000 : item.quantity }} {{item.weightOrVolume != undefined ? 'g' : 'x'}} </td>

                            <td class="shoppingCartLine mainColor" style="font-weight:600;">
                                <span v-if="item.formattedDiscountedPrice == undefined"> 
                                    {{item.weightOrVolume != undefined ? item.formattedTotalPrice : item.formattedPrice}}  
                                    <span style="font-weight:400; font-size:14px;">{{item.weightOrVolume != undefined ? " - " + item.formattedPrice + "/kg" : ""}} 
                                    </span> 
                                </span>
                                <span v-if="item.formattedTotalDiscountedPrice != undefined">                                         
                                    {{item.weightOrVolume != undefined ? item.formattedPrice : item.formattedTotalDiscountedPrice}}  
                                    <span v-if="item.weightOrVolume == undefined" style="color:gray;text-decoration: line-through;">
                                        {{ item.formattedTotalPrice }}  
                                    </span>
                                    <span style="font-weight:400; font-size:14px;">{{item.weightOrVolume != undefined ? " - " + item.formattedDiscountedPrice + "/kg" : ""}} 
                                    </span> 
                                </span>
                            </td>
                        </tr>
                    </table>
                    <!-- </span> -->

                    <table class="shoppingCartSummaryTable" v-if="basketLoaded && basketItems.length > 0">  
                        <thead>
                            <th style="width:70%"></th>
                            <th style="width:30%"></th>
                        </thead>        
                        <tr> 
                            <td class="shoppingCartSummaryLine">{{ $t('summ') }}</td>
                            <td class="shoppingCartSummaryLine">{{basketTile.formattedTotal}}</td>
                        </tr>
                        <tr v-if="basketTile.paymentsList != undefined">
                            <td class="shoppingCartLine" style="color:gray;">{{$t('subsidyTitle')}}</td>
                            <td class="shoppingCartLine" style="color:gray;">{{formatCurrency(basketTile.paymentsList[0].paymentAmount)}}</td>
                        </tr>          
                    </table>
                </div>
            </div>

            <div v-if="!tableMode">
                <div class="fontSubTitle col-12 paymentSelectTitle" v-if="!showFreeButton">
                    {{$t('PleaseSelectPayment')}}
                </div>      
                <div class="col-12 fixBigScreen" v-if="paymentList.length > 0">
                    <check-box @change="onPaymentSelect" 
                        @hide="onPaymentHide"
                        v-for="payment in paymentList" :key="payment.subCode == undefined ? payment.code : (payment.code + '~~' + payment.subCode)" 
                        :label="payment.subCode != undefined ? payment.subCode : payment.title"                         
                        :value="payment.code"   
                        :subCode="payment.subCode"
                        v-model="currentPayment">
                    </check-box>

                    <!-- <button @click="onPaymentSelect" 
                        v-for="payment in paymentList" :key="payment.subCode == undefined ? payment.code : (payment.code + '~~' + payment.subCode)" 
                        :label="payment.subCode != undefined ? payment.subCode : payment.title"                         
                        :value="payment.code"   
                        :subCode="payment.subCode"                        
                        >
                    </button> -->
                </div> 
                <div style="width:100%; display:flow-root;">
                    <button type="button" class="btn btn-primary" style="margin-top:0px; height:45px;" v-if="currentlySelectedPayment == 'cod' || currentlySelectedPayment == 'customercard' || currentlySelectedPayment == 'TCPDebitor'" @click="onSubmit(false)"> {{$t('submitPayment')}} </button>
                    <button type="button" class="btn btn-primary" style="margin-top:30px; height:45px;" v-if="showFreeButton" @click="onSubmitFree(false)"> {{$t('submitPayment')}} </button>
                </div>
            </div>

            <div v-if="tableMode">

                <div class="col-12 customerCardBalance">
                    <span class="customerCardBalanceLabel">{{ $t('customerCardBalance') }}</span>
                    <span class="customerCardBalanceAmount"> {{ prepayBalanceCash }} {{ config.ACCOUNT_BALANCE_CURRENCY }}</span>   
                    <!-- <button v-if="config.TOPUP_ENABLED == true" class="customerCardBalanceButton btn btn-primary" @click="$refs.topUpModal.openModal()">{{ $t('topUp') }}</button>    -->
                </div>
                <div class="col-12">
                        <button class="btn btn-primary col-12" :disabled="prepayBalanceCash == 0 || prepayBalanceCash < basketTile.basketTotal" @click="payCustomerCard">{{$t('customercard')}}</button>
                        <button class="btn btn-primary col-12" @click="payNow">{{$t('creditcard')}}</button>
                        <button v-if="transactionEnabled" class="btn btn-primary col-12" @click="payTransaction">{{$t('payTransaction')}}</button>                        
                        <button class="btn btn-primary col-12" @click="payLater" v-if="config.QRLANDING_OPEN_TABLES_ENABLE == true">{{$t('payLater')}}</button>                  
                </div>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                <div class="col-12" style="height:300px;">
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                </div>
            </div>
        </div>

        <credit-card-payment-saferpay v-if='showSaferpay == true' :subCode="currentPaymentSubCode" ></credit-card-payment-saferpay>
        <credit-card-payment-securepay v-if='showSecurepay == true' :subCode="currentPaymentSubCode" ></credit-card-payment-securepay>
        <credit-card-payment-novalnet v-if='showNovalnet == true' :subCode="currentPaymentSubCode" ></credit-card-payment-novalnet>
        <credit-card-payment-payone v-if='showPayone == true' :subCode="currentPaymentSubCode" :callback="closePayOne" ></credit-card-payment-payone>
        <!-- <credit-card-payment-saferpay v-if='showSaferpay == true' :subCode="currentPaymentSubCode" ></credit-card-payment-saferpay> -->
        
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
    </div>
</template>

<script>

import $ from 'jquery'
import CreditCardPaymentComponentSaferpay from '../main-components/CreditCardPaymentComponentSaferpay.vue'
import CreditCardPaymentComponentSecurepay from '../main-components/CreditCardPaymentComponentSecurepay.vue'
import CreditCardPaymentComponentNovalnet from '../main-components/CreditCardPaymentComponentNovalnet.vue'
import CreditCardPaymentComponentPayone from '../main-components/CreditCardPaymentComponentPayone.vue'
import VueLoading from 'vue-loading-overlay'
import CheckBox from '../sub-components/CheckBoxSimple.vue'
import FlowIndicator from '../main-components/FlowIndicator.vue'
// import $ from 'jquery'

export default {
    data() {
        return {
            prepayBalanceCash: 0,
            showSaferpay: false,
            showSecurepay: false,
            showNovalnet: false,
            showPayone: false,
            paymentList: {
                Type: Array
            },
            currentPayment: "",
            paymentFinished: false,
            isLoading: false,
            shop: "",
            shopAddress: "",
            comment:"",
            time: "",
            basketTile: {
                Type:Object
            },
            tableMode: false,
            // pointCampaigns: [],   
            pointCampaignsDefinitions: [],
            basketLoaded: false,
            showFreeButton: false,
            currentPaymentSubCode: "",
            firstClickDone: false,

        }
    },
    components: { 
        'credit-card-payment-saferpay': CreditCardPaymentComponentSaferpay,
        'credit-card-payment-securepay': CreditCardPaymentComponentSecurepay,
        'credit-card-payment-novalnet': CreditCardPaymentComponentNovalnet,
        'credit-card-payment-payone': CreditCardPaymentComponentPayone,
        'loading': VueLoading,
        'check-box': CheckBox,
        'flow' : FlowIndicator
    },
    computed:{
        currentlySelectedPayment (){            
            if(this.currentPayment == "Saferpay")
            {
                console.log(this.currentPayment + "~~" + this.currentPaymentSubCode);
               return this.currentPayment + "~~" + this.currentPaymentSubCode;
            }
            else if(this.currentPayment == "PayOne")
            {
                if(this.currentPaymentSubCode !== "")
                {
                    return this.currentPayment + "~~" + this.currentPaymentSubCode;
                }
                else
                {
                    return this.currentPayment;
                }
            } 
            else
            {
                return this.currentPayment;
            }            
        },        
        pointCampaigns(){
            return this.basketTile.pointCampaigns;
        },
        basketItems(){
            return this.basketTile.basketItems;
        },
        orderType () {
            return window.orderType;
        },
        TableModeSetting () {
            return window.TableMode;
        },
        config(){
            return window.config;
        },
        transactionEnabled(){
            return this.paymentList.some(item => item.code == 'Saferpay' && item.subCode == 'T' );
        },
        tableId(){
            return window.tableId;
        },
        tableNumber() {
            return window.tableModeTable;
        }
    },
    beforeMount() {
        var vueInstance = this;      
        
        

        var config = window.config;
        var url = config.API_BASE + 'TCPAfterLoginJ.action';
        var params = {
            json: "true",       
        };

         // eslint-disable-next-line no-unused-vars
        var callbackSuccess = function(result, textStatus, jqXHR) {                
            if (result.exception !== undefined)
            {                
                vueInstance.$router.go(-1);
                return;
            }          
          
            if(result.wondCustomer != undefined)
            {
                if (result.wondCustomer.cardType.toUpperCase() === "CREDIT") {
                    vueInstance.prepayBalanceCash =(result.wondCustomer.creditLimit - result.wondCustomer.creditBalance).toFixed(config.CUSTOMER_BALANCE_DECIMAL_PLACES);
                }else{
                    vueInstance.prepayBalanceCash =(result.wondCustomer.prepayBalanceCash + result.wondCustomer.prepayBalanceVoucher + result.wondCustomer.prepayBalanceBonus).toFixed(config.CUSTOMER_BALANCE_DECIMAL_PLACES);
                }
            }
      
        }

         // eslint-disable-next-line no-unused-vars
        var callbackError = function(result, textStatus, jqXHR) {
            vueInstance.isLoading = false;
        }     
            
        vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);              

        vueInstance.confirmOrder(false);   
        vueInstance.tableMode = vueInstance.TableModeSetting;
        vueInstance.comment = window.comment;
        vueInstance.pointCampaignsDefinitions = window.pointCampaignsDefinitions;
    },
    mounted() {        
    },
    methods:{
        closePayOne(status){
            if(status >= 0)
            {                
                this.$router.push({ path: '/CheckoutFinished' });
            }
        },
        formatCurrency(value){
            // switch (this.config.) {
            //     case value:
                    
            //         break;
            
            //     default:
            //         break;
            // }
            // var formatter = new Intl.NumberFormat('de-DE', { maximumSignificantDigits: 4});
            // return formatter.format(value);
            var formatter = new Intl.NumberFormat('de-DE', {style: 'currency', currency: window.config.SUBSIDY_CURRENCY});
            return formatter.format(value);
        },
        startDeliveryFeeProcess(){
            var config = window.config;
            var deliveryFeeArticleId = 0;
            //TODO: GET ARTICLE ID THROUGH TCPPRODUCTSEARCH AND CALL ADDMENUITEM TO ADD DELIVERY ARTICLE TO TRANSACTION IF DELIVERY FEES ARE ENABLED
            if(config.DELIVERY_FEE_ENABLED == true)        
            {              
                if(config.DELIVERY_FEE_TRANSACTION_TRESHOLD != undefined && config.DELIVERY_FEE_TRANSACTION_TRESHOLD != null && config.DELIVERY_FEE_TRANSACTION_TRESHOLD != 0)
                {        
                    if(config.DELIVERY_FEE_TRANSACTION_TRESHOLD > this.basketTile.basketTotal)
                    {              
                        deliveryFeeArticleId = config.DELIVERY_FEE_TCPOS_ARTICLE_ID;
                        this.getProductId(deliveryFeeArticleId);
                    }
                }
                else
                {
                    deliveryFeeArticleId = config.DELIVERY_FEE_TCPOS_ARTICLE_ID;
                    this.getProductId(deliveryFeeArticleId);
                }
            }
        },
        getProductId(articleId, remove = false) {
            var config = window.config;
            var vueInstance = this;
            if(config.DELIVERY_FEE_TCPOS_ARTICLE_ID == null){
                vueInstance.confirmOrder(true);  
                return;
            }
    
            var url = config.API_BASE + 'TCPProductSearchJ.action'
            var params = {
                dataDescriptor: {    
                    custom1Int: articleId,                
                },
                json: 'true',
                orderType:  window.orderType,
            }

            params.productSearch = {
                custom1Int: articleId,                
                priceTypeRequired:"DE",                                    
            }

            // eslint-disable-next-line no-unused-vars
            var callbackSuccess= function(result, textStatus, jqXHR) {   
                if(result.products.productArray != undefined)
                {
                    if(result.products.productArray.length > 0)
                    {             
                        var productId = result.products.productArray[0].id;           
                        //CHECK IF DELIVERY FEE ARTICLE ALREADY PRESENT
                        if(vueInstance.basketTile.basketItems.filter(function(x) { return x.prodId == productId }).length > 0 )
                        {            
                            console.log("Delivery Fee already added, abort.");    
                            if(remove)
                            {
                                vueInstance.removeDeliveryCost( vueInstance.basketTile.basketItems.filter(function(x) { return x.prodId == productId })[0].id, productId);
                            }           
                            else
                            {
                                vueInstance.confirmOrder();  
                            }             
                                                 
                            return;
                        }
                        else
                        {
                            //IF DELIVERY FEE ARTICLE NOT IN BASKET, ADD IT
                            vueInstance.addDeliveryCost(productId);
                        }                         
                    }
                }
                else
                {
                    return 0;
                }                
            }

            // eslint-disable-next-line no-unused-vars
            var callbackError= function(result, textStatus, jqXHR){       
                return 0;      
            }

            vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);  
        },
        addDeliveryCost(deliveryFeeProductId) {
             var vueInstance = this;
             var config = window.config;
            
            //TODO: CHANGE FROM KTM LOGIC TO STANDARD
            //prevent breaking of functionality
            // if(window.customer === undefined)
            // {
            //     this.afterLogin();                      
            //     return;
            // }       
            if(window.orderType == "dinein" || window.orderType == "takeaway")
            {
                //if no delivery fee should be added, submit the order confirmation
                vueInstance.confirmOrder(true);
                return;
            }

            console.log("delivery fee product id:" + deliveryFeeProductId);
            if(config.DELIVERY_FEE_ENABLED == true)        
            {              
                if(config.DELIVERY_FEE_TRANSACTION_TRESHOLD != undefined && config.DELIVERY_FEE_TRANSACTION_TRESHOLD != null && config.DELIVERY_FEE_TRANSACTION_TRESHOLD != 0)
                {        
                    if(config.DELIVERY_FEE_TRANSACTION_TRESHOLD <= this.basketTile.basketTotal)
                    {         
                        //if no delivery fee should be added, submit the order confirmation
                        vueInstance.confirmOrder(true);     
                        return;                      
                    }
                }               
            }
            else
            {
                //if no delivery fee should be added, submit the order confirmation
                vueInstance.confirmOrder(true);
                return;
            }
            
            //CHECK TRESHOLD
            // if(window.shoppingCart.filter(function(x) { return x.prodId == prodId }).length > 0 )
            // {
            //     vueInstance.submitOrder();
            //     return;
            // }
            // else
            // {
            //     vueInstance.removeAnyPreviousDeliveryCosts()
            // }           
             vueInstance.isLoading = true;
            var url = config.API_BASE + 'TCPAddMenuItemToCartJ.action';
            var params = {
                productId: deliveryFeeProductId,
                json: "true",
                orderType: "delivery",
                quantity: 1,
            };
            if(window.customerLoggedIn)
            {
                params.calculateOrder = "true";
            }
            // eslint-disable-next-line no-unused-vars
            var callbackSuccess= function(result, textStatus, jqXHR) {       
                vueInstance.isLoading = false;
                if( (result.exception !== undefined) || (result.item !== undefined) )
                {
                    // if(result.exception !== undefined)
                    //     vueInstance.$alert(vueInstance.$t('addBasketItemFail') + result.exception.message);
                    // else
                    //     vueInstance.$alert(vueInstance.$t('addBasketItemFail'));

                    // if(result.basketTile != undefined)
                    // {
                    //     var articleFound = false;
                    //     for(var i = 0; i < result.basketTile.basketItems.length; i++)
                    //     {
                    //         // if(config.DELIVERY_FEE_ARTICLES.deliveryReservedArticles.indexOf(result.basketTile.basketItems.id) > 0)
                    //         // {
                    //         //     articleFound = true;
                    //         // }
                    //     }

                    //     if(articleFound)
                    //     {
                    //         return;
                    //     }
                    //     // else
                    //     // { vueInstance.$router.push({path: "Address"}); }
                    // }

                } 
                // window.shoppingCart = result.basketTile.basketItems;
                vueInstance.basketItems = result.basketTile.basketItems;
                vueInstance.basketTile = result.basketTile;

                vueInstance.confirmOrder(true);   
                // vueInstance.submitOrder();
            }

            // eslint-disable-next-line no-unused-vars
            var callbackError = function(result, textStatus, jqXHR) {         
                 vueInstance.isLoading = false;       
            }
        
            vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);
        },
        removeDeliveryCost(basketId, productId) {
            var config = window.config;
            var vueInstance = this;
            var url = config.API_BASE + 'TCPRemoveMenuItemFromCartJ.action';        
        
            var params = {
                id: basketId,
                orderType: window.orderType
            };

            // eslint-disable-next-line no-unused-vars
            var callbackSuccess= function(result, textStatus, jqXHR) {       
                if(result.exception !== undefined)
                {
                    vueInstance.$alert(this.$t('removeBasketItemFail') + result.exception.message);
                    return;
                } 
                window.shoppingCart = result.basketTile.basketItems;
                if(result.basketTile.paymentsList != undefined){
                    window.subsidy = vueInstance.formatCurrency(result.basketTile.paymentsList[0].paymentAmount);
                }else{
                    window.subsidy = "";
                }
                vueInstance.basketItems = result.basketTile.basketItems;
                vueInstance.addDeliveryCost(productId);           
            }

            // eslint-disable-next-line no-unused-vars
            var callbackError = function(result, textStatus, jqXHR) {         
            }
        
            if(params.id > 0)
                vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);
            else
                return;
        },
        getCampaignName(campaignId){
            for(var i  = 0; i < this.pointCampaignsDefinitions.length; i++)
            {
                if(this.pointCampaignsDefinitions[i].id == campaignId)
                {
                    return this.pointCampaignsDefinitions[i].description;
                }
            }

            return "";
        },
        preparePayments(){
            var config = window.config;
            var vueInstance = this;
            var url = config.API_BASE + 'TCPPaymentJ.action';   
            
            var params = {
                json: "true"
            };
            
            var callbackSuccess= function(result, textStatus, jqXHR){       
                if (result.page == "tcp.catalog.login.page")
                {
                    vueInstance.$router.push({path : '/Login'});
                    return;
                }


                vueInstance.shopAddress = result.formattedShopAddr;
                vueInstance.shop = result.shopName;
                vueInstance.time = result.formattedPickupTime;
                window.orderDateTime = result.formattedPickupTime;

                if(result.paymentDetails == undefined)
                {
                    vueInstance.showFreeButton = true;
                    return;
                }
                else
                {
                    vueInstance.showFreeButton = false;
                }

                vueInstance.paymentList = result.paymentDetails;

                if(vueInstance.paymentList.length == 1)
                {
                    var element = new Object;
                    element.value = vueInstance.paymentList[0].code;
                    element.subCode = vueInstance.paymentList[0].subCode;
                    console.log(element);
                    vueInstance.onPaymentSelect(element);
                }
          
                console.log(result);            
                console.log(textStatus);
                console.log(jqXHR);
            }

            var callbackError = function(result, textStatus, jqXHR){
                console.log(result);
                console.log(textStatus);
                console.log(jqXHR);
            }
        
            vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);



            window.onmessage = function(e) {       
                var config = window.config;     
                if(e.data == "S" || e.data == "A" || e.data == "F" ||
                e.data.startsWith("payoneA")  || e.data.startsWith("payoneB") || e.data.startsWith("payoneD"))
                {
                    vueInstance.isLoading = true;
                }


          
                
                if(e.data == "S")
                {        
                    
                    if(vueInstance.currentPaymentSubCode == "P")
                    {
                        vueInstance.isLoading = false;
                        vueInstance.$router.push({ path: '/CheckoutFinished' });
                        return; 
                    }              

                    var url = config.API_BASE + 'ProcessSaferpayJ.action';
                    var params = {
                        json: "true",
                        vresult: e.data
                    };
                    // eslint-disable-next-line no-unused-vars
                    var callbackSuccess= function(result, textStatus, jqXHR) {
                        
                        vueInstance.isLoading = false;
                        vueInstance.$router.push({ path: '/CheckoutFinished' });
                        
                    }
                    // eslint-disable-next-line no-unused-vars
                    var callbackError = function(){
                        vueInstance.isLoading = false;   
                    }
                
                    vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);

                } else if (e.data == "A")
                {
                    // vueInstance.$alert("PaymentAborted!");
                    // vueInstance.$router.push({ path: '/Payment' });
                    // vueInstance.$router.go(0);
                    // vueInstance.currentPayment = "cod";
                    vueInstance.showSaferpay = false;
                    vueInstance.showSecurepay = false;
                    vueInstance.showNovalnet = false;
                    vueInstance.showPayone = false;
                    vueInstance.isLoading = false; 

                } else if (e.data == "F")
                {                    
                    vueInstance.$alert(vueInstance.$t('paymentFail'));
                    // vueInstance.currentPayment = "cod";
                    vueInstance.showSaferpay = false;
                    vueInstance.showSecurepay = false;
                    vueInstance.showNovalnet = false;
                    vueInstance.showPayone = false;
                    vueInstance.isLoading = false; 
               
                } else if (e.data.startsWith("payoneA"))
                {
                    
                    //var vresult = e.data.slice(e.data.length - 1);
                    url = config.API_BASE + 'ProcessPayOneJ.action';   
                    params = {
                        json: "true",
                        vresult: e.data.replace('payone', '')
                    };
                    // eslint-disable-next-line no-unused-vars
                    callbackSuccess= function(result, textStatus, jqXHR) {
                        vueInstance.isLoading = false;
                        vueInstance.$router.push({ path: '/CheckoutFinished' });
                        
                    }
                    // eslint-disable-next-line no-unused-vars
                    callbackError = function(){
                        vueInstance.isLoading = false;   
                    }
                
                    vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);      
                } else if(e.data.startsWith("payoneB")){
                    vueInstance.$alert(vueInstance.$t('paymentAborted'));
                    vueInstance.showSaferpay = false;
                    vueInstance.showSecurepay = false;
                    vueInstance.showNovalnet = false;
                    vueInstance.showPayone = false;
                    vueInstance.isLoading = false; 
                    vueInstance.$refs.externalPaymentModal.closeModal();
                } else if(e.data.startsWith("payoneD")){
                    vueInstance.$alert(vueInstance.$t('paymentFail'));
                    vueInstance.showSaferpay = false;
                    vueInstance.showSecurepay = false;
                    vueInstance.showNovalnet = false;
                    vueInstance.showPayone = false;
                    vueInstance.isLoading = false; 
                    vueInstance.$refs.externalPaymentModal.closeModal();
                } else if (e.data.startsWith("novalnet")){
                                        vueInstance.isLoading = false;
                        vueInstance.$router.push({ path: '/CheckoutFinished' });
                }
            }
        },
        confirmOrder(submit = true){
            var vueInstance = this;
            // if(this.basketItems.length == 0)
            // {
            //     vueInstance.$alert(vueInstance.$t('NoProducts'));
            //     vueInstance.$router.push({ path: '/Menu' });
            //     return;
            // }

            this.isLoading = true;
            var config = window.config;

            var url = "";
            if(submit)
                url = config.API_BASE + 'TCPConfirmOrderSubmitJ.action';
            else
                url = config.API_BASE + 'TCPConfirmOrderJ.action';

            var params = {
                json: "true",
                orderType: window.orderType,
                comment: window.comment,
                promotionBarcode: window.promotionBarcode != undefined ? window.promotionBarcode : ""
            };
                 
            if(!submit)                           
                params.calculateOrder = "true";
            else
            {               
                if(window.xtoken != undefined)
                {
                    params.xsrfToken = btoa(window.xtoken);
                }
            }

            if(vueInstance.TableModeSetting)
            {
                params.tableId =  window.tableId;
            }

            // eslint-disable-next-line no-unused-vars
            var callbackSuccess= function(result, textStatus, jqXHR){    
                if(result.xsrfToken != undefined)
                {
                    window.xtoken = atob(result.xsrfToken);
                }  

                if(result.exception !== undefined)
                {
                    vueInstance.isLoading = false;                    
                    return;
                }

                if(result.requiresLogin == true)
                {    
                    vueInstance.isLoading = false;
                    return;
                }

                // if(window.shoppingCart == undefined)
                // {
                //     return;
                // }

                // if(window.shoppingCart.basketItems == undefined)
                // {
                //     return;
                // }

                window.shoppingCart = result.basketTile.basketItems;
                if(result.basketTile.paymentsList != undefined){
                    window.subsidy = vueInstance.formatCurrency(result.basketTile.paymentsList[0].paymentAmount);
                }else{
                    window.subsidy = "";
                }
                vueInstance.basketTile = result.basketTile;     
                vueInstance.isLoading = false;

                if(result.basketTile.pointCampaigns != undefined)
                {
                    vueInstance.pointsGained = result.basketTile.pointCampaigns.gainedPoints;
                    vueInstance.pointsBurned = result.basketTile.pointCampaigns.usedPoints;
                }              
                
                vueInstance.basketLoaded = true;

                if(submit)
                    vueInstance.preparePayments();               

                //DELIVERY FEE
                if(submit == false)
                {
                    vueInstance.removePreviousDeliveryFees();
                    //vueInstance.startDeliveryFeeProcess();
                }
                //vueInstance.startDeliveryFeeProcess();
            }

            // eslint-disable-next-line no-unused-vars
            var callbackError = function(result, textStatus, jqXHR){
                vueInstance.isLoading = false;
            }
        
            vueInstance.makeRequest("POST", url, JSON.stringify(params), callbackSuccess, callbackError);
        },
        removePreviousDeliveryFees(){
            var vueInstance = this;
            var config = window.config;
            //TODO: REMOVE DELIVERY FEES USING getProductId
            vueInstance.getProductId(config.DELIVERY_FEE_TCPOS_ARTICLE_ID, true)
        },
        goBack(){
            this.$router.go(-1);
        },  
        onPaymentHide(){
            this.showSaferpay = false;
            this.showSecurepay = false;
            this.showNovalnet = false;
            this.showPayone = false;
            this.currentPayment = "";
        },
        onPaymentSelect(element){  
            $(":checkbox[ident!=" + element.value.replace('~~', "") + "]").prop("checked", false);
            this.showSaferpay = false;
            this.showSecurepay = false;
            this.showNovalnet = false;
            this.showPayone = false;


            //if values are identical then this is the deselecting click on the check-box
            // if(this.currentPayment.value == element.value)
            // {
            //     if(!this.firstClickDone)
            //         this.firstClickDone = true;

            //     this.currentPayment = "";
                
            //     this.$forceUpdate();
            //     console.log("curr payment");
            //     console.log(this.currentPayment);
            //     return;
            // }

            this.currentPayment = element.value;
            console.log("current payment method: " + this.currentPayment);
            console.log("current subcode:" + element.subCode);
            
            if(element.subCode != undefined)
            {
                if(element.subCode == "T")
                {
                    this.currentPaymentSubCode = element.subCode;
                }
                else if(element.subCode == "P")
                {
                    this.currentPaymentSubCode = element.subCode;
                }
                // else if(element.subCode == "PDT")
                // {
                //     this.currentPaymentSubCode = element.subCode;
                // }
                else
                {
                    element.subCode = "";
                    this.currentPaymentSubCode = "";
                }
            }
            else
            {
                element.subCode = "";
                this.currentPaymentSubCode = "";
            }

            if(this.currentlySelectedPayment != "customercard")
            {
                this.onSubmit(true);
            }
        },
        onSubmitFree(){
            this.currentPayment = "free";
            this.onSubmit(false);
        },
        onSubmit(checkCod) {   
            // AP 20220504 The vue-loading-overlay does not always correctly block multiple submits
            // Therefore a second failsafe check is needed to stop multiple submits.
            if(this.isLoading){
                return;
            }

            var vueInstance = this;
            vueInstance.showSaferpay = false;
            vueInstance.showSecurepay = false;
            vueInstance.showNovalnet = false;
            vueInstance.showPayone = false;
            
            if(this.currentlySelectedPayment == "customercard" && this.basketTile.basketTotal > this.prepayBalanceCash)
            {
                this.$alert(this.$t("balanceNotEnough"));
                return;
            }

            if( (this.currentlySelectedPayment == "cod" || this.currentlySelectedPayment== "customercard" || this.currentlySelectedPayment== "TCPDebitor") && checkCod)
            {
               return;
            }    

            this.isLoading = true;
            var config = window.config;
           
            var url = config.API_BASE + 'TCPPaymentSubmitJ.action';            
            var params = {                         
                deliveryAddress: "0",
                paymentMethod: this.currentlySelectedPayment,
                json: "true"
            };

            if(window.xtoken != undefined)
            {
                params.xsrfToken = btoa(window.xtoken);
            }

            // eslint-disable-next-line no-unused-vars
            var callbackSuccess= function(result, textStatus, jqXHR) 
            {
                if(result.paymentMethod == "Saferpay~~P" || result.paymentMethod == "Saferpay~~T")
                {                    
                    vueInstance.isLoading = false;
                    console.log(vueInstance.currentPayment);     
                                   
                    vueInstance.showSaferpay = true;
                    vueInstance.$forceUpdate();
                    return;
                }
                
                if(result.paymentMethod == "Securepay"){
                    vueInstance.isLoading = false;
                    console.log(vueInstance.currentPayment);     

                    vueInstance.showSecurepay = true;
                    vueInstance.$forceUpdate();
                    return;
                }

                if(result.paymentMethod == "Novalnet"){
                    vueInstance.isLoading = false;
                    console.log(vueInstance.currentPayment);

                    vueInstance.showNovalnet = true;
                    vueInstance.$forceUpdate();
                    return;
                }

                if(result.paymentMethod == "PayOne")
                {
                    vueInstance.showPayone = true;
                    console.log(vueInstance.currentPayment); 
                    vueInstance.isLoading = false;
                    vueInstance.$forceUpdate();

                      setTimeout(() => {
                        var objDiv = document.getElementsByClassName("container")[0];
                        var iFrame = document.getElementById("payoneCheckoutContainer");
                        objDiv.scrollTop = objDiv.scrollHeight;
                        iFrame.scrollIntoView({behavior: "smooth"});
                    }, 300);

                    return;
                }

                if(result.paymentMethod == "StripeModule")
                {                                                          
                    return;
                }

                if(result.exception !== undefined)
                {
                    vueInstance.isLoading = false;
                    vueInstance.$alert(this.$t("exceptionMessage"));
                    return;
                }

                if(result.page == "tcp.catalog.checkoutfinished.page")
                {
                    if(vueInstance.currentlySelectedPayment == "cod" || vueInstance.currentlySelectedPayment == "customercard" || vueInstance.currentlySelectedPayment == "free")
                    {
                        window.shoppingCart = null;
                        url = config.API_BASE + 'TCPCheckoutFinishedJ.action';            

                        params = {                    
                            json: "true"
                        };
                        
                        vueInstance.makeRequest("GET", url, JSON.stringify(params), null, null);
                    }

                    vueInstance.isLoading = false;
                    window.shoppingCart = undefined;
                    vueInstance.$router.push({path: '/CheckoutFinished'});
                    return;
                }

                vueInstance.isLoading = false;
                window.shoppingCart = null;
                vueInstance.$router.push({ path: '/Payment' });                     
            }

            // eslint-disable-next-line no-unused-vars
            var callbackError = function(result, textStatus, jqXHR){
                vueInstance.isLoading = false;
                vueInstance.$alert(vueInstance.$t('failedGeneric'));
            }
        
            vueInstance.makeRequest("POST", url, JSON.stringify(params), callbackSuccess, callbackError);
        },
        payLater(){
            this.isLoading = true;
            var config = window.config;
            var vueInstance = this;
            var url = config.API_BASE + 'TCPPaymentSubmitJ.action';            
            var params = {                       
                deliveryAddress: "0",
                paymentMethod: "cod",     
                json: "true"
            };

            if(window.xtoken != undefined)
            {
                params.xsrfToken = btoa(window.xtoken);
            }

            // eslint-disable-next-line no-unused-vars
            var callbackSuccess= function(result, textStatus, jqXHR) 
            {    
                if(result.exception !== undefined)
                {
                    vueInstance.isLoading = false;
                    vueInstance.$alert(this.$t("exceptionMessage"));
                    return;
                }

                if(result.page == "tcp.catalog.checkoutfinished.page")
                {
                    if(vueInstance.currentlySelectedPayment == "cod" || vueInstance.currentlySelectedPayment == "customercard")
                    {
                            url = config.API_BASE + 'TCPCheckoutFinishedJ.action';            
                            params = {                    
                                json: "true"
                            };
                            vueInstance.makeRequest("GET", url, JSON.stringify(params), null, null);
                    }

                    vueInstance.isLoading = false;
                    window.shoppingCart = undefined;
                    vueInstance.$router.push({path: '/CheckoutFinished'});
                    return;
                }

                vueInstance.isLoading = false;
                window.shoppingCart = null;
                vueInstance.$router.push({ path: '/Payment' });                     
            }

            // eslint-disable-next-line no-unused-vars
            var callbackError = function(result, textStatus, jqXHR){
                vueInstance.isLoading = false;
                vueInstance.$alert(vueInstance.$t('failedGeneric'));
            }
        
            vueInstance.makeRequest("POST", url, JSON.stringify(params), callbackSuccess, callbackError);
        },
        payCustomerCard(){
            if(this.basketTile.basketTotal > this.prepayBalanceCash)
            {
                this.$alert(this.$t("balanceNotEnough"));
                return;
            }
               // return;
            this.isLoading = true;
            var config = window.config;
            var vueInstance = this;
            var url = config.API_BASE + 'TCPPaymentSubmitJ.action';            
            var params = {               
                deliveryAddress: "0",
                paymentMethod: "customercard",     
                json: "true"
            };

            if(window.xtoken != undefined)
            {
                params.xsrfToken = btoa(window.xtoken);
            }

            // vueInstance.currentPayment = "Saferpay";
            // vueInstance.showSaferpay = true;
            // eslint-disable-next-line no-unused-vars
            var callbackSuccess= function(result, textStatus, jqXHR) 
            {             
                if(result.exception !== undefined)
                {
                    vueInstance.isLoading = false;
                    vueInstance.$alert(this.$t("exceptionMessage"));
                    return;
                }

                if(result.page == "tcp.catalog.checkoutfinished.page")
                {
            
                    window.shoppingCart = null;
                    url = config.API_BASE + 'TCPCheckoutFinishedJ.action';            

                    params = {                    
                        json: "true"
                    };
                        
                    vueInstance.makeRequest("GET", url, JSON.stringify(params), null, null);
                    

                    vueInstance.isLoading = false;
                    window.shoppingCart = undefined;
                    vueInstance.$router.push({path: '/CheckoutFinished'});
                    return;
                }

                vueInstance.isLoading = false;
                window.shoppingCart = null;
                vueInstance.$router.push({ path: '/Payment' });                    
            }

            // eslint-disable-next-line no-unused-vars
            var callbackError = function(result, textStatus, jqXHR){
                vueInstance.isLoading = false;
                vueInstance.$alert(vueInstance.$t('failedGeneric'));
            }
        
            vueInstance.makeRequest("POST", url, JSON.stringify(params), callbackSuccess, callbackError);
        },
        payNow() {
            this.currentPaymentSubCode = 'P';
            this.currentPayment = 'Saferpay';
            this.onSubmit(true);
            // return;
            // this.isLoading = true;
            // var config = window.config;
            // var vueInstance = this;
            // var url = config.API_BASE + 'TCPPaymentSubmitJ.action';            
            // var params = {                      
            //     deliveryAddress: "0",
            //     paymentMethod: "Saferpay~~P",     
            //     json: "true"
            // };

            // if(window.xtoken != undefined)
            // {
            //     params.xsrfToken = btoa(window.xtoken);
            // }

            // vueInstance.currentPayment = "Saferpay";
            // // vueInstance.showSaferpay = true;
            // // eslint-disable-next-line no-unused-vars
            // var callbackSuccess= function(result, textStatus, jqXHR) 
            // {
            //     if(result.paymentMethod == "Saferpay~~P")
            //     {       
            //         vueInstance.isLoading = false;
            //         console.log(vueInstance.currentPayment);
            //         vueInstance.showSaferpay = true;

            //         setTimeout(() => {                 
            //             var objDiv = document.getElementsByClassName("content")[0];
            //             var iFrame = document.getElementById("ExternalPaymentFormFrame");
            //             objDiv.scrollTop = objDiv.scrollHeight;
            //             iFrame.scrollIntoView({behavior: "smooth"});                                     
            //         }, 400);
                    
            //         return;
            //     }

            //     if(result.paymentMethod == "StripeModule")
            //     {
            //         return;
            //     }

            //     if(result.exception !== undefined)
            //     {
            //         vueInstance.isLoading = false;
            //         vueInstance.$alert(this.$t("exceptionMessage"));
            //         return;
            //     }

            //     if(result.page == "tcp.catalog.checkoutfinished.page")
            //     {
            //         if(vueInstance.currentlySelectedPayment == "cod" || vueInstance.currentlySelectedPayment == "customercard")
            //         {
            //                 url = config.API_BASE + 'TCPCheckoutFinishedJ.action';            
            //                 params = {                    
            //                     json: "true"
            //                 };
            //                 vueInstance.makeRequest("GET", url, JSON.stringify(params), null, null);
            //         }

            //         vueInstance.isLoading = false;
            //         window.shoppingCart = undefined;
            //         vueInstance.$router.push({path: '/CheckoutFinished'});
            //         return;
            //     }

            //     vueInstance.isLoading = false;
            //     window.shoppingCart = null;
            //     vueInstance.$router.push({ path: '/Payment' });                     
            // }

            // // eslint-disable-next-line no-unused-vars
            // var callbackError = function(result, textStatus, jqXHR){
            //     vueInstance.isLoading = false;
            //     vueInstance.$alert(vueInstance.$t('failedGeneric'));
            // }
        
            // vueInstance.makeRequest("POST", url, JSON.stringify(params), callbackSuccess, callbackError);
        },
        payTransaction() {
            // return;
            this.currentPaymentSubCode = 'T';
            this.currentPayment = 'Saferpay';
            this.onSubmit(true);
            // this.isLoading = true;
            // var config = window.config;
            // var vueInstance = this;
            // var url = config.API_BASE + 'TCPPaymentSubmitJ.action';            
            // var params = {                      
            //     deliveryAddress: "0",
            //     paymentMethod: "Saferpay~~T",     
            //     json: "true"
            // };

            // if(window.xtoken != undefined)
            // {
            //     params.xsrfToken = btoa(window.xtoken);
            // }

            // vueInstance.currentPayment = "Saferpay";
            // // vueInstance.showSaferpay = true;
            // // eslint-disable-next-line no-unused-vars
            // var callbackSuccess= function(result, textStatus, jqXHR) 
            // {
            //     if(result.paymentMethod == "Saferpay~~T")
            //     {       
            //         vueInstance.isLoading = false;
            //         console.log(vueInstance.currentPayment);
            //         vueInstance.showSaferpay = true;

            //         setTimeout(() => {                 
            //             var objDiv = document.getElementsByClassName("content")[0];
            //             var iFrame = document.getElementById("ExternalPaymentFormFrame");
            //             objDiv.scrollTop = objDiv.scrollHeight;
            //             iFrame.scrollIntoView({behavior: "smooth"});                                     
            //         }, 400);
                    
            //         return;
            //     }

            //     if(result.paymentMethod == "StripeModule")
            //     {
            //         return;
            //     }

            //     if(result.exception !== undefined)
            //     {
            //         vueInstance.isLoading = false;
            //         vueInstance.$alert(this.$t("exceptionMessage"));
            //         return;
            //     }

            //     if(result.page == "tcp.catalog.checkoutfinished.page")
            //     {
            //         if(vueInstance.currentlySelectedPayment == "cod" || vueInstance.currentlySelectedPayment == "customercard")
            //         {
            //                 url = config.API_BASE + 'TCPCheckoutFinishedJ.action';            
            //                 params = {                    
            //                     json: "true"
            //                 };
            //                 vueInstance.makeRequest("GET", url, JSON.stringify(params), null, null);
            //         }

            //         vueInstance.isLoading = false;
            //         window.shoppingCart = undefined;
            //         vueInstance.$router.push({path: '/CheckoutFinished'});
            //         return;
            //     }

            //     vueInstance.isLoading = false;
            //     window.shoppingCart = null;
            //     vueInstance.$router.push({ path: '/Payment' });                     
            // }

            // // eslint-disable-next-line no-unused-vars
            // var callbackError = function(result, textStatus, jqXHR){
            //     vueInstance.isLoading = false;
            //     vueInstance.$alert(vueInstance.$t('failedGeneric'));
            // }
        
            // vueInstance.makeRequest("POST", url, JSON.stringify(params), callbackSuccess, callbackError);
        },

        payNowVaulted() {        
            this.isLoading = true;
            var config = window.config;
            var vueInstance = this;
            var url = config.API_BASE + 'TCPPaymentSubmitJ.action';            
            var params = {                
                deliveryAddress: "0",
                paymentMethod: "Saferpay~~P",     
                json: "true"
            };


            if(window.xtoken != undefined)
            {
                params.xsrfToken = btoa(window.xtoken);
            }

            vueInstance.currentPayment = "Saferpay";
            // vueInstance.showSaferpay = true;
            // eslint-disable-next-line no-unused-vars
            var callbackSuccess= function(result, textStatus, jqXHR) 
            {
                if(result.paymentMethod == "Saferpay~~P")
                {                    
                    vueInstance.isLoading = false;
                    console.log(vueInstance.currentPayment);
                    vueInstance.showSaferpay = true;

                   
                    setTimeout(() => {
                        var objDiv = document.getElementsByClassName("content")[0];
                        var iFrame = document.getElementById("ExternalPaymentFormFrame");
        

                        // $(".content").css({"overflow-y": "hidden"});

                        objDiv.scrollTop = objDiv.scrollHeight;
                        iFrame.scrollIntoView({behavior: "smooth"});

                        // $(".content").css({"overflow-y": "auto"});
              
                    }, 300);
               
                    // vueInstance.currentPayment = vueInstance.currentlySelectedPayment;
                    return;
                }

                if(result.paymentMethod == "StripeModule")
                {                                      
                    // vueInstance.currentPayment = vueInstance.currentlySelectedPayment;
                    return;
                }

                if(result.exception !== undefined)
                {
                    vueInstance.isLoading = false;
                    vueInstance.$alert(this.$t("exceptionMessage"));
                    return;
                }

                if(result.page == "tcp.catalog.checkoutfinished.page")
                {
                    if(vueInstance.currentlySelectedPayment == "cod" || vueInstance.currentlySelectedPayment == "customercard")
                    {
                            url = config.API_BASE + 'TCPCheckoutFinishedJ.action';            
                            params = {                    
                                json: "true"
                            };
                            vueInstance.makeRequest("GET", url, JSON.stringify(params), null, null);
                    }

                    vueInstance.isLoading = false;
                    window.shoppingCart = undefined;
                    vueInstance.$router.push({path: '/CheckoutFinished'});
                    return;
                }

                vueInstance.isLoading = false;
                window.shoppingCart = null;
                vueInstance.$router.push({ path: '/Payment' });                     
            }

            // eslint-disable-next-line no-unused-vars
            var callbackError = function(result, textStatus, jqXHR){
                vueInstance.isLoading = false;
                vueInstance.$alert(vueInstance.$t('failedGeneric'));
            }
        
            vueInstance.makeRequest("POST", url, JSON.stringify(params), callbackSuccess, callbackError);
        } 

        
    }
}
</script>

<style scoped>
.fixBigScreen{
    max-width:1000px;
}


.paymentSelectTitle{
    margin-bottom:50px;
}

.paymentSelect {
    margin-top: 15px;
    margin-bottom: 15px;
}

.radioButton {
    min-width:50px;
    min-height:30px;
}

.chosenShopTile{
    border: 1px solid var(--body-color);
    border-radius:5px;
    margin:5px;
}

.chosenTimeTile {
    background-color: var(--body-color);
    border-radius:5px;
    margin: 5px;
}

.chosenArticlesTile {
    background-color: var(--body-color);
    border-radius:5px;
    margin: 5px;
    text-align: left;
}

.shoppingCartHeader{
    width:30%;
}

.shoppingCartLine{
    height:40px;    
}

.shoppingCartHeaderBig{
    width:60%;
}

.shoppingCartHeaderSmall{
    width:10%;
}

.shoppingCartItemsTable{
    width:100%;
}

.shoppingCartSummaryTable{
    width:100%;
    border-top: 1px solid var(--main-color);
}

.shoppingCartSummaryLine{
    font-weight:600;    
}

@media screen and (max-width:768px) {
    .chosenShopTile{
        margin:0px;
        margin-bottom:5px;
    }
    .chosenTimeTile {
        margin:0px;
        margin-bottom:5px;
    }
    .chosenArticlesTile {
        margin:0px;
        margin-bottom:5px;        
    }
    .paymentSelectTitle{
        margin-bottom:10px;
    }
    .wrapper {
        min-width:55%;
    }
}

</style>