<template>
    <div class="qrLandingPageWrapper">

        <!-- <video autoplay muted loop id="myVideo">
            <source src="@/assets/videos/landing-page.mp4" type="video/mp4">
        </video>



        <div class="qrLandingPageBody">
            <div class="col-12">
                <img style="max-width:600px;width:100%" src="@/assets/images/logo.png" />
            </div>   

            <div class="col-12 fontTitle fontLandingTitle" v-if="landSuccess">
                {{$t('qrLandingPageTitle')}} {{shop}}
            </div>            

            <div class="col-12 fontContent fontLandingContent" v-if="landSuccess">
                {{$t('qrLandingPageBody')}} {{table}}
            </div>            

            <div class="col-12 btnLandingWrapper" v-if="landSuccess">
                <button class="btn btn-primary btnLanding" v-if="openTableExists" @click="payOrder">{{$t('pay')}}</button>
                <button class="btn btn-primary btnLanding" @click="startOrder">{{$t('order')}}</button>                             
            </div>   
        </div> -->

       


        <table class="shoppingCartItemsTable"  style="table-layout:fixed">
            <thead>
                <th class="shoppingCartHeaderBig">{{ $t('article') }}</th>
                <!-- <th class="shoppingCartHeaderSmall"></th> -->
                <th class="shoppingCartHeaderBig">{{ $t('summ') }}</th>                
            </thead>

            <tr v-for="item in orderDetails.order.menuItems" v-bind:key="item.kkProdId">
                <td class="shoppingCartLine">{{item.name}}
                    <!-- <div v-if="item.hasOptions" style="padding:0;text-align:right;">
                        <span style="font-size:13px;padding:0;" v-html="item.optionText"></span>
                    </div> -->
                </td>
                <!-- <td class="shoppingCartLine">
                    {{item.formattedPrice}}
                </td> -->

                <!-- <td class="shoppingCartLine">
                </td> -->
                <!-- <td class="shoppingCartLine mainColor" style="text-align:center; font-size:14px;">{{ item.weightOrVolume != undefined ? item.weightOrVolume * 1000 : item.quantity }} {{item.weightOrVolume != undefined ? 'g' : 'x'}} </td>   <span style="font-weight:400; font-size:14px;">{{item.weightOrVolume != undefined ? " - " + item.formattedPrice + "/kg" : ""}} </span>-->
                <td class="shoppingCartLine mainColor" style="font-weight:600;">{{item.weightOrVolume != undefined ? item.formattedPrice : item.formattedPrice}}  </td>
            </tr>    

             <tr style="border-top:2px solid var(--main-color)">
                    <td class="shoppingCartLine" style="font-weight:600;">{{$t('total')}}
                        <!-- <div v-if="item.hasOptions" style="padding:0;text-align:right;">
                            <span style="font-size:13px;padding:0;" v-html="item.optionText"></span>
                        </div> -->
                    </td>
                    <!-- <td class="shoppingCartLine">
                        {{item.formattedPrice}}
                    </td> -->

                    <!-- <td class="shoppingCartLine">
                    </td> -->
                    <!-- <td class="shoppingCartLine mainColor" style="text-align:center; font-size:14px;">{{ item.weightOrVolume != undefined ? item.weightOrVolume * 1000 : item.quantity }} {{item.weightOrVolume != undefined ? 'g' : 'x'}} </td> <span style="font-weight:400; font-size:14px;">{{item.weightOrVolume != undefined ? " - " + item.formattedPrice + "/kg" : ""}} -->
                    <td class="shoppingCartLine mainColor" style="font-weight:800;">{{ orderDetails.order.formattedTotal }}</td>
                </tr>               
        </table>

        <div class="form-group">
                    <!-- <label class="fontField">{{ $t('streetAddress')}}</label> -->
            <input name="tipField" id="tipField" class="form-control tipField" type="number" placeholder="Add Tip"/>
            <button class="btn btn-primary tipFieldBtn"  @click="addTip">+</button>
        </div>      

        <button class="btn btn-primary" @click="payAtTable">{{$t('pay')}}</button>
        <button class="btn btn-primary" @click="cancel">{{$t('cancel')}}</button>
        
        <credit-card-payment-saferpay v-if=' showSaferpay == true '></credit-card-payment-saferpay>
        <credit-card-payment-novalnet v-if=' showSaferpay == true '></credit-card-payment-novalnet>                
        
    </div>
</template>


<script>
import CreditCardPaymentComponentSaferpay from '../main-components/CreditCardPaymentComponentSaferpay.vue'
import CreditCardPaymentComponentNovalnet from '../main-components/CreditCardPaymentComponentNovalnet.vue'
import $ from 'jquery'
// import { required, sameAs } from 'vuelidate/lib/validators'

export default {
     data() {
        return {
            orderDetails: {
                Type:Object
            },
            showSaferpay: false,
   
            // footerSaveDisplay: "",
            // headerSaveDisplay: "",
            // landSuccess: false,
            // openTableExists: false,
            // openTable: {
            //     Type:Object,
            //     default: null
            // }
        }
	},
    components: {
        'credit-card-payment-saferpay': CreditCardPaymentComponentSaferpay,
        'credit-card-payment-novalnet': CreditCardPaymentComponentNovalnet,
    },
    props:{
        // shop: {
        //     Type: String
        // },
        // table: {
        //     Type: String
        // }
    },
    computed: {
        basketItems(){
 
            return window.openTable.itemList;
        }
    },    
    methods: { 
        addItemsToOrder(){
            var config = window.config;
            var vueInstance = this;
            var url = config.API_BASE + 'TCPAddItemsToOrderJ.action';     

            var params = {
                json: "true",  
                emptyBasket: "true"     
            };

            // eslint-disable-next-line no-unused-vars
            var callbackSuccess= function(result, textStatus, jqXHR){   

                window.openTable = result.order;

                
                setTimeout(() => {                        
                    vueInstance.getOrderDetails();
                }, 500);
                
                // vueInstance.$router.push( { path: "/TablePay" } );
                // if(result.requiresLogin == true)
                // {
                   
                //     return;
                // }    
                
                // vueInstance.customer = result.customer;     
                // vueInstance.calculateOrder();
            }

            // eslint-disable-next-line no-unused-vars
            var callbackError = function(result, textStatus, jqXHR){
                vueInstance.isLoading = false;
            }          

            vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);    
        },
        addTip(){
            var config = window.config;
            var vueInstance = this;
            var url = config.API_BASE + 'TCPAddTipJ.action';

            var tip = $('#tipField').val();
            console.log("Tip");
            console.log(tip);

            var params = {
                    json: "true",
                    tip: tip,
            };

            if(window.customerLoggedIn)
            {
                params.calculateOrder = "true";
            }

            if(window.xtoken != undefined)
            {
                params.xsrfToken = btoa(window.xtoken);
            }
                        
            // eslint-disable-next-line no-unused-vars
            var callbackSuccess= function(result, textStatus, jqXHR) {       
                if( (result.exception !== undefined) || (result.item !== undefined) )
                {             
                    vueInstance.$alert(vueInstance.$t('getItemFailed'));
                    return;
                }                 

                vueInstance.addItemsToOrder();

                $('#tipField').val('');
                // vueInstance.orderDetails = result;
            }

            // eslint-disable-next-line no-unused-vars
            var callbackError = function(result, textStatus, jqXHR) {   

            }
            
            vueInstance.makeRequest("POST", url, JSON.stringify(params), callbackSuccess, callbackError);
        },
        payAtTable() {
            var config = window.config;
            var vueInstance = this;
            var url = config.API_BASE + 'TCPPayAtTableJ.action';
   
            var params = {
                guid: window.openTable.guid,
                amount: window.openTable.total,
                lockTable: "false",
                json: "true",                    
            };

            if(window.xtoken != undefined)
            {
                params.xsrfToken = btoa(window.xtoken);
            }

            // eslint-disable-next-line no-unused-vars
            var callbackSuccess= function(result, textStatus, jqXHR) {       
                if( (result.exception !== undefined) || (result.item !== undefined) )
                {             
                    vueInstance.$alert(vueInstance.$t('getItemFailed'));
                    return;
                }                 

                vueInstance.onSubmit();
                // vueInstance.addItemsToOrder();

                // vueInstance.getOrderDetails();
                // vueInstance.orderDetails = result;
            }

            // eslint-disable-next-line no-unused-vars
            var callbackError = function(result, textStatus, jqXHR) {   

            }
            
            vueInstance.makeRequest("POST", url, JSON.stringify(params), callbackSuccess, callbackError);
        },
        onSubmit() {      
            window.onmessage = function(e) {       
                var config = window.config;     
                if(e.data == "S" || e.data == "A" || e.data == "F")
                {
                    vueInstance.isLoading = true;
                }
                
                if(e.data == "S")
                {                                  
                    var url = config.API_BASE + 'SaferpayJ.action';   
                    var params = {
                        json: "true"
                    };
                    // eslint-disable-next-line no-unused-vars
                    var callbackSuccess= function(result, textStatus, jqXHR){  
                        console.log(result);     
                        vueInstance.isLoading = false;
                        vueInstance.$router.push({ path: '/CheckoutFinished' });
                        
                    }
                    // eslint-disable-next-line no-unused-vars
                    var callbackError = function(){
                        vueInstance.isLoading = false;   
                    }
                
                    vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);      
                } else if (e.data == "A")
                {
                    vueInstance.$alert(vueInstance.$t('paymentAborted'));
                    vueInstance.$router.push({ path: '/Address' });

                } else if (e.data == "F")
                {
                    vueInstance.$alert(vueInstance.$t('paymentFail'));
                }
            }
            // if( (this.currentlySelectedPayment == "cod" || this.currentlySelectedPayment== "customercard" || this.currentlySelectedPayment== "TCPDebitor") && checkCod)
            // {
            //    return;
            // }

            this.isLoading = true;
            var config = window.config;
            var vueInstance = this;
            var url = config.API_BASE + 'TCPPaymentSubmitJ.action';            
            var params = {                
                deliveryAddress: "0",
                paymentMethod: "Saferpay~~P",     
                json: "true"
            };

            if(window.xtoken != undefined)
            {
                params.xsrfToken = btoa(window.xtoken);
            }

            // eslint-disable-next-line no-unused-vars
            var callbackSuccess= function(result, textStatus, jqXHR) 
            {
                if(result.paymentMethod == "Saferpay~~P")
                {                    
                    vueInstance.isLoading = false;
      
                    vueInstance.showSaferpay = true;

                   
                    setTimeout(() => {
                        var objDiv = document.getElementsByClassName("content")[0];
                        var iFrame = document.getElementById("ExternalPaymentFormFrame");
              

                        // $(".content").css({"overflow-y": "hidden"});

                        objDiv.scrollTop = objDiv.scrollHeight;
                        iFrame.scrollIntoView({behavior: "smooth"});

                        // $(".content").css({"overflow-y": "auto"});
                
                    }, 300);
               
                    // vueInstance.currentPayment = vueInstance.currentlySelectedPayment;
                    return;
                }

                if(result.paymentMethod == "StripeModule")
                {                                      
                    // vueInstance.currentPayment = vueInstance.currentlySelectedPayment;
                    return;
                }

                if(result.exception !== undefined)
                {
                    vueInstance.isLoading = false;
                    vueInstance.$alert(vueInstance.$t('exceptionMessage'));
                    return;
                }

                if(result.page == "tcp.catalog.checkoutfinished.page")
                {
                    if(vueInstance.currentlySelectedPayment == "cod" || vueInstance.currentlySelectedPayment == "customercard")
                    {
                            url = config.API_BASE + 'TCPCheckoutFinishedJ.action';            
                            params = {                    
                                json: "true"
                            };
                            vueInstance.makeRequest("GET", url, JSON.stringify(params), null, null);
                    }

                    vueInstance.isLoading = false;
                    window.shoppingCart = undefined;
                    vueInstance.$router.push({path: '/CheckoutFinished'});
                    return;
                }

                vueInstance.isLoading = false;
                window.shoppingCart = null;
                vueInstance.$router.push({ path: '/Payment' });                     
            }

            // eslint-disable-next-line no-unused-vars
            var callbackError = function(result, textStatus, jqXHR){
                vueInstance.isLoading = false;
                vueInstance.$alert(vueInstance.$t('failedGeneric'));
            }
        
            vueInstance.makeRequest("POST", url, JSON.stringify(params), callbackSuccess, callbackError);
        } ,
        cancel(){
            this.$router.go(-1);
        },
        getOrderDetails() {

            var config = window.config;
            var vueInstance = this;
            var url = config.API_BASE + 'TCPOrderDetailsJ.action';
            var params = {
                    guid: window.openTable.guid,
                    json: "true",
                    // shopId: 1
                    shopId: window.openTable.shopId,
            };
                        
            // eslint-disable-next-line no-unused-vars
            var callbackSuccess= function(result, textStatus, jqXHR) {       
                if( (result.exception !== undefined) || (result.item !== undefined) )
                {             
                    vueInstance.$alert(vueInstance.$t('getItemFailed'));
                    return;
                }                 

                vueInstance.orderDetails = result;

            }

            // eslint-disable-next-line no-unused-vars
            var callbackError = function(result, textStatus, jqXHR) {   

            }
            
            vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);
        },
        getArticleInformation(id){
            var config = window.config;
            var vueInstance = this;
            var url = config.API_BASE + 'TCPGetProductDetailsJ.action';
            var params = {
                kkProdId: id,
                json: "true",
                includeAllergenIds: "true",
                includePropertyIds: "true",
                includeNutriValues: "true"
            };
                        
            // eslint-disable-next-line no-unused-vars
            var callbackSuccess= function(result, textStatus, jqXHR) {       
                if( (result.exception !== undefined) || (result.item !== undefined) )
                {             
                    vueInstance.$alert(vueInstance.$t('getItemFailed'));
                    return;
                }                 
                // vueInstance.longDescription = result.product.longDesc;     
                // var quantity;
                    
                // if(result.product.recipe != undefined)
                // {
                //     var quantityParsed = parseInt(result.product.recipe);
                //     if(quantityParsed > 0)
                //     {
                //         quantity = quantityParsed;
                //     }                    
                //     else
                //     {
                //         quantity = 0;   
                //     }
                // }
                // else {
                //     quantity = 0;
                // }
    
                // vueInstance.subItemQuantities.push({
                //     itemId : result.kkProdId,
                //     quantity: quantity
                // });        
                
            //    return { image1: result.product.image1, name: result.product.longDesc }

                // vueInstance.decimalQuantity = result.product.decimalQuantity;
            }

            // eslint-disable-next-line no-unused-vars
            var callbackError = function(result, textStatus, jqXHR) {   

            }
            
            vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);
 
        }
        // payOrder() {
        //     window.shopSelected = true;
        //     window.TableMode = true;
        //     this.$router.push( { path:'/TablePay' } );
        // },
        // startOrder() {            
        //     window.shopSelected = true;
        //     window.TableMode = true;
        //     this.$router.push( { path:'/Menu' } );
        // },
        // makeRequest: function (type, url, data, callbackSuccess, callbackError, dataType) {
        //     console.log("ajax request:");
        //     console.log(type);
        //     console.log(url);
        //     $.ajax({
        //         method : type,
        //         type : type,
        //         timeout : '20000',
        //         headers: {
        //             "Access-Control-Allow-Origin":"*",
        //         },
        //         scriptCharset : "utf-8",
        //         contentType : "application/json; charset=utf-8",        
        //         url : url,
        //         data : "kkj=kkj&" + encodeURIComponent(data),
        //         // context : callback,
        //         success : callbackSuccess,
        //         error: callbackError,
        //         dataType : dataType != undefined ? dataType :'jsonp'
        //     });
        // },
    },
    mounted() {

        this.getOrderDetails();
        // var vueInstance = this;
        // this.isLoading = true;
        // var config = window.config;
    
        // var url = config.API_BASE + 'TCPConfirmOrderSubmitJ.action';

        // var params = {
        //     json: "true",
        //     orderType: window.orderType,                
        //     comment: this.comment
        // };

        // // eslint-disable-next-line no-unused-vars
        // var callbackSuccess= function(result, textStatus, jqXHR){       
        //     if(result.exception !== undefined)
        //     {
        //         vueInstance.isLoading = false;                    
        //         return;
        //     }

        //     if(result.requiresLogin == true)
        //     {    
        //         vueInstance.isLoading = false;
        //         return;
        //     }

        // }

        // // eslint-disable-next-line no-unused-vars
        // var callbackError = function(result, textStatus, jqXHR){
        //     vueInstance.isLoading = false;
        // }
    
        // vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);


        // $("#siteHeader").css({"display": "none"});
        // $("#global-footer").css({"display": "none"});
        // $("#ControlGroupButtonClusterFixed").css({"display": "none"});

        // var config = window.config;
        // var vueInstance = this;

        // var url = config.API_BASE + 'TCPSelectTableByQRCodeJ.action';        
        //     var params = {                
        //         "json": "true",
        //         "billNum": "1",
        //         "shopCode": this.shop,
        //         "tableCode": this.table
        //     }

        //     var callbackSuccess= function(result){         
        //         console.log(result);
        //         if(result.exception != undefined)
        //         {                    
        //             return;
        //         }

        //         window.shopObj = vueInstance.shop;
        //         window.orderType = "dinein";
        //         vueInstance.landSuccess = true;

        //         if(result.table != undefined)
        //         {
        //             console.log(result.table)
        //             vueInstance.openTableExists = true;
        //             vueInstance.openTable = result.table;
        //         }
        //     }

        //     var callbackError = function(result, textStatus, jqXHR){
        //         console.log(result);
        //         console.log(textStatus);
        //         console.log(jqXHR);
        //     }
        
        //     this.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);
    },
    beforeMount() {
        
    },
    // eslint-disable-next-line no-unused-vars
    beforeRouteLeave (to, from , next) {
        next();
    },
    // eslint-disable-next-line no-unused-vars
    beforeRouteEnter (to, from, next) {  
        next();   
    },
}
</script>

<style scoped>

.tipFieldBtn {
    width:30%;
    display:inline-block;
       margin:-4px 0 0 5px;
}


.tipField {
    width:60%;
    display:inline-block;
    margin-top: 5px;
}

    .shoppingCartHeader{
        width:30%;
    }

    .shoppingCartLine{
        height:40px;    
    }

    .shoppingCartHeaderBig{
        width:40%;
    }

    .shoppingCartHeaderSmall{
        width:20%;
    }

    .shoppingCartItemsTable{
        width:100%;
    }

    .shoppingCartSummaryTable{
        width:100%;
        border-top: 1px solid var(--main-color);
    }

    .shoppingCartSummaryLine{
        font-weight:600;    
    }

</style>