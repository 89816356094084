<template>
<div v-if="!isInIFrame">
    <div class="header animTransition headerColor" id="siteHeader">

    <i class="fa fa-bars floatLeft navigationIcon" v-if="!overlayOpen && !guestMode && !tableMode && config.TABLE_MODE_OVERRIDE == false && config.SHOW_GLOBAL_HEADER_NAV_OVERLAY" @click.prevent="toggleSidebar"></i>
<!--      -->
    <div class="navOverlayHidden animTransition">  
       
         <div class="fullNavigationWrapper" style="padding-left:0px;">
            <i class="fa fa-times floatLeft navigationIcon navigationIconClose" @click.prevent="toggleSidebar"></i>    
            <ul class="fullNavigation" v-if="showMenuLinks">                        
                <li v-for="menu in menusFiltered" v-bind:key ="menu.Order" @click.stop="toggleSidebar" class="active nav-item">
                    <!-- <router-link :to="menu.Link" style="width:100%;text-align:start;"> {{ menu.Title }} </router-link>  -->
                    <div @click="navigateTo(menu.Link)">
                        <button class="btn btn-inverted navigationLinkButton" style="width:100%;">{{ $t(menu.Title) }}</button>
                    </div>
                </li>

                
                <radial-progress-bar v-if="showQr && config.SHOW_CUSTOMER_QR_CODE" style="display: inline-block;"
                                    :diameter="200"
                                    :completed-steps="30 - qrCodeSeconds"
                                    :total-steps="30"
                                    startColor="var(--main-color)" 
                                    stopColor="var(--main-color)" 		
                                    innerStrokeColor ="lightgray">
                    <qr-code :value="qrCode"></qr-code>
                    {{ qrCodeSeconds }}s
                </radial-progress-bar>

            </ul>
            <!-- <p>V 2.0.17</p> -->
         </div>


    </div>
        <!-- <div class="header-left">
            <div class="navbar navbar-expand-md navbar-bg fontNavigation" v-show="config.SHOW_GLOBAL_HEADER_NAVIGATION">
                <nav class="" >  
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#colNav">
                        <span class ="navbar-toggler-icon"></span>
                    </button>

                    <div class ="collapse navbar-collapse" id="colNav">
                        <ul class="navbar-nav">
                            <li v-for="menu in menus" v-bind:key ="menu.Order" class="active nav-item"> <router-link :to="menu.Link"> {{ menu.Title }} </router-link> </li>
                        </ul>
                    </div>
                </nav>
            </div> 
        </div> -->

        <logo-image class="logo col-auto" v-show="config.SHOW_GLOBAL_HEADER_LOGO" id="logo-image-wrapper"></logo-image>
        <div class="fontTitle col-auto" style="text-align:start;" v-if="config.SHOW_GLOBAL_HEADER_WEBSHOPTITLE">
            {{ $t('webshopTitle') }}
        </div>   

        <!-- AP 20220508 Added option to hide shop select -->
        <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12 choiceIcons" v-if="config.SHOW_GLOBAL_HEADER_CHOICES" style="padding:5px;">
            <div class="col-lg-12 col-md-12 col-sm-8 col-xs-8 choiceIcon shopChoiceIcon" v-if="lastStateShop != null" @click.stop="goToShop">
                <span>{{lastStateShop.name}}</span><i class="fa fa-chevron-right floatRight" style="padding:4px;"></i>
            </div>

            <div class="col-lg-12 col-md-12 col-sm-8 col-xs-8 choiceIcon timeChoiceIcon" v-if="lastStateTime != null" @click.stop="goToShop" >
                <span>{{$t(orderType)}}</span><span style="padding-left:5px;" v-if="orderType != 'delivery'">{{formattedTime}}</span>
            </div>
        </div>


        <div class="header-right">
            <div class="col-auto"  id="global-header-banner"  v-show="config.SHOW_GLOBAL_HEADER_BANNER">
                <img @click.stop="goToHome" src="../../assets/images/banner.jpg" />
            </div> 

            <div class="col-auto" id="global-header-control-group" v-show="config.SHOW_GLOBAL_HEADER_CONTROL_GROUP">
                <control-group :changeLocale="changeLocale" :config="config"></control-group>
            </div>       
        </div>
    </div>
</div>
</template>

<script>

import LogoImage from '../sub-components/LogoImage.vue'
import Vue from 'vue'   
import $ from 'jquery'
import VueRouter from 'vue-router'
import ControlGroup from '../main-components/ControlGroup.vue'
import { BootstrapVue } from 'bootstrap-vue'
// eslint-disable-next-line no-unused-vars
// import * as bootstrapjs from '../../../node_modules/bootstrap/dist/js/bootstrap.js'
import moment from 'moment'
import CryptoJS from 'crypto-js'
import base32Encode from 'base32-encode'
import RadialProgressBar from 'vue-radial-progress'
import QrcodeVue from 'qrcode.vue'

Vue.use(BootstrapVue);

Vue.use(VueRouter);

export default {
    data(){
        return {
            showNav: false,
            overlayOpen: false,
            showQr: false,
            lastStateShop: null,
            lastStateTime: null,
            orderType: "",
            formattedTime: "",
            tableMode :false,
            guestMode : false,
            intervalId:0,
            qrCode:"",
            qrCodeSeconds:30,
            cardNum: "",
            showMenuLinks : true,
        }
    },
    name:"global-header",
    props: {
        menus: {
            Type: Array,
            required:true
        },
        changeLocale: {
            Type: Function,
            required: true
        },
        config: {
            Type: Array
        }
    },
    components: {
        'logo-image' : LogoImage,
        'control-group': ControlGroup,
        'qr-code' : QrcodeVue,
        'radial-progress-bar': RadialProgressBar,
    },
    methods:{
        navigateTo(link) {
            var vueInstance = this;       
            if(link == "/Shop")
            {         
                if(!(vueInstance.$route.path == '/Shop'))
                {
                    if(window.shoppingCart != undefined && window.shoppingCart != null && window.shoppingCart.length > 0)
                    {
                        vueInstance.$confirm(
                            vueInstance.$t('resetBasketInfoMessage'),
                            vueInstance.$t('resetBasketInfoTitle'),
                            'info',
                            { 
                                confirmButtonText: vueInstance.$t('confirm'),
                                cancelButtonText: vueInstance.$t('cancel')
                            }).then(() => {
                                if(!window.TableMode)
                                {                        
                                    //return link;
                                    vueInstance.$router.push({ path: link });
                                }
                                else
                                {
                                    //vueInstance.$router.push({ path: link });
                                }
                        });
                    }
                    else
                    {
                        vueInstance.$router.push({ path: link });
                    }
                }  
            }
            else
            {
                vueInstance.$router.push({ path: link });
                //return link;
                // vueInstance.$router.push({ path: link });
            }
        },
        startQrCodeInterval() {
            var vueInstance = this;
            if(vueInstance.cardNum == "")
            {
                var config = window.config;         
                var url = config.API_BASE + 'TCPAfterLoginJ.action';  
                var params = {
                    json: "true"
                };

                // eslint-disable-next-line no-unused-vars
                var callbackSuccess = function(result, textStatus, jqXHR) {       
            
                    if (result.exception !== undefined)
                    {                                       
                        return;
                    }
                    if (result.requiresLogin)
                    {                        
                        return;
                    }  
                    
                    if (result.wondCustomer == undefined)
                    {                        
                        return;
                    }          

                    if(result.customer != undefined)
                    {
                        if(result.customer.type != undefined)
                        {
                            if(result.customer.type == 2)
                            {
                                window.isGuestCheckout = true;
                            }
                        }
                    }  
           
                    vueInstance.cardNum = result.wondCustomer.cardNum;

                    vueInstance.generateQrCode();            
                    vueInstance.intervalId = setInterval(() => {                               
                        vueInstance.qrCodeSeconds = vueInstance.qrCodeSeconds - 1;

                        if(vueInstance.qrCodeSeconds == 0)
                        {               
                            vueInstance.generateQrCode();                                                                
                        }                
                    }, 1000);
                }

                    // eslint-disable-next-line no-unused-vars
                var callbackError = function(result, textStatus, jqXHR) {
                    vueInstance.isLoading = false;
                }     
                    
                vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);            
            }
            else
            {
                vueInstance.generateQrCode();            
                vueInstance.intervalId = setInterval(() => {                               
                    vueInstance.qrCodeSeconds = vueInstance.qrCodeSeconds - 1;

                    if(vueInstance.qrCodeSeconds == 0)
                    {               
                        vueInstance.generateQrCode();                        
                    }                
                }, 1000);
            }                
        },        
        makeRequest: function (type, url, data, callbackSuccess, callbackError, dataType) {
            console.log("ajax request:");
            console.log(type);
            console.log(url);
            $.ajax({
                method : type,
                type : type,
                timeout : '20000',
                headers: {
                    "Access-Control-Allow-Origin":"*",
                },
                scriptCharset : "utf-8",
                contentType : "application/json; charset=utf-8",        
                url : url,
                data : "kkj=kkj&" + encodeURIComponent(data),
                // context : callback,
                success : callbackSuccess,
                error: callbackError,
                dataType : dataType != undefined ? dataType :'jsonp'
            });
            window.REQUEST_TIMESTAMP = new Date().getTime();
        },
        generateQrCode() {
            // 1- Gets cardNumber
            let date = new Date();
            var newDateObj = moment(date).add(30,'seconds').utc().format('yyyyMMDDHHmmss'); 
            var barcode = this.cardNum + newDateObj;

            //checksum
            var result = '*';
            var code39Chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ-. $/+%";       
            var sum = 0;
            for (var i = 0; i < barcode.length; i++)
            {
                sum += code39Chars.indexOf(barcode.charAt(i));
            }
            result = code39Chars[sum % 43];
            barcode = barcode + result;     
            
            console.log(barcode);

            var C = CryptoJS;
            var bytes = C.enc.Utf8.parse(barcode);
            var key = new Uint8Array(32);
            var IV = new Uint8Array(16);
            key = new Uint8Array( [60, 175, 77, 70, 58, 137, 151,252,5,30,164,241,182,3,136,151,129,196,108,49,255,108,131,159,207,90,24,11,38,215,110,138] );              
            IV = new Uint8Array( [140,184,62,82,119,188,41,89,188,112,122,223,51,65,170,21] );
            var aes = C.algo.AES.createEncryptor(C.lib.WordArray.create(key), {
                mode: C.mode.CBC,
                padding: C.pad.Pkcs7,
                iv: C.lib.WordArray.create(IV)
            });

            var encrypted = aes.finalize(bytes);
            var uint8Array = this.wordToByteArray(encrypted.words);
            var uint9Array = new Uint8Array(uint8Array);                
            var qrcode = base32Encode(uint9Array, 'RFC4648', { padding: false });
            qrcode = "TCPOSAPP" + qrcode;
            console.log(qrcode);
            this.qrCode = qrcode
            this.qrCodeSeconds=30;
            this.showQr = true;
            this.$forceUpdate();
        },
        wordToByteArray(wordArray) {
            var byteArray = [], word, i, j;
            for (i = 0; i < wordArray.length; ++i) {
                word = wordArray[i];
                for (j = 3; j >= 0; --j) {
                    byteArray.push((word >> 8 * j) & 0xFF);
                }
            }
            return byteArray;
        },
        goToHome(){
            var vueInstance = this;
            if(window.config.IS_PURE_ACCOUNTMANAGER)
            {
                return;
            }

            if(!(vueInstance.$route.path == '/Shop') )
            {
                if(window.shoppingCart != undefined && window.shoppingCart != null && window.shoppingCart.length > 0)
                {
                    vueInstance.$confirm(
                    vueInstance.$t('resetBasketInfoMessage'),
                    vueInstance.$t('resetBasketInfoTitle'),
                    'info',
                    { 
                        confirmButtonText: vueInstance.$t('confirm'),
                        cancelButtonText: vueInstance.$t('cancel')
                    }).then(() => {
                    if(!(vueInstance.$route.path == '/Shop') && !window.TableMode)
                    {
                        vueInstance.$router.push({ path: '/Shop' })
                    }   
                });}
                else
                {
                    vueInstance.$router.push({ path: '/Shop' });
                }
            }               
        },
        toggleSidebar(){            
            if($('.navOverlayHidden').hasClass('navOverlayShown'))
            {
                $('.navOverlayHidden').removeClass('navOverlayShown');
                this.overlayOpen = false;
                clearInterval(this.intervalId);
            }           
            else 
            {
                $('.navOverlayHidden').addClass('navOverlayShown');
                this.overlayOpen = true;
                this.startQrCodeInterval();
            }                

        },    
        goToShop(){
            var vueInstance = this;
            if(window.config.IS_PURE_ACCOUNTMANAGER)
            {
                return;
            }
            if(!(vueInstance.$route.path == '/Shop'))
            {
                if(window.shoppingCart != undefined && window.shoppingCart != null && window.shoppingCart.length > 0)
                {
                    vueInstance.$confirm(
                        vueInstance.$t('resetBasketInfoMessage'),
                        vueInstance.$t('resetBasketInfoTitle'),
                        'info',
                        { 
                            confirmButtonText: vueInstance.$t('confirm'),
                            cancelButtonText: vueInstance.$t('cancel')
                        }).then(() => {
                        if(!(vueInstance.$route.path == '/Shop') && !window.TableMode)
                        {
                            vueInstance.$router.push({ path: '/Shop' })
                        }   
                    });
                }
                else
                {
                    vueInstance.$router.push({ path: '/Shop' });
                }     
               
            }                            
        },
        updateChoices(shopobj, timestring){
            var vueInstance = this;
      
            if( (timestring == null && shopobj == null) || (timestring == undefined && shopobj == undefined) )
            {
                vueInstance.lastStateShop = shopobj;
                vueInstance.lastStateTime = timestring;
                vueInstance.orderType = "";
                return;
            }

            if(vueInstance.lastStateShop != shopobj)
            {
                if(shopobj == undefined)
                {
                   vueInstance.lastStateShop = null;   
                   return;
                }
                vueInstance.lastStateShop = shopobj;
            }
            
            vueInstance.orderType = window.orderType;        

            if(timestring)
            {
                vueInstance.lastStateTime = timestring.trim();
                window.timeString = timestring.trim();                    
                var datetime = new Date(timestring.trim().trim().trim());                            
                var fullLocale= vueInstance.$i18n.locale;               
                var jsLocale = fullLocale.split('_')[0] + "-" + fullLocale.split('_')[1];
                vueInstance.formattedTime = datetime.toLocaleDateString(jsLocale) + " " + datetime.toLocaleTimeString(jsLocale, { hour: '2-digit', minute: '2-digit' });//datetime.toLocaleDateString(vueInstance.$i18n.locale, options);                    
            }
            else
            {
                vueInstance.lastStateTime = null;
                return;
            }
                  
        }
    },
    computed:{
        confi(){
            return window.config;
        },
        showHeaderFooter(){
            if(window.showHeaderFooter === undefined){
                return true;
            }else{
                return window.showHeaderFooter;
            } 
        },
        isInIFrame(){
            try{
                return window.self !== window.top;
            } catch (e) {
                return false;
            }
        },
        menusFiltered(){
            return this.menus;
            // if(window.shopSelected)
            // {
            //     return this.menus;
            // }
            // else
            // {
            //     return this.menus.filter(menu => menu.Link != "/Shop");
            // }            
        }
        
    },
    beforeMount(){
        var vueInstance = this;
        vueInstance.lastStateShop = window.shopObj;
        vueInstance.lastStateTime = window.timeString;

        setInterval(function() {
            vueInstance.updateChoices(window.shopObj, window.timeString);
            vueInstance.tableMode = window.TableMode;
            vueInstance.guestMode = window.isGuestCheckout;           
        }, 1000);
    }
}
</script>

<style scoped>

.choiceIcon{
    max-height:50px;
    min-height:25px;
    background-color: var(--tint-color);
    border-radius: 2px; 
    margin-bottom:5px;
    padding:2px;
    font-size:13px;
}

.choiceIcon:hover{
    cursor:pointer;
}

#global-header-banner {
    height:8vh;
}

#global-header-banner img {
    padding:5px 0px 5px 0px;
    width:300px;
    height:inherit;
}

#logo-image-wrapper {
    height:50px;
    width:auto;
    padding:0px 10px 0px 10px;
    margin-top:10px;
    display:block;
    cursor: pointer;
}

@media screen and (max-width:770px) {
    #logo-image-wrapper {
        height: 45px;
        /* 35px;  */
        width:auto;
        /* margin-top:15px; */
        /* display:none; */
    }
/* 
    .choiceIcons{
        margin-top: 50px;
    } */
    .choiceIcons{
        display:none;
    }
}


@media screen and (max-width:550px) {
    #logo-image-wrapper {
        height:45px; 
         width:auto;
        /* margin-top:15px; */
        /* display:none; */
    }
    
    .choiceIcons{
        margin-top: 42px;
    }
}

#global-header-control-group {
    display:inline-block;
    width: 250px;
}

#global-header-banner {
    display:inline-block;
    width: 500px;
}

/* NAVIGATION BARS */

* {box-sizing: border-box;}

body { 
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}


.header {
    background-blend-mode: darken;
    background-size: cover;
    background-image:url("../../assets/images/bannerPlaceholder.jpg");
    top:0px;
    min-height: 75px;
    max-height: 200px;
    position:fixed;
    width:80%;
    padding:0px;
    align-content:center;
    align-items:center;
    background-color:var(--global-header-bg);
    padding: 10px 20px;
    padding-left: 55px;
}
@media screen and (max-width:1200px) {
    .header{
         width:100%;
         padding-bottom:0;
    }
}

.header a {
  float: left;
  color: black;
  text-align: center;
  padding: 12px;
  text-decoration: none;
  font-size: 18px; 
  line-height: 25px;
  border-radius: 4px;
}

.header a.logo {
  font-size: 25px;
  font-weight: bold;
}

.header a:hover {
  background-color: #ddd;
  color: black;
}

.header a.active {
  /* background-color: dodgerblue; */
  color: var(--text-color);
}

.header-right {
  float: right;
}

.header-left {
  float: left;
}

@media screen and (max-width: 500px) {
  .header a {
    float: none;
    display: block;
    text-align: left;
  }
  
  .header-right {
    float: none;
  }
}

.navigationIconClose {
    font-size:25px;
   
    color:black;
}

.navigationIcon {
    position:fixed;
    color:lightgray;
    left:10%;
    top:30px;
    font-size:25px;
    height:65px;
    width:65px;
    align-content:center;
}
@media screen and (max-width: 1200px) {
    .navigationIcon {
        position:fixed;
        left:0%;
    }
}
/* END NAVIGATION BARS */


/* Make whole div behind anchor in menu clickable */
a {
   display:block;
}

.fullNavigation{
    /* margin-left: 50px; */    
    margin-top:80px;
    height:100%;  
    width:100%;  
    height:fit-content;
    display:table;
    padding:0px;
    /* box-shadow: 1px 1px 0px #999,
            2px 2px 0px #999,
            3px 3px 0px #999,
            4px 4px 0px #999; */
            /* 5px 5px 0px #999,
            6px 6px 0px #999; */
}


.fullNavigation li{
    display:table-row;
    background-color: var(--background-color);
}

.fullNavigation li a{
     color:black;
}

.fullNavigation li a:hover{
     background-color:var(--main-color);
     color:var(--main-color-highlight);
}

.navOverlayHidden {
    /* visibility:hidden; */
    display:none;
    position:fixed;
    top:0;
    bottom:100%;
    right:0;
    left:0;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.75);
}

.navOverlayShown{
    /* visibility:visible; */
    display:block;
    bottom:0;
    z-index: 50000;
}


.navigationLinkButton{
    width:100%;    
    height:50px;
    margin:0px;
}

.navigationLinkButton:hover{
    text-decoration:underline !important;
}

</style>