import { render, staticRenderFns } from "./ModalAccessible.vue?vue&type=template&id=78a1223c&scoped=true&"
import script from "./ModalAccessible.vue?vue&type=script&lang=js&"
export * from "./ModalAccessible.vue?vue&type=script&lang=js&"
import style0 from "./ModalAccessible.vue?vue&type=style&index=0&id=78a1223c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78a1223c",
  null
  
)

export default component.exports