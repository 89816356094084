<template>
    <div class="orderConfirmationWrapper">

        <div class="col-12 subPageHeader"  >      
            {{ $t('orderConfirmationTitle') }}
            <button class="btn btn-primary subPageHeader subPageHeaderButton" @click="goBack(false)" >
               {{$t('back')}}
            </button>
        </div>      

        <!-- <div>
            <button @click="addTip()">ADDTIP</button>
        </div> -->

        <flow :currentPoint="2"> </flow>

        <loading :active.sync="isLoading" 
            :can-cancel="true"        
            :is-full-page="true"
            loader='bars'></loading>

        <div class="col-12 checkoutPadding">
            <ul class="col-12" style="padding-left: 0px;padding-right: 0px; padding-top:0px;">
                <div style="margin-left:10%; margin-right:10%;">
                    <div v-for="item in pointCampaigns" v-bind:key="item.campaignId"  style="text-align:right; padding: 5px; border-bottom:0.2px solid lightgray">
                        <span style="font-size:20px;float:left;">{{ getCampaignName(item.campaignId) }}</span>
                        
                        <span v-if="item.gainedPoints > 0" style="display: inline-block; width: 30%; text-align: center; ">
                            <span style="font-size:15px;"> {{ $t('rewardPointsGained') }} </span>                        
                            <span style="color:var(--main-color); font-size:20px;"> {{ item.gainedPoints }} </span>
                        </span>
                        
                        <span v-if="item.usedPoints > 0" style="display: inline-block; width: 30%; text-align: center; ">
                            <span style="font-size:15px;"> {{ $t('rewardPointsBurned') }} </span>
                            <span style="color:var(--main-color); font-size:20px;"> {{ item.usedPoints }} </span>
                        </span>
                    </div>
                </div>
                <br/>

                <table class="shoppingCartItemsTable"  style="table-layout:fixed;" v-if="basketItems.length > 0">
                    <thead>
                        <th style="width:100%;"></th>
                        <!-- <th class="shoppingCartHeaderBig" style="text-align:left;">{{ $t('article') }}</th>                        
                        <th class="shoppingCartHeader" style="width:17%;"></th>
                        <th class="shoppingCartHeader">{{ $t('summ') }}</th>
                        <th class="shoppingCartHeaderSmall"></th>
                        <th class="shoppingCartHeaderSmall"></th>
                        <th class="shoppingCartHeaderSmall"></th> -->
                    </thead>
        
                    <tr v-for="item in basketItems" v-bind:key="item.id" style="height:65px;">
                        <td class="shoppingCartEntry">
                            <cart-entry :item="item" :callbackAddNormal="addArticle" :isDeliveryArticle="isDeliveryArticle" :callbackEdit="changeItemQuantity" :callbackAddWeight="weightAlert" :callbackDelete="deleteItem" :callbackRemove="removeArticle"></cart-entry>
                        </td>
                    </tr>            
                </table>

      
                <div class="shoppingCartDiscounts" v-if="discounts.length > 0">
                    <div class="shoppingCartDiscountsTitle">{{ $t('discounts') }}</div>
                    <div v-for="discount in discounts" v-bind:key="discount.id">
                        <div class="shoppingCartDiscountsEntry">
                            <span class="shoppingCartDiscountsEntryName">{{discount.description}}</span>
                            <span class="shoppingCartDiscountsEntryValue">{{formatDiscount(discount.value)}}</span>
                        </div>
                    </div>
                </div>
            </ul>

            <div class="col-12">
                <div class="col-6" style="font-size:20px; text-align:left;">{{$t('total')}}:</div>
                <div class="col-6" style="font-size:30px; text-align:right;">{{basketTotal}} </div>
            </div>

            <div class="col-12" v-if="paymentLists.length > 0">
                <!-- <div class="shoppingCartDiscountsTitle">{{ $t('discounts') }}</div> -->
                <!-- <div v-for="paymentList in paymentLists" v-bind:key="paymentList.paymentId" class="col-12">
                        <span class="col-6 shoppingCartDiscountsEntryName" style="font-size:20px; text-align:left;">{{$t('subsidyTitle')}}</span>
                        <span class="col-6 shoppingCartDiscountsEntryValue" style="font-size:30px; text-align:right;">{{formatDiscount(paymentList.paymentAmount)}}</span>
                </div> -->
                <span class="col-6" style="font-size:16px; text-align:left;">{{$t('subsidyTitle')}}</span>
                <span class="col-6" style="font-size:22px; text-align:right; color:gray;">{{formatCurrency(paymentLists[0].paymentAmount)}}</span>
            </div>

            <div class="col-12" v-if="customerLoggedIn == true && promotionsEnabled == true">
                <input type="text" class="col-12 commentField" v-model="promoBarcode" :placeholder="$t('promotionBarcode')" />
                <button @click="onPromotionBarcodeSubmit" class=" col-2 btn btn-primary floatRight" style="">{{ $t('promotionBarcodeSubmit') }}</button>
            </div>

            <input v-if="config.SHOW_DESCRIPTION_TEXT_INPUTS" type="text" class="col-12 commentField" v-model="comment" :placeholder="$t('comment')" />


            <div class="gdprCheckBox" v-if="config.SHOW_ORDERCONFIRMATION_TC_CONSENT == true">
                <check-box                         
                    :label='$t("customerTC") +  "<span style="+"color:var(--main-color);"+">*</span>"'
                    :value="1"
                    :trueValue="'1'"
                    :falseValue="'0'"
                    :hyperlink ="config.REGISTRATION_FORM_FIELDS.tcHyperlink"
                    :biggerCheckbox = true   
                    :newTab = true               
                    v-model="termsAndConditionsFlag">
                </check-box>       
            </div>
    
            <div class="gdprCheckBox" v-if="ageVerification == true">
                <check-box                         
                    :label='$t("customerAge") +  "<span style="+"color:var(--main-color);"+">*</span>"'
                    :value="1"
                    :trueValue="'1'"
                    :falseValue="'0'"
                    :hyperlink ="''"
                    v-model="ageVerificationFlag">
                </check-box>       
            </div>

            <button type="button" @click="onSubmit" class="btn btn-primary floatRight">{{$t('continue')}}</button>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
        </div>

    </div>
</template>

<script>
import FlowIndicator from '../main-components/FlowIndicator.vue'
import VueLoading from 'vue-loading-overlay'
import ShoppingCartEntry from '../sub-components/ShoppingCartEntry.vue'
import CheckBox from '../sub-components/CheckBoxCustomer.vue'
import $ from 'jquery'

export default {
    data(){
        return {
            comment:"",
            basketItems: {
                Type: Array
            },
            basketTotal: "",
            quantityButtonDisabled: 'qtyBtnDisabled',
            pointCampaigns: [],   
            discounts: [],
            paymentLists: [],
            pointCampaignsDefinitions: [],
            isLoading: false,  
            termsAndConditionsFlag: 0,
            ageVerificationFlag: 0,
            delivery_fee_article_prod_id: 0,
            customerLoggedIn: false,   
            promoBarcode:""  
        }
    },
    computed: {
        config() {
            return window.config;
        },  
        ageVerification(){
            return window.ageVerification;
        } ,
        promotionsEnabled(){
            return window.config.PROMOTION_CODE_ENABLED;
        }
    },
    components: {
        'loading' : VueLoading,
        'flow' : FlowIndicator,
        'cart-entry' : ShoppingCartEntry,
        'check-box' : CheckBox
    },
    mounted(){
        window.paymentOrigin = "Shop";
    },
    beforeRouteEnter (to, from, next) {
         // eslint-disable-next-line no-unused-vars
         next(vm => {
            var myDiv = document.getElementById('contentSection');
            $("#contentSection").scrollTop(0);
            $("#app").scrollTop(0);
            if(myDiv != null)
            myDiv.scrollTop = 0;
        })        
    }, 
    beforeMount() {
        var config = window.config;
        var vueInstance = this;
        vueInstance.isLoading = true;
        var url = config.API_BASE + 'TCPAfterLoginJ.action';     

        var params = {
            json: "true",       
        };

        // eslint-disable-next-line no-unused-vars
        var callbackSuccess= function(result, textStatus, jqXHR){   
            vueInstance.isLoading = false;
            if(result.requiresLogin == true)
            {
                vueInstance.$router.push( { path: "/Login" } );
                return;
            }    
            
            vueInstance.customer = result.customer;
            if(result.wondCustomer != undefined)
            {
                vueInstance.pointCampaignsDefinitions = result.wondCustomer.pointCampaigns;
            }

            if(result.customer != undefined)
            {
                if(result.customer.type != undefined)
                {
                    if(result.customer.type == 2)
                    {
                        window.isGuestCheckout = true;
                    }
                }
            }  
            
            window.pointCampaignsDefinitions = vueInstance.pointCampaignsDefinitions;
            vueInstance.customerLoggedIn = true;
            window.customerLoggedIn = true;
            // if(!window.TableMode)     
            vueInstance.calculateOrder();
        }

        // eslint-disable-next-line no-unused-vars
        var callbackError = function(result, textStatus, jqXHR){
            vueInstance.isLoading = false;
        }          

        vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);
    },
    methods: { 
        onPromotionBarcodeSubmit() {
            var vueInstance = this;
            vueInstance.isLoading = true;
            if(this.promoBarcode == "")
            {
                console.log(this.$t('emptyPromotionCode'));
            }

            var config = window.config;            
            var url = config.API_BASE + 'TCPCalculateOrderJ.action';     

            window.promotionBarcode = this.promoBarcode;
            // var params = { calculateOrder: true };
            var params = {
                orderType: window.orderType,
                json: "true",
                promotionBarcode: this.promoBarcode,
                comment: this.comment
                // calculateOrder: "true"
            };
            // eslint-disable-next-line no-unused-vars
            var callbackSuccess= function(result, textStatus, jqXHR) {   
                vueInstance.isLoading = false;     
                if(result.exception != undefined)
                {             
                    if(result.exception.code == 611)
                    {
                        vueInstance.$alert(vueInstance.$t('invalidPromotionCode'));
                    }      
                    
                    vueInstance.promoBarcode = "";
                    window.promotionBarcode = "";                              
                }
                
                vueInstance.basketItems = result.basketTile.basketItems;
                vueInstance.basketTile = result.basketTile;
                vueInstance.basketTotal = result.basketTile.formattedTotal;                
                window.shoppingCart = result.basketTile.basketItems;
                if(result.basketTile.paymentsList != undefined){
                    window.subsidy = vueInstance.formatCurrency(result.basketTile.paymentsList[0].paymentAmount);
                }else{
                    window.subsidy = "";
                }                        
                vueInstance.dataCounter++;  
                vueInstance.updateCart();
                vueInstance.$forceUpdate();   
            }
            // eslint-disable-next-line no-unused-vars
            var callbackError = function(result, textStatus, jqXHR){
     
            }
        
            vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);
        },
        formatDiscount(value){
            var formatter = new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: window.config.SUBSIDY_CURRENCY,

            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
            });

            return formatter.format(value); /* $2,500.00 */
        },
        formatCurrency(value){
            // switch (this.config.) {
            //     case value:
                    
            //         break;
            
            //     default:
            //         break;
            // }
            // var formatter = new Intl.NumberFormat('de-DE', { maximumSignificantDigits: 4});
            // return formatter.format(value);
            var formatter = new Intl.NumberFormat('de-DE', {style: 'currency', currency: window.config.SUBSIDY_CURRENCY});
            return formatter.format(value);
            // return Number(value).toFixed(2) + '' + this.config.SUBSIDY_CURRENCY;
        },
        isDeliveryArticle (itemProdId){                        
            var config = window.config;
            var vueInstance = this;

            if(config.DELIVERY_FEE_TCPOS_ARTICLE_ID == null){
                return;
            }
            
            var articleId = config.DELIVERY_FEE_TCPOS_ARTICLE_ID;

            if(itemProdId == window.delivery_fee_article_prod_id && window.delivery_fee_article_prod_id > 0)
            {
                return true;
            }
            else if(window.delivery_fee_article_prod_id > 0)
            {
                return false;
            }
            

            if(itemProdId == vueInstance.delivery_fee_article_prod_id && vueInstance.delivery_fee_article_prod_id > 0)
            {
                return true;
            }
            else if(vueInstance.delivery_fee_article_prod_id > 0)
            {
                return false;
            }

            var url = config.API_BASE + 'TCPProductSearchJ.action'
            var params = {
                dataDescriptor: {    
                    custom1Int: articleId,
                },
                json: 'true',
                orderType:  window.orderType,           
            }

            params.productSearch = {
                custom1Int: articleId,                
                priceTypeRequired:"DE",                                    
            }

            // eslint-disable-next-line no-unused-vars
            var callbackSuccess= function(result, textStatus, jqXHR) {   
                if(result.products.productArray != undefined)
                {
                    if(result.products.productArray.length > 0)
                    {             
                        var productId = result.products.productArray[0].id;    
                        vueInstance.delivery_fee_article_prod_id = productId;   
                        window.delivery_fee_article_prod_id = productId;
                        //CHECK IF DELIVERY FEE ARTICLE ALREADY PRESENT
                        if(productId == itemProdId)
                        {                            
                            return true;
                        }    
                    }
                }
                else
                {
                    return false;
                }                
            }

            // eslint-disable-next-line no-unused-vars
            var callbackError= function(result, textStatus, jqXHR){       
                return false;      
            }

            vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);  
        
        },
        getCampaignName(campaignId){
            for(var i  = 0; i < this.pointCampaignsDefinitions.length; i++)
            {
                if(this.pointCampaignsDefinitions[i].id == campaignId)
                {
                    return this.pointCampaignsDefinitions[i].description;
                }
            }

            return "";
        },
        isNumeric(str) {
            if (typeof str != "string") return false // we only process strings!  
            return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
                    !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
        },
        changeItemQuantity(element, articleId) {
            var value = element.srcElement.value;
            if(!this.isNumeric(value))
            {
                this.$alert(this.$t('inputQuantityNotValid'));
                element.srcElement.value = "1";
                return;                
            }

            var config = window.config;
            var vueInstance = this;
            vueInstance.isLoading = true;
            var url = config.API_BASE + 'TCPEditCartSubmitJ.action';     
            var cartId = 0;

            cartId = articleId;
            // if(vueInstance.basketItems != undefined)
            // {
            //     for(var i = 0; i < vueInstance.basketItems.length; i++)
            //     {                        
            //         if(vueInstance.basketItems[i].prodId == articleId)
            //         {                        
            //             cartId = vueInstance.basketItems[i].id;                         
            //         }
            //     }
            // }

            var params = {
                basketId: cartId,
                quantity: value,
                orderType: window.orderType
            };

            
            if(window.xtoken != undefined)
            {
                params.xsrfToken = btoa(window.xtoken);
            }

            if(params.quantity == 0)
            {
                vueInstance.deleteItem(articleId);
                return;
            }

            // eslint-disable-next-line no-unused-vars
            var callbackSuccess= function(result, textStatus, jqXHR) {      
                vueInstance.isLoading = false; 
                window.shoppingCart = result.basketTile.basketItems;
                if(result.basketTile.paymentsList != undefined){
                    window.subsidy = vueInstance.formatCurrency(result.basketTile.paymentsList[0].paymentAmount);
                }else{
                    window.subsidy = "";
                }
                vueInstance.basketItems = result.basketTile.basketItems;                
                vueInstance.basketTotal = result.basketTile.formattedTotal;
                if(result.basketTile.discounts != undefined)
                {
                    vueInstance.discounts = result.basketTile.discounts;
                    // for(var i = 0; i < result.basketTile.pointCampaigns.length; i++)
                    // {
                    //     vueInstance.pointCampaigns
                    // }
                    // vueInstance.pointsGained = result.basketTile.pointCampaigns.gainedPoints;
                    // vueInstance.pointsBurned = result.basketTile.pointCampaigns.usedPoints;
                } 
                else
                {
                    vueInstance.discounts = [];
                }

                if (result.basketTile.paymentsList != undefined) 
                {
                    vueInstance.paymentLists = result.basketTile.paymentsList;
                }
                else
                {
                    vueInstance.paymentLists = [];
                }

                if(result.exception !== undefined)
                {
                    if(result.exception.code === 64){
                        vueInstance.$alert(vueInstance.$t('changeItemMinError'));
                        return;
                    }else if(result.exception.code === 65){
                        vueInstance.$alert(vueInstance.$t('changeItemMaxError'));
                        return;
                    }
                    vueInstance.$alert(vueInstance.$t('changeItemQtyFailed'));
                    return;
                } 
                // element.cartQuantity = 0;
            }

            // eslint-disable-next-line no-unused-vars
            var callbackError = function(result, textStatus, jqXHR) {
                vueInstance.isLoading = false;     
            }
        
            vueInstance.makeRequest("POST", url, JSON.stringify(params), callbackSuccess, callbackError);
        },  
        addItemsToOrder(){
            if(window.TableMode == true && window.openTable != undefined)
            {
                var config = window.config;
                var vueInstance = this;
                vueInstance.isLoading = true;
                var url = config.API_BASE + 'TCPAddItemsToOrderJ.action';     

                var params = {
                    json: "true",  
                    emptyBasket: "true"     
                };

                // eslint-disable-next-line no-unused-vars
                var callbackSuccess= function(result, textStatus, jqXHR){   
                    vueInstance.isLoading = false;
                    window.openTable = result.order;
                    // vueInstance.$router.push( { path: "/TablePay" } );
                    // if(result.requiresLogin == true)
                    // {
                    
                    //     return;
                    // }    
                    
                    // vueInstance.customer = result.customer;     
                    // vueInstance.calculateOrder();
                }

                // eslint-disable-next-line no-unused-vars
                var callbackError = function(result, textStatus, jqXHR){
                    vueInstance.isLoading = false;
                }          

                vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);    
            }
         
        },
        calculateOrder(){
            
            var config = window.config;
            var vueInstance = this;
            vueInstance.isLoading = true;
            var url = config.API_BASE + 'TCPCalculateOrderJ.action';     
            // var params = { calculateOrder: true };
            var params = {
                orderType: window.orderType,
                json: "true",
                promotionBarcode: window.promotionBarcode
                // calculateOrder: "true"
            };
            
            var callbackSuccess= function(result, textStatus, jqXHR) {
                vueInstance.isLoading = false;
                if(result.requiresLogin == true)
                {
                    vueInstance.$router.push( { path: "/Login" } );
                }

                if(result.exception !== undefined)
                {
                    vueInstance.$alert(this.$t("exceptionMessage"));
                    return;
                }   
                            
                if(result.errorCode > 0)
                {
                    vueInstance.$alert(result.erroMsg);
                    return;
                }      
                    
                window.shoppingCart = result.basketTile.basketItems;
                vueInstance.basketItems = result.basketTile.basketItems;
                if(result.basketTile.paymentsList != undefined){
                    window.subsidy = vueInstance.formatCurrency(result.basketTile.paymentsList[0].paymentAmount);
                }else{
                    window.subsidy = "";
                }
                vueInstance.basketTotal = result.basketTile.formattedTotal;
                
                if(result.basketTile.pointCampaigns != undefined)
                {
                    vueInstance.pointCampaigns = result.basketTile.pointCampaigns;
                    // for(var i = 0; i < result.basketTile.pointCampaigns.length; i++)
                    // {
                    //     vueInstance.pointCampaigns
                    // }
                    // vueInstance.pointsGained = result.basketTile.pointCampaigns.gainedPoints;
                    // vueInstance.pointsBurned = result.basketTile.pointCampaigns.usedPoints;
                }        
                
                if(result.basketTile.discounts != undefined)
                {
                    vueInstance.discounts = result.basketTile.discounts;
                    // for(var i = 0; i < result.basketTile.pointCampaigns.length; i++)
                    // {
                    //     vueInstance.pointCampaigns
                    // }
                    // vueInstance.pointsGained = result.basketTile.pointCampaigns.gainedPoints;
                    // vueInstance.pointsBurned = result.basketTile.pointCampaigns.usedPoints;
                }         
                else
                {
                    vueInstance.discounts = [];
                }

                if (result.basketTile.paymentsList != undefined) 
                {
                    vueInstance.paymentLists = result.basketTile.paymentsList;
                }
                else
                {
                    vueInstance.paymentLists = [];
                }

                if(result.basketTile.basketItems == undefined || result.basketTile.basketItems.length == 0)
                {
                    vueInstance.$router.push( { path: "/Menu" } );
                    vueInstance.$alert(vueInstance.$t('NoProducts'));
                }

                console.log(result);            
                console.log(textStatus);
                console.log(jqXHR);
            }

            var callbackError = function(result, textStatus, jqXHR){
                vueInstance.isLoading = false;
                console.log(result);
                console.log(textStatus);
                console.log(jqXHR);
            }
        
            vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);

        },
        // confirmSubmit(){
        //     var vueInstance = this;
        //     if(this.basketItems.length == 0)
        //     {
        //         vueInstance.$alert(vueInstance.$t('NoProducts'));
        //         vueInstance.$router.push({ path: '/Menu' });
        //         return;
        //     }
        //     this.isLoading = true;
        //     var config = window.config;
        //     var url = config.API_BASE + 'TCPConfirmOrderSubmitJ.action';
        //     var params = {
        //         json: "true",
        //         orderType: window.orderType,
        //         comment: this.comment
        //     };
        //     // eslint-disable-next-line no-unused-vars
        //     var callbackSuccess= function(result, textStatus, jqXHR){       
        //         if(result.exception !== undefined)
        //         {
        //             vueInstance.isLoading = false;                    
        //             return;
        //         }
        //         if(result.requiresLogin == true)
        //         {    
        //             vueInstance.isLoading = false;
        //             return;
        //         }
        //         window.shoppingCart = result.basketTile.basketItems;
        //         vueInstance.isLoading = false;
        //     }
        //     // eslint-disable-next-line no-unused-vars
        //     var callbackError = function(result, textStatus, jqXHR){
        //         vueInstance.isLoading = false;
        //     }
        //     vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);
        // },
        weightAlert(){
            this.$alert(this.$t('weightArticleQuantityChangeAlert'))
        },
        deleteItem(article) {   
            var config = window.config;
            var vueInstance = this;
            vueInstance.isLoading = true;
            var url = config.API_BASE + 'TCPRemoveMenuItemFromCartJ.action';     
            var elementCartId = 0;
            vueInstance.isLoading = true;

            elementCartId = article.id;
            // if(window.shoppingCart != undefined)
            // {
            //     for(var i = 0; i < window.shoppingCart.length; i++)
            //     {               
            //         if(window.shoppingCart[i].prodId == article.prodId)
            //         {
            //             elementCartId = window.shoppingCart[i].id;
            //         }
            //     }
            // }      

            var params = {
                id: elementCartId,
                orderType: window.orderType,
            };

            // eslint-disable-next-line no-unused-vars
            var callbackSuccess= function(result, textStatus, jqXHR) {       
                vueInstance.isLoading = false;
                if(result.exception !== undefined)
                {
                    vueInstance.$alert(vueInstance.$t('removeItemFailed'));
                    return;
                } 
                
                vueInstance.updateCart();
                window.shoppingCart = result.basketTile.basketItems;
                if(result.basketTile.paymentsList != undefined){
                        window.subsidy = vueInstance.formatCurrency(result.basketTile.paymentsList[0].paymentAmount);
                }else{
                    window.subsidy = "";
                }
                if(result.basketTile.basketItems.length < 1)
                {
                    vueInstance.$router.push({ path: '/Menu' });
                    return;
                }

                vueInstance.basketTotal = result.basketTile.formattedTotal;
                if(result.basketTile.discounts != undefined)
                {
                    vueInstance.discounts = result.basketTile.discounts;
                    // for(var i = 0; i < result.basketTile.pointCampaigns.length; i++)
                    // {
                    //     vueInstance.pointCampaigns
                    // }
                    // vueInstance.pointsGained = result.basketTile.pointCampaigns.gainedPoints;
                    // vueInstance.pointsBurned = result.basketTile.pointCampaigns.usedPoints;
                }
                else
                {
                    vueInstance.discounts = [];
                }

                if (result.basketTile.paymentsList != undefined) 
                {
                    vueInstance.paymentLists = result.basketTile.paymentsList;
                }
                else
                {
                    vueInstance.paymentLists = [];
                }

                vueInstance.$forceUpdate();
                vueInstance.dataCounter++;                
            }

            // eslint-disable-next-line no-unused-vars
            var callbackError = function(result, textStatus, jqXHR) {     
                vueInstance.isLoading = false;           
            }
        
            vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);
        },
        goBack(){
            this.$router.go(-1);
        },
        addArticle(article) {
               var config = window.config;
            var vueInstance = this;
            vueInstance.isLoading = true;
            var url = config.API_BASE + 'TCPEditCartSubmitJ.action'; 
            var elementCartId = 0;
            var elementQuantity = 0;
     
            elementCartId = article.id
            elementQuantity = article.quantity;
            // if(window.shoppingCart != undefined)
            // {
            //     for(var i = 0; i < window.shoppingCart.length; i++)
            //     {
            //         if(window.shoppingCart[i].prodId == article.prodId)
            //         {
            //             elementCartId = window.shoppingCart[i].id;
            //             elementQuantity = window.shoppingCart[i].quantity;
            //         }
            //     }
            // }

            var params = {
                basketId: elementCartId,
                quantity: elementQuantity+1,
                orderType: window.orderType
            };

            
            if(window.xtoken != undefined)
            {
                params.xsrfToken = btoa(window.xtoken);
            }

            if(params.quantity == 0)
            {
                vueInstance.deleteItem(article);
                return;
            }

            // eslint-disable-next-line no-unused-vars
            var callbackSuccess= function(result, textStatus, jqXHR) {    
                vueInstance.isLoading = false;   
                
                window.shoppingCart = result.basketTile.basketItems;     
                
                if(result.basketTile.paymentsList != undefined){
                    window.subsidy = vueInstance.formatCurrency(result.basketTile.paymentsList[0].paymentAmount);
                }else{
                    window.subsidy = "";
                }

                if(result.basketTile.basketItems.length < 1)
                {
                    vueInstance.$router.push({ path: '/Menu' });
                    return;
                }

                if(result.basketTile.discounts != undefined)
                {
                    vueInstance.discounts = result.basketTile.discounts;
                    // for(var i = 0; i < result.basketTile.pointCampaigns.length; i++)
                    // {
                    //     vueInstance.pointCampaigns
                    // }
                    // vueInstance.pointsGained = result.basketTile.pointCampaigns.gainedPoints;
                    // vueInstance.pointsBurned = result.basketTile.pointCampaigns.usedPoints;
                }
                else
                {
                    vueInstance.discounts = [];
                }

                if (result.basketTile.paymentsList != undefined) 
                {
                    vueInstance.paymentLists = result.basketTile.paymentsList;
                }
                else
                {
                    vueInstance.paymentLists = [];
                }

                vueInstance.$forceUpdate();
                vueInstance.updateCart();
                vueInstance.dataCounter++;         
                
                if(result.exception !== undefined)
                {
                    if(result.exception.code === 64){
                        vueInstance.$alert(vueInstance.$t('removeItemMinError'));
                        return;
                    }else if(result.exception.code === 65){
                        vueInstance.$alert(vueInstance.$t('removeItemMaxError'));
                        return;
                    }
                    vueInstance.$alert(vueInstance.$t('removeItemFailed'));
                    return;
                } 
            }

            // eslint-disable-next-line no-unused-vars
            var callbackError = function(result, textStatus, jqXHR) {    
                vueInstance.isLoading = false;            
            }
        
            vueInstance.makeRequest("POST", url, JSON.stringify(params), callbackSuccess, callbackError);
            // var config = window.config;
            // var vueInstance = this;
            // vueInstance.isLoading = true;
            // var url = config.API_BASE + 'TCPAddMenuItemToCartJ.action';

            // var params = {
            //     productId: article.prodId,                
            //     json: "true",
            //     orderType:  window.orderType,
            //     quantity: 1
            // };

            // // eslint-disable-next-line no-unused-vars
            // var callbackSuccess= function(result, textStatus, jqXHR) {       
            //     if(result.exception !== undefined)
            //     {
            //         vueInstance.$alert("Add item failed: ");
            //         return;
            //     } 
            //     vueInstance.isLoading = false;
            //     vueInstance.basketItems = result.basketTile.basketItems;
            //     vueInstance.basketTotal = result.basketTile.formattedTotal;   
            //     vueInstance.$forceUpdate();
            //     vueInstance.dataCounter++;
            // }

            // // eslint-disable-next-line no-unused-vars
            // var callbackError = function(result, textStatus, jqXHR) {    
            //     vueInstance.isLoading = false;  
            // }
        
            // vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);
        },
        updateCart(){
            var config = window.config;
            var vueInstance = this;
            vueInstance.isLoading = true;
            var url = config.API_BASE + 'TCPConfirmOrderJ.action';   
            var params = {};
            if(window.customerLoggedIn)
            {
                params = {
                    calculateOrder: "true",
                    orderType: window.orderType,
                    promotionBarcode: vueInstance.promoBarcode != undefined ? vueInstance.promoBarcode : ""
                };
            }  
             
            // eslint-disable-next-line no-unused-vars
            var callbackSuccess= function(result, textStatus, jqXHR) {
                vueInstance.isLoading = false;

                if(result.xsrfToken != undefined)
                {
                    window.xtoken = atob(result.xsrfToken);
                }
                 
                if(result.exception !== undefined)
                {
                    vueInstance.$alert(this.$t("exceptionMessage"));
                    return;
                }
                
                if(result.errorCode > 0)
                {
                    vueInstance.$alert(result.erroMsg);
                    return;
                }

                window.shoppingCart = result.basketTile.basketItems;
                if(result.basketTile.paymentsList != undefined){
                    window.subsidy = vueInstance.formatCurrency(result.basketTile.paymentsList[0].paymentAmount);
                }else{
                    window.subsidy = "";
                }        
                vueInstance.basketItems = result.basketTile.basketItems;
                vueInstance.basketTotal = result.basketTile.formattedTotal;    
            }
            // eslint-disable-next-line no-unused-vars
            var callbackError = function(result, textStatus, jqXHR){
                vueInstance.isLoading = false;
            }
        
            vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);
        },
        removeArticle(article) {            
            var config = window.config;
            var vueInstance = this;
            vueInstance.isLoading = true;
            var url = config.API_BASE + 'TCPEditCartSubmitJ.action'; 
            var elementCartId = 0;
            var elementQuantity = 0;
     
            elementCartId = article.id
            elementQuantity = article.quantity;
            // if(window.shoppingCart != undefined)
            // {
            //     for(var i = 0; i < window.shoppingCart.length; i++)
            //     {
            //         if(window.shoppingCart[i].prodId == article.prodId)
            //         {
            //             elementCartId = window.shoppingCart[i].id;
            //             elementQuantity = window.shoppingCart[i].quantity;
            //         }
            //     }
            // }

            var params = {
                basketId: elementCartId,
                quantity: elementQuantity-1,
                orderType: window.orderType
            };

            
            if(window.xtoken != undefined)
            {
                params.xsrfToken = btoa(window.xtoken);
            }

            if(params.quantity == 0)
            {
                vueInstance.deleteItem(article);
                return;
            }

            // eslint-disable-next-line no-unused-vars
            var callbackSuccess= function(result, textStatus, jqXHR) {     
                vueInstance.isLoading = false;  
                
                window.shoppingCart = result.basketTile.basketItems;    
                if(result.basketTile.paymentsList != undefined){
                    window.subsidy = vueInstance.formatCurrency(result.basketTile.paymentsList[0].paymentAmount);
                }else{
                    window.subsidy = "";
                }
                if(result.basketTile.basketItems.length < 1)
                {
                    vueInstance.$router.push({ path: '/Menu' });
                    return;
                }      

                if(result.basketTile.discounts != undefined)
                {
                    vueInstance.discounts = result.basketTile.discounts;
                    // for(var i = 0; i < result.basketTile.pointCampaigns.length; i++)
                    // {
                    //     vueInstance.pointCampaigns
                    // }
                    // vueInstance.pointsGained = result.basketTile.pointCampaigns.gainedPoints;
                    // vueInstance.pointsBurned = result.basketTile.pointCampaigns.usedPoints;
                }
                else
                {
                    vueInstance.discounts = [];
                }
                      
                if (result.basketTile.paymentsList != undefined) 
                {
                    vueInstance.paymentLists = result.basketTile.paymentsList;
                }
                else
                {
                    vueInstance.paymentLists = [];
                }

                vueInstance.$forceUpdate();
                vueInstance.updateCart();
                vueInstance.dataCounter++;

                if(result.exception !== undefined)
                {
                    if(result.exception.code === 64){
                        vueInstance.$alert(vueInstance.$t('removeItemMinError'));
                        return;
                    }else if(result.exception.code === 65){
                        vueInstance.$alert(vueInstance.$t('removeItemMaxError'));
                        return;
                    }
                    vueInstance.$alert(vueInstance.$t('removeItemFailed'));
                    return;
                }                 
            }

            // eslint-disable-next-line no-unused-vars
            var callbackError = function(result, textStatus, jqXHR) {    
                vueInstance.isLoading = false;            
            }
        
            vueInstance.makeRequest("POST", url, JSON.stringify(params), callbackSuccess, callbackError);
        },
        addTip(){
            // Only for testing
            var config = window.config;
            var vueInstance = this;
            var url = config.API_BASE + 'TCPAddTipJ.action';

            // var tip = $('#tipField').val();
            var tip = 11;
            console.log("Tip");
            console.log(tip);

            var params = {
                    json: "true",
                    tip: tip,
            };

            if(window.customerLoggedIn)
            {
                params.calculateOrder = "true";
            }

            if(window.xtoken != undefined)
            {
                params.xsrfToken = btoa(window.xtoken);
            }
                        
            // eslint-disable-next-line no-unused-vars
            var callbackSuccess= function(result, textStatus, jqXHR) {       
                if( (result.exception !== undefined) || (result.item !== undefined) )
                {             
                    vueInstance.$alert(vueInstance.$t('getItemFailed'));
                    return;
                }                 

                console.log(result);
                vueInstance.updateCart();
                // vueInstance.addItemsToOrder();

                // $('#tipField').val('');
                // vueInstance.orderDetails = result;
            }

            // eslint-disable-next-line no-unused-vars
            var callbackError = function(result, textStatus, jqXHR) {   

            }
            
            vueInstance.makeRequest("POST", url, JSON.stringify(params), callbackSuccess, callbackError);
        },
        onSubmit() {
            if(this.config.SHOW_ORDERCONFIRMATION_TC_CONSENT == true)            
            {
                if(this.termsAndConditionsFlag == 0)
                {
                    this.$alert(this.$t("tcConsentMessage"));
                    return;
                }
            }

            if(window.ageVerification == true)            
            {
                if(this.ageVerificationFlag == 0)
                {
                    this.$alert(this.$t("ageConsentMessage"));
                    return;
                }
            }

            window.comment = this.comment;
            this.addItemsToOrder();

            var path = "";
            if(window.TableMode == true)
            {
                if(window.openTable != undefined)
                    path = '/TablePay'
                else
                    path = '/Payment'
            }
            else
            {
                switch(window.orderType)
                {
                    case 'delivery':{
                        path = '/Address'
                        break;
                    }
                    case 'takeaway':{
                        path = '/Payment'
                        break;
                    }
                    default: {
                        path = '/Payment'
                        break;
                    }

                }
            }
            
            this.$router.push({ path: path });     
        }  
    }
}
</script>

<style scoped>
.gdprCheckBox{
    margin: 20px 50px 20px 50px;
    width:100%;
    display:inline-block;
}

.shoppingCartWrapper{
    color:black;    
}

.shoppingCartLine{
    /* color:black; */
    text-align:right;
    border-bottom: 0.5px solid var(--main-color);
    font-weight:400;
}

.shoppingCartHeader {
    width:20%;
    color:black;
    background-color: lightgray; 
    text-align:right;
    /* border-bottom: 2px solid orange; */
}

.shoppingCartHeaderSmall {
    width:10%;
    color:black;
    background-color: lightgray; 
    text-align:right;
    /* border-bottom: 2px solid orange; */
}

.shoppingCartHeaderBig {
    width:100%;
    color:black;
    background-color: lightgray; 
    text-align:right;
    /* border-bottom: 2px solid orange; */
}

/* @media screen and (max-width: 900px) {
    .shoppingCartHeaderBig{
        width:100%;
    }
} */

.shoppingCartItemsTable {
    color: var(--text-color);
    width:100%;
    background-color:#f2f2f2;
}

.navBackIcon {
    height:50px;
    float:left;
    width:50px;
}

.navBack{
    color:var(--main-color);
}

.commentField {
    width:100%;
    height:100%;
    border: 0.2px solid var(--main-color);
}


.shoppingCartDiscounts {
    border-bottom: 1px solid rgba(236,236,236,1);
    /* border-top: 1px solid rgba(236,236,236,1); */
    padding:20px;
    text-align:left;    
}

.shoppingCartDiscountsEntry {
    text-align:left;    
    padding-left:5%;
}

.shoppingCartDiscountsTitle {
    padding: 0px;
    text-align:left;
    margin-bottom:10px;
    font-weight:600;
    font-size:15px;
    color: var(--main-color);
}

.shoppingCartDiscountsEntryName{
    width:40%;
    display: "inline-block"
}

.shoppingCartDiscountsEntryValue{
    margin-left:10%;
}

</style>