<template>
    <div class="articleDetails">
        <div class="col-12">
            <div class="articleDetailsImage"
                :style="'background-image:url(' + article.image1 + ');'">                
            </div>

            <div v-if='errorMessage != ""'>{{errorMessage}}</div>
        
            <div v-for="lineItem in article.items" :key="lineItem.menuId" @click.stop>
                {{lineItem.name}}
                <div>
                    <check-box @change="onCheckSelect" 
                        v-for="subItem in lineItem.items" :key="subItem.lineId" 
                        :label="subItem.name" 
                        :value="{ articleId: subItem.articleId, lineId: lineItem.lineId }" 
                        v-model="selectedValues">
                    </check-box>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Checkbox from '../../components/sub-components/CheckBox.vue'

export default {
    data() {
        return {
            selectedValues: [],
            errorMessage : ""
        }
    },
    name: 'article-details',
    components:{
        'check-box': Checkbox
    },
    props: {
        article: {
            type: Object,
            required: true
        },
        callbackIsValid:{
            Type: Function
        }
    },
    methods:{
          onCheckSelect(){
            this.errorMessage="";
            var menuLines = this.article.items;

            for(var i = 0; i < menuLines.length; i++)
            {                
                var min = menuLines[i].minQty;
                var max = menuLines[i].maxQty;
                var lineId = menuLines[i].lineId;           
                var lineValuesSelected = this.selectedValues.filter(function(x) { return x.lineId == lineId });

                if(lineValuesSelected.length == 0 && min > 0 )
                {                    
                    this.errorMessage="Not all mandatory extras selected";
                    this.callbackIsValid(false);
                    return;
                }

                if(lineValuesSelected.length > max )
                {                          
                    this.callbackIsValid(false);
                    //selectedValues.splice(selectedValues.findIndex(e => e.lineId === lineId),1);                            
                    this.errorMessage="Too much items selected";
                    return;
                }  
            }

            this.callbackIsValid(true);
        },
    }
}
</script>