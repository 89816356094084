<script src="https://gist.github.com/Jonarod/7ff2fe4f81aae39e431aa7a08ce815bc.js"></script>

<template>
  <label class="flex items-center wrapper " >
    {{$t(label)}}
    <input class="checkbox" type="checkbox" @checked="isChecked" :value="value" @change="updateInputSimple" :ident="value + ((subCode === 'undefined' || subCode === undefined) ? '' : subCode)"/>
    <span class="checkmark" v-html="checkedMarker"></span>
  </label>
</template>

<script>
import $ from 'jquery'
export default {
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    "value": { type: String },
    "modelValue": { default: "" },
    "label": { type: String, required: true},
    "subCode": { type: String },
  },
  computed: {
    // isChecked() {
    //   var valueOfThis = this.value;
    //   if(this.subCode != undefined)
    //   {
    //     valueOfThis += "~~";
    //     valueOfThis += this.subCode;
    //   }

    //   return this.modelValue === valueOfThis;
    // },
    isChecked() {
      if (this.modelValue instanceof Array) {
        return this.modelValue.includes(this.value)
      }
      // Note that `true-value` and `false-value` are camelCase in the JS
      return this.modelValue === this.trueValue
    }, 
    checkedMarker() {
      return window.config.CHECKBOX_INNER_SYMBOL;
    }
  },
  methods: {
    updateInput() {   
      this.$emit('change', { value: this.value == "Saferpay" ? "Saferpay~~" + this.subCode : this.value, subCode: this.subCode } );   
    },
    updateInputSimple(event) {
      // JP 20230911: Unchecking should not trigger another payment
      let isChecked = event.target.checked; 
      if (isChecked) {
        this.$emit('change', { value: this.value == "Saferpay" ? "Saferpay~~" + this.subCode : this.value, subCode: this.subCode } );      
      }else{
        this.$emit('hide');
      }
      // let isChecked = event.target.checked;  
      // console.log(this.modelValue);    
      // if (this.modelValue instanceof Array) {
      //   let newValue = [...this.modelValue]
      //   if (isChecked) {
      //     newValue.push(this.value)
      //   } else {
      //     newValue.splice(newValue.indexOf(this.value), 1)
      //   }
      //   // console.log("emit1");
      //   // this.$emit('change', { value: this.value == "Saferpay" ? "Saferpay~~" + this.subCode : this.value, subCode: this.subCode } );
      //   // updateInput();
      //   // this.$emit('change', newValue)
      // } 
      // else {
      //   // console.log("emit2");
      //   // this.$emit('change', isChecked ? this.trueValue : this.falseValue)
      // }
    },
  }
}
</script>

<style lang="postcss" scoped>
/* Customize the label (the wrapper)
.wrapper {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  padding-right: 35px;
  margin-bottom: 6px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 16px;
}


.wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}


.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 21px;
  width: 21px;
  border-radius: 2px;
  background-color: var(--background-color);
  border: 0.5px solid darkgray;
  color: transparent;
  font-size: 20px;
  line-height: 0.9;
}

.wrapper:hover input ~ .checkmark {
  color: white;
  background-color: var(--main-color);
}

.wrapper input ~ .checkmark {
  color: transparent;
  background-color: var(--background-color);
}

.wrapper input:checked ~ .checkmark {  
  background-color: white;
  cursor: pointer;
  color:var(--main-color);
}
@media screen and (max-width:800px) {
  .wrapper:hover input ~ .checkmark {
    color: transparent;
    background-color: var(--background-color);
    color:var(--main-color);
  }  
}


.checkmark:after {
  
  position: absolute;
  display: none;
}


.wrapper input:checked ~ .checkmark:after {
  display: block;
}


.wrapper .checkmark:after {
  left: 7px;
  top: 0px;
  width: 7px;
  height: 15px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.btn-success{
  display:none;
} */


/* Customize the label (the wrapper) */
.wrapper {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  padding-right: 35px;
  margin-bottom: 6px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 16px;
  max-width:500px;
  width:250px;
}
/* Hide the browser's default checkbox */
.wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 21px;
  width: 21px;
  border-radius: 2px;
  background-color: var(--background-color);
  border: 0.5px solid darkgray;
  color: transparent;
  font-size: 20px;
  line-height: 0.9;
}

/* On mouse-over, add a grey background color */
.wrapper:hover input ~ .checkmark {
  color: white;
  background-color: var(--main-color);
}
@media screen and (max-width:800px) {
  .wrapper:hover input ~ .checkmark {
    color: transparent;
    background-color: var(--background-color);
  }  
}
/* On mouse-over, add a grey background color */
.wrapper input ~ .checkmark {
  color: transparent;
  background-color: var(--background-color);
}
/* When the checkbox is checked, add a blue background */
.wrapper input:checked ~ .checkmark {  
  background-color: white;
  cursor: pointer;
  color:var(--main-color);
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  /* content: ""; */
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.wrapper input:checked ~ .checkmark:after {
  display: block;
}
/* Style the checkmark/indicator */
.wrapper .checkmark:after {
  left: 7px;
  top: 0px;
  width: 7px;
  height: 15px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.btn-success{
  display:none;
}
.wrapper input[type=checkbox]+label::after {
  content: "\2713";
  /* color: #000; */
  text-align: center;
  opacity: 0;
  position: absolute;
  left: 2px;
  transform: rotate(45deg);
  font-weight: bold;
}
.wrapper input[type=checkbox]:checked+label {
  transform: rotate(-45deg);
  /* Testing purpose */
  cursor: pointer;
  /* doesnt work like supposed to */
}
</style>
