<template>

  <div id="app"  class="col-12">
    
    <div v-show="appIsIdle" @click="refreshPage" style="position:fixed;width:100%; height:100%;top:0;left:0;right:0;bottom:0; background:rgba(108, 122, 137, 0.8) ; color:var(--main-color-highlight); z-index:99999999999999999999999999999999999999999999999999999;">  
      <div style="padding-top:15vh;">
        {{$t('sessionTerminated')}}
      </div>
      <img class="appIdleIcon" src="@/assets/images/excl-point.png" />
    </div>

    <div v-bind:class="{ appIsIdle: appIsIdle}">
       
    <div style="position: fixed; z-index: 99999; left:2%" ><a class="sr-only sr-only-focusable bg-dark text-light" href="#main">Skip to main content</a></div>

    <div id="ControlGroupWrapperFixed" class="fixed-div fixed-right" v-show="config.SHOW_GLOBAL_CONTROL_GROUP">
      <control-group-fixed :config="config" :changeLocale="changeLocale" :itemsQuantity="itemsQuantity"></control-group-fixed>
    </div>   

    <header class="noMargin fixed-div header" role="banner">
      <global-header  v-show="config.SHOW_GLOBAL_HEADER" :menus="getMenus" :config="config" :changeLocale="changeLocale"> </global-header>
    </header>
    <main role="main" id="main">
      <div class="oneWayScroll content col-12" id="contentSection">
          
        <global-left v-show="config.SHOW_GLOBAL_LEFT" :config="config" :menus="getMenus" class="col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 col-2" id="global-left-wrapper"></global-left> 
        <!-- 
          ID=MobileRight -> When running on mobile hardware, the section to the right of the content must come before the content section because responsivity
          is achieved by stacking columns one under another. 
          If we allow that and dont switch to the Section with ID=MobileRight, then the section to the right (ID=DesktopRight) gets pushed to the end of the page 
        -->
        <global-right v-show="config.SHOW_GLOBAL_RIGHT" :config="config" :menus="getMenus" id="MobileRight" class="col-2 col-md-2"></global-right>

        <!--
          This is where pages get rendered, managed by the vue-router module
        -->

        <router-view id="content-wrapper" class="oneWayScroll col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">        
        </router-view>    
          <!--
            ID=DesktopRight -> When running on mobile hardware, the section to the right of the content must come before the content section because responsivity
            is achieved by stacking columns one under another. 
            If we allow that and dont switch to the Section with ID=MobileRight, then the section to the right (ID=DesktopRight) gets pushed to the end of the page 
          -->
          <!-- <global-right v-show="config.SHOW_GLOBAL_RIGHT" :config="config" :menus="getMenus" id="DesktopRight" class="col-12 col-md-2 col-lg-custom-right"></global-right> -->

        </div>
      </main>
      <footer id="footer" role="contentinfo" class="noMargin col-12" style="padding:0;margin:0;">
        <global-footer v-if="config.SHOW_GLOBAL_FOOTER" :footerLinks="config.FOOTER_LINKS" :config="config" :changeLocale="changeLocale"></global-footer>
      </footer>  
    </div>

    <!-- Modal definition example --> 
    <modal ref="exampleMODAL">
        <template v-slot:header>
            <h1>Modal title</h1>
        </template>

        <template v-slot:body>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nunc sed velit dignissim sodales ut eu sem integer vitae. Id aliquet lectus proin nibh nisl condimentum. Fringilla urna porttitor rhoncus dolor purus. Nam aliquam sem et tortor. Nisl vel pretium lectus quam id. Cras pulvinar mattis nunc sed. Quis ipsum suspendisse ultrices gravida dictum fusce ut placerat orci. Tristique magna sit amet purus. Fermentum et sollicitudin ac orci phasellus egestas tellus. Erat pellentesque adipiscing commodo elit at imperdiet dui accumsan. Felis eget nunc lobortis mattis aliquam faucibus. Tincidunt eget nullam non nisi est sit amet facilisis. Mi in nulla posuere sollicitudin aliquam ultrices sagittis orci. Vitae proin sagittis nisl rhoncus mattis rhoncus urna neque. Eget nunc scelerisque viverra mauris in aliquam sem fringilla ut. Nec nam aliquam sem et tortor consequat id. Commodo nulla facilisi nullam vehicula ipsum a. Elementum tempus egestas sed sed. Faucibus purus in massa tempor nec feugiat nisl pretium fusce.</p>
            <p>Arcu cursus vitae congue mauris rhoncus aenean. Tempor id eu nisl nunc mi. Pharetra diam sit amet nisl suscipit adipiscing bibendum. Ut faucibus pulvinar elementum integer enim. Odio facilisis mauris sit amet massa vitae tortor condimentum lacinia. Eu non diam phasellus vestibulum lorem sed risus. Porttitor rhoncus dolor purus non enim praesent. Sit amet mauris commodo quis imperdiet. Lobortis feugiat vivamus at augue eget. Nibh tellus molestie nunc non blandit. Tellus mauris a diam maecenas sed enim ut. Tortor aliquam nulla facilisi cras fermentum odio eu feugiat pretium. Mattis aliquam faucibus purus in massa.</p>
        </template>

        <template v-slot:footer>
            <div class="d-flex align-items-center justify-content-between">
            <button class="btn btn--secondary" @click="$refs.modalName.closeModal()">Cancel</button>
            <button class="btn btn--primary" @click="$refs.modalName.closeModal()">Save</button>
            </div>
        </template>
    </modal>
  

  </div>       
</template>

<script>  
  import Modal from './components/sub-components/Modal.vue'
  import GlobalHeader from './components/layout-components/GlobalHeader.vue'
  import GlobalFooter from './components/layout-components/GlobalFooter.vue'
  import GlobalLeft from './components/layout-components/GlobalLeft.vue'
  import GlobalRight from './components/layout-components/GlobalRight.vue'
  import ControlGroupFixed from './components/main-components/ControlGroupFixed.vue'
  import Vue from 'vue'   
  import $ from 'jquery'
  import './css/app.scss'
  import BootstrapVue from 'bootstrap-vue'
  import axios from 'axios'
  import 'vue-loading-overlay/dist/vue-loading.css';

  Vue.use(BootstrapVue);

  export default {
    name: 'App',
    components: {
      'global-header' : GlobalHeader,
      'global-footer' : GlobalFooter,
      'global-left'   : GlobalLeft,
      'global-right'  : GlobalRight,
      'control-group-fixed' : ControlGroupFixed,
      'modal': Modal
    },

  destroyed () {
      document.getElementById("app").removeEventListener('scroll', this.handleScroll);
  },

  methods: {
    refreshPage() {
      document.location.reload();
    },
    handleWheel(event) {

      if (event.originalEvent.deltaY < 0)
      {
        this.maximizeHeader();
      }
      else if (event.originalEvent.deltaY > 0)
      {
        this.minimizeHeader();
      }    
    },
    makeRequest: function (type, url, data, callbackSuccess, callbackError, dataType) {
      if(type == "POST")
      {
        axios.post(url, JSON.parse(data))
        .then(function (response) {
            console.log(response);
            callbackSuccess(response.data,response,response);
        })
        .catch(function (error) {
            callbackError(error,error,error);
        });
      }
      else
      {
        $.ajax({
          method : "GET",
          type : "GET",
          timeout : '20000',
          headers: {
            "Access-Control-Allow-Origin":"*",
          },
          scriptCharset : "utf-8",
          contentType : "application/json; charset=utf-8",        
          url : url,
          data : "kkj=kkj&" + encodeURIComponent(data),
          // context : callback,
          success : callbackSuccess,
          error: callbackError,
          dataType : dataType != undefined ? dataType :'jsonp'
        });
      }


        window.REQUEST_TIMESTAMP = new Date().getTime();
    },
    handleWheelContent() {
      console.log(event.originalEvent.deltaY);
      if (event.originalEvent.deltaY < 0 && $("#content-wrapper").scrollTop() == 0)
      {       
        this.maximizeHeader();         
      }
      else if (event.originalEvent.deltaY > 0)
      {
        if($(".header").hasClass("header-big"))
        {
          var myDiv = document.getElementById('content-wrapper');
          
          myDiv.scrollTop = 0;
          this.minimizeHeader();
          // setTimeout(() => {
    
          // }, 30);
        }  
      }    
    },    
    maximizeHeader() {
      var config = window.config;
      if(window.location.href.includes('/Shop') && config.SHOW_GLOBAL_HEADER_BANNER_IMAGE)
      {
        $(".header").addClass("header-big");
        $(".header").addClass("animTransition");
        $(".content").addClass("header-big-content-margin");
        $(".content").removeClass("header-small-content-margin");  
      }
      
    },
    minimizeHeader() {
      $(".header").removeClass("header-big");
      $(".header").addClass("animTransition");
      $(".content").removeClass("header-big-content-margin");
      $(".content").addClass("header-small-content-margin");
    },
    handleScroll () {     
      if( $("#app").scrollTop() == 0) {
        this.maximizeHeader();     
      }
      else {
        this.minimizeHeader();
      }
    
      // Any code to be executed when the window is scrolled
    },
    handleScrollContent () {
      if( $("#content-wrapper").scrollTop() == 0) {
        this.maximizeHeader();     
      }
      else {
        this.minimizeHeader();
      }
    
      // Any code to be executed when the window is scrolled
    },
    updateDietaryList(){
            var vueInstance = this;
            var config = window.config;
            var parms = {
                "json": "true"
            };

            var url = config.API_BASE + 'TCPGetDietaryInfoTypesJ.action';

            // eslint-disable-next-line no-unused-vars
            var callbackSuccess = function(result, textStatus, jqXHR) {
                window.allergens = result.allergens;
                window.nutriValues = result.nutriValues;
                window.dietaryProperties = result.properties;
            }

            // eslint-disable-next-line no-unused-vars
            var callbackError = function(){
                //vueInstance.$router.push({ path: config.AFTER_SHOP_PAGE });
            }

            vueInstance.makeRequest("GET", url, JSON.stringify(parms), callbackSuccess, callbackError);
    },  
    changeLocale(locale) {    
        this.$i18n.locale = locale;       
          var vueInstance = this;
          var config = window.config;
          var parms = {
              appLocale: locale,
              "json": "true"
          };

          var url = config.API_BASE + 'TCPSetLocaleJ.action';

          // eslint-disable-next-line no-unused-vars
          var callbackSuccess = function(data, textStatus, request) {
              console.log("language Change successfull " + locale);
              window.locale = locale;
              document.documentElement.lang = config.LANGUAGE_LIST.find(lang => lang.value === locale).tag;
              if(window.timeString != null && window.timeString != undefined)
                window.timeString = window.timeString + ' ';
              //vueInstance.$router.push({ path: config.AFTER_SHOP_PAGE });     
              vueInstance.updateDietaryList();                        
          }

          // eslint-disable-next-line no-unused-vars
          var callbackError = function(){
              //vueInstance.$router.push({ path: config.AFTER_SHOP_PAGE });
          }

          // let loader = this.$loading.show({
          //     loader: 'dots'
          // });            

          vueInstance.makeRequest("GET", url, JSON.stringify(parms), callbackSuccess, callbackError);
      },  
  },
    computed: {     
      config: function() {
        return window.config;
      },
      getMenus: function() {
        return this.config.MENU_CONTENT;
      },
      itemsQuantity() {        
        if(window.shoppingCart !== undefined)
        {                      
            return window.shoppingCart.length;
        }            
        else
        {               
            return 0;
        }
      }
    },
    data() {
      return {
        mobileView: true,
        showNav: false,
        appIsIdle: false,
        intervalId: 0,
        appIsIdleBlur: "appIdleBlur"
      }      
    },
    // created() {
    //   window.baseTitle = document.title;
    //   // Check cookies:
    //       if(window.config.COOKIES_ENABLED){
    //         if(this.$cookies.isKey('TableMode')){
    //             window.TableMode = this.$cookies.get('TableMode');
    //             if(window.TableMode){
    //               Object.assign(window.config, window.configTablemode);
    //             }
    //         } else {
    //             // window.TableMode = false;
    //         }
    //         if(this.$cookies.isKey('tableId')){
    //             window.tableId = this.$cookies.get('tableId');                
    //             window.tableModeTable = this.$cookies.get('tableId');
    //         } else {
    //            // RETURN TO TABLE LANDING?
    //         }
    //         if(this.$cookies.isKey('shopId')){
    //             window.shopId = this.$cookies.get('shopId');
    //         }
    //         // if(this.$cookies.isKey('shopObj')){
    //         //     window.shopObj = this.$cookies.get('shopObj');
    //         // }
    //         if(this.$cookies.isKey('orderType')){
    //             window.orderType = this.$cookies.get('orderType');
    //         }
    //       }  
    // },
    mounted() {
      var vueInstance = this;
      var config = window.config;

      //Version Number:
      console.log("2.0.45");

      if(window.locale == undefined || window.locale == null){
          this.$i18n.locale = config.LANGUAGE_LIST[0].value;    
          // Check cookies:
          if(window.config.COOKIES_ENABLED){
            if(this.$cookies.isKey('TableMode')){
                if (this.$cookies.get('TableMode') == true || this.$cookies.get('TableMode').toUpperCase() === "TRUE") {
                  window.TableMode = this.$cookies.get('TableMode').toUpperCase() === "TRUE";
                }
                if(window.TableMode){
                  Object.assign(window.config, window.configTablemode);
                }
            } else {
                // window.TableMode = false;
            }
            if(this.$cookies.isKey('tableId')){
                window.tableId = this.$cookies.get('tableId');                
                window.tableModeTable = this.$cookies.get('tableId');
            } else {
               // RETURN TO TABLE LANDING?
            }
            if(this.$cookies.isKey('shopId')){
                window.shopId = this.$cookies.get('shopId');
            }
            // if(this.$cookies.isKey('shopObj')){
            //     window.shopObj = this.$cookies.get('shopObj');
            // }
            if(this.$cookies.isKey('orderType')){
                window.orderType = this.$cookies.get('orderType');
            }
          }  

          var parms = {
              appLocale: config.LANGUAGE_LIST[0].value,
              "json": "true"
          };

          var url = config.API_BASE + 'TCPSetLocaleJ.action';

          // eslint-disable-next-line no-unused-vars
          var callbackSuccess = function(data, textStatus, request) {
              console.log("language Change successfull " + config.LANGUAGE_LIST[0].value);
              window.locale = config.LANGUAGE_LIST[0].value;
              document.documentElement.lang = config.LANGUAGE_LIST[0].tag;
              if(window.timeString != null && window.timeString != undefined)
                window.timeString = window.timeString + ' ';
              //vueInstance.$router.push({ path: config.AFTER_SHOP_PAGE });
              vueInstance.updateDietaryList();                        
          }

          // eslint-disable-next-line no-unused-vars
          var callbackError = function(){
              //vueInstance.$router.push({ path: config.AFTER_SHOP_PAGE });
          }

          // let loader = this.$loading.show({
          //     loader: 'dots'
          // });            

          vueInstance.makeRequest("GET", url, JSON.stringify(parms), callbackSuccess, callbackError);
      }

      vueInstance.intervalId = setInterval(() => {  
          var cookiesEnabled = true;
          var cookieEnabled = navigator.cookieEnabled;

          // When cookieEnabled flag is present and false then cookies are disabled.
          if (cookieEnabled === false) {
              cookiesEnabled = false;
          }

          // try to set a test cookie if we can't see any cookies and we're using 
          // either a browser that doesn't support navigator.cookieEnabled
          // or IE (which always returns true for navigator.cookieEnabled)
          if (!document.cookie && (cookieEnabled === null || /*@cc_on!@*/false))
          {
              document.cookie = "testcookie=1";

              if (!document.cookie) {
                  cookiesEnabled = false;
              } else {
                  document.cookie = "testcookie=; expires=" + new Date(0).toUTCString();
              }
          }          

          console.log(cookiesEnabled);
          
          if(window.REQUEST_TIMESTAMP == undefined)
          {
            // console.log("Could not find request timestamp");
            console.log("Could not find request timestamp, session stopped!");
            vueInstance.appIsIdle = true;
            return;

                // var url = config.API_BASE + 'TCPAfterLoginJ.action';  
                // var params = {
                //     json: "true"
                // };

                // // eslint-disable-next-line no-unused-vars
                // var callbackSuccess = function(result, textStatus, jqXHR) {       
  
                // }

                //     // eslint-disable-next-line no-unused-vars
                // var callbackError = function(result, textStatus, jqXHR) {

                // }     
                    
                // vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);        
          }
                                     
          var datetimeStampNow = new Date().getTime();
          window.remainingSessionTime = window.REQUEST_TIMESTAMP + window.config.IDLE_TIMER_MILISECONDS - datetimeStampNow;

          //Update document title
          if(vueInstance.config.USE_EXTENDED_PAGETITLE && vueInstance.config.IS_PURE_ACCOUNTMANAGER){
            var remainingTimeMessage = "";

            if (vueInstance.config.SHOW_TITLE_LOGOUT_TIMER) {
              var remainingTimeInMillisecounds = window.remainingSessionTime;
              if (remainingTimeInMillisecounds > 0) {
                  var timeString = "";
                  if ((remainingTimeInMillisecounds / 60000) > 1) {
                      timeString = Math.round(remainingTimeInMillisecounds / 60000) + " min";
                  }else{
                      timeString = "< 1 min";
                  }
                  remainingTimeMessage = this.$t('timeRemaining') + "" + timeString + " - ";
              }
            }

            document.title = remainingTimeMessage + this.$t('webshopTitle') + " - " + vueInstance.$route.name;
        }

          if(datetimeStampNow - vueInstance.config.IDLE_TIMER_MILISECONDS > window.REQUEST_TIMESTAMP)
          {
            vueInstance.appIsIdle = true;
          }          

      }, 1000);

   
      //BIND EVENTS
      $("#app").bind("scroll", function () {
        vueInstance.handleScroll();
      });

      $("#siteHeader").bind("wheel", function (event) {
        vueInstance.handleWheel(event);
      }); 

      $("#content-wrapper").bind("wheel", function (event) {
        vueInstance.handleWheelContent(event);
      });

      $("#content-wrapper").bind("scroll", function (event) {
        vueInstance.handleScrollContent(event);
      });

      if(config.SHOW_GLOBAL_HEADER_BANNER_IMAGE == true)
      {
        $("#siteHeader").bind("click", function () {        
          if($('#siteHeader').hasClass('header-big'))
          {
            vueInstance.minimizeHeader();
          }
          else
          {
            vueInstance.maximizeHeader();
          }
        }); 
      }

      // if( !window.location.href.includes('/PaymentEnd') && !window.location.href.includes('/Login') && !window.location.href.includes('/QRLanding')  && !window.location.href.includes('/Confirm') && !window.location.href.includes('/CheckoutFinished'))
      if( !window.location.href.includes('/PaymentEnd') && !window.location.href.includes('/Login') && !window.location.href.includes('/QRLanding')  && !window.location.href.includes('/Confirm'))
      {

        // AP 20220509 T60190W WAM redirect to Account instead of /Shop
        if(!config.IS_PURE_ACCOUNTMANAGER)
        {
          // console.log("IS NOT ACCOUNTMANAGER");
          // if(!config.TABLE_MODE_OVERRIDE){
          //   console.log("IS TABLE MODE");
          //   this.$router.push( {path:'/Menu'} ); 
          // }else{

            // if (config.TABLE_MODE_OVERRIDE && vueInstance.$route.query && "id_token" in vueInstance.$route.query) {
            if (config.TABLE_MODE_OVERRIDE) {
              if (window.shopId != undefined && window.tableId != undefined) {
                //shop und tableid vorhanden?
                if (window.jwt != undefined || window.ssoCode != undefined) {
                  //mit shop tableid und dem  logincde zum ding senden                  
                  let path = "/QRLanding?s=" + window.shopId + "&t=" + window.tableId; 
                  if (window.jwt != undefined) {
                    path = path + "&id_token=" + window.jwt; 
                  } else if(window.ssoCode != undefined){
                    path = path + "&code=" + window.ssoCode; 
                  }
                  window.jwt = undefined;
                  window.ssoCode = undefined;
                  vueInstance.$router.push({path:path});
                }else{
                  //nur mit shop und tableid senden
                  let path = "/QRLanding?s=" + window.shopId + "&t=" + window.tableId; 
                  vueInstance.$router.push({path:path});
                }
              }else{
                  let path = "/QRLanding?h=true"; 
                  vueInstance.$router.push({path:path});
                // this.$router.push( {path:'/Shop'} );
              }
            } else {
              
              this.$router.push( {path:'/Shop'} );
            }
          // }

        } else
        {
          this.$router.push( {path:'/Account'} );
        }
      }     
    }
  }
</script>


<style scoped>
.open {
  transform: translateX(300px);
}

#navigation-icon {
  padding: 10px 10px 20px;
  margin-right: 10px;
  cursor: pointer;
}

.top-bar {
  display: flex;
  width: 100%;
}

.customerTCCheck {
  border-width: 3;
}

</style>
