<template>
    <div class="articlePageWrapper" >   
        <loading :active.sync="isLoading" 
            :can-cancel="true"        
            :is-full-page="true"
            loader='bars'></loading>

        <div class="col-12 subPageHeader"  >
            <!-- {{ $t('articlePageTitle') }} -->
            <!-- <button class="btn subPageHeader" @click="goBack(false)" style="float:left;width:100px; margin:10px; margin-top:5px;">
               {{$t('back')}}
            </button> -->
        </div>

        <div style="text-align:left;">
            <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1" @click="previousArticle">
                <img  v-if="!isFarLeft" class="navIcon" src="@/assets/images/arrow-left-black.png" />
            </div>

            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5" :class="(!isOnStock == true) && articleOutOfStockCSS">
                <div class="articlePageImage col-12">
                    <img class="artImageLimit" id="artImage" :src="image1"/>
                </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5" :class="(!isOnStock == true) && articleOutOfStockCSS">
                <!-- <div class="fontTitle">{{articleObj.text1}}</div> -->
                <p style="text-align:left;white-space: pre-line" v-html="longDescription" ></p>

               
                <!-- <input type="number" id="weightInput" v-if="decimalQuantity == true"/> -->

                <div class="form-group" v-if="decimalQuantity">
                        <label class="fontField" for="weightInput">{{$t('weightInputLabel')}}</label>                        
                        <input type="number" :placeholder="$t('weightLabel')" class="form-control form-control-lg" v-model="weightOfArticle" />                        
                </div>

                <div style="text-align:left;" v-if="config.ARTICLE_PAGE_SHOW_ALLERGENS && articleObj.allergens != undefinded">
                    <h5>{{$t('allergenTitle')}}</h5>
                        <label v-for="(item, index) in articleObj.allergens" :key="item" class="fontField" for="weightInput">{{item.name}}{{index != (articleObj.allergens.length - 1) ? "/" : ""}}</label>                        
                </div>
                
                <div style="text-align:left;" v-if="config.ARTICLE_PAGE_SHOW_DIETARY_PROPTERTIES && articleObj.properties != undefinded">
                    <h5>{{$t('articlePropertiesTitle')}}</h5>
                        <label v-for="(item, index) in articleObj.properties" :key="item" class="fontField" for="weightInput">{{item.name}}{{index != (articleObj.properties.length - 1) ? "/" : ""}}</label>                        
                </div>

                <div style="text-align:left;" v-if="config.ARTICLE_PAGE_SHOW_ALLERGENS && articleObj.allergenIds != undefinded && articleObj.allergenIds.length > 0 && articleObj.allergens == undefinded">
                    <h5>{{$t('allergenTitle')}}</h5>
                        <label v-for="(item, index) in articleObj.allergenIds" :key="item" class="fontField" for="weightInput">{{allergenIds.find(element => element.id === item).name}}{{index != (articleObj.allergenIds.length - 1) ? "/" : ""}}</label>                        
                </div>
                
                <div style="text-align:left;" v-if="config.ARTICLE_PAGE_SHOW_DIETARY_PROPTERTIES && articleObj.propertyIds != undefinded && articleObj.propertyIds.length > 0 && articleObj.properties == undefinded">
                    <h5>{{$t('articlePropertiesTitle')}}</h5>
                        <label v-for="(item, index) in articleObj.propertyIds" :key="item" class="fontField" for="weightInput">{{dietaryProperties.find(element => element.id === item).name}}{{index != (articleObj.propertyIds.length - 1) ? "/" : ""}}</label>                        
                </div>

                <div v-for="(lineItem, indexLineItem) in articleObj.items" :key="lineItem.menuId" class="col-12" style="padding-left:0px;text-align:left;">            
                        <div class="row">
                            <p>{{lineItem.name}}</p>  
                            <label style="padding-left: 10%;" :class=" 'errorLabel id_' + indexLineItem">{{ errorMessages[indexLineItem] }}</label>
                        </div>
                    
                    <div v-if="hideLocked(lineItem.items).length<1">{{ $t('noSubitemAvailable') }}</div>

                    <div style="text-align: start;" v-for="(subItem, indexSubItem) in hideLocked(lineItem.items)" :key="subItem.lineId" >
                        <check-box @change="onCheckSelect(); if(subItem.items != undefined) updateShow('' + indexLineItem + '_' + indexSubItem);"                             
                            :object="subItem"
                            :dataOperationCount="dataOpCnt"
                            :label="subItem.name.replace(articleObj.text1,'').replace(lineItem.name, '')" 
                            :value="{ price: (articleObj.price > 0 && articleObj.menu) ? 0 : subItem.price, lineId: lineItem.lineId, articleId: subItem.articleId, prodId: subItem.kkProdId, minQty: lineItem.minQty, maxQty: lineItem.maxQty, uniqueItemId: '' + indexLineItem + '_' + indexSubItem, uniqueLineId: '' + indexLineItem, orderString: 'opt_' + lineItem.minQty + '_' + lineItem.maxQty + '_' + ((indexSubItem + 1) * 1000) + '_' + subItem.kkProdId}"   
                            :available="getSubItemAvailability(subItem)"
                            :hidePrice="articleObj.price > 0 && articleObj.menu"                  
                            v-model="selectedValues">
                        </check-box>
                        <transition>
                        <div v-show="show['' + indexLineItem + '_' + indexSubItem]">
                        <div style="text-align: start; padding-left: 15%;" v-for="(subLineItem2, indexSubLineItem2) in subItem.items" :key="subLineItem2.lineId">
                            <div class="row">
                                <p>{{subLineItem2.name}}  </p>  
                                <label style="padding-left: 10%;" :class=" 'errorLabel id_' + indexLineItem+ '_' + indexSubItem + '_' + indexSubLineItem2"></label>
                            </div>       

                            <div v-if="hideLocked(subLineItem2.items).length<1">{{ $t('noSubitemAvailable') }}</div>

                            <div style="text-align: start;" v-for="(subItem2, indexSubItem2) in hideLocked(subLineItem2.items)" :key="subItem2.lineId" >
                                <check-box @change="onCheckSelect(); if(subItem2.items != undefined) updateShow(indexLineItem+ '_' + indexSubItem + '_' + indexSubLineItem2 + '_' + indexSubItem2);"                             
                                    :object="subItem2"
                                    :dataOperationCount="dataOpCnt"
                                    :label="subItem2.name.replace(articleObj.text1,'').replace(subLineItem2.name, '')" 
                                    :value="{ price: subItem2.price, lineId: subLineItem2.lineId, parentArticle: subItem.kkProdId, articleId: subItem2.articleId, prodId: subItem2.kkProdId, minQty: subLineItem2.minQty, maxQty: subLineItem2.maxQty, uniqueItemId: indexLineItem+ '_' + indexSubItem + '_' + indexSubLineItem2 + '_' + indexSubItem2, uniqueLineId: indexLineItem+ '_' + indexSubItem + '_' + indexSubLineItem2, orderString: 'subOpt_' + subLineItem2.minQty + '_' + subLineItem2.maxQty + '_' + (((indexSubItem + 1) * 1000) + ((indexSubItem2 + 1) * 100)) + '_' + subItem.kkProdId }"   
                                    :available="getSubItemAvailability(subItem2)"                     
                                    v-model="selectedValues">
                                </check-box>
                                <transition>
                                <div v-show="show['' + indexLineItem + '_' + indexSubItem + '_' + indexSubLineItem2 + '_' + indexSubItem2]">    
                                <div style="text-align: start; padding-left: 15%;" v-for="(subLineItem3, indexSubLineItem3) in subItem2.items" :key="subLineItem3.lineId">
                                    <div class="row">
                                        <p>{{subLineItem3.name}}  </p> 
                                        <label style="padding-left: 10%;" :class=" 'errorLabel id_' + indexLineItem+ '_' + indexSubItem + '_' + indexSubLineItem2 + '_' + indexSubItem2 + '_' + indexSubLineItem3"></label>
                                    </div>  

                                    <div v-if="hideLocked(subLineItem3.items).length<1">{{ $t('noSubitemAvailable') }}</div>

                                    <div style="text-align: start;" v-for="(subItem3, indexSubItem3) in hideLocked(subLineItem3.items)" :key="subItem3.lineId" >
                                        <check-box @change="onCheckSelect"                             
                                            :object="subItem3"
                                            :dataOperationCount="dataOpCnt"
                                            :label="subItem3.name.replace(articleObj.text1,'').replace(subLineItem3.name, '')" 
                                            :value="{ price: subItem3.price, lineId: subLineItem3.lineId, articleId: subItem3.articleId, prodId: subItem3.kkProdId, minQty: subLineItem3.minQty, maxQty: subLineItem3.maxQty, uniqueLineId: indexLineItem+ '_' + indexSubItem + '_' + indexSubLineItem2 + '_' + indexSubItem2 + '_' + indexSubLineItem3, orderString: 'subOpt_' + subLineItem3.minQty + '_' + subLineItem3.maxQty + '_' + (((indexSubItem + 1) * 1000) + ((indexSubItem2 + 1) * 100) + ((indexSubItem3 + 1) * 10)) + '_' + subItem.kkProdId + '_' + subItem2.kkProdId }"   
                                            :available="getSubItemAvailability(subItem3)"                     
                                            v-model="selectedValues">
                                        </check-box>
                                    </div>
                                </div>
                                </div>
                                </transition>	
                            </div>
                        </div>	
                        </div>
                        </transition>
                    </div>
                </div>

                <div v-if='errorMessage != ""' style="text-align:left;">{{errorMessage}}</div>

                <textarea v-if="config.SHOW_DESCRIPTION_TEXT_INPUTS" type="text" :placeholder="$t('articleComment')" maxlength="40" class="form-control form-control-lg articleCommentField" v-model="articleComment" />   

                <!-- <div class="fontTitle" v-if="!articleObj.menu">{{ articleObj.formattedPrice }}</div> -->
                <div class="fontTitle" >{{ menuPrice }}</div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1" @click="nextArticle">
                <img class="navIcon"  v-if="!isFarRight" src="@/assets/images/arrow-right-black.png" />
            </div>
        </div>

        <div class="form-group col-lg-2 col-md-2 col-sm-2 col-5" style="float:right;" v-if="config.SHOW_ARTICLE_AMOUNT_SELECT">
            <label class="fontField">{{ $t('articleAmountSelect') }}</label>
            <input type="number" class="form-control form-control-lg" v-model="menge" min="1"/>
        </div>

        <div class="col-12" style="padding: 5px 5px 5px 0px;" :class="(!isOnStock == true) && articleOutOfStockCSS">
            <button type="button" class="btn btn-primary" v-if="errorMessage == '' && isOnStock" :disabled="decimalQuantity && (weightOfArticle == undefined || weightOfArticle < 1)" @click="onSubmit" style="float:right;width:100px; margin:0px;"> {{ $t('order') }} </button>
            <button type="button" class="btn btn-primary btn-inverted" v-if="!isOnStock" style="float:right;width:100px; margin:0px;">  {{ $t('notAvailable') }}</button>
        </div>
        <div v-if="config.ARTICLE_PAGE_SHOW_ALLERGENS_IMAGES" class="col-12" style="padding: 5px 5px 5px 0px;">
            
            <h5 v-if="dietaryProps.length > 0">{{$t('allergenTitle')}}</h5>

<b-list-group horizontal class="justify-content-center">
    <b-list-group-item v-for="(lineItem) in dietaryProps" :key="lineItem.id" >
        <img :src="lineItem.image" style="max-width: 50px;">
        <label class="ml-2">{{lineItem.name}}</label>
    </b-list-group-item>
</b-list-group>

        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import VueLoading from 'vue-loading-overlay'
import CheckBox from '../sub-components/CheckBox.vue'
import $ from 'jquery'
// import func from 'vue-editor-bridge'

export default {
    data() {
        return {
            isLoading: false,
            articleObj: {
                Type: Object
            },
            selectedValues: [],
            errorMessage : "",
            longDescription: "",
            decimalQuantity: false,
            weightOfArticle: undefined,
            articleOutOfStockCSS: "articleCardDisabled",
            subItemQuantities: [],
            dataOpCnt: 0,
            image1: "",
            keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
            articleComment: "",
            reload:false,
            errorMessages: {},
            menuPrice: 0,
            show: {},
            menge: 1,
            dietaryProps: [],
        }
    },
    props: {
        article:{
            Type: Object
        },
        callbackBack:{
            Type: Function
        },
        callbackPrevious:{
            Type: Function
        },
        callbackNext:{
            Type: Function
        },
        isOnStock: {
            Type:Boolean
        },
        isFarLeft:{
            Type:Boolean
        },
        isFarRight:{
            Type:Boolean
        }
    },
    components:{
        'loading': VueLoading,
        'check-box': CheckBox
    },
    beforeMount() {
        this.articleObj = this.article; 

        this.getLongDescriptionAndStock();
        // this.getStock();
    },
    mounted(){
    //    this.onCheckSelect();
        this.menuPrice = this.articleObj.formattedPrice;
    },
    computed:{
        config(){
            return window.config;
        },
        baseUrl(){
            return window.imageBaseUrl;
        },
        allergenIds(){
            return window.allergens;
        },
        dietaryProperties(){
            return window.dietaryProperties;
        },
        // menuPriceC(){
        //     this.menuPrice = 0;
        //     if (this.articleObj.price > 0) {
        //         this.menuPrice = this.articleObj.price;
        //     }else{
        //         this.selectedValues.forEach(element => {
        //             this.menuPrice += element.price;
        //         });
        //     }

        //     return this.menuPrice; //FORMATIEREN NICHT VERGESSEN
        // },
        subItemsWithId(){
            var items = this.articleObj.items;

            if(items != undefined)
            for (let index = 0; index < items.length; index++) {
                Object.assign(items[index], { uniqueLineId: "" + index});
                let subItems = items[index].items;
                if(subItems != undefined){
                    for (let indexSubitem = 0; indexSubitem < subItems.length; indexSubitem++) {
                        Object.assign(subItems[indexSubitem], { uniqueLineId: "" + index});
                        let lines2 = subItems[indexSubitem].items;
                        if(lines2 != undefined){
                            for (let indexLines2 = 0; indexLines2 < lines2.length; indexLines2++) {
                                Object.assign(lines2[indexLines2], { uniqueLineId: "" + index + "_" + indexSubitem + "_" + indexLines2, uniqueParentItemId: "" + index + "_" + indexSubitem });                                
                                // uniqueParentItemId: '' + indexLineItem + '_' + indexSubItem, 
                                let subItems2 = lines2[indexLines2].items;
                                if (subItems2 != undefined) {
                                    for (let indexSubItems2 = 0; indexSubItems2 < subItems2.length; indexSubItems2++) {
                                        Object.assign(subItems2[indexSubItems2], { uniqueLineId: "" + index + "_" + indexSubitem + "_" + indexLines2});
                                        let lines3 = subItems2[indexSubItems2].items;
                                        if(lines3 != undefined){
                                            for (let indexLines3 = 0; indexLines3 < lines3.length; indexLines3++) {
                                                Object.assign(lines3[indexLines3], { uniqueLineId: "" + index + "_" + indexSubitem + "_" + indexLines2 + "_" + indexSubItems2 + "_" + indexLines3, uniqueParentItemId: "" + index + "_" + indexSubitem + "_" + indexLines2 + "_" + indexSubItems2});
                                                // , uniqueParentItemId: indexLineItem+ '_' + indexSubItem + '_' + indexSubLineItem2 + '_' + indexSubItem2
                                                let subItems3 = lines3[indexLines3].items;
                                                if (subItems3 != undefined) {
                                                    for (let indexSubItems3 = 0; indexSubItems3 < subItems.length; indexSubItems3++) {
                                                        Object.assign(subItems3[indexSubItems3], { uniqueLineId: "" + index + "_" + indexSubitem + "_" + indexLines2 + "_" + indexSubItems2 + "_" + indexLines3});
                                                        
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            return items;
        }
    },
    methods:{
        hideLocked(items) {
            return items.filter(function(item){
                return !item.locked;
            });
        },
        updateShow(index){
            Vue.set(this.show, index, !this.show[index]);
        },
        encodeUtf8(string){
            string = string.toString().replace(/\r\n/g, "\n");
            var utftext = "";

            for (var n = 0; n < string.length; n++) {
                
                var c = string.charCodeAt(n);

                if (c < 128) {
                    utftext += String.fromCharCode(c);
                } else if ((c > 127) && (c < 2048)) {
                    utftext += String.fromCharCode((c >> 6) | 192);
                    utftext += String.fromCharCode((c & 63) | 128);
                } else {
                    utftext += String.fromCharCode((c >> 12) | 224);
                    utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                    utftext += String.fromCharCode((c & 63) | 128);
                }

            }

            return utftext;
        },
        encode64(input){
            var output = "";
            var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
            var i = 0;

            input = this.encodeUtf8(input);

            while (i < input.length) {
                chr1 = input.charCodeAt(i++);
                chr2 = input.charCodeAt(i++);
                chr3 = input.charCodeAt(i++);

                enc1 = chr1 >> 2;
                enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
                enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
                enc4 = chr3 & 63;

                if (isNaN(chr2)) {
                    enc3 = enc4 = 64;
                } else if (isNaN(chr3)) {
                    enc4 = 64;
                }

                output = output +
                this.keyStr.charAt(enc1) + this.keyStr.charAt(enc2) +
                this.keyStr.charAt(enc3) + this.keyStr.charAt(enc4);
            }

            return output;
        },
        getSubItemAvailability(item) {
            if(this.subItemQuantities == undefined || this.subItemQuantities == null || this.subItemQuantities.length == 0)
            {
                return 0;
            }

            var itemQuantity = Object.values(this.subItemQuantities).filter(quantity => quantity.itemId === item.id)

            if(itemQuantity == undefined)
            {
                return 0;
            }

            if(itemQuantity == null)
            {
                return 0;
            }     

            if(itemQuantity.length == 0)
            {
                return 0;
            }             
            
            if(itemQuantity[0].quantity > 0)
            {
                return 1;
            }
        },           
        getLongDescriptionAndStock() {
            var config = window.config;
            var vueInstance = this;
            vueInstance.isLoading = true;
            var url = config.API_BASE + 'TCPGetProductDetailsJ.action';
            var params = {
                kkProdId: vueInstance.articleObj.id,
                json: "true",
                includeAllergenIds: "true",
                includePropertyIds: "true",
                includeNutriValues: "true",
                addChildren: "true"
            };
                        
            // eslint-disable-next-line no-unused-vars
            var callbackSuccess= function(result, textStatus, jqXHR) {   
                vueInstance.isLoading = false;    
                if( (result.exception !== undefined) || (result.item !== undefined) )
                {             
                    vueInstance.$alert(vueInstance.$t('getItemFailed'));
                    return;
                }                 
                vueInstance.longDescription = result.product.longDesc;     
                var quantity;
                    
                if(result.product.recipe != undefined)
                {
                    var quantityParsed = parseInt(result.product.recipe);
                    if(quantityParsed > 0)
                    {
                        quantity = quantityParsed;
                    }                    
                    else
                    {
                        quantity = 0;   
                    }
                }
                else {
                    quantity = 0;
                }
    
                if (config.ARTICLE_PAGE_SHOW_ALLERGENS_IMAGES && result.product.allergens != undefined) {
                    vueInstance.dietaryProps = []
                    if (result.product.allergens.length > 0) {
                        vueInstance.dietaryProps = result.product.allergens;
                    }

                }

                vueInstance.subItemQuantities.push({
                    itemId : result.kkProdId,
                    quantity : quantity
                });        
                
                vueInstance.image1 = result.product.image1;
                vueInstance.decimalQuantity = result.product.menu == true ? false : result.product.decimalQuantity;
                vueInstance.articleObj = result.product;

                // this.articleObj.items.forEach(element => {
                //     element.price = 0;
                //     element.formattedTotal = "";
                // });

                if(vueInstance.articleObj.items != undefined)
                {   
                    vueInstance.errorMessage = vueInstance.$t("articleChoicesInstructionMessage");
                    vueInstance.onCheckSelect(); 
                }
                


                vueInstance.$forceUpdate();
            }

            // eslint-disable-next-line no-unused-vars
            var callbackError = function(result, textStatus, jqXHR) {   
                vueInstance.isLoading = false;
            }
            
            vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError); 
        },   
      
        previousArticle(){
            this.callbackPrevious(this.articleObj);
        },
        nextArticle(){
            this.callbackNext(this.articleObj);
        },
        onCheckSelect() {
            console.log("onCheckSelect START");
            let vueInstance = this;
            this.errorMessage="";
            if(this.articleObj.items == undefined)
            {
                return;
            }

            this.menuPrice = 0;
            if (this.articleObj.price > 0) {
                this.menuPrice = this.articleObj.price;
            }

            if(!this.config.EXTRAS_CONSIDER_SEPARATE){
                this.selectedValues.forEach(element => {
                    this.menuPrice += element.price;
                });
            }

            var formattedMenuPrice = new Intl.NumberFormat('de-DE', { style: 'currency', currency: window.config.MENU_CURRENCY}).format(this.menuPrice);
            // console.log(formattedMenuPrice);
            this.menuPrice = formattedMenuPrice;

            this.reload = true;

            document.getElementsByClassName('errorLabel').forEach(element => {
                element.innerHTML = "";
            });
            vueInstance.errorMessages = new Object();
            var subLines1 = this.subItemsWithId;

            if(subLines1 != undefined)
            subLines1.forEach(subLines1Element => {
                let lineValuesSelected = this.selectedValues.filter(function(x) { 
                    return x.uniqueLineId == subLines1Element.uniqueLineId });
                if(lineValuesSelected.length < subLines1Element.minQty){
                    vueInstance.errorMessage = " ";
                    // $('#id_'+subLines1Element.uniqueLineId).text = "asdf";
                    // document.getElementsByClassName('id_' + subLines1Element.uniqueLineId)[0].innerHTML = vueInstance.$t('notEnoughExtrasSelected');
                    document.getElementsByClassName('id_' + subLines1Element.uniqueLineId)[0].innerHTML = this.$t('subItemMin') + subLines1Element.minQty;
                    // vueInstance.Vue.set(vueInstance.errorMessages, subLines1Element.uniqueLineId, 'Min ' + subLines1Element.minQty);
                    // vueInstance.errorMessages[subLines1Element.uniqueLineId] = 'Min ' + subLines1Element.minQty;
                }else if(lineValuesSelected.length > subLines1Element.maxQty){
                    vueInstance.errorMessage = " ";
                    document.getElementsByClassName('id_' + subLines1Element.uniqueLineId)[0].innerHTML = this.$t('subItemMax') + subLines1Element.maxQty;
                    // vueInstance.errorMessages[subLines1Element.uniqueLineId] = 'Max ' + subLines1Element.maxQty;
                }

                var subItems1 = subLines1Element.items;
                if(subItems1 != undefined)
                subItems1.forEach(subItemElement => {
                    var subLines2 = subItemElement.items;
                    if(subLines2 != undefined)
                    subLines2.forEach(subLines2Element => {
                        let lineValues2 = this.selectedValues.filter(function(x){
                            return x.uniqueItemId == subLines2Element.uniqueParentItemId
                        });
                        if (lineValues2.length > 0) {
                            let lineValuesSelected2 = this.selectedValues.filter(function(x) { 
                                return x.uniqueLineId == subLines2Element.uniqueLineId });
                            if(lineValuesSelected2.length < subLines2Element.minQty){
                                vueInstance.errorMessage = " ";
                                document.getElementsByClassName('id_' + subLines2Element.uniqueLineId)[0].innerHTML = this.$t('subItemMin') + subLines2Element.minQty;
                                // vueInstance.errorMessages[subLines2Element.uniqueLineId] = 'Min ' + subLines2Element.minQty;
                            }else if(lineValuesSelected2.length > subLines2Element.maxQty){
                                vueInstance.errorMessage = " ";
                                document.getElementsByClassName('id_' + subLines2Element.uniqueLineId)[0].innerHTML = this.$t('subItemMax') + subLines2Element.maxQty;
                                // vueInstance.errorMessages[subLines2Element.uniqueLineId] = 'Max ' + subLines2Element.minQty;
                            }

                            var subItems2 = subLines2Element.items;
                            if(subItems2 != undefined)
                            subItems2.forEach(subItem2Element => { 
                                var subLines3 = subItem2Element.items;
                                if(subLines3 != undefined)
                                subLines3.forEach(subLines3Element => {
                                    let lineValues3 = this.selectedValues.filter(function(x){
                                    return x.uniqueItemId == subLines3Element.uniqueParentItemId
                                    });
                                    if (lineValues3.length > 0) {
                                        let lineValuesSelected3 = this.selectedValues.filter(function(x) { 
                                            return x.uniqueLineId == subLines3Element.uniqueLineId });
                                        if(lineValuesSelected3.length < subLines3Element.minQty){
                                            vueInstance.errorMessage = " ";
                                            document.getElementsByClassName('id_' + subLines3Element.uniqueLineId)[0].innerHTML = this.$t('subItemMin') + subLines3Element.minQty;
                                            // vueInstance.errorMessages[subLines3Element.uniqueLineId] = 'Min ' + subLines3Element.minQty;
                                        }else if(lineValuesSelected3.length > subLines3Element.maxQty){
                                            vueInstance.errorMessage = " ";
                                            document.getElementsByClassName('id_' + subLines3Element.uniqueLineId)[0].innerHTML = this.$t('subItemMax') + subLines3Element.maxQty;
                                            // vueInstance.errorMessages[subLines3Element.uniqueLineId] = 'Max ' + subLines3Element.minQty;
                                        }       
                                    }
                                    // var subItems3 = subLines3Element.items;
                                    // if(subItems3 != undefined)
                                    // // subItems3.forEach(subItem3Element => {
                                    // //     // console.log(subItem3Element);
                                    // // }); 
                                });
                            });
                        }
                        
                    });
                });
            });

            // const counts = {};
            // for (const val of this.selectedValues){
            //     counts[val.uniqueLineId] = counts[val.uniqueLineId] ? counts[val.uniqueLineId] + 1 : 1;
            // }
            // var keys = Object.keys(counts);

            // keys.forEach(element => {
            //     var lineValuesSelected = this.selectedValues.filter(function(x) { 
            //           return x.uniqueLineId == element });
            //     if (lineValuesSelected[0].minQty > counts[element]) {
            //         console.log("Zu wenige in " + element);
            //     }else if(lineValuesSelected[0].maxQty < counts[element]){
            //         console.log("Zu viele in " + element);
            //     }
            // });

            // var lineValuesSelected = this.selectedValues.groupBy(lineItem => { return lineItem.uniqueLineId;});
            // console.log(lineValuesSelected);
            // ALT:
        //     var menuLines = this.articleObj.items;
        //    for(var i = 0; i < menuLines.length; i++)
        //     {                
        //         var menuLine = menuLines[i];     
        //         var min = menuLine.minQty;
        //         var max = menuLine.maxQty;
        //         var lineId = menuLine.lineId;   

        //          var lineValuesSelected = this.selectedValues.filter(function(x) { 
        //              return x.lineId == lineId });
        //         //var otherLineValuesSelected = this.selectedValues.filter(function(x) { return x.lineId != lineId });                

        //         // if(lineValuesSelected.length == (max + 1))
        //         // {         
        //         //     this.selectedValues.splice(this.selectedValues.indexOf(lineValuesSelected[0]),1);       
        //         // }         

        //         if(lineValuesSelected.length > max)
        //         {
        //             this.errorMessage=this.$t('tooManyExtrasSelected') + " (min: " + min + " / max: " + max + ")";
        //         }

        //         if( (lineValuesSelected.length == 0 || lineValuesSelected == undefined || lineValuesSelected == null ) && min > 0 )
        //         {                    
        //             this.errorMessage=this.$t('notEnoughExtrasSelected') + " (min: " + min + " / max: " + max + ")";
        //         }

        //         if( (lineValuesSelected.length < min))
        //         {                    
        //             this.errorMessage=this.$t('notEnoughExtrasSelected') + " (min: " + min + " / max: " + max + ")";
        //         }

        //         //Error Logging:
        //         // console.log("lineId: " + lineId);
        //         // console.log("max: " + max);
        //         // console.log("min: " + min);
        //         // console.log("lineValuesSelecetd.length: " + lineValuesSelected.length);
        //         // lineValuesSelected.forEach(element => {
        //         //     console.log("orderString: " + element.orderString);
        //         // });
        //         // console.log("orderString: " + lineValuesSelected[0].orderString);
        //     }
 

            this.reload = false;

            this.$forceUpdate();
            $( "#artImage" ).trigger( "click" );
            // console.log("onCheckSelect END");
        },
        onPaymentSelect(element){
            this.currentPayment == element.target.value;
        },   
        onSubmit() {
            var config = window.config;
            var vueInstance = this;
            vueInstance.isLoading = true;
            var optLines = [];
            if(this.articleObj.menu == true || (this.articleObj.hasChildren && config.EXTRAS_CONSIDER_SEPARATE == false))
            {
                // eslint-disable-next-line no-unused-vars
                var group = 0;
                // eslint-disable-next-line no-unused-vars
                var count = 0;

                if(this.selectedValues.length == 0)
                {
                     //"optionDiv": "optdiv_2514",
                    var opt = "optdiv" + this.articleObj.prodId;   
                    var optLineDesc= { optLine: opt, prodId: this.articleObj.prodId}  ;
                    optLines.push(optLineDesc);        
                }

                for(var j = 0; j < this.selectedValues.length; j++)
                {
                    group += 1000;
                    count ++;
                    console.log(this.selectedValues[j]);
                    // var optLine = "opt_" + this.selectedValues[j].minQty + "_" + this.selectedValues[j].maxQty + "_" + group + "_" + this.selectedValues[j].prodId + "_" + count;   
                    var optLine = this.selectedValues[j].orderString + '_' + count;
                    var optLineDescription= { optLine: optLine, prodId: this.selectedValues[j].prodId}  ;
                    optLines.push(optLineDescription);               
                }
            }

            if(this.errorMessage == "")
            {
                var urlNoError = config.API_BASE + 'TCPAddMenuItemToCartJ.action';
                
                var paramsNoError = {};

                if(this.articleObj.menu == true || (this.articleObj.hasChildren  > 0 && config.EXTRAS_CONSIDER_SEPARATE == false) )
                {
                    paramsNoError = {
                        prod: this.articleObj.kkProdId,                        
                        scroll: 0,
                        xsfrToken: "",
                        freeText: vueInstance.articleComment,
                        orderType: window.orderType,                        
                    };
                      if(window.customerLoggedIn)
                    {
                        paramsNoError.calculateOrder = "true";
                    }

                    if(this.decimalQuantity)
                    {
                        paramsNoError.weightOrVolume = this.weightOfArticle / 1000;
                    }
                    else
                    {
                        paramsNoError.quantity = 1;
                    }

                    if(this.menge > 1){
                        paramsNoError.quantity = this.menge;
                    }

                    if(optLines.length > 0)
                    {
                        for(var x = 0; x < optLines.length; x++)
                        {                          
                            var paramName =optLines[x].optLine;
                            paramsNoError[paramName] = optLines[x].prodId;
                        }

                        paramsNoError.kkjson = this.encode64(JSON.stringify(paramsNoError));
                    }
                }
                else
                {            
                    paramsNoError = {
                        productId: this.articleObj.kkProdId,
                        json: "true",
                        orderType:  window.orderType,                        
                        freeText: vueInstance.articleComment
                        
                        // quantity: 1
                    };
                    if(window.customerLoggedIn)
                    {
                        paramsNoError.calculateOrder = "true";
                    }

                    if(this.decimalQuantity)
                    {
                        paramsNoError.weightOrVolume = this.weightOfArticle / 1000;
                    }
                    else
                    {
                        paramsNoError.quantity = 1;
                    }
                    
                    if(this.menge > 1){
                        paramsNoError.quantity = this.menge;
                    }
                }                 
                // eslint-disable-next-line no-unused-vars
                var callbackSuccessNoError= function(result, textStatus, jqXHR) {     
                   
                    window.shoppingCart = result.basketTile.basketItems;
                    if(result.basketTile.paymentsList != undefined){
                        window.subsidy = vueInstance.formatCurrency(result.basketTile.paymentsList[0].paymentAmount);
                    }else{
                        window.subsidy = "";
                    }                   

                    vueInstance.$forceUpdate();    
                    vueInstance.isLoading = false; 
                    if( (result.exception !== undefined) )
                    {
                        if(result.exception.code === 64){
                            vueInstance.$alert(vueInstance.$t('addItemMinError'));
                        }else if(result.exception.code === 65){
                            vueInstance.$alert(vueInstance.$t('addItemMaxError'));
                        } else{
                            vueInstance.$alert(vueInstance.$t('addItemFailed'));
                        }
                    }                 
                    // vueInstance.$alert("Erfolgreich zum Warenkorb hinzugefügt"); 
                    vueInstance.callbackBack(result.basketTile.formattedTotal, true);       
                }

                // eslint-disable-next-line no-unused-vars
                var callbackErrorNoError = function(result, textStatus, jqXHR) {   
                    vueInstance.isLoading = false;
                }
            
                vueInstance.makeRequest("GET", urlNoError, JSON.stringify(paramsNoError), callbackSuccessNoError, callbackErrorNoError);
            }

            for(var i = 0; i < this.selectedValues.length && (this.articleObj.menu == false && (config.EXTRAS_CONSIDER_SEPARATE == true) ); i++)
            {
                var url = config.API_BASE + 'TCPAddMenuItemToCartJ.action';  
                
                var params = {
                    productId: this.selectedValues[i].prodId,
                    json: "true",
                    freeText: vueInstance.articleComment,
                    orderType:  window.orderType,
                    quantity: 1,
                };

                if(window.customerLoggedIn)
                {
                    params.calculateOrder = "true";
                }
                
                if(this.menge > 1){
                    paramsNoError.quantity = this.menge;
                }

                // eslint-disable-next-line no-unused-vars
                var callbackSuccess= function(result, textStatus, jqXHR) {
                    window.shoppingCart = result.basketTile.basketItems;
                    if(result.basketTile.paymentsList != undefined){
                        window.subsidy = vueInstance.formatCurrency(result.basketTile.paymentsList[0].paymentAmount);
                    }else{
                        window.subsidy = "";
                    }                            
                    vueInstance.$forceUpdate();         
                    if( (result.exception !== undefined) || (result.item !== undefined) )
                    {
                        vueInstance.$alert(vueInstance.$t('addItemFailed'));
                        vueInstance.isLoading = false;
                        return;
                    }                 
                    // vueInstance.$alert("Erfolgreich zum Warenkorb hinzugefügt");
                    // console.log("additem success");                       
                    vueInstance.callbackBack(result.basketTile.formattedTotal, true);              
                }

                // eslint-disable-next-line no-unused-vars
                var callbackError = function(result, textStatus, jqXHR) {   
                    
                }
            
                vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);
            }            

           
        },
        formatCurrency(value){
            var formatter = new Intl.NumberFormat('de-DE', {style: 'currency', currency: window.config.SUBSIDY_CURRENCY});
            return formatter.format(value);
        },        
    }
}
</script>

<style scoped>

.articleCommentField {
    margin-bottom: 10px;
    margin-top: 10px;
    font-size:15px;
    width:100%;
    float:inline-end;
}

.articleCommentField:focus {
    border-color: var(--focus-color);
    box-shadow: 0 1px 1px var(--focus-color) inset, 0 0 8px var(--focus-color);
    outline: 0 none;
}

.navIcon {
    margin-top: 150px;
    opacity: 1;
}

@media screen and (max-width:1000px) {
    .navIcon {
        margin-top: 0px;
    }
}

@media screen and (max-width:770px) {
    .navIcon {
        display:none;
    }    
}

</style>