import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n);

let messages = window.messages;

export const i18n = new VueI18n({
    locale:'de_DE',
    fallbackLocale: 'fr_FR',
	messages
})