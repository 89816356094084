<template>
    <div class="addressSelectionWrapper" style="text-align:left;">

        <div class="col-12 subPageHeader"  >
 
            {{ $t('addressSelectionTitle') }}
            <button class="btn btn-primary subPageHeader subPageHeaderButton" @click="goBack(false)" >
               {{$t('back')}}
            </button>
        </div>  
        
        <flow :currentPoint="3"> </flow>

        <!-- <form @submit.prevent disabled class="checkoutPadding">
            
            <div class="col-12" style="padding:0">
                
                <button type="button" @click="editAddress" class="btn btn-primary floatRight" style="height: 48px; ">
                    <img class="editIcon" src="@/assets/images/editIcon.png" />   
                </button> 
            </div>
            <div class="col-lg-6 col-md-12 col-xs-12">  
                <div class="form-group">
                    <label class="fontField">{{ $t('streetAddress')}}</label>
                    <input type="text" class="form-control form-control-lg" v-model="addresses[0].streetAddress" disabled/>     
                </div>

                <div class="form-group">
                    <label class="fontField">{{ $t('streetAddressAddition')}}</label>
                    <input type="text" class="form-control form-control-lg" v-model="addresses[0].streetAddress1" disabled/>                 
                </div>

                <div class="form-group" >
                    <label class="fontField">{{ $t('suburb')}}</label>
                    <input type="text" class="form-control form-control-lg" v-model="addresses[0].suburb" disabled/>                 
                </div>

                <div class="form-group">
                    <label class="fontField">{{ $t('postalCode')}}</label>
                    <input type="text" class="form-control form-control-lg" v-model="addresses[0].postcode" disabled/>   
                </div>

            </div>
            <div class="col-lg-6 col-md-12 col-xs-12" style="padding-right:0px;">

           

                <div class="form-group">
                    <label class="fontField">{{ $t('city')}}</label>
                    <input type="text"  class="form-control form-control-lg" v-model="addresses[0].city" disabled/>    
                </div>

                <div class="form-group">
                    <label class="fontField">{{ $t('state')}}</label>
                    <input type="text" class="form-control form-control-lg" v-model="addresses[0].state" disabled/>
                </div>

                <div class="form-group">
                    <label class="fontField">{{ $t('telephoneNumber')}}</label>
                    <input type="text" class="form-control form-control-lg" v-model="addresses[0].telephoneNumber" disabled/>    
                </div>
            </div>        
            
            <div class="col-12">
                <button type="button" @click="onSubmit" class="btn btn-primary floatRight">{{$t('continue')}}</button> 
            </div>       
         
        </form> -->

       <div class="col-12 addressFormPadding" v-show="showAddressForm">
            <select class="vueselect-wrapper" id="addressPicker" @change="closeAddressForm">
                <option v-for="address in addresses" :key="address.id" :value="address.id" v-html="address.firstName + ' ' + address.lastName + ', ' + address.streetAddress + ', ' + address.postcode + ' ' +  address.city + ', ' + address.countryName +  ' - ' + (address.telephoneNumber ? address.telephoneNumber: '') "></option>
            </select>

            <button type="button" @click="editAddress" class="btn btn-primary btnAddress" >
                <i class="editIcon fa fa-pencil">   </i>  
            </button> 

            <button type="button" @click="addNewAddress" class="btn btn-primary btnAddress">
                <i class="editIcon fa fa-plus">   </i>
            </button> 

        </div>

        <div id="addressFormWrapper" style="width:100%;">
            <div class="col-12 addressFormPadding"  v-if="showEditAddressForm">
                <div class="col-9"><h2>{{$t('editAddress')}}</h2></div>
                <button type="button" v-if="selectedAddress.isPrimary == false" @click="deleteAddress" class="btn btn-inverted col-1" style="margin: 0px 0px 5px 0px;height: 48px;min-width:10%;">
                    <i class="editIcon fa fa-trash">   </i>
                 </button> 
                <address-form :addressId="selectedAddress.id" :addressModel="selectedAddress" :isEdit="true" :callbackClose="closeAddressForm" :customer="customer"></address-form>
                
            </div>   

            <div class="col-12 addressFormPadding"  v-if="showNewAddressForm">
                <h2>{{$t('addAddress')}}</h2>
                <address-form :addressId="0" :addressModel="addAddress" :isEdit="false" :callbackClose="closeAddressForm" :customer="customer"></address-form>            
            </div>      
        </div>

        <div class="col-12" style="padding-right:10%;" v-if="showEditAddressForm == false && showNewAddressForm == false">
            <button type="button" @click="onSubmit" class="btn btn-primary floatRight">{{$t('continue')}}</button> 
        </div>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
    </div>
</template>


<script>
import { required } from 'vuelidate/lib/validators'
import FlowIndicator from '../main-components/FlowIndicator.vue'
import AddressForm from '../main-components/AddressForm.vue'
import $ from 'jquery'

export default {
    data(){
        return {
            comment:"",
            addresses: {
                Type: Array
            },
            customer: {
                Type: Object
            },
            currentAddress: "",
            showNewAddressForm: false,

            addAddress:{
                street: "",
                postalCode :"",
                city: ""
            },

            showEditAddressForm: false,
            showAddressForm: false,
            selectedAddress: {
                street: "",
                postalCode :"",
                city: ""
            }
        }
    },
    watch:{
        addresses: function () {
            if(window.orderType == "dinein")
            {

                setTimeout(() => {                        
                    this.onSubmit();
                    this.$router.push({ path: '/Payment' });
                }, 200);
              
            }
            
            // this.answer = 'Waiting for you to stop typing...'
            // this.debouncedGetAnswer()
        }
    },
    components:{
        'flow' : FlowIndicator,
        'address-form': AddressForm
    },
    validations: {
         addAddress: {
            street: {
                required 
            },
            postalCode: {
                required                   
            },
            city: {
                required
            }
        },        
    },

    methods:{
        checkForEmpty() {
            var vueInstance = this;
            var primary = vueInstance.addresses.filter(function(x) { return x.isPrimary == true})[0]
            if( primary == undefined || primary == null)
            {
                return;
            }

            if(primary.streetAddress.startsWith("---") && primary.city.startsWith("---"))
            {
                primary.streetAddress = "";
                primary.city = "";
                vueInstance.editAddress(primary.id);                
            }
        },
        setAddress(val){
            this.currentAddress = val;
        },       
        goBack(){
            this.$router.go(-1);
        },
        editAddress(addrId = 0) {
            this.showEditAddressForm = true;
            this.showNewAddressForm = false;
            var selectedAddressId = 0;
            if(addrId > 0)
            {
                selectedAddressId = addrId;
            }
            else
            {
                selectedAddressId = $('#addressPicker').val(); 
            }
             
            var selectedAddr = this.addresses.filter(function(x) { return x.id == selectedAddressId })[0];          
            this.selectedAddress = selectedAddr;

            setTimeout(() => {                             
                $(".content").animate({ scrollTop: 450 }, 300);
            }, 150);

        } , 
        closeAddressForm(){
            $("#addressFormWrapper").animate({ height: 0 }, 900);
                  
            setTimeout(() => {                        
                this.showEditAddressForm = false;
                this.showNewAddressForm = false;
            }, 100);

            this.refreshAddresses();
        },
        deleteAddress(){
            var config = window.config;
            var vueInstance = this;
            var url = config.API_BASE + 'TCPDeleteAddrJ.action?json=true&addrId='+ vueInstance.selectedAddress.id;     
            var params = {
                
            };

            // eslint-disable-next-line no-unused-vars
            var callbackSuccess= function(result, textStatus, jqXHR){                   
                vueInstance.confirmDeleteAddress();
            }

            // eslint-disable-next-line no-unused-vars
            var callbackError = function(result, textStatus, jqXHR){
        
            }          

            vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);

        },
        confirmDeleteAddress(){
              var config = window.config;
                var vueInstance = this;
                var url = config.API_BASE + 'TCPDeleteAddrSubmitJ.action';     
                var params = {
                    json: "true"
                };
                
                if(window.xtoken != undefined)
                {
                    params.xsrfToken = btoa(window.xtoken);
                }

                // eslint-disable-next-line no-unused-vars
                var callbackSuccess= function(result, textStatus, jqXHR) {                   
                    vueInstance.$alert(vueInstance.$t('addressDeleted'));
                    vueInstance.closeAddressForm();
                }

                // eslint-disable-next-line no-unused-vars
                var callbackError = function(result, textStatus, jqXHR){
            
                }          

                vueInstance.makeRequest("POST", url, JSON.stringify(params), callbackSuccess, callbackError);
        },
        addNewAddress(){
            this.showEditAddressForm = false;
            this.showNewAddressForm = true;

            $("#addressFormWrapper").animate({ height: 1000 }, 900);
            setTimeout(() => {                             
                $(".content").animate({ scrollTop: 450 }, 300);
            }, 150);
        },
        refreshAddresses(){
            var config = window.config;
            var vueInstance = this;

            var url = config.API_BASE + 'TCPAddrBookJ.action';     

            var params = {
                json: "true",       
            };

            // eslint-disable-next-line no-unused-vars
            var callbackSuccess= function(result, textStatus, jqXHR){       
            
                if(result.exception !== undefined)
                {                
                    vueInstance.$router.go(-1);
                    return;
                }

                if(result.requiresLogin)
                {
                    vueInstance.$router.push({ path:'/Login' });
                    return;
                }

                
                vueInstance.showAddressForm = true;
                // vueInstance.customer = result.customer;
                vueInstance.addresses = result.addresses;

                if(window.orderType == "dinein")
                {
                    // vueInstance.onSubmit();
                    // vueInstance.$router.push({ path: '/Payment' });
                    return;
                }
                else if(window.orderType == "delivery")
                {
                    vueInstance.checkForEmpty();
                }
            }

            // eslint-disable-next-line no-unused-vars
            var callbackError = function(result, textStatus, jqXHR){
                vueInstance.isLoading = false;
            }          

            vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);
        },
        onSubmit(){
            var vueInstance = this;
            var config = window.config;
            var url = config.API_BASE + 'TCPCheckoutChangeDeliveryAddrJ.action';     
            var selectedAddressId = $('#addressPicker').val();         
            
            var params = {
                json: "true",  
                addrId: selectedAddressId != "" ? selectedAddressId : this.addresses[0].id 
            };

            // eslint-disable-next-line no-unused-vars
            var callbackSuccess= function(result, textStatus, jqXHR){                   
                vueInstance.$router.push({ path: '/Payment' });
            }

            // eslint-disable-next-line no-unused-vars
            var callbackError = function(result, textStatus, jqXHR){               
            }          

            vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);
        },       
    },
    created() {     
        this.refreshAddresses();             
    },
    mounted(){
        var config = window.config;
        var vueInstance = this;
        var url = config.API_BASE + 'TCPAfterLoginJ.action';     

        var params = {
            json: "true",       
        };

        // eslint-disable-next-line no-unused-vars
        var callbackSuccess= function(result, textStatus, jqXHR){       
            vueInstance.customer = result.customer;   
            if(result.customer != undefined)
            {
                if(result.customer.type != undefined)
                {
                    if(result.customer.type == 2)
                    {
                        window.isGuestCheckout = true;
                    }
                }
            }  
        }

        // eslint-disable-next-line no-unused-vars
        var callbackError = function(result, textStatus, jqXHR){
            vueInstance.isLoading = false;
        }

        vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);                       
    },
    // mounted() {
     
    // }
}
</script>

<style>
.btnAddress {
    width:60px;
    margin: 0px 5px 5px 0px !important;
    height: 48px;
}

.navBackIcon {
    height:50px;
    float:left;
    width:50px;
}

.navBack{
    color:var(--main-color);
}

.editIcon {
    height:15px;
    width:15px;
}

#addressPicker{
    width:400px;   
    margin-right: 5px;
    margin-bottom: 5px;
    height:50px;
}

@media screen and (max-width:1000px) {
    #addressPicker{
        width:100%;
    }
}

@media screen and (max-width:768px){
    .btnAddress {
        width:50%;
    }
}

</style>