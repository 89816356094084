<template>
    <div class="articleCard"  @click.stop="showArticlePage" :style="'transition: height 1s ease-out;' + (article.locked ? 'box-shadow: none;' : '')" :class="(quantity < 1) && articleOutOfStock">        
        <div class="articleCardImageGlow background-glow">
            <div class="articleCardImage" :style="'background-image:url(' + imageUrl + ');' + (article.locked ? '-webkit-filter: grayscale(100%); -webkit-filter: grayscale(1);  filter: grayscale(100%); filter: gray;' : '')" >
                
                <!-- <img v-if="imageUrl != ''" :src="imageUrl" style="height:inherit"/> -->
            </div>
        </div>

        <div :class="truncateText == true ? 'fontArticleTitle articleCartTitle col-12 text-truncate' : 'fontArticleTitle articleCartTitle col-12'"  style="">
            {{ trimDescription(article.description) }}
        </div>

        <div style="padding:0px; min-height: 55px;">          
            <div class="col-12 noMargin" style="padding:0px;">
                <div class="fontArticlePrice articleCartPrice col-12" v-if="article.locked">{{ $t('articleLocked') }}</div>
                <div class="fontArticlePrice articleCartPrice col-12" style="" v-else-if="article.custom10.startsWith('MEN_')">
                    <div v-if="article.price0 > 0">
                        {{ article.formattedPrice }}
                    </div>
                    <div v-else>
                        {{ $t('menuDefaultPriceReplacement')}}
                    </div>
                </div> 
                <div class="fontArticlePrice articleCartPrice col-12" style="" v-else>
                    {{ article.formattedPrice }}
                </div>
            </div>

            <div class="col-12 noMargin articleCardButtonPlacer" >
                <div class="col-12" v-if="!article.locked">
                    
                    <button class="btn-override onHover articleCardButton" @click.stop="addClick" v-if="!isInShoppingCart && isValidSelection && quantity > 0" style="">                       
                         <!-- {{ $t('order') }}  -->
                        <svg class="addIconMenu" viewBox="0 0 100 100">
                                <circle cx="50" cy="50" r="45" fill="none" stroke-width="10.5"></circle>
                                <line x1="32.5" y1="50" x2="67.5" y2="50" stroke-width="7"></line>
                                <line x1="50" y1="32.5" x2="50" y2="67.5" stroke-width="7"></line>
                        </svg>
                    </button>
                    <button class="btn-override onHover articleCardButton onHoverInvert" @click.stop="removeArticle" v-if="isInShoppingCart && quantity > 0">                            
                         <!-- {{ $t('remove') }}  -->
                         <svg class="addIconMenu" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="24px" height="24px" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M9 3v1H4v2h1v13a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V6h1V4h-5V3H9m0 5h2v9H9V8m4 0h2v9h-2V8z"/></svg>
                         
                         </button>
                    <button class="btn-override articleCardButton" @click.stop v-if="quantity < 1">{{ $t('notAvailable') }}</button>
                </div>
            </div>
        </div>

        <article-detail @click.stop="stopTheEvent" :article="article" v-if="showDetail" :callbackIsValid="setIsValid"></article-detail>        
    </div>
</template>

<script>
import $ from 'jquery'
import ArticleDetail from './ArticleDetails.vue'

export default {
    data() {
        return {
            showDetail: false,
            inCart: false,
            isValidSelection: false,
            quantity:{
                Type: Number,
                default: 100
            },
            articleOutOfStock: "articleCardDisabled",
            image1: "",
            articleObj: {
                Type:Object
            },
            // formattedPrice: "",           
            decimalQuantity: false,
            shopCartQuantity:0,
        }
    },
    name:'article-card',
    props: {
        callbackAdd: {
            Type: Function
        },
        callbackRemove: {
            Type: Function
        },
        callbackSelected: {
            Type: Function
        },
        callbackAddWeight: {
            Type: Function
        },        
        article:{
            Type: Object,
            required: true
        },
        cartQuantity:{
            Type:Number
        }        
    },
    components:{
        'article-detail' : ArticleDetail
    },
    computed:{
        imageUrl(){ 
            return this.baseUrl() + this.articleObj.image;         
        },
 
        isInShoppingCart() {
            if(window.config.FORCE_SHOW_ARTICLE_DETAILS_ON_ADD)
            {
                return false;
            }

            if(this.shopCartQuantity > 0)
            {
                return true;
            }
            else
            {
                return false;
            }            
        },
        cartId (){
            return this.getCartId();
        },
        truncateText(){
            return window.config.ARTICLE_CARD_TRUNCATE_DESCRIPTION;
        }
    },
    mounted(){
        this.shopCartQuantity = this.cartQuantity;
    },
    // eslint-disable-next-line no-unused-vars
    created() {        
        this.articleObj = this.article;
        if(this.article.items == undefined)
        {
                this.isValidSelection = true;
        }

        console.log(this.article);
        this.quantity = 100;
        
        if(this.article.asdasd != undefined)
        {                
            var config = window.config;
            var vueInstance = this;
            // eslint-disable-next-line no-unused-vars
            var url = config.API_BASE + 'TCPGetProductDetailsJ.action';        
            // eslint-disable-next-line no-unused-vars
            var params = {
                    // prodId: vueInstance.article.id,
                    kkProdId: vueInstance.article.id,
                    json: "true",
                    includeChildren: "true",
                    addChildren: "true",
                    includeAllergenIds: "true",
                    includePropertyIds: "true",
                    includeNutriValues: "true"
                };
                    
            // eslint-disable-next-line no-unused-vars
            var callbackSuccess= function(result, textStatus, jqXHR) {       
                if( (result.exception !== undefined) || (result.item !== undefined) )
                {             
                    vueInstance.$alert(vueInstance.$t('getItemFailed'));
                    return;
                }                 

                vueInstance.quantity = 100;
                // if(result.product.recipe != undefined)
                // {
                //     var quantityParsed = parseInt(result.product.recipe);
                //     if(quantityParsed > 0)
                //     {
                //         vueInstance.quantity = quantityParsed;
                //     }                    
                //     else
                //     {
                //         vueInstance.quantity = 0;
                //     }
                // }
                // else {
                //     vueInstance.quantity = 0;
                // }
                            
                vueInstance.formattedPrice = result.product.formattedPrice;   
                vueInstance.decimalQuantity = result.product.decimalQuantity;       
            }
            
            // eslint-disable-next-line no-unused-vars
            var callbackError = function(result, textStatus, jqXHR) {   
            }
            
            if(vueInstance.article.id > 0)
                vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);
        }

    },
    methods: {
        addClick(event){
            var config = window.config;
            if(this.article.locked) return;
            if(config.FORCE_SHOW_ARTICLE_DETAILS_ON_ADD == true)
            {
                this.showArticlePage(event);
            }
            else
            {
                this.selectArticle(event);
            }
        },
        baseUrl(){          
            return window.imageBaseUrl;
        },
        trimDescription(description){
            var config = window.config;
            var trimTo = config.trimArticleDescriptionTo;

            if(description.length <= trimTo)
            {
                return description;
            }

            return description.substring(0, trimTo) + "...";
        },
        setIsValid(isValid){
            this.isValidSelection = isValid;
        },
        showArticlePage() {     
            if(this.article.locked) return;
            if(this.quantity > 0)
            {     
                this.callbackSelected(this.article, this.quantity, this.article.description);
            }                
        },
        getCartId() {
            if(window.shoppingCart != undefined)
            {
                for(var i = 0; i < window.shoppingCart.length; i++)
                {               
                    if(window.shoppingCart[i].prodId == this.article.id)
                    {
                        return window.shoppingCart[i].id;
                    }
                }
            }
            return 0;
        },
        toggleDetail(event){
            event.preventDefault();     
        },
        removeArticle(event){
            event.preventDefault();           
            this.callbackRemove(this.article.id, this);
        },
        selectArticle(event) {            
            event.preventDefault();
            if(this.article.decimalQuantity)
            {
                this.callbackAddWeight(this.article, this.quantity);
            }
            else
            {
                this.callbackAdd(this.article.id, this);
            }         

            $('.articleCardButtonPlacer').trigger('blur');
        },
        stopTheEvent: (event) => event.stopPropagation() 
    }
}
</script>

<style scoped>
.onHover{
     
     stroke: var(--secondary-color) !important;
     fill: white !important;
}

.onHover:hover{
    background-color:var(--secondary-color);
    color:white;      
    stroke: white !important;
    fill: var(--secondary-color) !important;
}

.onHoverInvert{
    background-color:var(--secondary-color) !important;
    color:white !important; 
}


.onHoverInvert:hover{
    color:var(--secondary-color) !important;
    background-color:white !important;
    border: 0.5px solid var(--secondary-color) !important;  
}

@media screen and (max-width:768px) {
    .onHoverInvert:hover{
        background-color:var(--secondary-color) !important;
        color:white !important;
        border: 0.5px solid var(--secondary-color) !important;    
    }    
}

.btn-override {   
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    border-radius:0px;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 0.5px solid transparent;
    /* padding: 0.375rem 0.75rem; */
    font-size: 1rem;
    line-height: 1.5;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.articleCardButtonPlacer {
    padding:0;
}

.articleCardButtonPlacer div {
    padding:0;
}

.articleCartPrice {
    /* padding: 0px 0% 0px 0px; */
    text-align:center;
}

.articleCardImageGlow {
    max-height:100%;
    height:150px;
    background-size: var(--article-card-image-fit);
    background-repeat:no-repeat;
    background-position:center;
}

@media screen and (max-width:776px) {
    .articleCardImageGlow {
        height:100px;
    }
}

.addIconMenu, .minusIconMenu{
    height:22px;
    margin:5px 0px 5px 0px;
    /* stroke:var(--main-color); */
    stroke:inherit;
}   

.articleCardImage {
    max-height:100%;
    height:100%;  
    background-size: var(--article-card-image-fit);
    background-repeat:no-repeat;
    background-position:center;
}

</style>