<template>
    <div class="creditCardPaymentWrapper" id="credit-card-payment">

        <loading :active.sync="isLoading" 
            :can-cancel="true"        
            :is-full-page="true"
            loader='bars'></loading>

        <iframe v-if=" transactionInterface" frameborder="0" width="100%" height="500px" :onload="onLoad" style="margin-top:70px;" id="ExternalPaymentFormFrame" name="ExternalPaymentFormFrame" :src="externalUrl"></iframe> 
        <iframe v-if=" !transactionInterface " frameborder="0" width="100%" height="1000px" :onload="onLoad" style="margin-top:70px;" id="ExternalPaymentFormFrame" name="ExternalPaymentFormFrame" :src="externalUrl"></iframe> 

    </div>
</template>

<script>
import VueLoading from 'vue-loading-overlay'

export default {
    data(){
        return {
            externalUrl : "",
            isLoading: true,
            vaultedCards: null,
            showSaferpayFrame: false,
            noVaultedCards: true,
            creditCardId: "",
            showVaultedCardsNotation: false,
            transactionInterface: false,
        }
    },
    props: {
        subCode: {
            Type: String
        }
    },
    computed:{
        config(){
            return window.config;
        },
        isTableMode(){
            if(window.TableMode == undefined)
            {
                return false;
            }

            return window.TableMode;
        }
    },
    methods: {
        deleteCreditCard(cardId){
            var config = window.config;
            var vueInstance = this;
            var url = config.API_BASE + 'SaferpayDeleteCardSubmitJ.action';     

            var params = {
                json:"true",
                cardId: cardId
            };

            var callbackSuccess= function(result, textStatus, jqXHR){       
                if(result.errorCode < 0)
                {
                    vueInstance.$alert(result.errorMsg);
                    return;                             
                }                                               
           
                // vueInstance.externalUrl = result.redirectURL;
                // vueInstance.isLoading = true;
                console.log(result);            
                console.log(textStatus);
                console.log(jqXHR); 

                vueInstance.showSaferpayFrame = false;
                vueInstance.loadSecurepay();               
                // vueInstance.scrollFrameIntoView();       
            }

            var callbackError = function(result, textStatus, jqXHR){
                console.log(result);
                console.log(textStatus);
                console.log(jqXHR);
            }
            
            vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);

        },
        scrollFrameIntoView(override = false){
                  setTimeout(() => {                       
                        var objDiv = document.getElementsByClassName("content")[0];
                        var iFrame = document.getElementById("ExternalPaymentFormFrame");
                        var creditCardsWrapper = document.getElementById("credit-cards-wrapper");                                 
                        
                        if(this.vaultedCards != null && this.vaultedCards.length > 0 && !override)
                        {
                            objDiv.scrollTop = objDiv.scrollHeight;
                            creditCardsWrapper.scrollIntoView({behavior: "smooth"});    
                        }
                        else
                        {
                            if(window.screen.width < 700)
                            {
                                objDiv.scrollTop = objDiv.scrollHeight;
                                if(iFrame != null)
                                    iFrame.scrollIntoView({behavior: "smooth"});   
                                setTimeout(() => {
                                    objDiv.scrollBy({
                                        top: -100,
                                        left: 0,
                                        behavior: 'smooth'
                                    });
                                }, 800); 
                            }
                            else
                            {
                                objDiv.scrollTop = objDiv.scrollHeight;
                                if(iFrame != null)
                                    iFrame.scrollIntoView({behavior: "smooth"});   
                                setTimeout(() => {
                                    objDiv.scrollBy({
                                        top: -100,
                                        left: 0,
                                        behavior: 'smooth'
                                    });
                                }, 800); 
                            }                                          
                        }                                                                                         
                    }, 200);
        },
        selectCreditCard(id)
        {
            this.isLoading = true;          
            this.creditCardId = id;
            this.selectCard(this.creditCardId);
        },
       
        onLoad() {
            this.isLoading = false;
        },  
        
        storeCardPrepareVaulted(){
            var config = window.config;
            var vueInstance = this;
            var url = config.API_BASE + 'SaferpayJ.action';     

            var params = {
                json:"true",
                storeCard: "true"
            };

            var callbackSuccess= function(result, textStatus, jqXHR){       
                if(result.errorCode < 0)
                {
                    vueInstance.$alert(result.errorMsg);
                    return;                             
                }                                               
           
                vueInstance.externalUrl = result.redirectURL;
                vueInstance.isLoading = true;
                console.log(result);            
                console.log(textStatus);
                console.log(jqXHR);        
                vueInstance.scrollFrameIntoView(true);       
            }

            var callbackError = function(result, textStatus, jqXHR){
                console.log(result);
                console.log(textStatus);
                console.log(jqXHR);
            }
            
            vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);
            vueInstance.showVaultedCardsNotation = true;
        },

        selectCard(cardId){
            var config = window.config;
            var vueInstance = this;
            var url = config.API_BASE + 'SaferpayJ.action';     

            var params = {
                json:"true",
                cardId: cardId
            };

            var callbackSuccess= function(result, textStatus, jqXHR){       
                if(result.errorCode < 0)
                {
                    vueInstance.$alert(result.errorMsg);
                    return;                             
                }                                               
                vueInstance.externalUrl = result.redirectURL;
                console.log(result);            
                console.log(textStatus);
                console.log(jqXHR);        
                vueInstance.scrollFrameIntoView(true);
            }

            var callbackError = function(result, textStatus, jqXHR){
                console.log(result);
                console.log(textStatus);
                console.log(jqXHR);
            }
            
            vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);


            // document.getElementById('ExternalPaymentFormFrame').onload = function() {
            //    vueInstance.onLoad();    
            // }
        },
        storeCardPrepareVaultedList(){
            var config = window.config;
            var vueInstance = this;
            var url = config.API_BASE + 'SaferpayManageVaultJ.action';     

            var params = {
                json:"true",                
            };

            var callbackSuccess= function(result, textStatus, jqXHR){       
                if(result.errorCode < 0)
                {
                    vueInstance.$alert(result.errorMsg);
                    return;                             
                }                

                vueInstance.externalUrl = result.redirectURL;
           
                console.log(result);            
                console.log(textStatus);
                console.log(jqXHR);  

                vueInstance.scrollFrameIntoView();
            }

            var callbackError = function(result, textStatus, jqXHR){
                console.log(result);
                console.log(textStatus);
                console.log(jqXHR);
            }
            
            vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);


            // document.getElementById('ExternalPaymentFormFrame').onload = function() {
            //    vueInstance.onLoad();    
            // }
        },
        loadSecurepay(){
            var vueInstance = this;
            var config = window.config;         
            
            var url = config.API_BASE + 'SecurepayJ.action';     

            var params = {
                json:"true"
            };

            // AP 20230512: There are no subcodes for securepay
            // console.log(vueInstance.subCode);
            // if(vueInstance.subCode == "T")
            // {
            //     vueInstance.transactionInterface = true;
            // }
            // else
            // {
            //     vueInstance.transactionInterface = false;
            // }

            var callbackSuccess= function(result, textStatus, jqXHR){       
                if(result.errorCode < 0)
                {
                    vueInstance.$alert(result.errorMsg);
                    return;                             
                }

                // console.log(vueInstance.transactionInterface);
               
                // if(vueInstance.transactionInterface)
                // {   
                    
                    // AP 20220503 Wird zur zeit nicht benötigt. Soll mittels eignene NovalnetMethoden gemacht werden
                    // if(result.page == "catalog.saferpayvault.page")
                    // {
                    //     console.log(result.vaultedCards);
                    //     vueInstance.vaultedCards = result.vaultedCards;
                    //     if(result.vaultedCards == undefined)
                    //     {
                    //         vueInstance.storeCardPrepareVaulted();
                    //         return;
                    //     }

                    //     if(result.vaultedCards.length == 0)
                    //     {
                    //         vueInstance.storeCardPrepareVaulted();
                    //         return;
                    //     }                        
                    // }
                    // else
                    // {
                    //     vueInstance.vaultedCards = result.vaultedCards;
                    //     vueInstance.storeCardPrepareVaulted();
                    //     return;
                    // }
                // }            
                
                vueInstance.externalUrl = result.redirectURL;
                // vueInstance.externalUrl = "https://paygate.novalnet.de/nn/fabf3e214556d1fd476339e48903a690";

                // window.Novalnet.setParam("nn_it", "inline");
                // window.Novalnet.setParam("txn_secret", result.txnSecret);

                console.log(result);            
                console.log(textStatus);
                console.log(jqXHR);   
    
                
                vueInstance.scrollFrameIntoView();
            }

            var callbackError = function(result, textStatus, jqXHR){
                console.log(result);
                console.log(textStatus);
                console.log(jqXHR);
            }
            
            vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);
            vueInstance.isLoading = false;

        }
    },
    components:{
        'loading' : VueLoading
    },
    mounted() {
        // AP 20220506 Novalnet prototype
        // let novalnetScript = document.createElement('script');
        // novalnetScript.setAttribute('src', 'https://paygate.novalnet.de/v2/checkout.js');
        // novalnetScript.setAttribute('id', 'novalnet-checkout-js');
        // document.head.appendChild(novalnetScript);


        var vueInstance = this;
        // if(vueInstance.subCode == "T")
        // {
        //     vueInstance.transactionInterface = true;
        // }

        vueInstance.loadSecurepay();
    
        document.getElementById('ExternalPaymentFormFrame').onload = function() {
            vueInstance.onLoad();    
        }
 
         
    }
}
</script>

<style>
.creditCardCard {
    display:inline-block;
    margin:15px;
    width:270px;
    height:165px;
    border-radius:5px;
    border: 0.5px solid black;
    text-align:start;
    background: rgb(0,57,70);
    background: radial-gradient(circle, rgba(0,57,70,0.15) 0%, rgba(111,111,111,0) 100%);
}
@media screen and (max-width:600px) {
    .creditCardCard {    
        margin:12px;
        width:250px;
        height:160px;
        border-radius:5px;
        border: 0.5px solid black;
        text-align:start;
        background: rgb(0,57,70);
        background: radial-gradient(circle, rgba(0,57,70,0.4) 0%, rgba(111,111,111,0.08) 100%);
    }

    #credit-cards-wrapper{
        padding-top:20px !important;
    }    
}

.creditCardCard:hover{
    cursor:pointer;
    box-shadow: var(--box-shadow);
    border:0.2px solid var(--main-color);
}

.creditCardCardBrandImage{
    /* max-width:70px; */
    max-height:40px;
    height:40px;
    margin:10px;
}

.cardNumber { 
    font-size:20px;
    padding:8px;
    padding-left:15px;
}
.cardInfo { 
    font-size:17px;   
    padding:8px;
    padding-left:15px;
}
.vaultedCardsNotation{
    font-size:12px;
}
</style>