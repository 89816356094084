import Vue from 'vue';
import VueRouter from 'vue-router';
import VueSimpleAlert from "vue-simple-alert";
import axios from 'axios';
import routes from './routes';
import {i18n} from '../public/translation.js';
import App from './App.vue';
import $ from 'jquery'
import VueCookies from 'vue-cookies'
import VueValidate from 'vuelidate'
import VueLoading from 'vue-loading-overlay'

import Vuex from 'vuex'

Vue.use(Vuex)
 

// import store from './store'





Vue.config.productionTip = false

Vue.use(axios);
Vue.use(VueRouter);
Vue.use(VueSimpleAlert);
Vue.use(VueCookies);
Vue.use(VueValidate);
Vue.use(VueLoading);
// Vue.use(SplitCarousel);

const router = new VueRouter({
  // mode: 'history',
  routes}); 
//, mode: 'history'

// (function($) {
//   $.fn.hasScrollBar = function(elem) {
//       return elem.get(0). > elem.height();
//   }
// })($);
var vueApp = new Vue({
  router,
  i18n,
  render: h => h(App),
  routes
});

vueApp.$mount('#app')

// eslint-disable-next-line no-unused-vars
var mixin = Vue.mixin({
  data: function() {
    return {
      globalCartObject: {
        Type:Array
      },
      TableMode : false
    }
  },
  computed: {
    shoppingCart() {
      return this.globalCartObject;
    }
  },
  methods: {
    updateShoppingCart: function() {
      var config = window.config;
      var vueInstance = this;
      var url = config.API_BASE + 'TCPConfirmOrderJ.action'; 
      var params = {};    
      if(window.customerLoggedIn)
      {  
        params = {
          calculateOrder:"true",
          orderType: window.orderType,
        };
      }
                
      var callbackSuccess= function(result){       
          window.shoppingCart = result.basketTile.basketItems;   
          window.shoppingTile = result.basketTile;
      }

      var callbackError = function(){
      }
   
      vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);
    },
    makeRequest: function (type, url, data, callbackSuccess, callbackError, dataType) {
      console.log("ajax request:");
      console.log(type);
      console.log(url);

      if(type == "POST")
      {
        axios.post(url, JSON.parse(data))
        .then(function (response) {
            console.log(response);
            callbackSuccess(response.data,response,response);
        })
        .catch(function (error) {
            callbackError(error,error,error);
        });
      }
      else
      {
        $.ajax({
          method : "GET",
          type : "GET",
          timeout : '20000',
          headers: {
            "Access-Control-Allow-Origin":"*",
          },
          scriptCharset : "utf-8",
          contentType : "application/json; charset=utf-8",        
          url : url,
          data : "kkj=kkj&" + encodeURIComponent(data),
          // context : callback,
          success : callbackSuccess,
          error: callbackError,
          dataType : dataType != undefined ? dataType :'jsonp'
        });
      }


        window.REQUEST_TIMESTAMP = new Date().getTime();
    },

    handleLoginSuccess: function (result) {
      if(result.errorCode < 0)
      {
        return;
      }

      window.customerLoggedIn = result.loginUsername;
    },

    returnToPrevious() {
      this.$router.go(-1);
    },
  },
});

