<template>
    <div class="editCustomerPageWrapper">             

        <div class="col-12 subPageHeader row"  >
            <button class="btn btn-primary subPageHeader subPageHeaderButton" @click="goBack()">
               {{$t('back')}}
            </button>
            <div> <h1>{{ $t('editCustomerPageTitle') }}</h1> </div>
        </div>              

        <form @submit.prevent="onSubmit">

            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <!-- <div class="form-group" v-show="config.REGISTRATION_FORM_FIELDS.emailAddr == ''">
                        <label class="fontField">{{$t('customerEmail')}}</label>
                        <input type="email" class="form-control form-control-lg" v-model="edit.emailAddr" />          
                    </div> -->

                    <!-- <div class="form-group" v-show="config.REGISTRATION_FORM_FIELDS.password !== undefined">
                        <label class="fontField">{{$t('customerPassword')}}</label>
                        <input type="password" class="form-control form-control-lg" v-model="edit.password" />
                        <div class="inputError" role="alert" v-if="!$v.edit.password.minLength">Password must have at least {{$v.login.password.$params.minLength.min}} letters.</div>
                    </div> -->

                    <div class="form-group" v-show="config.REGISTRATION_FORM_FIELDS.title  == ''">
                        <label for="title" class="fontField">{{$t('customerTitle')}}</label>
                        <input id="title" type="text" class="form-control form-control-lg" maxlength="10"  v-model="edit.title" />
                        <!-- <div class="inputError" role="alert" v-if="!$v.edit.firstName.required">Please enter your name</div>           -->
                    </div>

                    <div class="form-group" v-show="config.REGISTRATION_FORM_FIELDS.firstName  == ''">
                        <label for="firstname" class="fontField">{{$t('customerFirstName')}}</label>
                        <input id="firstname" type="text" class="form-control form-control-lg" v-model="edit.firstName" />
                        <!-- <div class="inputError" role="alert" v-if="!$v.edit.firstName.required">Please enter your name</div>           -->
                    </div>

                    <div class="form-group" v-show="config.REGISTRATION_FORM_FIELDS.lastName  == ''">
                        <label for="lastname" class="fontField">{{$t('customerLastName')}}</label>
                        <input id="lastname" type="text" class="form-control form-control-lg"  maxlength="40"  v-model="edit.lastName" />
                        <!-- <div class="inputError" role="alert" v-if="!$v.edit.lastName.required">Please enter your last name</div>           -->
                    </div>

                     <div class="form-group" v-show="config.REGISTRATION_FORM_FIELDS.streetAddress  == ''">
                        <label for="address" class="fontField">{{$t('customerAddress')}}</label>
                        <input id="address" type="text" class="form-control form-control-lg" v-model="address.streetAddress" />
                        <!-- <div class="inputError" role="alert" v-if="!$v.edit.streetAddress.required">Please enter your address</div>           -->
                    </div>

                    <div class="form-group" v-show="config.REGISTRATION_FORM_FIELDS.otherInfos  == ''">
                        <label for="otherinfos" class="fontField">{{$t('customerOtherInfos')}}</label>
                        <input id="otherinfos" type="text" class="form-control form-control-lg" v-model="address.otherInfos" />
                        <!-- <div class="inputError" role="alert" v-if="!$v.edit.otherInfos.required">Please enter your address</div>           -->
                    </div>

            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" style="padding-right:0px;">
               <div class="form-group" v-show="config.REGISTRATION_FORM_FIELDS.postcode  == ''">
                        <label for="postal" class="fontField">{{$t('customerPostal')}}</label>
                        <input id="postal" type="text" class="form-control form-control-lg" maxlength="10" v-model="address.postcode" />
                        <!-- <div class="inputError" role="alert" v-if="!$v.address.postcode.required">Please enter your postal code</div>           -->
                    </div>

                    <div class="form-group" v-show="config.REGISTRATION_FORM_FIELDS.city  == ''">
                        <label for="city" class="fontField">{{$t('customerCity')}}</label>
                        <input id="city" type="text" class="form-control form-control-lg" maxlength="50"  v-model="address.city" />
                        <!-- <div class="inputError" role="alert" v-if="!$v.edit.city.required">Please enter your city</div>           -->
                    </div>

                    <div class="form-group" v-show="config.REGISTRATION_FORM_FIELDS.countryId  == ''">
                        <label for="countryid" class="fontField">{{$t('customerCountry')}}</label>
                        <input id="countryid" type="text" class="form-control form-control-lg" v-model="address.countryId" />
                        <!-- <div class="inputError" role="alert" v-if="!$v.edit.country.required">Please enter your country</div>           -->
                    </div>
             
                    <div class="form-group" v-show="config.REGISTRATION_FORM_FIELDS.country !== undefined">
                        <label for="country" class="fontField">{{$t('customerCountry')}}</label>
                        <!-- <input type="text" class="form-control form-control-lg" v-model="registration.country" />
                        <div class="inputError" role="alert" v-if="!$v.registration.country.required">Please enter your country</div> -->
                        <!-- <select-picker-country class="countrySelect" v-if="countriesLoaded" :options="countries" :callback="selectCountry" :preselectedId="selectedCountryId" ></select-picker-country> -->
                        <select-picker-country id="country" class="countrySelect" v-if="countriesLoaded" :options="countries" :callback="selectCountry" :preselectedId="selectedCountryId" ></select-picker-country>
                    </div>

                    <div class="form-group" v-show="config.REGISTRATION_FORM_FIELDS.telephoneNumber  == ''">
                        <label for="phone" class="fontField">{{$t('customerPhone')}}</label>
                        <input id="phone" type="text" class="form-control form-control-lg"  maxlength="40"  v-model="edit.telephoneNumber" />
                        <!-- <div class="inputError" role="alert" v-if="!$v.edit.phone.required">Please enter your phone number</div>           -->
                    </div>

                    <div class="form-group" v-show="config.REGISTRATION_FORM_FIELDS.telephoneNumber1  == ''">
                        <label for="phone1" class="fontField">{{$t('customerPhone')}}</label>
                        <input id="phone1" type="text" class="form-control form-control-lg"  maxlength="40"  v-model="edit.telephoneNumber1" />
                        <!-- <div class="inputError" role="alert" v-if="!$v.edit.phone.required">Please enter your phone number</div>           -->
                    </div>
                    
                    <div class="form-group" v-show="config.REGISTRATION_FORM_FIELDS.notes1 !== undefined">
                        <label for="notes1" class="fontField">{{$t('customerNotes1')}}</label>
                        <input id="notes1" type="text" class="form-control form-control-lg" maxlength="50"  v-model="edit.notes1" />
                        <!-- <div class="inputError" role="alert" v-if="!$v.edit.streetAddress.required">Please enter your address</div>           -->
                    </div>

                    <div class="form-group" v-show="config.REGISTRATION_FORM_FIELDS.notes2 !== undefined">
                        <label for="notes2" class="fontField">{{$t('customerNotes2')}}</label>
                        <input id="notes2" type="text" class="form-control form-control-lg" maxlength="50"  v-model="edit.notes2" />
                        <!-- <div class="inputError" role="alert" v-if="!$v.edit.streetAddress.required">Please enter your address</div>           -->
                    </div>
                    
                    <div class="form-group" v-show="config.REGISTRATION_FORM_FIELDS.notes3  == ''">
                        <label for="notes3" class="fontField">{{$t('customerNotes3')}}</label>
                        <input id="notes3" type="text" class="form-control form-control-lg" maxlength="50"  v-model="edit.notes3" />
                        <!-- <div class="inputError" role="alert" v-if="!$v.edit.streetAddress.required">Please enter your address</div>           -->
                    </div>
                    <div class="form-group" v-show="config.REGISTRATION_FORM_FIELDS.notes4  == ''">
                        <label for="notes4" class="fontField">{{$t('customerNotes4')}}</label>
                        <input id="notes4" type="text" class="form-control form-control-lg" v-model="edit.notes4" />
                        <!-- <div class="inputError" role="alert" v-if="!$v.edit.streetAddress.required">Please enter your address</div>           -->
                    </div>
                </div>

                <!-- <div class="col-12">
                    <div class="form-group" v-show="config.REGISTRATION_FORM_FIELDS.privacy  == ''">
                        

                        <check-box                         
                            :label="$t('customerPrivacy')"
                            :value="1"
                            :trueValue="'1'"
                            :falseValue="'0'"
                            v-model="edit.privacy">
                        </check-box>                   
                    </div>

                    <div class="form-group" v-show="config.REGISTRATION_FORM_FIELDS.newsletter  == ''">
                      

                    <check-box                         
                            :label="$t('customerNewsletter')"
                            :value="1"
                            :trueValue="'1'"
                            :falseValue="'0'"
                            v-model="edit.newsletter">
                        </check-box>                  
                    </div>

                    <div class="form-group" v-show="config.REGISTRATION_FORM_FIELDS.tc  == ''">
                       

                        <check-box                         
                            :label="$t('customerTC')"
                            :value="1"
                            :trueValue="'1'"
                            :falseValue="'0'"
                            v-model="edit.terms">
                        </check-box>                     
                    </div>
                </div>        -->
                   <div class="col-12">
                        <!-- <button type="submit" @click="onSubmit" :disabled="submitStatus === 'PENDING'" class="btn btn-dark btn-lg btn-block btnSubmit floatRight">{{ $t('save')}}</button> -->
                        <button type="submit" @click="onSubmit" :disabled="submitStatus === 'PENDING'" class="btn btn-success btn-lg btn-block btnSubmit floatRight">{{ $t('save')}}</button>
                   </div>
        </form>

            <br/>
                    <br/>
                    <br/>
                    <br/>
                <div class="col-12" style="height:300px;">
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                </div>

    </div>
</template>


<script>
import { required, minLength } from 'vuelidate/lib/validators'
import selectPickerCountry from '../sub-components/SelectPickerCountry.vue'
// import CheckBox from '../sub-components/CheckBox.vue'
// import VueLoading from 'vue-loading-overlay'

export default {
    data() {
        return {
            submitStatus: null,           
            edit: {     
                emailAddr:"",
                firstName:"",
                lastName:"",
                streetAddress: "",
                streetAddress1: "",
                suburb:"",
                postcode:"",
                otherInfos:"",
                notes1:"",
                notes2:"",
                notes3:"",
                notes4:"",
                city:"",
                telephoneNumber:"",
                state:"",
                privacy:"",
                newsletter:"",
                terms:"",

            },
            address:{

            },
            countriesLoaded: false,
            countries: {
                Type: Array
            },
             selectedCountry: {
                Type:Object
            },
            zones: {
                Type: Array
            },
            selectedCountryId: 0,
        }	
	},
    props:{
        customer: {
            Type: Object
        },
        user:{
            Type:String
        }
    },
    computed: {
        config(){
            return window.config;
        }
    },
    components:{
        'select-picker-country': selectPickerCountry
        // 'loading' : VueLoading
        // 'check-box': CheckBox
    },
    validations: {
        edit: {
            emailAddr:{
                required,         
                minLength : minLength(1)
            },
            firstName:{
                required
            },
            lastName:{
                required             
            },
            company: {
                required
            },
            streetAddress: {
                required
            },
            postcode: {
                required
            },
            city:{
                required
            },
            telephoneNumber:{
                required
            }
        }       
    },
    methods: { 
        selectCountry(countryId) {
            console.log("selectCountry");
            console.log(countryId);
            this.edit.countryId = countryId;      
        },
        goBack(){
            this.$router.go(-1);
        },
        onSubmit: function(event) {
			event.preventDefault();

            var params = {  
                "gender": "-",
                "json": "true",
                "allowNoRegister": "false",
                "firstName": this.edit.firstName,
                "title": this.edit.title,
                "lastName": this.edit.lastName,
                "company": this.edit.company,
                "taxId": "",
                "streetAddress": this.address.streetAddress,
                "otherInfos": this.address.otherInfos,
                "suburb": this.address.suburb,
                "postcode": this.address.postcode,
                "city":this.address.city,
                "countryId":this.edit.countryId,
                "notes1":this.edit.notes1,
                "notes2":this.edit.notes2,
                "notes3":this.edit.notes3,
                "notes4":this.edit.notes4,
                // "state":this.address.state,
                "telephoneNumber":this.edit.telephoneNumber,
                "telephoneNumber1": "",
                "faxNumber": "",
                "countryChange": "0",
                "privacy": this.edit.privacy,
                "tc": this.edit.terms,
                "newsletter": this.edit.newsletter,
            };

            if(window.xtoken != undefined)
            {
                params.xsrfToken = btoa(window.xtoken);
            }

            var config = window.config;
            var vueInstance = this;
            var url = config.API_BASE + 'TCPEditCustomerSubmitJ.action';     

            // eslint-disable-next-line no-unused-vars
            var callbackSuccess= function(result, textStatus, jqXHR){       
            
                if(result.exception !== undefined)
                {                
                    vueInstance.$router.go(-1);
                    return;
                }

                if(result.requiresLogin)
                {
                    vueInstance.$router.push({path:'/Login'});
                }
            
                vueInstance.$router.go(-1);
            }

            // eslint-disable-next-line no-unused-vars
            var callbackError = function(result, textStatus, jqXHR){
                vueInstance.isLoading = false;
            }          

            vueInstance.makeRequest("POST", url, JSON.stringify(params), callbackSuccess, callbackError);       
		}
    },
    beforeMount() {       
        this.edit = undefined;
        this.edit = window.customer;
        console.log(this.edit);
        if(this.edit == undefined)
        {
            this.$router.go(-1);
        }
        this.address = this.edit.addresses[0];

        window.customer = undefined;

        var config = window.config;
        var vueInstance = this;
        var url = config.API_BASE + 'TCPCustomerRegistrationJ.action';        
        var params = {
            json: "true",
            countryChange : 0
        };        

        // eslint-disable-next-line no-unused-vars
        var callbackSuccess= function(result, textStatus, jqXHR) {          
            vueInstance.countries = result.countries;
            var defaultAddr = vueInstance.edit.addresses.filter(function(x) { return x.id = vueInstance.edit.defaultAddrId})[0];
            if(defaultAddr != undefined)
            {                
                vueInstance.selectedCountryId = defaultAddr.countryId;  
            }
            else
            {
                vueInstance.edit.countryId = result.selectedCountry.id;  
                vueInstance.selectedCountryId = result.selectedCountry.id;  
            }
          
            vueInstance.countriesLoaded = true;
        }

        // eslint-disable-next-line no-unused-vars
        var callbackError = function(result, textStatus, jqXHR){  
        }

        vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);
    }
}
</script>

<style scoped>
.btnSubmit {
 width:200px;
 float:right;
}
</style>