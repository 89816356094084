<template>
    <div class="checkoutFinishedWrapper" v-if="!isInIFrame">   
        <!-- <flow :currentPoint="5"> </flow> -->

        <div class="fontTitle" style="text-align:center;">
            {{ $t('checkoutFinishedMessageTitle') }}
        </div>
    

        <div class="fontOrderSuccessMessage" v-if="orderTypeComputed == 'delivery' && !tableMode">
            {{ $t('checkoutFinishedMessageBodyDelivery') }}        
        </div>

        <div class="fontOrderSuccessMessage" v-if="orderTypeComputed == 'takeaway' && !tableMode">
            {{ $t('checkoutFinishedMessageBodyTakeaway') }}
            <br/>
            {{ orderDateTime }}
            <br/>
            <br/>
            <div>
                {{ $t('pickupSpot') }}:
                <br/>
                {{ shop.name }}
                <br/>
                <span v-html="shop.custom6"></span>
            </div>
        </div>

        <div class="fontOrderSuccessMessage" v-if="orderTypeComputed == 'dinein' && !tableMode">
            {{ $t('checkoutFinishedMessageBodyDinein') }}
            <br/>    
            {{ orderDateTime }}
            <br/>
            <br/>
            <div>
                {{ $t('pickupSpot') }}:
                <br/>
                {{ shop.name }}
                <br/>
                <span v-html="shop.custom6"></span>
            </div>
        </div>

        
        <div class="fontOrderSuccessMessage" v-if="tableMode">
            {{ $t('checkoutFinishedMessageBodyTableMode') }}
            <br/>
        </div>

        <button type="button" v-if="!tableMode && !isGuestCheckout" @click="orderOverviewPage" class="btn btn-primary">{{$t('orderOverview')}}</button>
        <button type="button" v-if="!tableMode" @click="newOrder" class="btn btn-primary">{{$t('newOrder')}}</button>
    </div>
    <div v-else>
        <div class="fontTitle" style="text-align:center;">
            {{ $t('checkoutFinishedIFrameRedirecting') }}
        </div>
    </div>
</template>


<script>
// import FlowIndicator from '../main-components/FlowIndicator.vue'

export default {
    data(){
        return {
            comment:"",
            basketItems: {
                Type: Array
            },
            tableMode: false,
            shop: {
                Type: Object
            }
        }
    },
    components:{
    },
    beforeMount() {
        if(window.config.GUEST_CHECKOUT == true && window.isGuestCheckout == true)
        {
            this.logout();
        }         

        this.tableMode = window.TableMode;
        
        // var url = window.config.API_BASE + 'TCPStartJ.action';            

        // // eslint-disable-next-line no-unused-vars
        // var callbackSuccess= function(result, textStatus, jqXHR) {
        //     window.shoppingCart = null;
        // }

        // var params = {                    
        //     json: "true",
        //     emptyBasket: "true"
        // };

        //this.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, null);
    },
    mounted() {
        this.shop = window.shopObj;
        // Delete/Reset cookies
        if(window.config.COOKIES_ENABLED){
            this.$cookies.remove('tableId');
            this.$cookies.remove('shopId');
            this.$cookies.remove('TableMode');
            // this.$cookies.remove('shopObj');
            this.$cookies.remove('orderType');
        }
    },
    computed: {
        isGuestCheckout(){
            if(window.isGuestCheckout == true)
                return true;
            else
                return false;
        },
        orderTypeComputed() {
     
            return window.orderType;
        },
        orderDateTime (){
            return window.orderDateTime;
        },
        isInIFrame(){
            try{
                return window.self !== window.top;
            } catch (e) {
                return true;
            }
        },
        // tableMode(){
        //     return window.TableMode;
        // }
    },
    methods:{
        logout(){
            
            var config = window.config;
            var vueInstance = this;
            var url = config.API_BASE + 'TCPLogoutJ.action';     

            var params = {};

            var callbackSuccess= function(result, textStatus, jqXHR){       
                if(result.errorCode < 0)
                {
                    vueInstance.$alert(result.errorMsg);
                    return;                   
                }
         
                window.customerLoggedIn = null;
                window.isGuestCheckout = false;
                console.log(result);            
                console.log(textStatus);
                console.log(jqXHR);        
            }

            var callbackError = function(result, textStatus, jqXHR){
                console.log(result);
                console.log(textStatus);
                console.log(jqXHR);
            }
            
            vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);
        },
        orderOverviewPage() {
            this.$router.push({ path: '/Account' });            
        },
        newOrder(){
            this.$router.push({ path: '/Shop' });
        }
    },
    beforeRouteLeave (to, from , next) {
        window.config.SHOW_GLOBAL_CONTROL_GROUP = !window.config.SHOW_GLOBAL_CONTROL_GROUP;
        // if(this.isInIFrame()) window.showHeaderFooter = true;
        next();
    },
    // eslint-disable-next-line no-unused-vars
    beforeRouteEnter (to, from, next) {
        window.config.SHOW_GLOBAL_CONTROL_GROUP = !window.config.SHOW_GLOBAL_CONTROL_GROUP;
        // if(this.isInIFrame()) window.showHeaderFooter = false;
         next();        
    },
}
</script>

<style scoped>
</style>