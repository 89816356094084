<template>
    <div class="shoppingCartWrapper">
       <div class="col-12 subPageHeader"  >
            {{ $t('shoppingCartTitle') }}
            <button class="btn btn-primary subPageHeader subPageHeaderButton" @click="goBack(false)">
               {{$t('back')}}
            </button>
        </div>      

        <div class="col-12" style="padding-left:0px;padding-right:0px;padding-top:10px;">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 shoppingCartSection" style="border-right:2px solid var(--main-color);">
                <span v-if="basketItems.length == 0"> {{ $t('shoppingCartEmpty') }}</span>
                <table class="shoppingCartItemsTable"  style="table-layout:fixed" v-if="basketItems.length > 0">
                    <thead>
                        <th class="shoppingCartHeaderBig">{{ $t('article') }}</th>
                        <th class="shoppingCartHeader">{{ $t('quantity') }}</th>
                        <th class="shoppingCartHeader">{{ $t('summ') }}</th>
                        <th class="shoppingCartHeaderSmall"></th>
                        <th class="shoppingCartHeaderSmall"></th>
                        <th class="shoppingCartHeaderSmall"></th>
                    </thead>
        
                    <tr v-for="item in basketItems" v-bind:key="item.id">
                        <td class="shoppingCartLine">{{(item.prodText !== undefined) ? item.prodText :item.text}}
                            <div v-if="item.hasOptions" style="padding:0;text-align:right;">
                                <span style="font-size:13px;padding:0;" v-html="item.optionText"></span>
                            </div>
                        </td>

                        <td class="shoppingCartLine mainColor" style="text-align:right">{{ item.weightOrVolume != undefined ? item.weightOrVolume * 1000 : item.quantity }} {{item.weightOrVolume != undefined ? 'g' : 'x'}}</td>
                        <td class="shoppingCartLine mainColor">{{item.formattedPrice}}</td>

                        <td class="shoppingCartLine" @click="addArticle(item)">
                            <svg class="addIcon" viewBox="0 0 100 100">
                                <circle cx="50" cy="50" r="45" fill="none" stroke-width="10.5"></circle>
                                <line x1="32.5" y1="50" x2="67.5" y2="50" stroke-width="7"></line>
                                <line x1="50" y1="32.5" x2="50" y2="67.5" stroke-width="7"></line>
                            </svg>                           
                        </td>

                        <td class="shoppingCartLine" @click="removeArticle(item)">
                            <svg class="minusIcon" viewBox="0 0 100 100">
                                <circle cx="50" cy="50" r="45" fill="none" stroke-width="10.5"></circle>
                                <line x1="32.5" y1="50" x2="67.5" y2="50" stroke-width="7"></line>                                
                            </svg>
                        </td>

                        <td class="shoppingCartLine" @click="deleteItem(item)">
                           <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="24px" height="24px" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M9 3v1H4v2h1v13a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V6h1V4h-5V3H9m0 5h2v9H9V8m4 0h2v9h-2V8z" fill="gray"/></svg>
                        </td>
                    </tr>            
                </table>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 shoppingCartSection">
                <table class="shoppingCartSummaryTable" v-if="basketItems.length > 0">
                    <thead>
                        <th class="shoppingCartSummaryHeader"> {{ $t('shoppingCartSummaryTitle') }}</th>
                    </thead>              
                </table>

                <table class="shoppingCartSummaryTable" v-if="basketItems.length > 0">
                    <thead>
                        <th style="width:50%"></th>
                        <th style="width:50%"></th>
                    </thead>
                    <tr>
                        <td class="shoppingCartSummaryLine">{{ $t('summ') }}</td>
                        <td class="shoppingCartSummaryLine">{{basketTotal}}</td>
                    </tr>
                </table>                
            </div>

            <div class="col-12">
                <button @click="goToCheckout" class="btn btn-primary" style="float:right;margin-right:0px;">Checkout</button>
            </div>
            
        </div>
       
    </div>
</template>


<script>

export default {
    data(){
        return {
            dataCounter: 0,
            basketItems: {
                Type:Array
            },
            basketTotal:""
        }
    },
    methods:{
        goBack(){
            this.$router.go(-1);
        },
        goToCheckout(){
            this.$router.push({ path: '/OrderConfirmation' });
        },
        updateCart(){
            var config = window.config;
            var vueInstance = this;
            var url = config.API_BASE + 'TCPConfirmOrderJ.action';  
            var params = {};
            if(window.customerLoggedIn)
            {
                params = {
                    calculateOrder: "true",
                    orderType: window.orderType,
                    promotionBarcode: window.promotionBarcode != undefined ? window.promotionBarcode : ""
                };
            }        
            
            var callbackSuccess= function(result, textStatus, jqXHR) {
                if(result.exception !== undefined)
                {
                    vueInstance.$alert(this.$t("exceptionMessage"));
                    return;
                }

                if(result.xsrfToken != undefined)
                {
                    window.xtoken = atob(result.xsrfToken);
                } 
                
                if(result.errorCode > 0)
                {
                    vueInstance.$alert(result.erroMsg);
                    return;
                }

                window.shoppingCart = result.basketTile.basketItems;
                if(result.basketTile.paymentsList != undefined){
                    window.subsidy = vueInstance.formatCurrency(result.basketTile.paymentsList[0].paymentAmount);
                }else{
                    window.subsidy = "";
                }        
                vueInstance.basketItems = result.basketTile.basketItems;
                vueInstance.basketTotal = result.basketTile.formattedTotal;
                console.log(result);            
                console.log(textStatus);
                console.log(jqXHR);
            }

            var callbackError = function(result, textStatus, jqXHR){
                console.log(result);
                console.log(textStatus);
                console.log(jqXHR);
            }
        
            vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);
        },
        addArticle(article) {
            var config = window.config;
            var vueInstance = this;
            var url = config.API_BASE + 'TCPAddMenuItemToCartJ.action';     

            var params = {
                productId: article.prodId,
                json: "true",
                orderType:  window.orderType,
                quantity: 1,
            };
            
            if(window.customerLoggedIn)
            {
                params.calculateOrder = "true";
            }
            // eslint-disable-next-line no-unused-vars
            var callbackSuccess= function(result, textStatus, jqXHR) {       
                if(result.exception !== undefined)
                {
                        vueInstance.$alert(vueInstance.$t('addItemFailed'));
                    return;
                } 
                
                vueInstance.updateCart();      
                vueInstance.$forceUpdate();
                vueInstance.dataCounter++;   
            }

            // eslint-disable-next-line no-unused-vars
            var callbackError = function(result, textStatus, jqXHR) {      
            }
        
            vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);
        },
        removeArticle(article) {   
            var config = window.config;
            var vueInstance = this;

            var url = config.API_BASE + 'TCPEditCartSubmitJ.action'; 
            var elementCartId = 0;
            var elementQuantity = 0;

            if(window.shoppingCart != undefined)
            {
                for(var i = 0; i < window.shoppingCart.length; i++)
                {               
                    if(window.shoppingCart[i].prodId == article.prodId)
                    {
                        elementCartId = window.shoppingCart[i].id;
                        elementQuantity = window.shoppingCart[i].quantity;
                    }
                }
            }      

            var params = {
                basketId: elementCartId,
                quantity: elementQuantity-1
            };

            
            if(window.xtoken != undefined)
            {
                params.xsrfToken = btoa(window.xtoken);
            }

            if(params.quantity == 0)
            {
                vueInstance.deleteItem(article);
                return;
            }

            // eslint-disable-next-line no-unused-vars
            var callbackSuccess= function(result, textStatus, jqXHR) {       
                if(result.exception !== undefined)
                {
                    if(result.exception.code === 64){
                        vueInstance.$alert(vueInstance.$t('removeItemMinError'));
                        return;
                    }else if(result.exception.code === 65){
                        vueInstance.$alert(vueInstance.$t('removeItemMaxError'));
                        return;
                    }
                    vueInstance.$alert(vueInstance.$t('removeItemFailed'));
                    return;
                } 
                
                vueInstance.updateCart();
                window.shoppingCart = result.basketTile.basketItems;
                if(result.basketTile.paymentsList != undefined){
                    window.subsidy = vueInstance.formatCurrency(result.basketTile.paymentsList[0].paymentAmount);
                }else{
                    window.subsidy = "";
                }
                vueInstance.$forceUpdate();
                vueInstance.dataCounter++;                
            }

            // eslint-disable-next-line no-unused-vars
            var callbackError = function(result, textStatus, jqXHR) {                
            }
        
            vueInstance.makeRequest("POST", url, JSON.stringify(params), callbackSuccess, callbackError);
        },
        formatCurrency(value){
            var formatter = new Intl.NumberFormat('de-DE', {style: 'currency', currency: window.config.SUBSIDY_CURRENCY});
            return formatter.format(value);
        },
        deleteItem(article) {   
            var config = window.config;
            var vueInstance = this;

            var url = config.API_BASE + 'TCPRemoveMenuItemFromCartJ.action';     
            var elementCartId = 0;

            if(window.shoppingCart != undefined)
            {
                for(var i = 0; i < window.shoppingCart.length; i++)
                {               
                    if(window.shoppingCart[i].prodId == article.prodId)
                    {
                        elementCartId = window.shoppingCart[i].id;
                    }
                }
            }      

            var params = {
                id: elementCartId,
                orderType: window.orderType,
            };

            // eslint-disable-next-line no-unused-vars
            var callbackSuccess= function(result, textStatus, jqXHR) {       
                if(result.exception !== undefined)
                {
                    vueInstance.$alert(vueInstance.$t('removeItemFailed'));
                    return;
                } 
                
                vueInstance.updateCart();
                window.shoppingCart = result.basketTile.basketItems;
                if(result.basketTile.paymentsList != undefined){
                    window.subsidy = vueInstance.formatCurrency(result.basketTile.paymentsList[0].paymentAmount);
                }else{
                    window.subsidy = "";
                }
                vueInstance.$forceUpdate();
                vueInstance.dataCounter++;                
            }

            // eslint-disable-next-line no-unused-vars
            var callbackError = function(result, textStatus, jqXHR) {                
            }
        
            vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);
        }
    },
    beforeMount() {
        this.updateCart();
    }   
}
</script>

<style scoped>
.navBackIcon {
    height:50px;
    float:left;
    width:50px;
}

.deleteIcon {
    color:white;
}

.navBack{
    color:var(--main-color);
}

.shoppingCartHeader {
    width:20%;
    color:black;
    background-color: lightgray; 
    text-align:right;    
}

.shoppingCartHeaderSmall {
    width:10%;
    color:black;
    background-color: lightgray; 
    text-align:right;    
}

.shoppingCartHeaderBig {
    width:30%;
    color:black;
    background-color: lightgray; 
    text-align:right;    
}

.shoppingCartSummaryHeader {
    width:100%;
    color:black;
    background-color: lightgray; 
    text-align:left;
}

.shoppingCartLine{
    text-align:right;
    border-bottom: 0.5px solid var(--main-color);
    font-weight:400;
}

.shoppingCartItemsTable{
    width:100%;
}
.shoppingCartSummaryLine{
    float:left;
    width:50%;
}

.shoppingCartSummaryTable{
    width:100%;
}

.addIcon, .minusIcon{
    height:20px;
    stroke:var(--main-color-highlight);
}

.addIcon img {
    height:17px;
}

@media screen and (max-width: 1000px) { 
    .shoppingCartSection{
        min-height:100px;    
    }
}

</style>