<template>
    <div class="registrationPageWrapper">        
        <loading :active.sync="isLoading" 
            :can-cancel="true"        
            :is-full-page="true"
            loader='bars'></loading>

        <div class="col-12 subPageHeader" style="  position:relative;">
            <span v-if="!isGuestCheckout">{{  $t('registrationPageTitle')}}</span>
            <span v-if="isGuestCheckout">{{  $t('orderAsGuest')}}</span>
            <button class="btn btn-primary subPageHeader subPageHeaderButton" @click="goBack()">
                {{$t('back')}}
            </button>
        </div>  

        <form @submit.prevent="onSubmit">
            <div class="registrationForm">
                <div class="registrationLeft">
                    <div class="form-group" v-show="config.GUEST_REGISTRATION_FORM_FIELDS.emailAddr !== undefined">
                        <label class="fontField">{{$t('customerEmail')}}<span v-if="status($v.registration.emailAddr)" style="color:var(--main-color);">*</span></label>
                        <input  @change="validate()" type="email" class="form-control form-control-lg" v-model="registration.emailAddr" />
                        <div class="inputError" role="alert" v-if="!$v.registration.emailAddr.email"><b-icon-exclamation-triangle-fill></b-icon-exclamation-triangle-fill> {{$t('invalidEmail')}}</div>  
                        <!-- <div class="inputError" role="alert" v-if="!$v.registration.emailAddr.email">{{$t('customerFieldGenericError')}}</div>   -->
                                
                    </div>

                    <div class="form-group" v-if="config.GUEST_REGISTRATION_FORM_FIELDS.password == '' && !isGuestCheckout">
                        <label class="fontField">{{$t('customerPassword')}}<span v-if="status($v.registration.password)" style="color:var(--main-color);">*</span></label>
                        <input  @change="validate()" type="password" class="form-control form-control-lg" v-model="registration.password" />
                        <div class="inputError" role="alert" v-if="!$v.registration.password.minLength"><b-icon-exclamation-triangle-fill></b-icon-exclamation-triangle-fill> {{ $t('inputErrorPasswordMinLength1') }} {{$v.registration.password.$params.minLength.min}} {{$t('inputErrorPasswordMinLength2')}}</div>
                    </div>

                    <div class="form-group" v-if="config.GUEST_REGISTRATION_FORM_FIELDS.cardNumber == ''">
                        <label class="fontField">{{$t('customerCardNumber')}}<span v-if="status($v.registration.cardNumber)" style="color:var(--main-color);">*</span></label>
                        <input @change="validate()" type="text"  maxlength="30"  class="form-control form-control-lg" v-model="registration.cardNumber" />
                        <!-- <div class="inputError" role="alert" v-if="!$v.registration.cardNumber.error == true">{{$t('customerFieldGenericError')}}</div> -->
                    </div>

                    <div class="form-group" v-if="config.GUEST_REGISTRATION_FORM_FIELDS.title == ''">
                        <label class="fontField">{{$t('customerTitle')}} <span v-if="status($v.registration.title)" style="color:var(--main-color);">*</span></label>
                        <input  @change="validate()" type="text" class="form-control form-control-lg" maxlength="10"  v-model="registration.title" />
                        <!-- <div class="inputError" role="alert" v-if="!$v.registration.title.error == true">{{$t('customerFieldGenericError')}}</div> -->
                    </div>

                    <div class="form-group" v-if="config.GUEST_REGISTRATION_FORM_FIELDS.firstName == ''">
                        <label class="fontField">{{$t('customerFirstName')}}<span v-if="status($v.registration.firstName)" style="color:var(--main-color);">*</span></label>
                        <input  @change="validate()" type="text" maxlength="40" class="form-control form-control-lg" v-model="registration.firstName" />
                        <!-- <div class="inputError" role="alert" v-if="!$v.registration.firstName.error == true">{{$t('customerFirstNameError')}}</div>           -->
                    </div>

                    <div class="form-group" v-if="config.GUEST_REGISTRATION_FORM_FIELDS.lastName == ''">
                        <label class="fontField">{{$t('customerLastName')}}<span v-if="status($v.registration.lastName)" style="color:var(--main-color);">*</span></label>
                        <input  @change="validate()" type="text" class="form-control form-control-lg"  maxlength="40"  v-model="registration.lastName" />
                        <!-- <div class="inputError" role="alert" v-if="!$v.registration.lastName.error == true">{{$t('customerLastNameError')}}</div>           -->
                    </div>

                     <div class="form-group" v-if="config.GUEST_REGISTRATION_FORM_FIELDS.streetAddress == ''">
                        <label class="fontField">{{$t('customerAddress')}}<span v-if="status($v.registration.streetAddress)" style="color:var(--main-color);">*</span></label>
                            <!-- <span style="color:var(--main-color);">*</span> -->
                        <input  @change="validate()" maxlength="40" type="text" class="form-control form-control-lg" v-model="registration.streetAddress" />
                        <!-- <div class="inputError" role="alert" v-if="!$v.registration.streetAddress.error == true">{{$t('customerFieldGenericError')}}</div> -->
                    </div>

                    <div class="form-group" v-if="config.GUEST_REGISTRATION_FORM_FIELDS.otherInfos == ''">
                        <label class="fontField">{{$t('customerOtherInfos')}} <span v-if="status($v.registration.otherInfos)" style="color:var(--main-color);">*</span></label>
                        <input  @change="validate()" type="text" class="form-control form-control-lg" v-model="registration.otherInfos" />
                        <!-- <div class="inputError" role="alert" v-if="!$v.registration.otherInfos.error == true">{{$t('customerFieldGenericError')}}</div> -->
                    </div>
             
                </div>

                <div class="registrationRight">                 

                    <div class="form-group" v-if="config.GUEST_REGISTRATION_FORM_FIELDS.postcode  == ''">
                        <label class="fontField">{{$t('customerPostal')}}<span v-if="status($v.registration.postcode)" style="color:var(--main-color);">*</span></label>
                        <input  @change="validate()" type="text" class="form-control form-control-lg" maxlength="10"  v-model="registration.postcode" />
                        <!-- <div class="inputError" role="alert" v-if="!$v.registration.postcode.error == true">{{$t('customerPostalcodeError')}}</div>           -->
                    </div>

                    <div class="form-group" v-if="config.GUEST_REGISTRATION_FORM_FIELDS.city  == ''">
                        <label class="fontField">{{$t('customerCity')}} <span v-if="status($v.registration.city)" style="color:var(--main-color);">*</span> </label>
                        
                        <input @change="validate()"  type="text" class="form-control form-control-lg" maxlength="50"  v-model="registration.city" />
                        <!-- <div class="inputError" role="alert" v-if="!$v.registration.city.error == true">{{$t('customerCityError')}}</div>           -->
                    </div>

                    <div class="form-group" v-if="config.GUEST_REGISTRATION_FORM_FIELDS.countryId  == ''">
                        <label class="fontField">{{$t('customerCountry')}}</label>
                        <!-- <input type="text" class="form-control form-control-lg" v-model="registration.countryId" /> -->
                        <!-- <div class="inputError" role="alert" v-if="!$v.registration.country.required">{{$t('customerCountryError')}}</div>           -->
                    </div>

                    <div class="form-group" v-if="config.GUEST_REGISTRATION_FORM_FIELDS.country !== undefined">
                        <label class="fontField">{{$t('customerCountry')}}<span v-if="status($v.registration.countryId)" style="color:var(--main-color);">*</span></label>   
                            <!-- <span style="color:var(--main-color);">*</span>                    -->
                        <select-picker-country class="countrySelect" v-if="countriesLoaded" :options="countries" :callback="selectCountry" :preselectedId="selectedCountryId" ></select-picker-country>
                        <!-- <div class="inputError" role="alert" v-if="!$v.registration.streetAddress.error == true">{{$t('customerFieldGenericError')}}</div> -->
                    </div>
             
                    <div class="form-group" v-if="config.GUEST_REGISTRATION_FORM_FIELDS.telephoneNumber  == ''">
                        <label class="fontField">{{$t('customerPhone')}}<span v-if="status($v.registration.telephoneNumber)" style="color:var(--main-color);">*</span></label>
                        <input @change="validate()"  type="text" class="form-control form-control-lg" maxlength="40" v-model="registration.telephoneNumber" />
                        <!-- <div class="inputError" role="alert" v-if="!$v.registration.telephoneNumber.error == true">{{$t('customerFieldGenericError')}}</div> -->
                    </div>                    

                    <div class="form-group" v-if="config.GUEST_REGISTRATION_FORM_FIELDS.telephoneNumber1  == ''">
                        <label class="fontField">{{$t('customerPhone')}}<span v-if="status($v.registration.telephoneNumber1)" style="color:var(--main-color);">*</span></label>
                        <input @change="validate()"  type="text" class="form-control form-control-lg" maxlength="40"  v-model="registration.telephoneNumber1" />
                        <!-- <div class="inputError" role="alert" v-if="!$v.registration.telephoneNumber1.error == true">{{$t('customerFieldGenericError')}}</div> -->
                    </div>

                       <div class="form-group" v-if="config.GUEST_REGISTRATION_FORM_FIELDS.birthDateString  == ''">
                        <label class="fontField">{{$t('customerBirthDate')}}<span v-if="status($v.registration.birthDateString)" style="color:var(--main-color);">*</span></label>
                        <!-- <span style="color:var(--main-color);">*</span></label> -->
                        <!-- <input type="text" class="form-control form-control-lg" v-model="registration.birthDateString" /> -->
                        <!-- <datetime-picker format="DD/MM/YYYY" width="100%" height="100%" firstDayOfWeek="1" v-model="registration.birthDateString"></datetime-picker> -->
                        <datepicker :language="currentLanguage" format="dd.MM.yyyy" class="form-control" style="width:100%; height:100%;" width="100%" height="100%" firstDayOfWeek="1" name="birthDateString" v-model="registration.birthDateString"></datepicker>
                        <!-- <div class="inputError" role="alert" v-if="!$v.registration.birthDateString.error == true">{{$t('customerFieldGenericError')}}</div> -->
                    </div>
                    
                    <div class="form-group" v-if="config.GUEST_REGISTRATION_FORM_FIELDS.notes1 !== undefined">
                        <label class="fontField">{{$t('customerNotes1')}}<span v-if="status($v.registration.notes1)" style="color:var(--main-color);">*</span></label>
                        <input @change="validate()"  type="text" class="form-control form-control-lg" maxlength="50"  v-model="registration.notes1" />
                        <!-- <div class="inputError" role="alert" v-if="!$v.registration.notes1.error == true">{{$t('customerFieldGenericError')}}</div> -->
                    </div>
                    <div class="form-group" v-if="config.GUEST_REGISTRATION_FORM_FIELDS.notes2 !== undefined">
                        <label class="fontField">{{$t('customerNotes2')}}<span v-if="status($v.registration.notes2)" style="color:var(--main-color);">*</span></label>
                        <input @change="validate()"  type="text" class="form-control form-control-lg" maxlength="50"  v-model="registration.notes2" />
                        <!-- <div class="inputError" role="alert" v-if="!$v.registration.notes2.error == true">{{$t('customerFieldGenericError')}}</div> -->
                    </div>
                    
                    <div class="form-group" v-if="config.GUEST_REGISTRATION_FORM_FIELDS.notes3  == ''">
                        <label class="fontField">{{$t('customerNotes3')}}<span v-if="status($v.registration.notes3)" style="color:var(--main-color);">*</span></label>
                        <input @change="validate()"  type="text" class="form-control form-control-lg" maxlength="50"  v-model="registration.notes3" />
                        <!-- <div  class="inputError" role="alert" v-if="!$v.registration.notes3.error == true">{{$t('customerFieldGenericError')}}</div> -->
                    </div>
                    <div class="form-group" v-if="config.GUEST_REGISTRATION_FORM_FIELDS.notes4  == ''">
                        <label class="fontField">{{$t('customerNotes4')}}<span v-if="status($v.registration.notes4)" style="color:var(--main-color);">*</span></label>
                        <input @change="validate()"  type="text" class="form-control form-control-lg" v-model="registration.notes4" />
                        <!-- <div class="inputError" role="alert" v-if="!$v.registration.notes4.required">{{$t('customerFieldGenericError')}}</div> -->
                        <!-- <div v-for="error of $v.registration.notes4.$errors" :key="error.$uid"> -->
                            <!-- <div class="inputError" role="alert" v-if="$v.registration.notes4.error == true">{{$t('customerFieldGenericError')}}</div> -->
                        <!-- </div> -->
                    </div>
                </div>

                <div class="col-12">
                    <div class="form-group" v-if="config.GUEST_REGISTRATION_FORM_FIELDS.privacy == ''">
                        <!-- <label class="fontField">{{$t('customerNotes4')}}</label> -->
                        <check-box                      
                            style="'border-width: 3px'"
                            :subClass = "'asdf'"
                            :label='$t("customerPrivacy") +  "<span style="+"color:var(--main-color);"+">*</span>"'
                            :value="1"
                            :trueValue="'1'"
                            :falseValue="'0'"
                            :hyperlink ="config.GUEST_REGISTRATION_FORM_FIELDS.privacyHyperlink"
                            :newTab=config.GUEST_REGISTRATION_FORM_FIELDS.privacyHyperlinkNewTab
                            :biggerCheckbox=true
                            v-model="registration.privacy">
                        </check-box>                   
                    </div>

                    <div class="form-group" v-if="config.GUEST_REGISTRATION_FORM_FIELDS.newsletter  == ''">
                        <!-- <label class="fontField">{{$t('customerNotes4')}}</label> -->

                        <check-box                         
                            :label='$t("customerNewsletter")'
                            :value="1"
                            :trueValue="'1'"
                            :falseValue="'0'"
                            :hyperlink ="config.GUEST_REGISTRATION_FORM_FIELDS.newsletterHyperlink"
                            :newTab=config.GUEST_REGISTRATION_FORM_FIELDS.newsletterHyperlink
                            v-model="registration.newsletter">
                        </check-box>                  
                    </div>

                    <div class="form-group" style="text-alignment:center;" v-if="config.GUEST_REGISTRATION_FORM_FIELDS.tc  == '' && config.SHOW_ORDERCONFIRMATION_TC_CONSENT != true">
                        <!-- <label class="fontField">{{$t('customerNotes4')}}</label> -->

                        <check-box class="customerTCCheck"                         
                            :label='$t("customerTC")+  "<span style="+"color:var(--main-color);"+">*</span>"'
                            :value="1"
                            :trueValue="'1'"
                            :falseValue="'0'"
                            :hyperlink ="config.GUEST_REGISTRATION_FORM_FIELDS.tcHyperlink"
                            :newTab=config.GUEST_REGISTRATION_FORM_FIELDS.tcHyperlinkNewTab
                            :biggerCheckbox=true
                            v-model="registration.tc">
                        </check-box>                     
                    </div>
                </div>

                <div class="col-12" style="padding-right:0px;">
                    <!-- <button type="submit" @click.stop="onClickCaptcha" v-if="!isGuestCheckout" :disabled="submitStatus === 'PENDING'" class="btn btn-dark btn-lg btnSubmit floatRight g-recaptcha">{{$t('register')}}</button> -->
                    <!-- <button type="submit" @click.stop="onClickCaptcha" v-if="isGuestCheckout" :disabled="submitStatus === 'PENDING'" class="btn btn-dark btn-lg btnSubmit floatRight g-recaptcha">{{$t('orderAsGuest')}}</button> -->
                    <button type="submit" @click.stop="onClickCaptcha" v-if="!isGuestCheckout" :disabled="submitStatus === 'PENDING'" class="btn btn-success btn-lg btnSubmit floatRight g-recaptcha">{{$t('register')}}</button>
                    <button type="submit" @click.stop="onClickCaptcha" v-if="isGuestCheckout" :disabled="submitStatus === 'PENDING'" class="btn btn-success btn-lg btnSubmit floatRight g-recaptcha">{{$t('orderAsGuest')}}</button>
                </div>
            </div>

        </form>

    </div>
</template>

<script>
import { required, minLength, email } from 'vuelidate/lib/validators'
import VueLoading from 'vue-loading-overlay'
import CheckBox from '../sub-components/CheckBoxCustomer.vue'
import $ from 'jquery'
// import datetime from 'vuejs-datetimepicker';
import datepicker from 'vuejs-datepicker'
import {en, de, fr} from 'vuejs-datepicker/dist/locale'
// import selectPickerCountry from '../sub-components/SelectPickerCountry.vue'
import selectPickerCountry from '../sub-components/SelectPickerCountry.vue'
import {BIconExclamationTriangleFill} from 'bootstrap-vue'

export default {
    data() {
        return {
            submitStatus: null,           
            registration: {
                default:''
            },
            isLoading:false,
            en: en,
            de: de, 
            fr: fr,
            countriesLoaded: false,
            countries: {
                Type: Array
            },
             selectedCountry: {
                Type:Object
            },
            zones: {
                Type: Array
            },
            selectedCountryId: 0,
            reponseToken:""
        }	
	},
    computed: {
        isTableMode(){
            if(window.TableMode == true)
                return true;
            else
                return false;
        },
        isGuestCheckout(){
            if(window.isGuestCheckout == true)
                return true;
            else
                return false;
        },
        config() {
            return window.config;
        },
        currentLanguage() {

            if (this.$i18n.locale == "fr_FR")
            {
                return fr;
            }
            if (this.$i18n.locale == "de_DE")
            {
                return de;
            }
            if (this.$i18n.locale == "en_GB")
            {
                return en;
            }

            return en;
        }
    },
    components:{
        'loading' : VueLoading,
        'check-box' : CheckBox,
        // 'datetime-picker': datetime,
        'datepicker': datepicker,
        'select-picker-country': selectPickerCountry,
        BIconExclamationTriangleFill
    },
    validations: {
        registration: {
            emailAddr:{
            required,
            email,
            minLength : minLength(1)
            },
            // password: {
            // minLength : minLength(8),
            // required
            // },
            firstName:{
            required,
            },
            lastName:{
            required,
            },
            streetAddress:{
             required,
            },
            city:{
             required,
            },
            postcode:{
             required,
            },
            title:{
            // required
            },
            cardNumber:{
            // required
            },
            notes1:{
            // required
            },
            notes2:{
            // required
            },
            notes3:{
            // required
            },
            notes4:{
            // required,
            },
            birthDateString:{
            // required
            },
            countryId:{
            // required
            },
            telephoneNumber:{
             required
            },
            telephoneNumber1:{
            // required
            },
            otherInfos:{
            // required
            },
        }
    },
    methods: { 	
          // eslint-disable-next-line no-undef
        onClickCaptcha(e) {
            var vueInstance = this;
            console.log("onClickCaptcha");
            e.preventDefault();
            if(window.config.G_CAPTCHA_ENABLED == true)
            {
                // eslint-disable-next-line no-undef
                grecaptcha.ready(function() {
                    // eslint-disable-next-line no-undef
                    grecaptcha.execute(window.config.G_CAPTCHA_SITE_KEY, {action: 'submit'}).then(function(token) {
                        vueInstance.responseToken = token;
                        vueInstance.onSubmit(e);             
                    });
                });
            }
            else
            {
                vueInstance.onSubmit(e);
            }
       
        },
        status(validation) 
        {
            // var result = {
            //     error: validation.$error,
            //     dirty: validation.$dirty
            // }   
            return validation.$error;
        },
        selectCountry(countryId) {
            console.log("selectCountry");
            console.log(countryId);
            this.registration.countryId = countryId;      
        },
        formatDate(date) {
            if(typeof(date) !== Date)
            {
                return;
            }

            let diff = new Date() - date; // the difference in milliseconds

            if (diff < 1000) { // less than 1 second
                return 'right now';
            }

            let sec = Math.floor(diff / 1000); // convert diff to seconds

            if (sec < 60) {
                return sec + ' sec. ago';
            }

            let min = Math.floor(diff / 60000); // convert diff to minutes
            if (min < 60) {
                return min + ' min. ago';
            }

            // format the date
            // add leading zeroes to single-digit day/month/hours/minutes
            let d = date;
            d = [
                '0' + d.getDate(),
                '0' + (d.getMonth() + 1),                
                // '0' + d.getHours(),
                // '0' + d.getMinutes()
            ].map(component => component.slice(-2)); // take last 2 digits of every component

            d.push(date.getFullYear());

            // join the components into date
            return d.slice(0, 3).join('/');
        },
        goBack() {
            this.$router.go(-1);
        },
        validate(){
            this.$v.$touch();
            this.$forceUpdate();
        },
        onSubmit: function(event) {
            var vueInstance = this;
            var config = window.config;
            if(config.GUEST_CHECKOUT == true && window.isGuestCheckout == true)
            {
                this.registration.password = "xxxxxxxx";
            }
			event.preventDefault();    
            console.log(vueInstance.registration);              
            this.$v.$touch();
            console.log(this.$v);
            
            if (this.$v.$invalid) {
                this.$alert(this.$t('fillAllRequiredFields'));
                return;
            }                

            if(this.registration.privacy == 0 || this.registration.privacy == undefined)
            {
                this.$alert(this.$t('acceptAllRequiredConditions'));
                return;
            }
            
            if( (this.registration.tc == 0 || this.registration.tc == undefined) && config.SHOW_ORDERCONFIRMATION_TC_CONSENT != true )
            {
                this.$alert(this.$t('acceptAllRequiredConditions'));
                return;
            }  

            if(this.registration.birthDateString != undefined && this.registration.birthDateString != null)
            {
                this.registration.birthDateString = this.formatDate(this.registration.birthDateString);
            }

            if(this.isGuestCheckout == true)
            {
                this.registration.allowNoRegister = "true";
            }

            if(this.responseToken != "")
            {
                this.registration.responseToken = this.responseToken;
            }

            this.registration.privacyHyperlink = undefined;
            this.registration.tcHyperlink = undefined;
            
            var url = config.API_BASE + 'TCPCustomerRegistrationSubmitJ.action';                
            // eslint-disable-next-line no-unused-vars
            var callbackSuccess= function(result, textStatus, jqXHR) {       
       
                
                if(result.page =="tcp.catalog.change.password.page")
                {
                    vueInstance.$router.push({ path: '/PasswordReset' });
                    return;
                }
                
                if(result.errorCode > 0)
                {

                    // AP-Soft 27.04.2022
                    vueInstance.$alert(vueInstance.$t("guestRegistrationFailGeneric"));
                    return;

                    // if(result.errorCode == 10) // password does confine to everything needed (length, special characters, numbers, ...)
                    // {
                    //     vueInstance.$alert(vueInstance.$t("customerRegistrationSubmitError17"));
                    //     return;
                    // }
                    // else
                    // {
                    //     vueInstance.$alert(vueInstance.$t('registrationFailGeneric'));
                    //     return;
                    // }
                    
                    // if(vueInstance.isGuestCheckout)
                    // {
                    //     if(vueInstance.isTableMode)
                    //     {
                    //         window.customer = result;
                    //         var path = "/QRLanding?s=" + window.tableModeShop + "&t=" + window.tableModeTable; 
                    //         vueInstance.$router.push({path:path});
                    //     }
                    //     else
                    //     {
                    //         window.customer = result;
                    //         vueInstance.$router.push({path:"/OrderConfirmation"});
                    //     }
                    // }
                    // else
                    // {
                    //     vueInstance.$alert(vueInstance.$t('registrationSuccess'));
                    //     vueInstance.$router.go(-1);
                    // }
                    
                }
                else
                {
                    if(result.errorCode == 0)
                    {
                        if(vueInstance.isGuestCheckout)
                        {
                            if(vueInstance.isTableMode)
                            {
                                window.customer = result;
                                var path = "/QRLanding?s=" + window.tableModeShop + "&t=" + window.tableModeTable; 
                                vueInstance.$router.push({path:path});
                            }
                            else
                            {
                                window.customer = result;
                                vueInstance.$router.push({path:"/OrderConfirmation"});
                            }
                        }
                        else
                        {
                            vueInstance.$alert(vueInstance.$t('registrationSuccess'));
                            vueInstance.$router.go(-1);
                        }
                        
                        return;
                    }

                    if(result.exception !== undefined)
                    {
                        vueInstance.$alert(vueInstance.$t('registrationFailGeneric'));
                        console.log(result.exception);
                        return;
                    }
                    // if(result.errorCode == -4)
                    // {
                    //     vueInstance.$alert(vueInstance.$t('emailAlreadyTaken'));
                    //     return;
                    // }
                    vueInstance.$alert(vueInstance.$t('registrationFailGeneric'));
                }
            }

            // eslint-disable-next-line no-unused-vars
            var callbackError = function(result, textStatus, jqXHR){  
            }

            vueInstance.registration.json = "true";
            // vueInstance.registration.countryId = config.GUEST_REGISTRATION_FORM_FIELDS.countryId;
            // vueInstance.registration.state = config.GUEST_REGISTRATION_FORM_FIELDS.state;
            vueInstance.makeRequest("POST", url, JSON.stringify(vueInstance.registration), callbackSuccess, callbackError);
		}
    },
    mounted(){
        var config = window.config;     

        this.registration = JSON.parse(JSON.stringify(config.GUEST_REGISTRATION_FORM_FIELDS))
        // this.registration = config.GUEST_REGISTRATION_FORM_FIELDS;
        
        if(config.GUEST_CHECKOUT == true && window.isGuestCheckout == true)
        {
            console.log("guestCheckout true");
            this.registration.password = "xxxxxxxxxxx";
        }  

        this.validate();
    },
    beforeMount(){
        var config = window.config;
        var vueInstance = this;
        var url = config.API_BASE + 'TCPCustomerRegistrationJ.action';        
        var params = {
            json: "true",
            countryChange : 1
        };        

        // eslint-disable-next-line no-unused-vars
        var callbackSuccess= function(result, textStatus, jqXHR) {          
            vueInstance.countries = result.countries;
            if(result.selectedCountry != undefined)
            {
                vueInstance.selectedCountry = result.selectedCountry;
                vueInstance.registration.countryId = result.selectedCountry.id;  
                vueInstance.selectedCountryId = result.selectedCountry.id; 
            }
            else
            {
                if(vueInstance.countries.length > 0)
                    vueInstance.selectCountry(vueInstance.countries[0].id);
            }    
            
            
            
            vueInstance.countriesLoaded = true;
        }

        // eslint-disable-next-line no-unused-vars
        var callbackError = function(result, textStatus, jqXHR){  
        }

        vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);
    },
    beforeCreate() {
      
    },
    beforeRouteEnter (to, from, next) {
         // eslint-disable-next-line no-unused-vars
         next(vm => {
            var myDiv = document.getElementById('contentSection');
            $("#contentSection").scrollTop(0);
            $("#app").scrollTop(0);
            if(myDiv != undefined)
                myDiv.scrollTop = 0;
        })        
    },
}
</script>

<style scoped>
    .registrationPageWrapper
    {
        overflow-y:auto;
        /* background-color: var(--text-color);
        color: black;       */
        padding:0px;               
    }

@media (min-width: 768px) {
    .registrationLeft
    {        
        width: 40%;
        float: left;
        margin-top:10px;
    }
}

@media (min-width: 768px) {
    .registrationRight
    {
        width: 40%;        
        float: right;
        margin-top:10px;
    }
}

.fontTitle { 
    text-align:center;
}

</style>