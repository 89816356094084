<template>
    <img v-if="!config.DISABLE_GLOBAL_HEADER_LOGO_LINK" @click="goToShop" id="LogoImage" :aria-label="$t('logoAriaLabel')" src="../../assets/images/logo.png"/>
    <img v-else id="LogoImage" :aria-label="$t('logoAriaLabel')" src="../../assets/images/logo.png"/>
</template>

<script>
export default {
    name:"logo-image",
    computed:{
        config(){
            return window.config;
        }
    },
    methods:{
         goToShop(){
            if(window.config.DISABLE_GLOBAL_HEADER_LOGO_LINK){
                return;
            }

            if(window.config.TABLE_MODE_OVERRIDE)
            {
                window.location = window.config.TABLE_MODE_OVERRIDE_HOMEPAGE;
                return;
            }

            if(window.config.IS_PURE_ACCOUNTMANAGER)
            {
                return;
            }
            var vueInstance = this;
            if(!(vueInstance.$route.path == '/Shop'))
            {
                if(window.shoppingCart != undefined && window.shoppingCart != null && window.shoppingCart.length > 0)
                {
                    vueInstance.$confirm(
                        vueInstance.$t('resetBasketInfoMessage'),
                        vueInstance.$t('resetBasketInfoTitle'),
                        'info',
                        { 
                            confirmButtonText: vueInstance.$t('confirm'),
                            cancelButtonText: vueInstance.$t('cancel')
                        }).then(() => {
                        if(!(vueInstance.$route.path == '/Shop') && !window.TableMode)
                        {
                            vueInstance.$router.push({ path: '/Shop' })
                        }   
                    });
                }
                else
                {
                    vueInstance.$router.push({ path: '/Shop' });
                }     
               
            }                            
        },
    }
}
</script>

<style scoped>

</style>