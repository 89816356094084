<template>
    <div class="globalRight elementBorder fontContent" >
        <nav class="col-12" v-show="config.SHOW_GLOBAL_RIGHT_NAVIGATION">  

            <div id="logo-image-wrapper" v-show="config.SHOW_GLOBAL_RIGHT_LOGO"><logo-image></logo-image></div>    

            <div class ="navbar navbar-bg fontNavigation">
                <ul class="navbar-nav">
                    <li v-for="menu in menus" v-bind:key ="menu.Order" class="active nav-item"> <router-link :to="menu.Link"> {{menu.Title}} </router-link> </li>
                </ul>      
            </div>  

        </nav>   

    </div>
</template>

<script>
import LogoImage from '../sub-components/LogoImage.vue'
export default {
    name:"global-right",
       props: {
        menus: {
            Type: Array,
            required:true
        },
        config: {
            Type: Array
        }
    },
    components:{
        'logo-image' : LogoImage
    }
}
</script>

<style scoped>

/* Make whole div behind anchor in menu clickable */
a {
   display:block;
}

</style>