<template>
    <div class="col-12">

        <!-- <div v-if="config.SHOW_ACCOUNT_BARCODE">
            <img src="../../assets/images/barcode.png" >
        </div> -->

        <div class="col-12 subPageHeader" style=" border-bottom:0.5px solid var(--main-color);"> 
            <span class="floatLeft" style="font-size:25px;"> {{ transaction.formattedDate }} </span>

            <!-- <button class="btn subPageHeader floatRight" @click="callbackClose()" style="float:right;width:100px; margin:0px; margin-top:5px; border:none; color:var(--main-color);">
                {{ $t('close') }}
            </button> -->
        </div>


        <div class="col-12" v-if="prepayment">
           <div class="" style="float:left;">{{$t('prepayment')}}</div>
           <div class="" style="float:right;">{{getPrepaymentAmount()}}</div>
        </div>

        <div class="col-12" v-if="!prepayment">
      
                <table class="orderDetailsTable">
                    <thead>
                        <th class="orderDetailsHeaderBig">{{ $t('article') }}</th>
                        <th class="orderDetailsHeader">{{ $t('quantity') }}</th>
                        <th class="orderDetailsHeader" style="text-align:right;">{{ $t('summ') }}</th>               
                    </thead>
<!--         
                    <tr v-for="item in transaction.articleslist" v-bind:key="item.articleCode" class="shoppingCartRow">
                        <td class="orderDetailsLine">{{item.articleDescription}}</td>
                        <td class="orderDetailsLine">{{item.qtyWeight}}</td>
                        <td class="orderDetailsLine"> 
                            <span v-if="item.formattedDiscountedPrice == undefined">{{item.formattedPrice}}</span> 
                            <span v-if="item.formattedDiscountedPrice != undefined"> 
                                <span>{{item.formattedDiscountedPrice}}</span>
                                <span style="text-decoration:line-through; color:lightgray; margin-left:5px;">{{item.formattedPrice}}</span><br/>
                                <span style="font-size:15px; color:var(--main-color);opacity:0.7;">{{item.formattedDiscount}}</span>                                
                            </span> 
                        </td>         
                    </tr>   -->

                  <!--  <li class="recentCustomerOrdersEntry">
                         remove the time since we are not ordering for a specific pickup-time(takeaway), but only have delivery 
                        <span class="floatLeft">{{item.name }} </span>
                
                        <span class="floatRight">{{item.formattedPrice }} </span>

                        <span class="floatRight">x {{item.quantity }} </span>
                    </li>                         -->
                    <!-- <li class="recentCustomerOrdersEntryAmount">
                        {{item.formattedTotal}} 
                    </li> -->
                </table>

                <div v-for="item in transaction.articleslist" v-bind:key="item.articleCode" class="shoppingCartRow" style="display:block; margin-bottom:20px;">
                        <div class="" style="width:100%; display:block; text-align:start;">{{item.articleDescription}}</div>
                        <div class="" style="width:50%; display:inline-block; text-align:right;">{{item.qtyWeight}}</div>
                        <div class="" style="width:50%; display:inline-block; text-align:right;"> 
                            <span v-if="item.formattedDiscountedPrice == undefined">{{item.formattedPrice}}</span>
                            <span v-if="item.formattedDiscountedPrice != undefined">
                                <span>{{item.formattedDiscountedPrice}}</span>
                            </span>
                        </div>
                </div> 

                <span class="floatLeft totalLineLabel">{{ $t('summ') }}</span>

                <span class="floatRight totalLineAmount">{{ transaction.formattedTotal }}</span>
          
            <!-- <table>
                <tr v-for="item in order.menuItems" :key="item.kkProdId"> {{item.quantity}} </tr>                
            </table> -->
        </div>
    </div>
</template>

<script>
export default {
    setup() {
        
    },
    data(){
        return {
            
        }
    },
    computed:{
        prepayment(){
            return this.transaction.prepayment;
        },
        config(){
            return window.config;
        }
    },
    props:{
        transaction:{
            Type:Object,
            required:true
        },
        callbackClose:{
            Type:Function
        }
    },
    methods:{
        getPrepaymentAmount(){           
            return this.transaction.paymentslist.filter(function(x) { return x.isPrepayment })[0].formattedAmount;
        }
    }
}
</script> 

<style scoped>
.totalLineLabel{
    font-size:25px; 
    color:var(--main-color); 
    width: 70%; 
    text-align:left;
    border-top:0.5px solid var(--main-color);
}

.totalLineAmount{
    font-size:25px;
    font-weight:600;
    color:var(--main-color); 
    width:30%; 
    text-align:end;
    border-top:0.5px solid var(--main-color);
}

.orderDetailsHeaderBig {
    width:40%;
    text-align:left;
    border-bottom:0.5px solid var(--main-color)
}
   
.orderDetailsHeader{
    width:30%;
    text-align:left;
    border-bottom:0.5px solid var(--main-color)
}

.orderDetailsLine{
    text-align:left;
    height:60px;
}
.orderDetailsTable{
    width:100%;
    border-bottom:0.5px solid var(--main-color);
}

@media screen and (max-width:768px) {
    .totalLineLabel{
        width: 40%;
    }
    .totalLineAmount{
        width: 60%;
        text-align: right;
    }
}

</style>