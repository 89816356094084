<template>
    <div id="footer-links-link">
        <a :href="link.Url" class="darkLink"> {{link.Text}} </a>
    </div>
</template>

<script>
export default {
    name:"footer-link",
    props: ['link']
}
</script>

<style scoped>
#footer-links-link a {    
    /* background-color:indianred; */
    padding: 5px 15px 5px 15px;   
}
</style>