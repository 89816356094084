<template>
    <div id="global-footer" class="globalFooter" v-if="!isInIFrame">
        
        <div id="global-footer-links-wrapper" class="col-12 col-sm-4 col-md-4 justify-content-center" v-show="config.SHOW_GLOBAL_FOOTER_LINKS_LEFT">
            <footer-links :links="footerLinks"></footer-links>
        </div>

        <div id="logo-image-wrapper" class="col-12 col-sm-3 col-md-4 justify-content-center"  v-show="config.SHOW_GLOBAL_FOOTER_LOGO && (config.FOOTER_HTML === '') ">
            <logo-image></logo-image>
        </div>

        <div id="global-footer-custom-html" style="display:inline-flex; padding:0;" class="col-12 col-sm-5 col-md-4 justify-content-center" v-show="config.FOOTER_HTML !== ''" v-html="config.FOOTER_HTML"></div>

        <div id="global-footer-links-wrapper" class="col-12 col-sm-5 col-md-4 justify-content-center"  v-show="config.SHOW_GLOBAL_FOOTER_LINKS_RIGHT">
            <footer-links :links="footerLinks"></footer-links>
        </div>

    </div>
</template>

<script>
import LogoImage from '../sub-components/LogoImage.vue'
import FooterLinks from '../main-components/FooterLinks.vue'

export default {
    name:"global-footer",
    props: {
        footerLinks : {
            Type: Array            
        },
        changeLocale: {
            Type: Function,
            required: true
        },
        config: {
            Type: Array
        }
    },
    components: {
        'footer-links' : FooterLinks,
        'logo-image' : LogoImage
    },
    computed: {
        showHeaderFooter(){
            if(window.showHeaderFooter === undefined){
                return true;
            }else{
                return window.showHeaderFooter;
            }
        },
        isInIFrame(){
            try{
                return window.self !== window.top;
            } catch (e) {
                return true;
            }
        },
    }
}
</script>

<style scoped>

#global-footer-links-wrapper {
    display:inline-flex;    
    padding: 2px 5px 2px 5px;    
}

#logo-image-wrapper {
    display:inline-flex;
    height:3vh;
}

</style>