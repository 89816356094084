<template>
    <div class="col-12">

        <div v-if="config.SHOW_ACCOUNT_BARCODE">
            <qr-code :value="order.barcode"></qr-code>
        </div>

        <div class="col-12 subPageHeader" style=" border-bottom:0.5px solid var(--main-color);"> 
            <span class="floatLeft" style="font-size:25px;"> {{ order.formattedDate.split(' ')[0] }} </span>

            <!-- <button class="btn subPageHeader floatRight" @click="callbackClose()" style="float:right;width:100px; margin:0px; margin-top:5px; border:none; color:var(--main-color);">
                {{ $t('close') }}
            </button> -->
        </div>

        <div class="col-12">
      
                <table class="orderDetailsTable">
                    <thead>
                        <th class="orderDetailsHeaderBig">{{ $t('article') }}</th>
                        <th class="orderDetailsHeader">{{ $t('quantity') }}</th>
                        <th class="orderDetailsHeader" style="text-align:right;">{{ $t('summ') }}</th>               
                    </thead>
        
                    

                  <!--  <li class="recentCustomerOrdersEntry">
                         remove the time since we are not ordering for a specific pickup-time(takeaway), but only have delivery 
                        <span class="floatLeft">{{item.name }} </span>
                
                        <span class="floatRight">{{item.formattedPrice }} </span>

                        <span class="floatRight">x {{item.quantity }} </span>
                    </li>                         -->
                    <!-- <li class="recentCustomerOrdersEntryAmount">
                        {{item.formattedTotal}} 
                    </li> -->
                </table>

                <div v-for="item in order.menuItems" v-bind:key="item.articleId" class="shoppingCartRow" style="display:block; margin-bottom:20px;">
                        <div class="" style="width:100%; display:block; text-align:start;">{{item.name}}</div>
                        <div class="" style="width:50%; display:inline-block; text-align:right;">{{item.quantity}}</div>
                        <div class="" style="width:50%; display:inline-block; text-align:right;"> 
                            <span v-if="item.formattedDiscountedPrice == undefined">{{item.formattedPrice}}</span>
                            <span v-if="item.formattedDiscountedPrice != undefined">
                                <span>{{item.formattedDiscountedPrice}}</span>
                            </span>
                        </div>
                </div>  

                <span class="floatLeft totalLineLabel">{{ $t('summ') }}</span>

                <span class="floatRight totalLineAmount">{{ order.formattedTotal }}</span>
          
            <!-- <table>
                <tr v-for="item in order.menuItems" :key="item.kkProdId"> {{item.quantity}} </tr>                
            </table> -->
        </div>
    </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'

export default {
    setup() {
        
    },
    data(){
        return {
        }
    },    
    computed:{
        config(){
            return window.config;
        }
    },
    components:{
        'qr-code' : QrcodeVue,
    },
    props:{
        order:{
            Type:Object,
            required:true
        },
        callbackClose:{
            Type:Function
        },
        guid: {
            Type:String
        }
    }
}
</script> 

<style scoped>
.totalLineLabel{
    font-size:25px; 
    color:var(--main-color); 
    width: 70%; 
    text-align:left;
    border-top:0.5px solid var(--main-color);
}

.totalLineAmount{
    font-size:25px;
    font-weight:600;
    color:var(--main-color); 
    width:30%; 
    text-align:end;
    border-top:0.5px solid var(--main-color);
}

.orderDetailsHeaderBig {
    width:40%;
    text-align:left;
    border-bottom:0.5px solid var(--main-color)
}
   
.orderDetailsHeader{
    width:30%;
    text-align:left;
    border-bottom:0.5px solid var(--main-color)
}
.orderDetailsLine{
    text-align:left;
    display:block;    
    height:30px;
}
.orderDetailsTable{
    width:100%;
    border-bottom:0.5px solid var(--main-color);
}

@media screen and (max-width:768px) {
    .totalLineLabel{
        width: 40%;
    }
    .totalLineAmount{
        width: 60%;
        text-align: right;
    }
}

</style>