<template>
    <div class="passwordResetPageWrapper">
        <div class="fontTitle row">
            <button class="btn btn-primary subPageHeader subPageHeaderButton" @click="goBack()">
                {{$t('back')}}
            </button>
            <div><h1>{{ $t('passwordResetPageTitle') }}</h1></div>
        </div>

        <form @submit.prevent="onSubmit">
            <!-- AP 20220506 added show password button-->
            <div class="form-group" v-if="!config.SHOW_PASSWORD_VISIBILITY_SWITCH">
                <label for="currentPassword" class="fontField">{{$t('currentPassword')}}</label>
                <input id="currentPassword" type="password" class="form-control form-control-lg" v-model="pwReset.current" required/>
                <!-- <div class="inputError" role="alert" v-if="!$v.forgotPw.email.required">Email is required</div> -->
                <div class="inputError" role="alert" v-if="!$v.pwReset.current.required && submitStatus === 'ERROR'"><b-icon-exclamation-triangle-fill></b-icon-exclamation-triangle-fill> {{$t('fieldWarningRequired')}}</div>   
            </div>
            <div class="form-group" v-else>
                <label id="currentPasswordLabel" class="fontField">{{$t('currentPassword')}}</label>
                <b-input-group>
                    <b-form-input aria-labelledby="currentPasswordLabel" type="password" v-if="hideCurrent" class="form-control form-control-lg" v-model="pwReset.current" required/>
                    <b-form-input aria-labelledby="currentPasswordLabel" type="text" v-else class="form-control form-control-lg" v-model="pwReset.current" required/>
                    <b-input-group-append>
                        <b-button variant="outline-secondary" type="button" @click="showCurrent()" :aria-label="hideCurrent ? $t('showPasswordOldAriaLabel') : $t('hidePasswordOldAriaLabel')">
                            <b-icon-eye-fill v-if="hideCurrent" :aria-label="$t('showPasswordOldAriaLabel')"></b-icon-eye-fill>
                            <b-icon-eye-slash-fill v-else  aria-hidden="true" :aria-label="$t('hidePasswordOldAriaLabel')"></b-icon-eye-slash-fill>
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
                <div class="inputError" role="alert" v-if="!$v.pwReset.current.required && submitStatus === 'ERROR'"><b-icon-exclamation-triangle-fill></b-icon-exclamation-triangle-fill> {{$t('fieldWarningRequired')}}</div>   
            </div>

            <div class="form-group" v-if="!config.SHOW_PASSWORD_VISIBILITY_SWITCH">
                <label for="newPassword" class="fontField">{{$t('newPassword')}}</label>
                <input id="newPassword" type="password" class="form-control form-control-lg" v-model="pwReset.password" required/>
                <!-- <div class="inputError" role="alert" v-if="!$v.forgotPw.email.required">Email is required</div> -->
                <div class="inputError" role="alert" v-if="!$v.pwReset.password.required && submitStatus === 'ERROR'"><b-icon-exclamation-triangle-fill></b-icon-exclamation-triangle-fill> {{$t('fieldWarningRequired')}}</div>   
            </div>
            <div class="form-group" v-else>
                <label id="newPasswordLabel" class="fontField">{{$t('newPassword')}}</label>
                <b-input-group>
                    <b-form-input aria-labelledby="newPasswordLabel" type="password" v-if="hidePassword" class="form-control form-control-lg" v-model="pwReset.password" required/>
                    <b-form-input aria-labelledby="newPasswordLabel" type="text" v-else class="form-control form-control-lg" v-model="pwReset.password" required/>
                    <b-input-group-append>
                        <b-button variant="outline-secondary" type="button" @click="showPassword()" :aria-label="hidePassword ? $t('showPasswordNewAriaLabel') : $t('hidePasswordNewAriaLabel')">
                            <b-icon-eye-fill v-if="hidePassword" aria-hidden="true" :aria-label="$t('showPasswordNewAriaLabel')"></b-icon-eye-fill>
                            <b-icon-eye-slash-fill v-else aria-hidden="true" :aria-label="$t('hidePasswordNewAriaLabel')"></b-icon-eye-slash-fill>
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
                <div class="inputError" role="alert" v-if="!$v.pwReset.password.required && submitStatus === 'ERROR'"><b-icon-exclamation-triangle-fill></b-icon-exclamation-triangle-fill> {{$t('fieldWarningRequired')}}</div>   
            </div>

            <div class="form-group" v-if="!config.SHOW_PASSWORD_VISIBILITY_SWITCH">
                <label for="newPasswordRepeat" class="fontField">{{$t('newPasswordRepeat')}}</label>
                <input id="newPasswordRepeat" type="password" class="form-control form-control-lg" v-model="pwReset.repeat" required/>
                <!-- <div class="inputError" role="alert" v-if="!$v.forgotPw.repeat.required">Please enter email twice</div>                  -->
                <div class="inputError" role="alert" v-if="$v.pwReset.repeat.required && v.pwReset.repeat.sameAsPassword && submitStatus === 'ERROR'"><b-icon-exclamation-triangle-fill></b-icon-exclamation-triangle-fill> {{$t('fieldWarningEmailEqual')}}</div>             
            </div>
            <div class="form-group" v-else>
                <label id="newPasswordRepeatLabel" class="fontField">{{$t('newPasswordRepeat')}}</label>
                <b-input-group>
                    <b-form-input aria-labelledby="newPasswordRepeatLabel" type="password" v-if="hideRepeat" class="form-control form-control-lg" v-model="pwReset.repeat" required/>
                    <b-form-input aria-labelledby="newPasswordRepeatLabel" type="text" v-else class="form-control form-control-lg" v-model="pwReset.repeat" required/>
                    <b-input-group-append>
                        <b-button variant="outline-secondary" type="button" @click="showRepeat()" :aria-label="hideRepeat ? $t('showPasswordRepeatedNewAriaLabel') : $t('hidePasswordRepeatedNewAriaLabel')">
                            <b-icon-eye-fill v-if="hideRepeat" aria-hidden="true" :aria-label="$t('showPasswordRepeatedNewAriaLabel')"></b-icon-eye-fill>
                            <b-icon-eye-slash-fill v-else aria-hidden="true" :aria-label="$t('hidePasswordRepeatedNewAriaLabel')"></b-icon-eye-slash-fill>
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
                <div class="inputError" role="alert" v-if="!$v.pwReset.password.required && submitStatus === 'ERROR'"><b-icon-exclamation-triangle-fill></b-icon-exclamation-triangle-fill> {{$t('fieldWarningRequired')}}</div>   
            </div>

            <button type="submit" @click="onSubmit" :disabled="submitStatus === 'PENDING'" class="btn btn-success btn-lg btn-block btnSubmit">{{$t('resetPasswordSubmit')}}</button>

            <!-- <p class="forgot-password text-right mt-2 mb-4">
                <router-link to="/Register">Dont have an account?</router-link>
            </p> -->

            <!--<p class="typo__p" v-if="submitStatus === 'OK'">Please check your inbox!</p> -->
            <p class="typo__p" v-if="submitStatus === 'ERROR'">Please fill the form correctly.</p>
            <!--<p class="typo__p" v-if="submitStatus === 'PENDING'">Sending...</p> -->
        </form>

    </div>
</template>


<script>

import { required, sameAs } from 'vuelidate/lib/validators'
import { BIconEyeFill, BIconEyeSlashFill, BIconExclamationTriangleFill } from 'bootstrap-vue'

export default {
     data() {
        return {
            submitStatus: null,
            pwReset: {
                current: "",
                password: "",
                repeat: ""
            },
            shake: false,
            good: "",
            hideCurrent: true,
            hidePassword: true,
            hideRepeat: true,          
        }	
	},
    computed: {
        config(){
            return window.config;
        },
    },
    components: {
        BIconEyeFill,
        BIconEyeSlashFill,
        BIconExclamationTriangleFill
    },
    validations: {
         pwReset: {
            current: {                
                required,
            },
            password: {
                required                      
            },
            repeat: {
                required,      
                sameAsPassword : sameAs('password')     
            }
        },        
    },
    methods: { 
        // AP 20220506
        showCurrent() {
            this.hideCurrent = !this.hideCurrent;
            return;
        },
        showPassword() {
            this.hidePassword = !this.hidePassword;
            return;
        },
        showRepeat() {
            this.hideRepeat = !this.hideRepeat;
            return;
        },
        goBack(){
            this.$router.go(-1);
        },
        onSubmit: function(event) {
			event.preventDefault();
           
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.submitStatus = 'ERROR';
                return;
            } else {
                this.submitStatus = 'OK'
            }
            
            var config = window.config;
            var vueInstance = this;
            var url = config.API_BASE + 'TCPChangePasswordSubmitJ.action';     

            var params = {     
                json: "true",           
                currentPassword: this.pwReset.current,
                password: this.pwReset.password,
                passwordConfirmation: this.pwReset.repeat
            };

            if(window.xtoken != undefined)
            {
                params.xsrfToken = btoa(window.xtoken);
            }

            // eslint-disable-next-line no-unused-vars
            var callbackSuccess= function(result, textStatus, jqXHR){        
                if(result.errorCode < 0)
                {
                    vueInstance.$alert(result.errorMsg);
                    return;
                    //failed, show errormsg
                }

                if(result.exception != undefined)
                {
                    vueInstance.$alert(vueInstance.$t('customerRegistrationSubmitError17'));
                    return;
                }
                
                // vueInstance.$alert(result.msg);
                vueInstance.$router.push({ path: '/Account' });          
            }

            // eslint-disable-next-line no-unused-vars
            var callbackError = function(result, textStatus, jqXHR){  
            }
            
            vueInstance.makeRequest("POST", url, JSON.stringify(params), callbackSuccess, callbackError);
			this.shake = false;   
		}
    },
    beforeMount() {
        // var config = window.config;
        // var vueInstance = this;
        // var url = config.API_BASE + 'TCPMenuJ.action';     

        // var params = {
        //     includeAllergenIds: "true",
        //     includePropertyIds: "true",
        //     includeNutriValues: "true",
        //     checkPickupTime: "true",
        //     addChildren: "true",
        //     json: "true",
        //     timestampIn: "2018-10-08T06:57:22",
        //     includeBasketItems: "true" 
        // };

        // var callbackSuccess= function(result, textStatus, jqXHR){       
        //     if(result.exception !== undefined)
        //     {
        //         vueInstance.$alert("Menu not available: ");
        //         return;
        //     }
            
        //     console.log(result);            
        //     console.log(textStatus);
        //     console.log(jqXHR);
        //     vueInstance.MenuGroups = result.menuTop.items;
        // }

        // var callbackError = function(result, textStatus, jqXHR){
        //     console.log(result);
        //     console.log(textStatus);
        //     console.log(jqXHR);
        // }
     
        // vueInstance.makeRequest("GET", url, JSON.stringify(params), callbackSuccess, callbackError);
    }   
}
</script>

<style scoped>

</style>