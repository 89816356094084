<template>
    <div class="vueselect-image-wrapper">
        <v-select :options="options" @input="onSelectChange" :value="selected" label="title">
            <template v-slot:option="option">
                <span :class="option.icon"></span>{{ option.title }}          
            </template>
            <template slot="selected-option" slot-scope="option" :value="option.level">
                <span :class="option.icon"></span>
            </template>
        </v-select>
    </div>
</template>

<script>

import vselect from 'vue-select'

export default {
    data() {
        return {
            selected: ""
        }
    },
    name:"select-picker-image",
    props: {
        options:{
            type: Array,
            required: true
        },
        callback: Function,
        value: {
            type: String,
            default: ""
        }        
    },
    components: {
        'v-select' : vselect
    },
    methods:{
        onSelectChange(element){            
            this.selected = element;
            if(this.callback != undefined)
            {
                if(element != undefined)
                {
                    this.callback(element.value);
                }
                else
                {
                    this.callback(null);
                }                
            }            
        }
    }
}
</script>

<style>

.vueselect-image-wrapper .vs--searchable .vs__dropdown-toggle {
    height:50px;
    width:80px;       
    margin-right:0px;
}

.vs__clear {
    display:none;
}

</style>

<style scoped>


</style>