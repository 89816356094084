<template>
   <div id="ControlGroup" class="controlGroup btn-group justify-content-center">
       <div id="ControlGroupButtonCluster" class="justify-content-center">
           <select-picker-image :options="config.LANGUAGE_LIST" :callback="changeLocale"></select-picker-image>
           <button id="BtnAccount" class="btn btn-secondary justify-content-center" @click="goToAccount"><span class="gg-user justify-content-center"></span></button>
           <button id="BtnShoppingCart" class="btn btn-secondary justify-content-center" @click="goToCart"><span class="gg-shopping-cart justify-content-center"></span></button>
       </div>
   </div>
</template>

<script>
import SelectPickerImage from '../sub-components/SelectPickerImage.vue'
export default {
  components: { SelectPickerImage },
    name:"control-group",
    props:  {
        changeLocale: {
            Type: Function,
            required: true
        },
        config: {
            Type: Array
        }
    },
    methods: {
        goToAccount() {
            //avoid redundant navigation since its throws a exception in the console of the site
            if(!(this.$route.path == '/Account'))
            {
                this.$router.push({ path: '/Account' });
            }            
        },
        goToCart() {
            //avoid redundant navigation since its throws a exception in the console of the site
            if(!(this.$route.path == '/Cart'))
            {
                this.$router.push({ path: '/Cart' });
            }            
        }
    }
}
</script>

<style scoped>
.btn-secondary {
    height:50px;
    width:50px;
}

button {
    text-align:center;
    margin:0px;
}

.gg-user {
   height:20px;
   margin-left:5px;
   align-content: center;
}
</style>