<template>
    <div name="footer-links" id="footer-links">
        <footer-link v-for="link in links" :key="link.Key" :link="link">
        </footer-link>
    </div>
</template>

<script>
import FooterLink from '../sub-components/FooterLink.vue'

export default {
    name:"footer-links",
    props:{
        links: {
            type: Array,
            required: true,
            default: () => []
        }
    },
    components:
    {
        FooterLink
    }
}
</script>

<style scoped>
#footer-links {
    display:inherit;
}
</style>