<script src="https://gist.github.com/Jonarod/7ff2fe4f81aae39e431aa7a08ce815bc.js"></script>

<template>
  <label class="wrapper flex items-center" >
    <span v-if='hyperlink == undefined || hyperlink == ""' v-html="label" :class="biggerCheckbox ? 'labelBig' : ''"></span>
    <a v-else :target="newTab ? '_blank' : '_self'" :href="hyperlink" v-html="label" :class="biggerCheckbox ? 'labelBig darkLink' : 'darkLink'"></a>
    <input class="checkbox" type="checkbox" :checked="isChecked" :value="value" @change="updateInput"/>
    <span  class="checkmark" :class="biggerCheckbox ? 'checkmarkBig' : ''" v-html="checkedMarker"></span>
  </label>
</template>

<script>
export default {
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    "value": "",
    "modelValue": { default: "" },
    "label": { type: String, required: true},
    "trueValue": { default: true },
    "falseValue": { default: false },
    available: { Type:Number },
    hyperlink: "",
    dataOpCnt: {Type:Number},
    biggerCheckbox: false,
    newTab: false,
  },
  computed: {
    isChecked() {
      if (this.modelValue instanceof Array) {
        return this.modelValue.includes(this.value)
      }
      // Note that `true-value` and `false-value` are camelCase in the JS
      return this.modelValue === this.trueValue
    },
    checkedMarker() {
      return window.config.CHECKBOX_INNER_SYMBOL;
    }
  },
  methods: {
    updateInput(event) {
      let isChecked = event.target.checked
      if (this.modelValue instanceof Array) {
        let newValue = [...this.modelValue]
        if (isChecked) {
          newValue.push(this.value)
        } else {
          if(newValue.indexOf(this.value) != -1){
            newValue.splice(newValue.indexOf(this.value), 1) 
          } else {
            let index = newValue.findIndex((val) => JSON.stringify(val) == JSON.stringify(this.value));
            if(index != -1){
              newValue.splice(index, 1)      
            }
          }        }
        this.$emit('change', newValue)
      } else {
        this.$emit('change', isChecked ? this.trueValue : this.falseValue)
      }
    }
  },
  mounted() {
      console.log(this.label);
      console.log(this.hyperlink);
  }
}
</script>

<style lang="postcss" scoped>
/* Customize the label (the wrapper) */
.wrapper {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  padding-right: 35px;
  margin-bottom: 6px;
  cursor: pointer;
  font-size: 27px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 16px;
  max-width:500px;
  width:250px;
}
/* Hide the browser's default checkbox */
.wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 21px;
  width: 21px;
  border-radius: 2px;
  background-color: var(--background-color);
  border: 0.5px solid darkgray;
  color: transparent;
  font-size: 20px;
  line-height: 0.9;
}

/* Create a bigger checkbox */
.checkmarkBig {
  height: 25px;
  width: 25px;
  border-width: 3px;
}

.labelBig {
  font-weight: bold;
}

/* On mouse-over, add a grey background color */
.wrapper:hover input ~ .checkmark {
  color: white;
  background-color: var(--main-color);
}
@media screen and (max-width:800px) {
  .wrapper:hover input ~ .checkmark {
    color: transparent;
    background-color: var(--background-color);
  }  
}
/* On mouse-over, add a grey background color */
.wrapper input ~ .checkmark {
  color: transparent;
  background-color: var(--background-color);
}
/* When the checkbox is checked, add a blue background */
.wrapper input:checked ~ .checkmark {  
  background-color: white;
  cursor: pointer;
  color:var(--main-color);
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  /* content: ""; */
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.wrapper input:checked ~ .checkmark:after {
  display: block;
}
/* Style the checkmark/indicator */
.wrapper .checkmark:after {
  left: 7px;
  top: 0px;
  width: 7px;
  height: 15px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.btn-success{
  display:none;
}
.wrapper input[type=checkbox]+label::after {
  content: "\2713";
  /* color: #000; */
  text-align: center;
  opacity: 0;
  position: absolute;
  left: 2px;
  transform: rotate(45deg);
  font-weight: bold;
}
.wrapper input[type=checkbox]:checked+label {
  transform: rotate(-45deg);
  /* Testing purpose */
  cursor: pointer;
  /* doesnt work like supposed to */
}
</style>