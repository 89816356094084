<template>
    <div class="col-12 flowWrapper" v-if="!tableMode && config.SHOW_FLOWPOINT_INDICATOR">
    <div class="col-12 flowPointContainer" style="padding:0;">
        <div class="flowPoint" :class="(currentPoint >= 1) && activeFlowPoint" style="text-align:right"  @click="pointClick(1)">
            <a>
                <i class="fa fa-shopping-basket" :class="(currentPoint == 1) && activePointImage"></i>
            </a>
            <div class="bar-right"></div>
        </div>

        <div class="flowPoint" :class="(currentPoint >= 2) && activeFlowPoint"  @click="pointClick(2)">
            <div class="bar-left"></div>
            <a>
                <i class="fa fa-eye" :class="(currentPoint == 2) && activePointImage"></i>                
            </a>
            <div class="bar-right"></div>
        </div>

        <div class="flowPoint" :class="(currentPoint >= 3 && isDelivery) && activeFlowPoint"  @click="pointClick(3)">
            <div class="bar-left"></div>
            <a>
                <i class="fa fa-envelope-open" :class="(currentPoint == 3) && activePointImage"></i>
            </a>
            <div class="bar-right"></div>
        </div>

        <div class="flowPoint" :class="(currentPoint >= 4) && activeFlowPoint" @click="pointClick(4)">
            <div class="bar-left"></div>
            <a >
                <i class="fa fa-credit-card" :class="(currentPoint == 4) && activePointImage"></i>
            </a>
            <div class="bar-right"></div>
        </div>
        
        <div class="flowPoint" :class="(currentPoint >= 5) && activeFlowPoint"  style="text-align:left" @click="pointClick(5)">
            <div class="bar-left"></div>
            <a>
                <i class="fa fa-ticket" :class="(currentPoint == 5) && activePointImage"></i>
            </a>
        </div>

    </div>
    <div class="col-12 flowPointContainer flowPointLabels" style="padding:0;">
        <div class="flowPoint flowPointLabel" :class="(currentPoint >= 1) && activeFlowPoint" @click="pointClick(1)">
            {{ $t('flowPoint1') }}
        </div>

        <div class="flowPoint flowPointLabel" :class="(currentPoint >= 2) && activeFlowPoint" @click="pointClick(2)">
            {{ $t('flowPoint2') }}
        </div>

        <div class="flowPoint flowPointLabel" :class="(currentPoint >= 3 && isDelivery) && activeFlowPoint" @click="pointClick(3)">
            {{ $t('flowPoint3') }}
        </div>

        <div class="flowPoint flowPointLabel" :class="(currentPoint >= 4) && activeFlowPoint" @click="pointClick(4)">
            {{ $t('flowPoint4') }}
        </div>
        
        <div class="flowPoint flowPointLabel" :class="(currentPoint >= 5) && activeFlowPoint" @click="pointClick(5)">
            {{ $t('flowPoint5') }}
        </div>
    </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            activeFlowPoint : "flowPointActive",
            activePointImage : "flowPointActiveImage",
            tableMode: false,
            isDelivery: false,
        }
    },
    props:{
        currentPoint: {
            Type:Number
        }
    },
    computed:{
        config(){
            return window.config;
        },
    },
    mounted(){
        var vueInstance = this;
        setTimeout(() => {                        
            vueInstance.tableMode = window.TableMode;
            vueInstance.isDelivery = window.orderType == "delivery" ? true : false;
        }, 100);
    },
    methods: {
        pointClick(index) {            
            switch(index) {
                case 1: {
                    this.$router.push({ path: '/Menu' });
                    break;
                }
                case 2: {
                    this.$router.push({ path: '/OrderConfirmation' });
                    break;
                }
                case 3: {
                    if(window.orderType != "dinein" && window.orderType != "takeaway")
                        this.$router.push({ path: '/Address' });
                    break;
                }
                case 4: {
                    this.$router.push({ path: '/Payment' });
                    break;
                }
                case 5: {      
                    break;
                }
            }
        }
    }
}
</script>

<style scoped>
.flowPoint {
    width:20%;
    display:inline-block;
    color:#bebebe;
    padding:10px 0;
    border:none;
    margin-left:0px;
    margin-right:0px;
    padding-left:0px;
    font-size:30px;
    padding-right:0px;
}

.flowPoint:hover{
    cursor:pointer;
}

.flowPointLabel { 
    font-size:14px;
}

.flowWrapper {
    margin-top:50px;
    margin-bottom:30px;
    padding:0;
}

@media screen and (max-width: 770px) {
    .flowPointLabel { 
        font-size:10px;
    }    

    .flowPointLabels {
        display:none;
    }

    .flowWrapper {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

.flowPointActive {
    color: var(--main-color);
    border:none;
}



.flowPointActiveImage {
    border-bottom:4px solid var(--main-color);
    padding-bottom:10px;
}

.bar-right, .bar-left {
    height:1px;
    width: calc(50% - 17px);
    background: #333;
    display:inline-block;
    border:none;
    margin-bottom: 10px;
    margin-left:0px;
    margin-right:0px;
    padding-left:0px;
    padding-right:0px;
}
</style>